import React, { Component } from "react";
//Styles
import { VerticalContainer } from "../../styles/GlobalStyles";

export default class BookImage extends Component {
  render() {
    return (
      <VerticalContainer
        style={{ width: this.props.width, height: this.props.height }}
      >
        <img
          src={this.props.src}
          style={{ width: "100%", maxHeight: this.props.height }}
        />
      </VerticalContainer>
    );
  }
}
