import React, { Component } from "react";
import styled from "styled-components";
//Component
import { RowContainer } from "../styles/GlobalStyles";
import {
  PrimaryGreen,
  PrimaryPurple,
  PrimaryRed,
  SvgBlue,
  White,
} from "../styles/Colors";
import { SpecBody } from "../styles/Typography";

class Tag extends Component {
  renderBackgroundColor = () => {
    if (this.props.backgroundColor) {
      switch (this.props.backgroundColor) {
        case "red":
          return PrimaryRed;
        case "purple":
          return PrimaryPurple;
        case "blue":
          return SvgBlue;
        case "green":
          return PrimaryGreen;
      }
    }

    return PrimaryGreen;
  };

  render() {
    return (
      <TagContainer
        style={{
          ...{
            backgroundColor: this.renderBackgroundColor(),
            height: this.props.height ? this.props.height : 50,
          },
          ...this.props.style,
        }}
      >
        {this.props.type === "narration" ? (
          <SpecBody style={{ color: White, fontSize: 14 }}>
            {this.props.text}
          </SpecBody>
        ) : (
          <SpecBody style={{ color: White }}>{this.props.text}</SpecBody>
        )}
      </TagContainer>
    );
  }
}

export default Tag;

const TagContainer = styled(RowContainer)`
  width: auto;
  height: auto;
  border-radius: 30px;
  padding: 0px 10px;
  margin: 5px;
`;
