import styled from "styled-components";
import { PrimaryGreen, SemanticBrown, SvgLightBlue } from "./Colors";

export const minBigLaptopBreakpoints = 1200;
export const minLaptopBreakpoints = 992;
export const minTabletBreakpoints = 768;
export const minMobileBreakpoints = 576;
export const bookInteractionAspectRatio = 19.5 / 9;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.div`
  background-color: ${PrimaryGreen};
  height: 5px;
  width: 10%;
  border-radius: 50px;
`;

export const FlexibleRowContainer = styled(RowContainer)`
  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    flex-direction: column;
  }
`;

export const FlexibleVerticalContainer = styled(VerticalContainer)`
  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    flex-direction: row;
  }
`;

export const LoginContainer = styled(VerticalContainer)`
  background-color: ${SvgLightBlue};
  height: 800px;
  width: 100%;
  max-width: 1440px;
`;

export const SemanticBackground = styled(VerticalContainer)`
  background-color: ${SemanticBrown};
`;

export const SemanticLayout = styled(SemanticBackground)`
  height: 75%;
  width: 95%;
  justify-content: flex-start;
  border-radius: 20px;
`;

export const Overlay = styled(VerticalContainer)`
  position: absolute;
  width: 100%;
  height: 100%;
`;
