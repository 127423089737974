import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import { connect } from "react-redux";
import { RowContainer, Overlay } from "../styles/GlobalStyles";
import { White, SemanticBrown } from "../styles/Colors";
//Assets
import LeftMarker from "../assets/icons/left.png";

const indicatorSize = 12;
let imageWidth = 0;
let imageHeight = 0;

class Indicator extends Component {
  render() {
    return (
      <CustomIndicator
        style={
          this.props.pos == this.props.currentTranslate
            ? {
                opacity: 1,
                borderColor: White,
                backgroundColor: White,
              }
            : {}
        }
      />
    );
  }
}

class BannerSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTranslate: "translateB",
      currentAnimation: ["center", "left", "right"],
      banners: [],
    };
  }

  componentDidMount = () => {
    this.interval = setInterval(() => this.handleTranslateNext(), 3500);
    if (
      this.props.dataStore.homeLoginItems &&
      this.props.dataStore.homeLoginItems.banners.length > 0
    )
      this.handleBannersState();
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dataStore.homeLoginItems &&
      this.props.dataStore.homeLoginItems.banners.length > 0 &&
      JSON.stringify(this.props.dataStore.homeLoginItems) !==
        JSON.stringify(prevProps.dataStore.homeLoginItems)
    )
      this.handleBannersState();
  }

  handleBannersState = () => {
    let banners = [];
    this.props.dataStore.homeLoginItems.banners.map((eachBanner) => {
      let imageName = eachBanner.imageName.split(" ").join("%20");
      banners.push(
        `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_banner%2F${imageName}?alt=media&token=${eachBanner.image}`
      );
    });
    this.setState({
      banners: banners,
    });
  };

  handleTranslateNext = () => {
    if (this.state.currentTranslate === "translateC")
      this.setState({
        currentTranslate: "translateA",
      });
    else if (this.state.currentTranslate === "translateB")
      this.setState({
        currentTranslate: "translateC",
      });
    else if (this.state.currentTranslate === "translateA")
      this.setState({
        currentTranslate: "translateB",
      });

    let newAnimation = [];
    this.state.currentAnimation.map((eachAnimation) => {
      switch (eachAnimation) {
        case "center":
          newAnimation.push("left");
          break;
        case "left":
          newAnimation.push("right");
          break;
        case "right":
          newAnimation.push("center");
          break;
      }
    });

    this.setState({
      currentAnimation: newAnimation,
    });
  };

  renderIndicators = () => {
    let translateId = ["translateA", "translateB", "translateC"];
    let indicatorView = [];

    translateId.map((eachTranslateId, i) => {
      indicatorView.push(
        <Indicator
          pos={eachTranslateId}
          key={i}
          currentTranslate={this.state.currentTranslate}
        />
      );
    });
    return indicatorView;
  };

  renderBanners = () => {
    if (this.props.utilsStore.deviceSize > 1440) imageWidth = 1440 * 0.6;
    else imageWidth = this.props.utilsStore.deviceSize * 0.6;
    imageHeight = imageWidth * 0.4;

    let bannerView = [];

    if (this.state.banners.length > 0)
      this.state.banners.map((eachBanner, index) => {
        let translateId;
        let translateType;
        switch (index) {
          case 0:
            translateId = "translateB";
            translateType = this.state.currentAnimation[0];
            break;
          case 1:
            translateId = "translateA";
            translateType = this.state.currentAnimation[1];
            break;
          case 2:
            translateId = "translateC";
            translateType = this.state.currentAnimation[2];
            break;
        }

        if (translateId)
          bannerView.push(
            <SliderImage
              key={index}
              style={{
                width: imageWidth,
                height: imageHeight,
                backgroundImage: `url(${eachBanner})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                position: "absolute",
                filter:
                  translateId === this.state.currentTranslate
                    ? null
                    : "brightness(50%) grayscale(100%)",
              }}
              type={translateType}
              time={1}
              value={imageWidth}
            />
          );
      });

    return bannerView;
  };

  render() {
    return (
      <RowContainer
        style={{
          width: "100%",
          maxWidth: 1440,
          overflow: "hidden",
          minHeight: imageHeight,
          backgroundColor: SemanticBrown,
          position: "relative",
        }}
      >
        {/* <Arrow
          src={LeftMarker}
          style={{ left: 10 }}
        /> */}
        {this.renderBanners()}
        <CustomIndicatorView>{this.renderIndicators()}</CustomIndicatorView>
        {/* <Arrow
          src={LeftMarker}
          style={{
            right: 10,
            transform: "rotate(180deg)",
          }}
        /> */}
      </RowContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataStore: state.dataStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(BannerSlider);

const bannerAnimation = (imageWidth, time, type) => {
  let animationFunction;
  switch (type) {
    case "left":
      animationFunction = moveToLeft(imageWidth);
      break;
    case "center":
      animationFunction = moveToCenter(imageWidth);
      break;
    case "right":
      animationFunction = moveToRight(imageWidth);
      break;
  }
  return css`
    ${animationFunction} ${time}s forwards;
  `;
};

const moveToLeft = (imageWidth) =>
  keyframes`
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(${-imageWidth}px);
    }
  `;

const moveToCenter = (imageWidth) =>
  keyframes`
    0% {
      transform: translateX(${imageWidth}px);
    }
    100% {
      transform: translateX(0px);
    }
  `;

const moveToRight = (imageWidth) =>
  keyframes`
    0% {
      transform: translateX(${-imageWidth}px);
    }

    50% {
      transform: translateX(${-imageWidth - imageWidth / 2}px);
    }

    51% {
      transform: translateX(${imageWidth + imageWidth / 5}px);
    }

    100% {
      transform: translateX(${imageWidth}px);
    }
  `;

const renderAnimationType = (props) => {
  if (props) return bannerAnimation(props.value, props.time, props.type);
  else return null;
};

const SliderImage = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  animation: ${(props) => renderAnimationType(props)};
`;

const CustomIndicator = styled.div`
  margin: 10px;
  width: ${indicatorSize}px;
  height: ${indicatorSize}px;
  border-radius: ${indicatorSize / 2}px;
  opacity: 0.9;
  border: 1px solid ${White};
`;

const CustomIndicatorView = styled(RowContainer)`
  position: absolute;
  background-color: transparent;
  bottom: 20px;
`;

const Arrow = styled.img`
  position: absolute;
  height: 80px;
  width: 80px;
  cursor: pointer;
  z-index: 3;
`;
