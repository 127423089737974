import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
//Components
import AnimatedWrapper from "../global/AnimatedWrapper";
import Button from "../global/Button";
//Actions
import { updateSelectedSeries } from "../store/actions/libraryActions";
import { PrimaryBlack, PrimaryRed, White } from "../styles/Colors";
import {
  Divider,
  RowContainer,
  VerticalContainer,
} from "../styles/GlobalStyles";
//Styles
import { SliderIcon, SliderTitleRowContainer } from "../styles/SliderStyles";
import { Body, SpecBody, Title4 } from "../styles/Typography";

class SeriesIcon extends Component {
  renderContent = () => {
    let uri = this.props.imageUrl;
    let divSize;
    if (this.props.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.deviceSize;

    let imageHeight = divSize * 0.15;

    return (
      <CustomAnimatedWrapper
        style={{ width: "15%", marginLeft: "2%", marginRight: "2%" }}
        onClick={this.props.onClick}
      >
        <SeriesImage src={uri} style={{ height: imageHeight }} />
        <SpecBody
          style={{
            color: this.props.userAuth ? White : PrimaryBlack,
            marginTop: 20,
            marginBottom: 20,
            textAlign: "center",
          }}
        >
          {this.props.name}
        </SpecBody>

        {!this.props.userAuth && (
          <Button style={{ width: "100%" }} buttonText="Read" />
        )}
      </CustomAnimatedWrapper>
    );
  };

  render() {
    if (this.props.imageUrl) return this.renderContent();
    else return null;
  }
}

class SeriesSlider extends Component {
  handleSeriesSelected = (path, selectedSeries) => {
    if (this.props.authStore.userAuth) {
      if (selectedSeries) {
        this.props.updateSelectedSeries(selectedSeries);
        this.props.history.push(path + `/${selectedSeries.id}`);
        window.scrollTo({ top: 115, behavior: "smooth" });
      } else this.props.history.push(path);
    } else this.props.history.push("/auth/signin");
  };

  renderSeriesData = () => {
    let count = 0;
    let seriesView = [];

    if (this.props.data && this.props.data.length > 0)
      for (let i = 0; i < this.props.data.length; i++) {
        if (this.props.type === "exclude")
          seriesView.push(
            <SeriesIcon
              key={i}
              imageUrl={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${this.props.data[i].id}%2Fimage.png?alt=media&token=${this.props.data[i].image}`}
              name={this.props.data[i].name}
              userAuth={this.props.authStore.userAuth}
              deviceSize={this.props.utilsStore.deviceSize}
              onClick={this.handleSeriesSelected.bind(
                this,
                "/series",
                this.props.data[i]
              )}
            />
          );
        else {
          let eachSeries = this.props.data[i];

          if (eachSeries.tags.includes(this.props.tagId) && count < 5) {
            let imageUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${eachSeries.id}%2Fimage.png?alt=media&token=${eachSeries.image}`;

            seriesView.push(
              <SeriesIcon
                key={i}
                imageUrl={imageUrl}
                name={eachSeries.name}
                userAuth={this.props.authStore.userAuth}
                deviceSize={this.props.utilsStore.deviceSize}
                onClick={this.handleSeriesSelected.bind(
                  this,
                  "/series",
                  this.props.data[i]
                )}
              />
            );

            count += 1;
          }
        }
      }

    return seriesView;
  };

  render() {
    return (
      <CustomVerticalContainer style={this.props.style}>
        {this.props.authStore.userAuth ? (
          <SliderTitleRowContainer style={{ marginTop: 0, marginBottom: 30 }}>
            <RowContainer>
              <SliderIcon
                style={this.props.iconStyle}
                resizemode="contain"
                src={this.props.image}
              />
              <Title4 style={{ color: PrimaryRed }}>{this.props.title}</Title4>
            </RowContainer>
            <Divider
              style={{
                height: 4,
                flexGrow: 1,
                marginLeft: 20,
                marginRight: 30,
              }}
            />
          </SliderTitleRowContainer>
        ) : (
          <Body style={this.props.titleStyle}>{this.props.title}</Body>
        )}
        <RowContainer style={{ width: "100%" }}>
          {this.renderSeriesData()}
        </RowContainer>
      </CustomVerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    series: state.dataStore.series,
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedSeries: (seriesData) =>
      dispatch(updateSelectedSeries(seriesData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SeriesSlider));

const CustomVerticalContainer = styled(VerticalContainer)`
  padding-top: 15px;
  width: 100%;
  max-width: 1440px;
`;

const SeriesImage = styled.img`
  width: 100%;
  border-radius: 200px;
`;

const CustomAnimatedWrapper = styled(AnimatedWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
