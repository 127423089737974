const initState = {
  selectedChildReport: null,
  childAnalytics: [],
  childAnalyticsPagination: "",
  childNarration: [],
  childNarrationPagination: "",
  parentPriviledge: false,
};

const childReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_SELECTED_CHILD_REPORT":
      return {
        ...state,
        selectedChildReport: action.payload.report,
      };
    case "UPDATE_CHILD_ANALYTICS":
      return {
        ...state,
        childAnalytics: action.payload.childAnalytics,
        childAnalyticsPagination: action.payload.childAnalyticsPagination,
      };
    case "UPDATE_CHILD_NARRATION":
      return {
        ...state,
        childNarration: action.payload.childNarration,
        childNarrationPagination: action.payload.childNarrationPagination,
      };
    case "UPDATE_CHILD_PRIVILEDGE":
      return {
        ...state,
        parentPriviledge: action.payload.parentPriviledge,
      };
    default:
      return state;
  }
};

export default childReducer;
