import styled from "styled-components";
import { RowContainer, minMobileBreakpoints } from "./GlobalStyles";

export const SliderTitleRowContainer = styled(RowContainer)`
  margin: 5px;
  width: 100%;
`;

export const SliderIcon = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 20px;

  @media only screen and (max-width: ${minMobileBreakpoints - 1}px) {
    width: 50px;
    height: 50px;
  }
`;
