import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
//Styles
import {
  SubTitle2,
  Title3,
  SubButtonHeadline,
  Footnote,
} from "../../styles/Typography";
import {
  PrimaryRed,
  PrimaryGreen,
  White,
  PrimaryPurple,
} from "../../styles/Colors";
import {
  RowContainer,
  VerticalContainer,
  SemanticLayout,
  Overlay,
} from "../../styles/GlobalStyles";
//Components
import TextInput from "../../global/TextInput";
import Button from "../../global/Button";
import AvatarSelection from "../../global/loginComponents/AvatarSelection";
//Assets
import ErrorSound from "../../assets/audio/utils_error_audio.mp3";
import DeleteVisual from "../../assets/images/lastPage/deleteConfirmation.png";
//Utils
import { childAvatarList } from "../../utils/avatarList";
import { avatarSoundList } from "../../utils/avatarSoundList";
//Actions
import {
  updateCurrentChildAttribute,
  deleteChild,
} from "../../store/actions/childActions";
import { updateSelectedChild } from "../../store/actions/authActions";

class EditChildProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameError: "",
      selectedAvatar: 0,
      removeProfileModal: false,
    };
    this.errorAudio = new Audio(ErrorSound);
  }

  componentDidMount = async () => {
    this.handleSelectedChildAvatar();
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.authStore.children) !==
      JSON.stringify(prevProps.authStore.children)
    )
      this.handleSelectedChildAvatar();
  }

  handleSelectedChildAvatar = () => {
    if (
      this.props.renderSelectedChildAttribute("avatar") !==
      this.state.selectedAvatar
    )
      this.setState({
        selectedAvatar: this.props.renderSelectedChildAttribute("avatar"),
      });
  };

  handleDeleteChildModal = (bool) => {
    if (
      this.props.authStore.children &&
      this.props.authStore.children.length > 1
    )
      this.setState({
        removeProfileModal: true,
      });
    else
      this.setState({
        nameError: "This is the only child left in your profile",
      });
  };

  handleDeleteChild = () => {
    let selectedChild = this.props.authStore.selectedChild;
    let nextChildId = "";
    this.props.authStore.children.map((eachChild) => {
      if (eachChild.id !== selectedChild) nextChildId = eachChild.id;
    });

    this.props.updateSelectedChild(nextChildId);
    this.props.deleteChild(selectedChild);
    this.props.history.push("/");
    this.setState({
      removeProfileModal: false,
    });
  };

  handleSubmit = (type) => {
    this.setState({
      nameError: "",
    });

    if (this.props.authStore.children.length <= 0) return;

    switch (type) {
      case "name":
        if (this.state.name === "") {
          this.errorAudio.currentTime = 0;
          this.errorAudio.play();
          this.setState({
            nameError: "Please enter your first name",
          });
        } else {
          this.props.updateCurrentChildAttribute(
            this.props.renderSelectedChildAttribute("index"),
            "name",
            this.state.name
          );
          this.setState({
            name: "",
          });
        }
        break;
    }
  };

  handleChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleAvatarSelected = async (avatarIndex) => {
    if (this.props.authStore.children.length > 0) {
      this.setState(
        {
          selectedAvatar: avatarIndex,
        },
        async () => {
          let soundAvatarLength = avatarSoundList.length;
          if (avatarIndex <= soundAvatarLength - 1) {
            let selectedAvatarSound = avatarSoundList[avatarIndex];
            let selectedAvatarSoundLength =
              selectedAvatarSound.voiceList.length;
            let selectedSoundIndex = Math.floor(
              Math.random() * selectedAvatarSoundLength
            );
            let selectedSound =
              selectedAvatarSound.voiceList[selectedSoundIndex];
            this.audio = new Audio(selectedSound);
            this.audio.pause();
            this.audio.currentTime = 0;
            this.audio.play();
          }
          this.props.updateCurrentChildAttribute(
            this.props.renderSelectedChildAttribute("index"),
            "avatar",
            this.state.selectedAvatar
          );
        }
      );
    }
  };

  render() {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    let imageSize;
    if (divSize > 1200) imageSize = 120;
    else imageSize = 100;

    let scale = 0.9;
    let svgWidth = 830 * scale;
    let svgHeight = 460 * scale;
    let fill = PrimaryPurple;
    let stroke = PrimaryGreen;

    return (
      <VerticalContainer style={{ width: "100%" }}>
        {this.state.removeProfileModal && (
          <Overlay
            style={{
              width: "100vw",
              height: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 20,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <svg
              height={svgHeight}
              width={svgWidth}
              style={{ position: "absolute" }}
            >
              <path
                d="M10.4744 258.535C17.3051 234.819 33.9549 129.034 41.4259 79.1059H63.8389H92.6558C99.4154 71.3046 118.057 53.5177 138.549 44.7803C164.165 33.8586 158.828 32.2982 254.885 12.0148C332.797 -8.26852 292.24 12.0148 306.115 12.0148H407.508C437.392 12.0148 593.217 24.4969 634.841 32.2982C676.466 40.0994 676.466 46.3405 713.821 55.702C751.176 65.0635 757.58 90.0276 768.253 100.949C778.926 111.871 817.349 272.578 822.685 288.18C828.021 303.783 763.984 375.555 749.042 405.199C734.1 434.844 695.677 427.043 644.447 450.447C593.217 473.851 448.065 427.043 391.498 427.043H100.127C65.9735 427.043 56.368 420.802 25.4165 416.121C-5.53501 411.441 1.93605 288.18 10.4744 258.535Z"
                fill={fill}
                stroke={stroke}
                strokeWidth="5"
                style={{ transform: `scale(${scale})` }}
              />
            </svg>
            <SubButtonHeadline
              style={{
                color: White,
                width: 580,
                textAlign: "center",
                position: "absolute",
                top: window.innerHeight / 2 - svgHeight / 2 - 60,
              }}
            >
              Would you like to proceed to{" "}
              <SubButtonHeadline style={{ textDecorationLine: "underline" }}>
                delete{" "}
              </SubButtonHeadline>
              this profile?
            </SubButtonHeadline>
            <RowContainer
              style={{ marginLeft: -120, marginTop: 25, zIndex: 5 }}
            >
              <img
                src={DeleteVisual}
                style={{
                  width: 250,
                  height: 250,
                }}
              />
              <BigText>
                All Achievements and Reading Progress Will Be Gone Forever ...
              </BigText>
            </RowContainer>
            <RowContainer>
              <Button
                style={{ marginRight: 20 }}
                buttonText="Yes"
                onClick={this.handleDeleteChild}
              />
              <Button
                buttonText="Back"
                onClick={() => {
                  this.setState({
                    removeProfileModal: false,
                  });
                }}
              />
            </RowContainer>
          </Overlay>
        )}

        <RowContainer style={{ marginTop: 50 }}>
          <VerticalContainer>
            <img
              src={
                childAvatarList[
                  this.props.renderSelectedChildAttribute("avatar")
                ]
              }
              style={{
                height: imageSize,
                width: imageSize,
              }}
            />
            <Title3 style={{ color: White, margin: 10 }}>
              {this.props.renderSelectedChildAttribute("name")}
            </Title3>
          </VerticalContainer>

          <VerticalContainer style={{ marginLeft: 50 }}>
            <TextInput
              style={{ width: 300 }}
              value={this.state.name}
              placeholder="First Name"
              onChange={this.handleChange}
              id="name"
              autoCapitalize="none"
              maxLength={15}
            />
            {this.state.nameError && (
              <Footnote style={{ color: PrimaryRed }}>
                {this.state.nameError}
              </Footnote>
            )}
          </VerticalContainer>

          <VerticalContainer
            style={{
              marginLeft: 50,
            }}
          >
            <Button
              style={{
                width: 250,
                marginTop: 10,
              }}
              buttonText="Edit Profile Name"
              onClick={this.handleSubmit.bind(this, "name")}
            />
            {this.props.type === "parentDashboard" && (
              <Button
                style={{
                  width: 250,
                  backgroundColor: "#fff",
                  marginTop: 20,
                }}
                textStyle={{ color: PrimaryRed }}
                buttonText="Remove Profile"
                onClick={this.handleDeleteChildModal.bind(this, true)}
              />
            )}
          </VerticalContainer>
        </RowContainer>
        <SemanticLayout
          style={{
            marginTop: this.props.type === "childProfile" ? 50 : 0,
            height: "auto",
            maxWidth: 1000,
          }}
        >
          <SubTitle2
            style={{
              color: PrimaryGreen,
              marginTop: 40,
            }}
          >
            Change Avatar
          </SubTitle2>
          <AvatarSelection
            type="child"
            onChange={this.handleAvatarSelected}
            selectedAvatar={this.state.selectedAvatar}
            borderColor={PrimaryRed}
            tintColor={PrimaryRed}
            style={{ marginBottom: 40 }}
          />
        </SemanticLayout>
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedChild: (childId) => dispatch(updateSelectedChild(childId)),
    updateCurrentChildAttribute: (childIndex, attrType, attrValue) =>
      dispatch(updateCurrentChildAttribute(childIndex, attrType, attrValue)),
    deleteChild: (childId) => dispatch(deleteChild(childId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditChildProfile)
);

const BigText = styled(SubButtonHeadline)`
  font-size: 50px;
  color: ${White};
  letter-spacing: 5px;
  line-height: 60px;
  width: 45%;
`;
