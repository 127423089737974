import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import DownIcon from "../assets/icons/downIcon.png";
//Assets
import PnmLogo from "../assets/images/header/pnmLogo.png";
import AnimatedWrapper from "../global/AnimatedWrapper";
//Components
import Button from "../global/Button";
import AuthModal from "../modal/AuthModal";
//Actions
import {
  signOut,
  updateLanguagePreferences,
} from "../store/actions/authActions";
import { DarkGrey, LightGrey, PrimaryGreen, White } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/GlobalStyles";
//Styles
import { Headline, SubHeadline } from "../styles/Typography";
//Utils
import { parentAvatarList } from "../utils/avatarList";

const menuLeftMargin = 50;

class Header extends Component {
  state = {
    authModal: false,
  };

  handleNavigate = (type) => {
    if (type === "signIn") this.props.history.push("/auth/signin");
    else if (type === "signUp") this.props.history.push("/auth/signup");
    else if (type === "home") this.props.history.push("/");
    else if (type === "help") this.props.history.push("/support");
    else if (type === "logout") {
      this.props.signOut();
      this.props.history.push("/");
    }
  };

  handleLanguagePreferences = (language) => {
    this.props.updateLanguagePreferences(language);
  };

  handleAuthModal = () => {
    this.setState({
      authModal: !this.state.authModal,
    });
  };

  renderColor = (type) => {
    let color = "";
    if (this.props.authStore.userLanguage === type) color = PrimaryGreen;
    return color;
  };

  renderMenuItems = () => {
    if (this.props.utilsStore.deviceType === 2)
      return (
        <RowContainer>
          <SubHeadline
            style={{ marginLeft: menuLeftMargin, fontWeight: 900 }}
            onClick={this.handleNavigate.bind(this, "help")}
          >
            Help
          </SubHeadline>
          <SubHeadline
            style={{ marginLeft: menuLeftMargin, fontWeight: 900 }}
            onClick={this.handleNavigate.bind(this, "signUp")}
          >
            Sign Up
          </SubHeadline>
          <Button
            textStyle={{
              fontFamily: "visbyRound",
              fontWeight: 600,
              fontSize: 21,
            }}
            style={{ marginLeft: menuLeftMargin }}
            buttonText="Log in"
            onClick={this.handleNavigate.bind(this, "signIn")}
          />
        </RowContainer>
      );
    else
      return (
        <Button
          textStyle={{
            fontFamily: "visbyRound",
            fontWeight: 600,
            fontSize: 16,
          }}
          style={{ marginLeft: menuLeftMargin }}
          buttonText="Sign Up"
          onClick={this.handleNavigate.bind(this, "signUp")}
        />
      );
  };

  renderParentAvatar = () => {
    let selectedAvatar = 0;
    if (this.props.authStore.user)
      selectedAvatar = this.props.authStore.user.avatar;

    return selectedAvatar;
  };

  renderParentsHeader = () => {
    if (this.props.utilsStore.deviceType === 2)
      return (
        <AnimatedWrapper onClick={this.handleAuthModal}>
          <RowContainer>
            <img
              style={{
                marginLeft: menuLeftMargin,
                marginRight: 20,
                width: 60,
              }}
              src={parentAvatarList[this.renderParentAvatar()]}
              alt="Selected Parent Avatar"
            />
            <VerticalContainer style={{ alignItems: "flex-start" }}>
              <Headline style={{ color: LightGrey }}>Parent</Headline>
              <RowContainer>
                <Headline style={{ color: DarkGrey }}>
                  {this.props.authStore.user && this.props.authStore.user.name}
                </Headline>
                <img
                  style={{ width: 20, height: 10, marginLeft: 10 }}
                  src={DownIcon}
                />
              </RowContainer>
            </VerticalContainer>
          </RowContainer>
        </AnimatedWrapper>
      );
    else
      return (
        <Button
          textStyle={{
            fontFamily: "visbyRound",
            fontWeight: 600,
            fontSize: 16,
          }}
          style={{ marginLeft: menuLeftMargin }}
          buttonText="Log Out"
          onClick={this.handleNavigate.bind(this, "logout")}
        />
      );
  };

  renderHeaderComponent = () => {
    if (!this.props.authStore.userAuth) return this.renderMenuItems();
    else if (this.props.authStore.userAuth) return this.renderParentsHeader();
  };

  render() {
    return (
      <>
        {this.state.authModal && (
          <AuthModal handleAuthModal={this.handleAuthModal} />
        )}
        <HeaderContainer>
          <RowContainer
            style={{
              width: "95%",
              maxWidth: 1368,
            }}
          >
            <img
              style={{
                width: this.props.utilsStore.deviceType === 0 ? 200 : 300,
                cursor: "pointer",
                marginLeft: 10,
              }}
              src={PnmLogo}
              alt="Perpustakaan Nasional Malaysia"
              onClick={this.handleNavigate.bind(this, "home")}
            />
            <div style={{ flexGrow: 1 }} />
            {this.renderHeaderComponent()}
          </RowContainer>
        </HeaderContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    updateLanguagePreferences: (language) =>
      dispatch(updateLanguagePreferences(language)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

const HeaderContainer = styled(RowContainer)`
  height: 115px;
  width: 100%;
  background-color: ${White};
`;
