import React, { Component } from "react";
import styled from "styled-components";
//Component
import { Overlay } from "../styles/GlobalStyles";
import { Body } from "../styles/Typography";

class Loading extends Component {
  render() {
    return (
      <LoadingContainer>
        <Loader />
        <Body
          style={{
            marginTop: 20,
          }}
        >
          Loading ...
        </Body>
      </LoadingContainer>
    );
  }
}

export default Loading;

const LoadingContainer = styled(Overlay)`
  background: rgba(247, 247, 252, 0.95);
  width: 100vw !important;
  height: 100vh !important;
  z-index: 20;
  position: fixed;
  left: 0px;
  top: 0px;
`;

const Loader = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #39bda1;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
`;
