import React, { Component } from "react";
import { connect } from "react-redux";
//Styles
import {
  RowContainer,
  VerticalContainer,
  SemanticLayout,
} from "../../styles/GlobalStyles";
//Components
import Tag from "../../global/Tag";
import { Title1, SubTitle2 } from "../../styles/Typography";
import StickerSignBoard from "./StickerSignBoard";
import Pagination from "../../global/Pagination";
//Assets
import StickerAchievement from "../../assets/images/childrenDashboard/stickerAchievement.png";
//Utils
import { childAvatarList } from "../../utils/avatarList";
//Actions
import { getStickers } from "../../store/actions/dataActions";

let stickerLimit = 10;
class ChildStickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCategory: "narration",
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.props.getStickers(this.state.currentCategory, "", true, stickerLimit);
  }

  handleCurrentCategory = (category) => {
    this.setState(
      {
        currentCategory: category,
        currentPage: 1,
      },
      () => {
        this.props.getStickers(
          this.state.currentCategory,
          "",
          true,
          stickerLimit
        );
      }
    );
  };

  handlePagination = (type) => {
    switch (type) {
      case "minus":
        if (this.state.currentPage > 1)
          this.setState({
            currentPage: this.state.currentPage - 1,
          });
        break;
      case "plus":
        if (
          this.props.stickerPagination &&
          this.props.stickers.length == this.state.currentPage * stickerLimit
        ) {
          this.props.getStickers(
            this.state.currentCategory,
            this.props.stickerPagination,
            false,
            stickerLimit
          );
          this.setState({
            currentPage: this.state.currentPage + 1,
          });
        } else if (
          this.props.stickers.length >
          this.state.currentPage * stickerLimit
        )
          this.setState({
            currentPage: this.state.currentPage + 1,
          });

        break;
    }
  };

  renderStickerHeader = () => {
    return (
      <RowContainer
        style={{
          margin: 25,
          marginBottom: 20,
          marginLeft: 50,
          justifyContent: "flex-start",
          alignSelf: "flex-start",
        }}
      >
        <img
          src={
            childAvatarList[this.props.renderSelectedChildAttribute("avatar")]
          }
          resizemode="contain"
          style={{
            width: 90,
            height: 90,
          }}
        />
        <VerticalContainer
          style={{
            marginLeft: 30,
            alignItems: "flex-start",
          }}
        >
          <SubTitle2 style={{ color: "#39BDA1", marginLeft: 10 }}>
            {this.props.renderSelectedChildAttribute("name")}
          </SubTitle2>
          <RowContainer>
            <Tag text="Voice-Over" width={150} />
            <Tag backgroundColor="purple" width={150} text="Stickers" />
          </RowContainer>
        </VerticalContainer>
      </RowContainer>
    );
  };

  renderChildStickersScreen = () => {
    let imageWidth;
    let marginTop = false;
    if (this.props.utilsStore.deviceSize > 1200) {
      imageWidth = 320;
      marginTop = true;
    } else {
      imageWidth = 230;
      marginTop = false;
    }

    return (
      <>
        <RowContainer
          style={{
            width: "80%",
            position: "relative",
            maxWidth: 1000,
          }}
        >
          {this.props.type === "childProfile" && (
            <>
              <img
                src={StickerAchievement}
                style={{
                  width: imageWidth,
                  position: "absolute",
                }}
              />
              <Title1
                style={{
                  color: "#fff",
                  marginTop: 30,
                  position: "absolute",
                }}
              >
                {this.props.renderSelectedChildAttribute("stickers")}
              </Title1>
            </>
          )}
          <div style={{ flexGrow: 1 }} />
          <StickerSignBoard
            type={this.props.type}
            currentCategory={this.state.currentCategory}
            handleCurrentCategory={this.handleCurrentCategory}
          />
        </RowContainer>
        <SemanticLayout
          style={{
            marginTop: marginTop ? 50 : 0,
            height: "auto",
            maxWidth: 1000,
          }}
        >
          {this.renderStickerHeader()}
          <Pagination
            type="sticker"
            data={this.props.stickers}
            limit={stickerLimit}
            currentPage={this.state.currentPage}
            handlePagination={this.handlePagination}
            nextAvailable={
              (this.props.stickerPagination &&
                this.props.stickers.length ==
                  this.state.currentPage * stickerLimit) ||
              this.props.stickers.length > this.state.currentPage * stickerLimit
            }
            renderSelectedChildAttribute={
              this.props.renderSelectedChildAttribute
            }
          />
        </SemanticLayout>
      </>
    );
  };

  render() {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        {this.renderChildStickersScreen()}
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
    stickers: state.dataStore.stickers,
    stickerPagination: state.dataStore.stickerPagination,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStickers: (type, startAfter, reset, limit) =>
      dispatch(getStickers(type, startAfter, reset, limit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildStickers);
