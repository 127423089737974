import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
//Styles
import { SubTitle4, Body, SubButtonHeadline } from "../styles/Typography";
import {
  White,
  PrimaryGreen,
  PrimaryRed,
  PrimaryPurple,
  SvgBlue,
} from "../styles/Colors";
import {
  RowContainer,
  VerticalContainer,
  Overlay,
} from "../styles/GlobalStyles";
//Component
import Button from "./Button";
import TextInput from "./TextInput";
//Assets
import DeleteVisual from "../assets/images/lastPage/deleteConfirmation.png";
import SaveNarration from "../assets/images/lastPage/saveNarration.png";
import GracefullyDone from "../assets/images/lastPage/gracefullyDone.png";
import DeleteGirl from "../assets/images/lastPage/delete.png";
//Utils
import EventBus from "js-event-bus";

const eventBus = EventBus();

class SaveModal extends Component {
  state = {
    title: "",
    titleError: "",
    loading: false,
  };

  componentDidMount() {
    eventBus.on("voice-over-loading", this.handleLoading);
  }

  componentWillUnmount() {
    eventBus.detach("voice-over-loading", this.handleLoading);
  }

  handleLoading = () => {
    this.setState({
      loading: true,
    });
  };

  handleSaveVoiceOver = () => {
    if (this.state.title) {
      this.setState({
        loading: true,
      });
      this.props.onSave(this.state.title, this.props.voiceOver);
    } else
      this.setState({
        titleError: "Please enter a file name",
      });
  };

  handleEditVoiceOver = () => {
    if (this.state.title) {
      this.setState({
        loading: true,
      });
      this.props.onEdit(this.state.title);
    } else
      this.setState({
        titleError: "Please enter a file name",
      });
  };

  handleChangeTitle = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  renderVisual = () => {
    if (this.props.voiceOver === "delete" || this.props.voiceOver === "leave")
      return DeleteGirl;
    if (this.props.voiceOver === "reset" || this.props.voiceOver === "load")
      return DeleteVisual;
    if (this.props.voiceOver === "save" || this.props.voiceOver === "saveLeave")
      return SaveNarration;
    if (this.props.voiceOver === "edit") return GracefullyDone;
  };

  renderModalContent = () => {
    if (
      this.props.voiceOver === "reset" ||
      this.props.voiceOver === "load" ||
      this.props.voiceOver === "delete" ||
      this.props.voiceOver === "leave"
    )
      return (
        <RowContainer style={{ marginLeft: -120, marginTop: 25 }}>
          <img
            src={this.renderVisual()}
            style={{
              width: 250,
              height: 250,
            }}
          />
          <BigText>
            {this.props.voiceOver === "delete"
              ? "Are You Sure You Want to Delete this Voice-Over?"
              : "Your Current Voice-Over is not saved!"}
          </BigText>
        </RowContainer>
      );
  };

  renderCallToActions = () => {
    return (
      <RowContainer style={{ marginTop: 10 }}>
        <Button
          style={{ marginRight: 20 }}
          buttonText="Yes"
          onClick={this.props.onConfirm.bind(this, this.props.voiceOver)}
        />
        <Button
          buttonText="No"
          onClick={
            this.props.voiceOver === "leave"
              ? this.props.onConfirmLeave
              : this.props.onCancel
          }
        />
      </RowContainer>
    );
  };

  renderContent = () => {
    switch (this.props.voiceOver) {
      case "save":
      case "saveLeave":
        let suggestionsTitle = [
          "My precious storytime with mama and papa",
          "Awesome moment with siblings",
          "My precious storytime with mama and papa",
        ];
        let titleList = [];

        suggestionsTitle.map((eachTitle, index) => {
          titleList.push(
            <SubTitle4
              key={index}
              style={{
                color: White,
                textDecoration: "underline",
                cursor: "pointer",
                margin: 5,
              }}
              onClick={() => {
                this.setState({
                  title: eachTitle,
                });
              }}
            >
              {eachTitle}
            </SubTitle4>
          );
        });

        return (
          <>
            <RowContainer style={{ marginLeft: -120 }}>
              <img
                src={this.renderVisual()}
                style={{
                  width: 250,
                  height: 250,
                }}
              />
              {this.state.loading ? (
                <VerticalContainer style={{ height: 250, width: 300 }}>
                  <SubTitle4 style={{ color: White }}>Loading...</SubTitle4>
                </VerticalContainer>
              ) : (
                <VerticalContainer style={{ marginLeft: 30 }}>
                  <SubTitle4 style={{ alignSelf: "flex-start", color: White }}>
                    File Name
                  </SubTitle4>
                  <TextInput
                    value={this.state.title}
                    maxLength={45}
                    onChange={this.handleChangeTitle}
                    style={{ width: 400 }}
                  />
                  <SubTitle4 style={{ color: PrimaryRed }}>
                    {this.state.titleError}
                  </SubTitle4>
                  <RowContainer style={{ marginTop: 20 }}>
                    <Button
                      buttonText="Save"
                      onClick={this.handleSaveVoiceOver}
                    />
                  </RowContainer>
                </VerticalContainer>
              )}
            </RowContainer>
            {!this.state.loading && (
              <VerticalContainer style={{ marginTop: 20 }}>
                <SubTitle4 style={{ color: White, marginBottom: 20 }}>
                  Or Save As Suggestions
                </SubTitle4>
                {titleList}
              </VerticalContainer>
            )}
          </>
        );
      case "edit":
        return (
          <RowContainer style={{ marginLeft: -100, marginTop: -160 }}>
            <img
              src={this.renderVisual()}
              style={{
                width: 250,
                height: 250,
              }}
            />
            {this.state.loading ? (
              <VerticalContainer style={{ height: 250, width: 300 }}>
                <SubTitle4 style={{ color: White }}>Loading...</SubTitle4>
              </VerticalContainer>
            ) : (
              <VerticalContainer
                style={{
                  marginLeft: 50,
                  alignItems: "flex-start",
                }}
              >
                <SubButtonHeadline
                  style={{
                    color: White,
                  }}
                >
                  Current File Name
                </SubButtonHeadline>
                <Body
                  style={{
                    color: White,
                    marginBottom: 15,
                  }}
                >
                  {this.props.editNarrationName}
                </Body>
                <TextInput
                  value={this.state.title}
                  maxLength={45}
                  onChange={this.handleChangeTitle}
                  style={{ width: 350 }}
                />
                <SubTitle4 style={{ color: PrimaryRed }}>
                  {this.state.titleError}
                </SubTitle4>
                <Button
                  buttonText="Save As"
                  style={{ marginTop: 15, width: 150 }}
                  onClick={this.handleEditVoiceOver}
                />
              </VerticalContainer>
            )}
          </RowContainer>
        );
      case "reset":
      case "load":
      case "delete":
      case "leave":
        return (
          <>
            {this.renderModalContent()}
            {this.renderCallToActions()}
          </>
        );
    }
  };

  renderTopMessages = (svgHeight) => {
    let callToAction = "";
    let subject = "";
    if (this.props.voiceOver === "reset") {
      callToAction = "reset";
      subject = " to original ";
    } else if (this.props.voiceOver === "load") {
      callToAction = "load";
      subject = " other ";
    } else if (this.props.voiceOver === "delete") {
      callToAction = "delete";
      subject = " this ";
    } else {
      callToAction = "save";
      subject = " this ";
    }

    return (
      <SubButtonHeadline
        style={{
          color: White,
          width: 480,
          textAlign: "center",
          position: "absolute",
          top: window.innerHeight / 2 - svgHeight / 2 - 60,
        }}
      >
        Would you like to proceed to{" "}
        <SubButtonHeadline style={{ textDecorationLine: "underline" }}>
          {callToAction}
        </SubButtonHeadline>
        {subject} voice-over(s)?
      </SubButtonHeadline>
    );
  };

  render() {
    let scale = 0.75;
    let svgWidth = 1000 * scale;
    let svgHeight = 850 * scale;
    let fill = PrimaryPurple;
    let stroke = PrimaryGreen;

    if (
      this.props.voiceOver === "reset" ||
      this.props.voiceOver === "load" ||
      this.props.voiceOver === "delete" ||
      this.props.voiceOver === "leave"
    ) {
      scale = 0.9;
      svgWidth = 830 * scale;
      svgHeight = 460 * scale;
      fill = SvgBlue;
      stroke = PrimaryGreen;
    }

    return (
      <Overlay
        style={{
          position: "fixed",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          width: "100vw",
          height: "100vh",
          top: 0,
          zIndex: 20,
        }}
      >
        <svg
          height={svgHeight}
          width={svgWidth}
          style={{ position: "absolute" }}
        >
          {this.props.voiceOver === "reset" ||
          this.props.voiceOver === "load" ||
          this.props.voiceOver === "delete" ||
          this.props.voiceOver === "leave" ? (
            <path
              d="M10.4744 258.535C17.3051 234.819 33.9549 129.034 41.4259 79.1059H63.8389H92.6558C99.4154 71.3046 118.057 53.5177 138.549 44.7803C164.165 33.8586 158.828 32.2982 254.885 12.0148C332.797 -8.26852 292.24 12.0148 306.115 12.0148H407.508C437.392 12.0148 593.217 24.4969 634.841 32.2982C676.466 40.0994 676.466 46.3405 713.821 55.702C751.176 65.0635 757.58 90.0276 768.253 100.949C778.926 111.871 817.349 272.578 822.685 288.18C828.021 303.783 763.984 375.555 749.042 405.199C734.1 434.844 695.677 427.043 644.447 450.447C593.217 473.851 448.065 427.043 391.498 427.043H100.127C65.9735 427.043 56.368 420.802 25.4165 416.121C-5.53501 411.441 1.93605 288.18 10.4744 258.535Z"
              fill={fill}
              stroke={stroke}
              strokeWidth="5"
              style={{ transform: `scale(${scale})` }}
            />
          ) : (
            <path
              d="M11.869 480.862C19.9742 436.513 39.7306 238.689 48.5956 145.321H75.1905H109.384C117.405 130.733 139.525 97.4703 163.841 81.131C194.235 60.7069 187.903 57.7888 301.882 19.8581C394.332 -18.0726 346.208 19.8581 362.671 19.8581H482.983C518.443 19.8581 703.343 43.2001 752.734 57.7888C802.125 72.3776 802.125 84.0486 846.45 101.555C890.775 119.062 898.374 165.746 911.038 186.17C923.702 206.594 969.294 507.122 975.626 536.3C981.958 565.477 905.972 699.694 888.242 755.131C870.512 810.568 824.92 795.979 764.131 839.745C703.343 883.512 531.107 795.979 463.986 795.979H118.249C77.7234 795.979 66.3257 784.308 29.5991 775.555C-7.12752 766.802 1.73753 536.3 11.869 480.862Z"
              fill={fill}
              stroke={stroke}
              strokeWidth="5"
              style={{ transform: `scale(${scale})` }}
            />
          )}
        </svg>

        {(this.props.voiceOver === "reset" ||
          this.props.voiceOver === "load" ||
          this.props.voiceOver === "delete" ||
          this.props.voiceOver === "leave") &&
          this.renderTopMessages(svgHeight)}
        <VerticalContainer
          style={{ width: svgWidth, height: svgHeight, zIndex: 5 }}
        >
          {this.renderContent()}
        </VerticalContainer>

        <VerticalContainer
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -5,
          }}
          onClick={
            !this.state.loading
              ? this.props.handleModal.bind(this, "svgSave", "")
              : null
          }
        />
      </Overlay>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(SaveModal);

const BigText = styled(SubButtonHeadline)`
  font-size: 50px;
  color: ${White};
  letter-spacing: 5px;
  line-height: 60px;
  width: 50%;
`;
