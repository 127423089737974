import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
//Styles
import { SpecHeadline, SpecBody } from "../../styles/Typography";
import { VerticalContainer, RowContainer } from "../../styles/GlobalStyles";
import { PrimaryBrown, White } from "../../styles/Colors";
//Component
import StorytellerSelection from "../dashboard/StorytellerSelection";
import AnimatedWrapper from "../../global/AnimatedWrapper";
import IconButton from "../../global/IconButton";
//Assets
import SuperStar from "../../assets/images/lastPage/superStar.png";
import Hotspot from "../../assets/images/lastPage/hotspot.png";
import Save from "../../assets/icons/save.png";
import QuestionMark from "../../assets/icons/question.png";
import LeftIcon from "../../assets/icons/leftIcon.png";
import RightIcon from "../../assets/icons/rightIcon.png";
//Utils
import { avatarSoundList } from "../../utils/avatarSoundList";

class Recommendation extends Component {
  handleAvatarSelected = async (avatarIndex, type, id) => {
    let soundAvatarLength = avatarSoundList.length;
    if (id) this.props.handleSelectMember(id);

    if (avatarIndex <= soundAvatarLength - 1 && type === "child") {
      let selectedAvatarSound = avatarSoundList[avatarIndex];
      let selectedAvatarSoundLength = selectedAvatarSound.voiceList.length;
      let selectedSoundIndex = Math.floor(
        Math.random() * selectedAvatarSoundLength
      );
      let selectedSound = selectedAvatarSound.voiceList[selectedSoundIndex];
      this.audio = new Audio(selectedSound);
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio.play();
    }
  };

  render() {
    return (
      <>
        <RootContainer style={{ height: this.props.open ? "auto" : 80 }}>
          {!this.props.open ? (
            <RowContainer style={{ width: "100%" }}>
              <RowContainer
                style={{ width: "32.5%", justifyContent: "flex-start" }}
              >
                <AnimatedWrapper
                  onClick={
                    !this.props.circleDotShowing && this.props.handleToggleDot
                  }
                >
                  <RowContainer>
                    <img
                      src={Hotspot}
                      style={{
                        width: 50,
                        height: 50,
                      }}
                    />
                    <SpecHeadline style={{ color: White, marginLeft: 10 }}>
                      Click here to view <br />
                      “Sound Spots” and “Narration”
                    </SpecHeadline>
                  </RowContainer>
                </AnimatedWrapper>
              </RowContainer>

              <RowContainer
                style={{
                  marginTop: -20,
                  width: "32.5%",
                  justifyContent: "flex-start",
                }}
              >
                <AnimatedWrapper onClick={this.props.handleToggleViewHotspots}>
                  <RowContainer>
                    <AvatarImageButton src={SuperStar} />
                    <VerticalContainer>
                      <SpecHeadline style={{ color: White, marginTop: 10 }}>
                        Be a storyteller today and <br />
                        RECORD your own narration
                      </SpecHeadline>
                    </VerticalContainer>
                  </RowContainer>
                </AnimatedWrapper>
              </RowContainer>
              <RowContainer
                style={{
                  width: "30%",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  iconImage={LeftIcon}
                  imageStyle={{
                    width: 40,
                    height: 40,
                    margin: 5,
                  }}
                  onClick={this.props.handleNavigationButtonPressed.bind(
                    this,
                    "right"
                  )}
                />
                <IconButton
                  iconImage={RightIcon}
                  imageStyle={{
                    width: 40,
                    height: 40,
                    margin: 5,
                  }}
                  onClick={this.props.handleNavigationButtonPressed.bind(
                    this,
                    "left"
                  )}
                />
              </RowContainer>
            </RowContainer>
          ) : (
            <RowContainer style={{ width: "100%" }}>
              <VerticalContainer style={{ width: "60%", marginLeft: "20%" }}>
                <SpecBody style={{ color: White, margin: 10, marginTop: 15 }}>
                  Select a Storyteller to Narrate:
                </SpecBody>
                <RowContainer style={{ marginBottom: 15 }}>
                  <StorytellerSelection
                    handleAvatarSelected={this.handleAvatarSelected}
                    selectedMember={this.props.selectedMember}
                  />
                </RowContainer>
              </VerticalContainer>
              <div style={{ flexGrow: 1 }} />
              <RowContainer style={{ width: "20%" }}>
                <VerticalContainer style={{ marginRight: 20 }}>
                  <IconButton
                    iconImage={QuestionMark}
                    imageStyle={{ width: 50, height: 50, margin: 5 }}
                    onClick={this.props.handleModal.bind(this, "video", true)}
                  />
                  <SpecBody style={{ color: White, cursor: "pointer" }}>
                    Tutorial
                  </SpecBody>
                </VerticalContainer>
                <VerticalContainer>
                  <IconButton
                    iconImage={Save}
                    imageStyle={{
                      width: 50,
                      height: 50,
                      margin: 5,
                    }}
                    onClick={this.props.handleModal.bind(
                      this,
                      "narration",
                      "narration"
                    )}
                  />
                  <SpecBody style={{ color: White, cursor: "pointer" }}>
                    Save
                  </SpecBody>
                </VerticalContainer>
              </RowContainer>
            </RowContainer>
          )}
        </RootContainer>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(Recommendation);

const RootContainer = styled(RowContainer)`
  width: 100%;
  max-width: 1440px;
  background: ${PrimaryBrown};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const AvatarImageButton = styled.img`
  height: 75px;
  width: 75px;
  background: ${PrimaryBrown};
  border-radius: 80px;
  padding: 10px;
  cursor: pointer;
`;
