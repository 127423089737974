import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import {
  White,
  BlackBrown,
  PrimaryBlack,
  PrimaryBrown,
  PrimaryGreen,
} from "../styles/Colors";
import {
  SubTitle2,
  Title3,
  SpecTitle4,
  Headline,
  SpecHeadline,
  SubFootnote,
  Caption,
} from "../styles/Typography";
import {
  RowContainer,
  SemanticBackground,
  VerticalContainer,
} from "../styles/GlobalStyles";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
import CharacterSlider from "../slider/CharacterSlider";
import BookSlider from "../slider/BookSlider";
import SeriesSlider from "../slider/SeriesSlider";
import BannerSlider from "../slider/BannerSlider";
import Button from "../global/Button";
import VideoModal from "../modal/VideoModal";
//Background
import UpperBackground from "../assets/images/homeScreen/upperBackground.png";
import HomeBackground from "../assets/images/homeScreen/homeBackground.jpg";
import CenterBackground from "../assets/images/homeScreen/homeCenterBackground.jpg";
//Assets
import ReadImage from "../assets/images/homeScreen/readImage.png";
import NarrateImage from "../assets/images/homeScreen/narrateImage.png";
import CreateImage from "../assets/images/homeScreen/createImage.png";
import SyncImage from "../assets/images/homeScreen/syncImage.png";
import MeBooksLogo from "../assets/images/homeScreen/meBooksLogo.png";
import BooksImage from "../assets/images/homeScreen/booksImage.png";
import ThinkOutsideAudio from "../assets/audio/home_think_outside_box.mp3";
import KeepFightingAudio from "../assets/audio/home_keep_fighting.mp3";
import ManeEventAudio from "../assets/audio/home_mane_event.mp3";
import MouseterfullyDone from "../assets/audio/home_mouseterfully_done.mp3";
//Utils
import { homeScreenData } from "../utils/homeUIList";
import EventBus from "js-event-bus";
//Actions
import {
  getHomePreviewItems,
  getHomePreviewLoginItems,
} from "../store/actions/dataActions";

const eventBus = EventBus();

let footerData = [
  {
    title: "Read & Listen",
    image: ReadImage,
    description:
      "Enjoy read-along audio and extra sound effects on every page.",
  },
  {
    title: "Personalise & Narrate",
    image: NarrateImage,
    description:
      'Personalise your own voices by using our patented "Draw and Narrate" feature.',
  },
  {
    title: "Act & Create",
    image: CreateImage,
    description:
      "Whisk little ones away to another world and play the characters in the stories by creating their own sounds, side-scripts or soar high like the unicorn from the stories.",
  },
  {
    title: "Save & Sync",
    image: SyncImage,
    description:
      "Save voice-overs, make invaluable memories and share across devices with the same account.",
  },
];

class Home extends Component {
  state = {
    modalVisible: false,
    isMobile: true,
  };

  componentDidMount() {
    if (this.props.authStore.userAuth) this.props.getHomePreviewLoginItems();
    else this.props.getHomePreviewItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.authStore.userLoading !== this.props.authStore.userLoading &&
      !this.props.authStore.userLoading
    ) {
      if (this.props.authStore.userAuth) this.props.getHomePreviewLoginItems();
      else this.props.getHomePreviewItems();
    }

    if (prevProps.authStore.userAuth !== this.props.authStore.userAuth) {
      if (this.props.authStore.userAuth) this.props.getHomePreviewLoginItems();
      else this.props.getHomePreviewItems();
    }
  }

  handleLogin = () => {
    this.props.getHomePreviewLoginItems();
  };

  handleLogout = () => {
    this.props.getHomePreviewItems();
  };

  handleShowModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleHideModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  handleSelectedAudio = async (input) => {
    this.selectionSound = new Audio(input);
    this.selectionSound.currentTime = 0;
    this.selectionSound.pause();
    this.selectionSound.play();
  };

  renderFooterBottom = (details) => {
    return (
      <VerticalContainer
        style={{
          width: "23%",
          alignSelf: "flex-start",
          marginBottom: 20,
          maxWidth: 300,
        }}
      >
        <SubTitle2 style={{ textAlign: "center", marginBottom: 5 }}>
          {details.title}
        </SubTitle2>
        <img src={details.image} style={{ width: "75%", maxHeight: 280 }} />
        <SpecHeadline
          style={{
            color: PrimaryBlack,
            marginTop: 20,
            textAlign: "center",
            width: "100%",
            fontWeight: "bold",
          }}
        >
          {details.description}
        </SpecHeadline>
      </VerticalContainer>
    );
  };

  renderHomeFooter = () => {
    return (
      <VerticalContainer style={{ width: "100%" }}>
        <SemanticBackground
          style={{
            backgroundColor: !this.props.authStore.userAuth && White,
            width: "100%",
            maxWidth: 1440,
            paddingTop: 50,
          }}
        >
          <SubTitle2 style={{ color: BlackBrown }}>
            How Me Books Works?
          </SubTitle2>
          <Text style={{ color: BlackBrown }}>Beyond Reading</Text>
          <Button
            buttonText="Watch Video"
            type="video"
            textStyle={{
              fontFamily: "doctorSoos",
              fontSize: 22,
              fontWeight: 600,
              color: White,
            }}
            style={{ marginBottom: 60 }}
            onClick={this.handleShowModal}
          />
          {!this.props.authStore.userAuth && (
            <RowContainer
              style={{
                width: "100%",
                maxWidth: 1440,
                margin: 20,
                marginBottom: 40,
                justifyContent: "space-around",
              }}
            >
              {this.renderFooterBottom(footerData[0])}
              {this.renderFooterBottom(footerData[1])}
              {this.renderFooterBottom(footerData[2])}
              {this.renderFooterBottom(footerData[3])}
            </RowContainer>
          )}
        </SemanticBackground>
      </VerticalContainer>
    );
  };

  renderSubscriptionStatus = () => {
    let today = new Date().getTime();
    let subscriptionEnd = null;
    if (this.props.authStore.user)
      subscriptionEnd = new Date(
        this.props.authStore.user.subscriptionEnd.seconds * 1000
      ).getTime();

    let differenceInTime = subscriptionEnd - today;

    if (differenceInTime > 0) {
      return (
        <BookSlider
          style={homeScreenData[1].style ? homeScreenData[1].style : {}}
          title={homeScreenData[1].title}
          categoryId={homeScreenData[1].categoryId}
          tagId={homeScreenData[1].tagId}
          image={homeScreenData[1].image}
          iconStyle={
            homeScreenData[1].iconStyle ? homeScreenData[1].iconStyle : {}
          }
          data={
            this.props.homeLoginItems
              ? this.props.homeLoginItems.movieStarBooks
              : []
          }
        />
      );
    } else {
      return this.renderSubscriptionExpired();
    }
  };

  renderLoginHome = () => {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    let bannerImageHeight = divSize * 0.284;
    return (
      <>
        <BannerSlider />
        <RowContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            position: "relative",
          }}
        >
          <img
            src={UpperBackground}
            style={{ width: "100%", maxWidth: 1440 }}
          />
          <HomeBannerImage
            style={{
              left: divSize * 0.17,
              width: divSize * 0.17,
              height: bannerImageHeight * 0.66,
              top: bannerImageHeight * 0.12,
            }}
            onClick={this.handleSelectedAudio.bind(this, ThinkOutsideAudio)}
          />
          <HomeBannerImage
            style={{
              left: divSize * 0.41,
              width: divSize * 0.15,
              height: bannerImageHeight * 0.66,
              top: bannerImageHeight * 0.11,
            }}
            onClick={this.handleSelectedAudio.bind(this, KeepFightingAudio)}
          />
          <HomeBannerImage
            style={{
              left: divSize * 0.55,
              width: divSize * 0.15,
              height: bannerImageHeight * 0.66,
              top: bannerImageHeight * 0.11,
            }}
            onClick={this.handleSelectedAudio.bind(this, ManeEventAudio)}
          />
          <HomeBannerImage
            style={{
              left: divSize * 0.68,
              width: divSize * 0.15,
              height: bannerImageHeight * 0.41,
              top: bannerImageHeight * 0.34,
            }}
            onClick={this.handleSelectedAudio.bind(this, MouseterfullyDone)}
          />
        </RowContainer>
        <CharacterSlider
          title={homeScreenData[0].title}
          categoryId={homeScreenData[0].categoryId}
          height={200}
        />
        {this.renderSubscriptionStatus()}
        <SeriesSlider
          style={homeScreenData[3].style ? homeScreenData[3].style : {}}
          title={homeScreenData[3].title}
          tagId={homeScreenData[3].tagId}
          image={homeScreenData[3].image}
          iconStyle={
            homeScreenData[3].iconStyle ? homeScreenData[3].iconStyle : {}
          }
          data={
            this.props.homeLoginItems
              ? this.props.homeLoginItems.oxfordSeries
              : []
          }
        />
        <SeriesSlider
          style={homeScreenData[4].style ? homeScreenData[4].style : {}}
          title={homeScreenData[4].title}
          tagId={homeScreenData[4].tagId}
          image={homeScreenData[4].image}
          iconStyle={
            homeScreenData[4].iconStyle ? homeScreenData[4].iconStyle : {}
          }
          data={
            this.props.homeLoginItems
              ? this.props.homeLoginItems.malaysiaSeries
              : []
          }
        />
      </>
    );
  };

  renderSubscriptionExpired = () => {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    return (
      <VerticalContainer
        style={{ backgroundColor: White, justifyContent: "flex-start" }}
      >
        <img
          src={CenterBackground}
          style={{ width: "100%", marginTop: (-45 * divSize) / 1440 }}
        />
        <VerticalContainer
          style={{
            position: "absolute",
            alignSelf: "flex-end",
          }}
        >
          <Caption style={{ margin: 20 }}>
            *Content/Titles are subject to region lock and availability.
          </Caption>
        </VerticalContainer>
        <VerticalContainer
          style={{ position: "absolute", margin: (30 * divSize) / 1440 }}
        >
          <img
            src={BooksImage}
            objectFit="contain"
            style={{
              height: (130 * divSize) / 1440,
            }}
          />
          <Title3
            style={{
              color: PrimaryGreen,
            }}
          >
            Me Books+
          </Title3>
          <Button
            buttonText={"Register an account via PNM"}
            style={{
              width: 380,
              marginTop: 15,
              marginBottom: 15,
            }}
            onClick={() => {
              window.open("https://www.u-library.gov.my/portal/web/guest/home");
            }}
          />
          <SubFootnote>to enjoy </SubFootnote>
          <SpecTitle4
            style={{
              marginTop: 3,
              marginBottom: 15,
            }}
          >
            300 more titles*
          </SpecTitle4>
          <div>
            <Headline
              style={{
                textDecorationLine: "underline",
                marginBottom: 20,
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://www.u-library.gov.my/portal/web/guest/home"
                );
              }}
            >
              Learn More
            </Headline>
          </div>
        </VerticalContainer>
      </VerticalContainer>
    );
  };

  renderHome = () => {
    return (
      <VerticalContainer style={{ width: "100%", maxWidth: 1440 }}>
        <img src={HomeBackground} style={{ width: "100%", minHeight: 200 }} />
        <RowContainer>
          <SubTitle2>
            Your own narrated story - an exciting journey to discover!
          </SubTitle2>
        </RowContainer>
        <SubTitle2>
          Designed to make you the most awesome storyteller ever!
        </SubTitle2>
        <img src={MeBooksLogo} style={{ width: 400, minHeight: 100 }} />
        <VerticalContainer style={{ width: "100%" }}>
          <img
            src={CenterBackground}
            style={{ width: "100%", minHeight: 150 }}
          />
          <SubTitle2
            style={{
              color: BlackBrown,
              position: "absolute",
              marginTop: -100,
              maxWidth: "50%",
              textAlign: "center",
            }}
          >
            Filled with Children’s Books full of silly sounds, lovely rhymes and
            tongue twisting fun.
          </SubTitle2>
        </VerticalContainer>

        <SeriesSlider
          titleStyle={{
            marginTop: -120,
            color: PrimaryBrown,
            marginBottom: 25,
          }}
          title="Recommended International Children’s Books Series"
          data={
            this.props.homeItems ? this.props.homeItems.internationalSeries : []
          }
          type="exclude"
        />
        <SeriesSlider
          titleStyle={{
            color: PrimaryBrown,
            marginTop: 60,
            marginBottom: 25,
          }}
          title="Recommended Malaysian Children’s Books Series"
          data={this.props.homeItems ? this.props.homeItems.malaysiaSeries : []}
          type="exclude"
        />
        <BookSlider
          data={
            this.props.homeItems ? this.props.homeItems.recommendedBooks : []
          }
          type="exclude"
        />
      </VerticalContainer>
    );
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (this.props.authStore.userAuth) {
      if (this.props.authStore.user && !this.props.authStore.user.name)
        return <Redirect to="/user/create/parent" />;
      else if (
        this.props.authStore.user &&
        this.props.authStore.user.children.length === 0
      )
        return <Redirect to="/user/create/child" />;
    }

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        {this.state.modalVisible && (
          <VideoModal
            handleShowModal={this.handleShowModal}
            handleHideModal={this.handleHideModal}
            show={this.state.modalVisible}
          />
        )}
        {this.props.authStore.userAuth
          ? this.renderLoginHome()
          : this.renderHome()}
        {this.renderHomeFooter()}
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
    dataStore: state.dataStore,
    homeItems: state.dataStore.homeItems,
    homeLoginItems: state.dataStore.homeLoginItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHomePreviewItems: () => dispatch(getHomePreviewItems()),
    getHomePreviewLoginItems: () => dispatch(getHomePreviewLoginItems()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

const Text = styled.h1`
  font-family: allisonScript;
  font-weight: 300 !important;
  font-size: 38px;
`;

const HomeBannerImage = styled(RowContainer)`
  position: absolute;
`;
