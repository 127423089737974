import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Assets
import LoginBackground from "../assets/background/login.jpg";
import Footer from "../components/Footer";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import SignInComponent from "../components/SignInComponent";
import WindowResizer from "../components/WindowResizer";
import { RowContainer, VerticalContainer } from "../styles/GlobalStyles";

class SignIn extends Component {
  render() {
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (
      this.props.authStore.userAuth &&
      this.props.authStore.user &&
      this.props.authStore.user.name
    )
      return <Redirect to="/" />;
    else if (
      this.props.authStore.userAuth &&
      this.props.authStore.user &&
      !this.props.authStore.user.name
    )
      return <Redirect to="/user/create/parent" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader screen={"Welcome !"} />
        <RowContainer style={{ width: "100%", maxWidth: 1440 }}>
          <VerticalContainer
            style={{
              backgroundImage: "url(" + LoginBackground + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "50%",
              height: 500,
            }}
          />
          <VerticalContainer style={{ width: "50%" }}>
            <SignInComponent />
          </VerticalContainer>
        </RowContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(SignIn);
