import React, { Component } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
//Styles
import { SubFootnote, SpecFootnote, Title4 } from "../styles/Typography";
import { White, BorderGrey, PrimaryRed, SemanticBrown } from "../styles/Colors";
import {
  RowContainer,
  VerticalContainer,
  Divider,
} from "../styles/GlobalStyles";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
import AnimatedWrapper from "../global/AnimatedWrapper";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.sound = null;
  }

  handleSelectCategory = (categoryId, categorySound) => {
    this.props.history.push(`/categories/${categoryId}`);
    if (categorySound) {
      let soundUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${categoryId}%2Fsound.mp3?alt=media&token=${categorySound}`;
      this.sound = new Audio(soundUrl);
      this.sound.currentTime = 0;
      this.sound.play();
    }
  };

  renderCategories = (categoriesList) => {
    let categoryList = [];

    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    divSize = divSize * 0.8;
    divSize = divSize - 120;
    let iconSize = Math.floor(divSize / 5);

    if (
      categoriesList &&
      categoriesList.length > 0 &&
      this.props.categories &&
      this.props.categories.length > 0
    ) {
      this.props.categories.map((eachCategory, index) => {
        if (categoriesList.includes(eachCategory.id)) {
          categoryList.push(
            <AnimatedWrapper
              style={{
                width: iconSize,
                margin: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              key={index}
              onClick={this.handleSelectCategory.bind(
                this,
                eachCategory.id,
                eachCategory.sound
              )}
            >
              <img
                resizeMode="contain"
                src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategory.id}%2Fselection_icon.png?alt=media&token=${eachCategory.selectionIcon}`}
                style={{
                  height: iconSize - 20,
                  width: iconSize - 20,
                }}
              />
              <DescriptionView style={{ maxWidth: iconSize }}>
                <SpecFootnote
                  style={{
                    color: White,
                    height: "100%",
                    maxWidth: iconSize - 20,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {eachCategory.discoverName}
                </SpecFootnote>
              </DescriptionView>
            </AnimatedWrapper>
          );
        }
      });
    }

    return categoryList;
  };

  renderEachCollection = () => {
    let collectionView = [];
    if (this.props.collections.length > 0)
      this.props.collections.map((eachCollection, index) => {
        if (eachCollection.id.includes("0f37fb87-414c-4c76-b0ec-508fddd41997"))
          return;

        collectionView.push(
          <>
            <RowContainer
              style={{ width: "100%", marginTop: 30, marginBottom: 30 }}
              key={index}
            >
              <Title4 style={{ color: PrimaryRed }}>
                {eachCollection.name}
              </Title4>
              <Divider
                style={{
                  flexGrow: 1,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
            </RowContainer>
            <RowContainer
              style={{
                width: "100%",
                maxWidth: "100%",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              {this.renderCategories(eachCollection.categories)}
            </RowContainer>
          </>
        );
      });

    return collectionView;
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            backgroundColor: SemanticBrown,
          }}
        >
          <VerticalContainer
            style={{ width: "90%", marginTop: 50, marginBottom: 50 }}
          >
            <SubFootnote style={{ fontWeight: 600, alignSelf: "flex-start" }}>
              All Categories
            </SubFootnote>
            {this.renderEachCollection()}
          </VerticalContainer>
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    categories: state.dataStore.categories,
    collections: state.dataStore.collections,
  };
};

export default connect(mapStateToProps)(Categories);

const DescriptionView = styled(RowContainer)`
  height: 30px;
  border-radius: 20px;
  margin: 10px 0px;
  padding: 0px 20px;
  background-color: ${BorderGrey};
`;
