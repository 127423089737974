import React, { Component } from "react";
import { connect } from "react-redux";
import appStore from "../assets/images/footer/appStore.png";
//Assets
import logoLineUps from "../assets/images/footer/logoLineUps.png";
import playStore from "../assets/images/footer/playStore.png";
import Footer from "../components/Footer";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import WindowResizer from "../components/WindowResizer";
//Styles
import { PrimaryBlack, SemanticBrown } from "../styles/Colors";
import { VerticalContainer } from "../styles/GlobalStyles";
import { Footnote } from "../styles/Typography";

class Mobile extends Component {
  renderMobileView = () => {
    return (
      <VerticalContainer
        style={{
          backgroundColor: SemanticBrown,
          padding: 30,
          width: "100%",
        }}
      >
        <img
          src={logoLineUps}
          style={{
            width: this.props.utilsStore.deviceType === 0 ? 260 : 300,
            height: this.props.utilsStore.deviceType === 0 ? 165 : 200,
            margin: 10,
          }}
        />

        <Footnote
          style={{
            color: PrimaryBlack,
            maxWidth: 450,
            width: "90%",
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          Download Me Books Mobile App for Best Viewing Experience.
        </Footnote>
        <img
          src={appStore}
          style={{ width: 125, cursor: "pointer", marginTop: 20 }}
          alt="Button Linked to Me Books App in App Store"
          onClick={() => {
            window.open("https://apps.apple.com/us/app/id1510382298");
          }}
        />
        <img
          src={playStore}
          style={{ width: 145, cursor: "pointer", marginTop: 5 }}
          alt="Button Linked to Me Books App in Play Store"
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=co.mebooks.plus"
            );
          }}
        />
      </VerticalContainer>
    );
  };

  render() {
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        {this.renderMobileView()}
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(Mobile);
