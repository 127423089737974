import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import {
  RowContainer,
  SemanticLayout,
  VerticalContainer,
  Divider,
} from "../styles/GlobalStyles";
import {
  SubTitle4,
  Title4,
  SpecTitle4,
  SubButtonHeadline,
  SubFootnote,
} from "../styles/Typography";
import {
  PrimaryRed,
  PrimaryBlack,
  BookModalBackground,
  SvgYellow,
} from "../styles/Colors";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import Tag from "../global/Tag";
import Button from "../global/Button";
import AnimatedWrapper from "../global/AnimatedWrapper";
import WindowResizer from "../components/WindowResizer";
import SubscriptionEndModal from "../modal/SubscriptionEndModal";
//Assets
import InfoIcon from "../assets/icons/info.png";
import EmptyBottle from "../assets/images/previewBook/emptyLikeBottle.png";
import FillBottle from "../assets/images/previewBook/filledBottle.png";
//Actions
import {
  updateFavouriteBook,
  getBookAnalytics,
  getSelectedBook,
} from "../store/actions/libraryActions";

class Book extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favourite: false,
      subscriptionEndModal: false,
    };
    this.audio = null;
    this.timeout = null;
  }

  componentDidMount() {
    this.handlePreviewSound();
    if (this.props.selectedBook) this.handleGetBookAnalytics();
    else this.handleUpdateSelectedBook();
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.selectedBook) !==
        JSON.stringify(prevProps.selectedBook) &&
      this.props.selectedBook
    ) {
      this.timeout = setTimeout(() => {
        this.handleGetBookAnalytics();
      }, 500);
    }

    if (
      this.props.selectedBook &&
      this.props.selectedBookAnalytics &&
      this.props.selectedBookAnalytics.id === this.props.selectedBook.id &&
      JSON.stringify(prevProps.selectedBookAnalytics) !==
        JSON.stringify(this.props.selectedBookAnalytics)
    )
      this.setState({
        favourite: this.props.selectedBookAnalytics.favourite,
      });
  }

  handlePreviewSound = () => {
    if (
      this.props.selectedBook &&
      this.props.selectedBook.sound &&
      this.props.selectedBook.sound.length > 0
    ) {
      let selectedSoundLength = this.props.selectedBook.sound.length;
      let randomIndex = Math.floor(Math.random() * selectedSoundLength);
      let selectedSound = this.props.selectedBook.sound[randomIndex];
      let tokenIndex = -1;
      this.props.selectedBook.audioName.map((eachAudio, index) => {
        if (eachAudio === selectedSound) tokenIndex = index;
      });
      selectedSound = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.props.selectedBook.id}%2Fcontent%2F${selectedSound}?alt=media&token=${this.props.selectedBook.audio[tokenIndex]}`;
      this.audio = new Audio(selectedSound);
      this.audio.play();
    }
  };

  handleGetBookAnalytics = () => {
    if (
      this.props.authStore.selectedChild &&
      this.props.authStore.children.length > 0
    ) {
      this.props.authStore.children.map((eachChild) => {
        if (eachChild.id === this.props.authStore.selectedChild)
          this.props.getBookAnalytics(this.props.selectedBook.id, eachChild.id);
      });
    }
  };

  handleSubscriptionEndModal = (bool) => {
    this.setState({
      subscriptionEndModal: bool,
    });
  };

  handleUpdateFavouriteBook = () => {
    this.setState(
      {
        favourite: !this.state.favourite,
      },
      async () => {
        let selectedChildId = "";
        if (
          this.props.authStore.selectedChild &&
          this.props.authStore.children.length > 0
        ) {
          this.props.authStore.children.map((eachChild) => {
            if (eachChild.id === this.props.authStore.selectedChild)
              selectedChildId = eachChild.id;
          });
        }

        if (selectedChildId && this.props.selectedBook.id)
          this.props.updateFavouriteBook(
            this.props.selectedBook.id,
            selectedChildId,
            this.state.favourite
          );
      }
    );
  };

  handleUpdateSelectedBook = () => {
    let pathNameList = this.props.location.pathname.split("/");
    if (pathNameList.length > 0) {
      let pathName = pathNameList[pathNameList.length - 1];

      if (pathName) this.props.getSelectedBook(pathName);
      else this.props.history.push("/");
    }
  };

  handleNavigate = () => {
    this.props.history.push(`/bookInteraction/${this.props.selectedBook.id}`);
    window.scrollTo({ top: 115, behavior: "smooth" });
  };

  handleFavourite = () => {
    this.setState({
      favourite: !this.state.favourite,
    });
  };

  renderCallToActions = () => {
    let buttonText;
    let buttonColor;
    let buttonFunctions;

    if (!this.props.authStore.user) return;

    let today = new Date().getTime();
    let subscriptionEnd = new Date(
      this.props.authStore.user.subscriptionEnd.seconds * 1000
    ).getTime();

    let differenceInTime = subscriptionEnd - today;
    if (differenceInTime > 0) {
      buttonText = "Read";
      buttonColor = PrimaryRed;
      buttonFunctions = this.handleNavigate;
    } else {
      buttonText = "Subscription Ended";
      buttonColor = SvgYellow;
      buttonFunctions = this.handleSubscriptionEndModal.bind(this, true);
    }

    return (
      <Button
        buttonText={buttonText}
        style={{
          width: buttonText === "Read" ? 150 : 250,
          marginTop: 20,
          backgroundColor: buttonColor,
        }}
        onClick={buttonFunctions}
      />
    );
  };

  renderTags = () => {
    let tagList = [];
    let count = 0;
    if (
      this.props.selectedBook &&
      this.props.selectedBook.tags.length > 0 &&
      this.props.tags.length > 0
    )
      this.props.selectedBook.tags.map((eachBookTag) => {
        this.props.tags.map((eachTag, index) => {
          if (
            eachBookTag === eachTag.id &&
            !eachTag.title.includes("Home - ") &&
            eachTag.title.length <= 15 &&
            count <= 1
          ) {
            tagList.push(<Tag key={index} text={eachTag.title} />);
            count += 1;
          }
        });
      });

    return tagList;
  };

  renderSemanticContent = () => {
    return (
      <>
        <CustomSemanticLayout>
          <RowContainer
            style={{
              alignSelf: "flex-end",
              marginRight: 20,
              marginTop: 20,
              cursor: "pointer",
            }}
          >
            <AnimatedWrapper
              style={{ display: "flex", alignItems: "center" }}
              onClick={this.handleUpdateFavouriteBook}
            >
              <SubButtonHeadline
                style={{ fontSize: 20, fontWeight: 300, color: PrimaryRed }}
              >
                Love this Book?
              </SubButtonHeadline>
              <img src={this.state.favourite ? FillBottle : EmptyBottle} />
            </AnimatedWrapper>
          </RowContainer>
          <SubFootnote
            style={{
              margin: "10px 30px",
              fontSize: 18,
              lineHeight: 1.5,
            }}
          >
            {this.props.selectedBook && this.props.selectedBook.description}
          </SubFootnote>
        </CustomSemanticLayout>
        <VerticalContainer style={{ alignSelf: "flex-end" }}>
          {this.renderCallToActions()}
        </VerticalContainer>
      </>
    );
  };

  renderBookInformation = () => {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    let imageWidth = divSize * 0.33;
    let imageHeight = Math.floor(imageWidth * 1.46);

    let smallDiv = divSize * 0.9;

    return (
      <RowContainer style={{ width: "90%" }}>
        <VerticalContainer
          style={{
            height: imageHeight,
            width: imageWidth,
            backgroundColor: BookModalBackground,
          }}
        >
          {this.props.selectedBook &&
            this.props.selectedBook.backgroundImage && (
              <img
                style={{ height: imageHeight, width: imageWidth }}
                src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.props.selectedBook.id}%2FbackgroundImage.png?alt=media&token=${this.props.selectedBook.backgroundImage}`}
              />
            )}
        </VerticalContainer>

        <VerticalContainer
          style={{
            width: smallDiv - imageWidth,
            marginTop: 30,
          }}
        >
          <VerticalContainer
            style={{
              width: "90%",
              alignItems: "flex-start",
              marginLeft: "10%",
            }}
          >
            <RowContainer style={{ marginBottom: 20 }}>
              <img src={InfoIcon} style={{ width: 40 }} />
              <SubTitle4 style={{ color: PrimaryRed, marginLeft: 10 }}>
                About this Book
              </SubTitle4>
            </RowContainer>
            <RowContainer>
              <VerticalContainer style={{ width: 140 }}>
                {this.props.selectedBook && (
                  <img
                    style={{ width: 140 }}
                    src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.props.selectedBook.id}%2FpreviewImage.png?alt=media&token=${this.props.selectedBook.image}`}
                  />
                )}
              </VerticalContainer>
              <VerticalContainer
                style={{
                  alignItems: "flex-start",
                  marginLeft: 20,
                }}
              >
                <Title4 style={{ color: PrimaryBlack }}>
                  {this.props.selectedBook && this.props.selectedBook.title}
                </Title4>
                <BookTagsView>{this.renderTags()}</BookTagsView>
              </VerticalContainer>
            </RowContainer>
            {this.renderSemanticContent()}
          </VerticalContainer>
        </VerticalContainer>
      </RowContainer>
    );
  };

  renderBookRoles = (selectedRole) => {
    let roleName = "";
    let attribute = selectedRole.toLowerCase();
    if (this.props.selectedBook) {
      let selectedAttribute = this.props.selectedBook[attribute];
      if (
        selectedAttribute &&
        selectedAttribute.length > 0 &&
        this.props.roles.length > 0
      ) {
        let count = 0;
        this.props.roles.map((eachRole) => {
          if (selectedAttribute.includes(eachRole.id)) {
            if (!count) roleName += eachRole.name;
            else roleName += ", " + eachRole.name;
            count += 1;
          }
        });
      }
    }

    return roleName;
  };

  renderRoleContent = (title, type) => {
    return (
      <BookRolesContainer>
        <SubFootnote
          style={{
            width: "42%",
          }}
        >
          {title}
        </SubFootnote>
        <SubFootnote
          style={{
            width: "3%",
          }}
        >
          :
        </SubFootnote>
        <SubFootnote
          style={{
            width: "55%",
          }}
        >
          {this.renderBookRoles(type)}
        </SubFootnote>
      </BookRolesContainer>
    );
  };

  renderBookRoleContainer = (previewSize) => {
    return (
      <CustomSemanticLayout
        style={{
          justifyContent: "center",
          width: previewSize,
          padding: 20,
        }}
      >
        {this.renderRoleContent("Publisher", "Publisher")}
        {this.renderRoleContent("Voice-over by", "Narrator")}
        {this.renderRoleContent("Written by", "Writer")}
        {this.renderRoleContent("Illustrated by", "Illustrator")}
      </CustomSemanticLayout>
    );
  };

  renderBookPreviewImage = (index) => {
    if (this.props.selectedBook && this.props.selectedBook.pages.length > index)
      return `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.props.selectedBook.id}%2Fcontent%2F${this.props.selectedBook.pagesName[index]}?alt=media&token=${this.props.selectedBook.pages[index]}`;
  };

  renderBookPreviews = () => {
    let divWidth;
    if (this.props.utilsStore.deviceSize > 1440) divWidth = 1440;
    else divWidth = this.props.utilsStore.deviceSize;

    divWidth = divWidth * 0.9;

    let previewSize = Math.floor(divWidth / 2) - 20;

    return (
      <VerticalContainer
        style={{
          width: "90%",
          marginTop: 30,
        }}
      >
        <RowContainer
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
        >
          <SpecTitle4 style={{ color: PrimaryRed }}>Previews</SpecTitle4>
          <Divider
            style={{
              height: 8,
              flexGrow: 1,
              marginLeft: 20,
            }}
          />
        </RowContainer>
        <RowContainer style={{ width: "100%", marginTop: 20 }}>
          <RowContainer style={{ width: previewSize, marginRight: 40 }}>
            <img
              src={this.renderBookPreviewImage(4)}
              style={{ width: previewSize / 2 }}
            />
            <img
              src={this.renderBookPreviewImage(5)}
              style={{ width: previewSize / 2 }}
            />
          </RowContainer>
          <RowContainer style={{ width: previewSize }}>
            <img
              src={this.renderBookPreviewImage(6)}
              style={{ width: previewSize / 2 }}
            />
            <img
              src={this.renderBookPreviewImage(7)}
              style={{ width: previewSize / 2 }}
            />
          </RowContainer>
        </RowContainer>
        <RowContainer
          style={{
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <RowContainer style={{ width: previewSize, marginRight: 50 }}>
            <img
              src={this.renderBookPreviewImage(8)}
              style={{ width: previewSize / 2 }}
            />
            <img
              src={this.renderBookPreviewImage(9)}
              style={{ width: previewSize / 2 }}
            />
          </RowContainer>
          {this.renderBookRoleContainer(previewSize)}
        </RowContainer>
      </VerticalContainer>
    );
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <>
        {this.state.subscriptionEndModal && (
          <SubscriptionEndModal
            handleSubscriptionEndModal={this.handleSubscriptionEndModal}
          />
        )}
        <VerticalContainer>
          <WindowResizer />
          <Header />
          <SecondaryHeader />
          <VerticalContainer style={{ width: "100%", maxWidth: 1440 }}>
            {this.renderBookInformation()}
            {this.renderBookPreviews()}
          </VerticalContainer>
          <Footer />
        </VerticalContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    books: state.dataStore.books,
    tags: state.dataStore.tags,
    roles: state.dataStore.roles,
    selectedBook: state.libraryStore.selectedBook,
    selectedBookAnalytics: state.libraryStore.selectedBookAnalytics,
    utilsStore: state.utilsStore,
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFavouriteBook: (bookId, childId, favourite) =>
      dispatch(updateFavouriteBook(bookId, childId, favourite)),
    getSelectedBook: (bookId) => dispatch(getSelectedBook(bookId)),
    getBookAnalytics: (bookId, childId) =>
      dispatch(getBookAnalytics(bookId, childId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Book);

const BookTagsView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 380px;
  margin-top: 20px;
`;

const BookRolesContainer = styled(RowContainer)`
  margin-top: 10px;
  align-items: flex-start;
  width: 95%;
`;

const CustomSemanticLayout = styled(SemanticLayout)`
  border-radius: 30px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 250px;
`;
