import React, { Component } from "react";
import { connect } from "react-redux";
//Styles
import { bookInteractionAspectRatio } from "../../styles/GlobalStyles";
//Assets
import RecordAudio from "../../assets/audio/book_int_record.mp3";
import EngageAudio from "../../assets/audio/book_int_engage.mp3";
//Utils
import EventBus from "js-event-bus";

const eventBus = EventBus();

class BookHotspots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scalePressed: 0,
      soundPlayingIndex: -1,
      recordingIndex: -1,
      polygonDown: false,
    };
    this.playSoundObject = new Audio();
    this.recordSoundObject = new Audio(RecordAudio);
    this.engageSoundObject = new Audio(EngageAudio);
    this.polygonPressDownTimer = null;
    this.doublePressTime = 0;
    this.singleTapTimeout = null;
    this.scaleInterval = null;
    this.audioRecorder = null;
  }

  componentDidMount = async () => {
    eventBus.on("swipe-success", this.handleStopAudio.bind(this));
  };

  componentWillUnmount() {
    eventBus.detach("swipe-success", this.handleStopAudio.bind(this));
    this.handleStopAudio();
  }

  handlePlaySoundObject = async (url) => {
    try {
      this.playSoundObject = new Audio(url);
      this.playSoundObject.addEventListener(
        "ended",
        this.handleStopAudio.bind(this)
      );
      this.playSoundObject.play();
    } catch (err) {}
  };

  handlePlayAudio = async (index) => {
    try {
      if (this.state.soundPlayingIndex > -1) await this.handleStopAudio();
      let selectedHotspot = this.props.hotspots[index];
      this.setState({
        soundPlayingIndex: index,
      });
      this.props.handleUpdateSelectedIndex(-1);

      if (selectedHotspot.PrerecordingFilename == "")
        setTimeout(() => {
          this.setState({
            soundPlayingIndex: -1,
          });
        }, 100);
      else {
        if (selectedHotspot.PrerecordingFilename.includes("blob:"))
          this.handlePlaySoundObject(selectedHotspot.PrerecordingFilename);
        else if (selectedHotspot.Member) {
          if (this.props.selectedNarration) {
            let selectedIndex = -1;
            this.props.selectedNarration.narrationName.map(
              (eachNarrationName, index) => {
                if (eachNarrationName === selectedHotspot.PrerecordingFilename)
                  selectedIndex = index;
              }
            );
            if (selectedIndex > -1) {
              let assetUrl = this.props.renderNarrationAssetUrl(
                selectedHotspot.PrerecordingFilename,
                this.props.selectedNarration.narration[selectedIndex]
              );
              this.handlePlaySoundObject(assetUrl);
            }
          }
        } else {
          let audioSourceList = selectedHotspot.PrerecordingFilename.split("/");
          let audioName = audioSourceList[audioSourceList.length - 1];
          let filteredAudioName = audioName.replace(/ /g, "_");
          let selectedIndex = -1;
          this.props.selectedBook.audioName.map((eachAudioName, index) => {
            let eachFilteredAudioName = eachAudioName.replace(/ /g, "_");
            if (eachFilteredAudioName.includes(filteredAudioName))
              selectedIndex = index;
          });

          if (selectedIndex >= 0)
            this.handlePlaySoundObject(
              this.props.renderBookAssets(
                this.props.selectedBook.audioName[selectedIndex],
                this.props.selectedBook.audio[selectedIndex]
              )
            );
        }
      }
    } catch (err) {
      this.setState({
        soundPlayingIndex: -1,
      });
    }
  };

  handleStopAudio = () => {
    if (this.state.soundPlayingIndex >= 0 && this.props.hotspots.length > 0) {
      this.playSoundObject.removeEventListener(
        "ended",
        this.handleStopAudio.bind(this)
      );
      this.playSoundObject.currentTime = 0;
      this.playSoundObject.pause();
      this.setState({
        soundPlayingIndex: -1,
      });
    }
  };

  handlePressPolygon = (selectedIndex) => {
    this.setState({
      scalePressed: 0,
    });
    this.props.handleHighlightSound(false);

    const time = new Date().getTime();
    const delta = time - this.doublePressTime;
    const doublePressDelay = 400;

    if (delta < doublePressDelay && this.props.viewHotspots) {
      clearInterval(this.scaleInterval);
      clearTimeout(this.singleTapTimeout);
      this.handleStopAudio();
      this.props.handleUpdateSelectedIndex(selectedIndex);
      this.scaleInterval = setInterval(this.handleEnlargeScale, 5);
      setTimeout(() => {
        clearInterval(this.scaleInterval);
      }, 300);
      this.engageSoundObject.play();
    } else {
      if (this.state.recordingIndex === -1) {
        if (this.props.selectedIndex !== selectedIndex) {
          //INFO : Stop audio if it's playing
          if (this.state.soundPlayingIndex === selectedIndex)
            this.handleStopAudio();
          else
            this.singleTapTimeout = setTimeout(() => {
              this.handlePlayAudio(selectedIndex);
            }, doublePressDelay);
        }
      }
    }

    this.doublePressTime = time;
  };

  handlePolygonDown = (index) => {
    if (this.props.viewHotspots) {
      if (this.polygonPressDownTimer) clearTimeout(this.polygonPressDownTimer);
      this.polygonPressDownTimer = setTimeout(() => {
        this.handlePolygonRecord(index);
      }, 500);

      this.setState({
        polygonDown: true,
      });
    }
  };

  handlePolygonRecord = (index) => {
    this.handleStopAudio();
    this.props.handleHighlightSound(false);

    if (this.state.polygonDown)
      this.setState(
        {
          recordingIndex: index,
        },
        async () => {
          this.recordSoundObject.currentTime = 0;
          this.recordSoundObject.play();

          setTimeout(() => {
            let reactClass = this;
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then((stream) => {
                try {
                  reactClass.audioRecorder = new MediaRecorder(stream);
                  reactClass.audioRecorder.start();

                  const audioChunks = [];

                  reactClass.audioRecorder.addEventListener(
                    "dataavailable",
                    (event) => {
                      audioChunks.push(event.data);
                    }
                  );

                  reactClass.audioRecorder.addEventListener("stop", () => {
                    const audioBlob = new Blob(audioChunks);
                    const audioUrl = URL.createObjectURL(audioBlob);
                    reactClass.props.hotspots[
                      index
                    ].PrerecordingFilename = audioUrl;
                    reactClass.props.hotspots[
                      index
                    ].Member = this.props.selectedMember;
                    reactClass.props.hotspots[index].Modified = true;
                    reactClass.props.handleUpdateHotspots(
                      reactClass.props.hotspots
                    );
                  });
                } catch (err) {}
              });
          }, 500);
        }
      );
  };

  handlePolygonRelease = (e) => {
    if (this.props.viewHotspots) {
      if (this.state.polygonDown)
        this.setState({
          polygonDown: false,
        });
      if (this.polygonPressDownTimer) clearTimeout(this.polygonPressDownTimer);
      if (this.state.recordingIndex > -1)
        setTimeout(() => {
          this.setState(
            {
              recordingIndex: -1,
            },
            () => {
              if (this.audioRecorder) {
                this.audioRecorder.stop();
                this.audioRecorder = null;
              }
            }
          );
        }, 500);
    }
  };

  //===================================================================================================
  //INFO - Polygon Utils

  handleEnlargeScale = () => {
    this.setState({
      scalePressed: this.state.scalePressed + 0.001,
    });
  };

  renderPolygonColor = (
    recordingColor,
    playingColor,
    pressedColor,
    normalColor,
    noColor,
    index
  ) => {
    if (this.props.viewHotspots) {
      if (this.state.recordingIndex === index) return recordingColor;
      else if (this.state.soundPlayingIndex === index) return playingColor;
      else if (
        this.state.scalePressed > 0 &&
        this.props.selectedIndex === index
      )
        return pressedColor;
      else return normalColor;
    } else return noColor;
  };

  renderHotspots = () => {
    let screenHeight;
    let screenWidth;
    if (this.props.utilsStore.deviceSize > 1440) {
      screenWidth = 1440;
      screenHeight = 1440 * (1 / bookInteractionAspectRatio);
    } else {
      screenWidth = this.props.utilsStore.deviceSize;
      screenHeight =
        this.props.utilsStore.deviceSize * (1 / bookInteractionAspectRatio);
    }

    let hotspotsView = [];
    if (
      this.props.pListData &&
      this.props.hotspots &&
      this.props.hotspots.length > 0
    ) {
      let pageWidth = screenWidth / 2;
      let scaleRatioWidth = Number(this.props.imageWidth) / pageWidth;
      let exactHeight = Number(this.props.imageHeight) / scaleRatioWidth;
      let scaleRatioHeight = Number(this.props.imageHeight) / screenHeight;
      let exactWidth = Number(this.props.imageWidth) / scaleRatioHeight;
      let offsetY = (screenHeight - exactHeight) / 2;

      this.props.hotspots.map((eachHotspot, index) => {
        if (
          eachHotspot.PageIndex === this.props.viewingPageArray[1] ||
          eachHotspot.PageIndex === this.props.viewingPageArray[1] + 1
        ) {
          let drawingPoints = "";
          let totalWidth = 0;
          let totalHeight = 0;

          if (eachHotspot.Points.length > 0) {
            let midPoint = 0;
            if (index === this.props.selectedIndex)
              midPoint = this.props.handleCalculateMidpoint(
                eachHotspot.Points,
                eachHotspot.PageIndex
              );

            eachHotspot.Points.map((eachPoint) => {
              let point = eachPoint
                .substring(1, eachPoint.length - 1)
                .split(",");
              let drawingX = 0;
              let drawingY = 0;
              if (eachHotspot.PageIndex % 2 == 0) {
                if (!isNaN(point[0])) {
                  if (this.props.offsetX > 0)
                    drawingX =
                      Number(point[0]) * exactWidth + this.props.offsetX * 2;
                  else drawingX = Number(point[0]) * pageWidth;
                }
              } else {
                if (!isNaN(point[0])) {
                  if (this.props.offsetX > 0)
                    drawingX = Number(point[0]) * exactWidth + pageWidth;
                  else
                    drawingX = Number(point[0]) * Number(pageWidth) + pageWidth;
                }
              }

              if (!isNaN(point[1])) {
                if (offsetY > 0)
                  drawingY = Number(point[1]) * exactHeight + offsetY;
                else drawingY = Number(point[1]) * screenHeight;
              }

              //INFO : Scale Polygon
              if (index === this.props.selectedIndex) {
                drawingX =
                  drawingX +
                  this.state.scalePressed * (drawingX - midPoint[0]) +
                  this.props.transformX;
                drawingY =
                  drawingY + this.state.scalePressed * (drawingY - midPoint[1]);
              }
              totalWidth += drawingX;
              totalHeight += drawingY;

              if (drawingX !== 0 && drawingY !== 0)
                drawingPoints += drawingX + "," + drawingY + " ";
            });

            if (
              !this.props.viewHotspots &&
              this.props.circleDotShowing &&
              eachHotspot.PrerecordingFilename
            ) {
              hotspotsView.push(
                <circle
                  key={index + "circle"}
                  cx={totalWidth / eachHotspot.Points.length}
                  cy={totalHeight / eachHotspot.Points.length}
                  r="7"
                  stroke="white"
                  strokeWidth="1"
                  fill={
                    this.state.soundPlayingIndex === index ? "#34deeb" : "white"
                  }
                  onClick={this.handlePlayAudio.bind(this, index)}
                />
              );
            }
            hotspotsView.push(
              <polygon
                key={index + "pListPolygon"}
                points={drawingPoints}
                fill={this.renderPolygonColor(
                  "#FF000066",
                  "#34deeb66",
                  "#FFA50066",
                  "#4B008266",
                  "rgba(255, 255, 255, 0.0)",
                  index
                )}
                stroke={this.renderPolygonColor(
                  "#ff0000",
                  "#34bdeb",
                  "#FFA500",
                  "#800080",
                  null,
                  index
                )}
                strokeDasharray="8, 3"
                strokeWidth="4"
                onClick={this.handlePressPolygon.bind(this, index)}
                onMouseDown={this.handlePolygonDown.bind(this, index)}
                onMouseUp={this.handlePolygonRelease}
                onMouseLeave={this.handlePolygonRelease}
                onTouchStart={this.handlePolygonDown.bind(this, index)}
                onTouchEnd={this.handlePolygonRelease}
              />
            );
          }
        }
      });
    }
    return hotspotsView;
  };

  render() {
    let screenHeight;
    let screenWidth;
    if (this.props.utilsStore.deviceSize > 1440) {
      screenWidth = 1440;
      screenHeight = 1440 * (1 / bookInteractionAspectRatio);
    } else {
      screenWidth = this.props.utilsStore.deviceSize;
      screenHeight =
        this.props.utilsStore.deviceSize * (1 / bookInteractionAspectRatio);
    }

    return (
      <svg
        height={screenHeight}
        width={screenWidth}
        style={{ position: "absolute", top: 0, left: 0 }}
      >
        {this.renderHotspots()}
      </svg>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBook: state.libraryStore.selectedBook,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(BookHotspots);
