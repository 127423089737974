import React, { Component } from "react";
import { connect } from "react-redux";
//Assets
import UnavailableIcon from "../assets/images/login/unavailable.png";
//Components
import Footer from "../components/Footer";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import WindowResizer from "../components/WindowResizer";
//Styles
import { PrimaryBlack, PrimaryGreen, SemanticBrown } from "../styles/Colors";
import {
  LoginContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/GlobalStyles";
import { Body, SubHeadline, Title3 } from "../styles/Typography";

class Unavailable extends Component {
  renderContent = () => {
    return (
      <VerticalContainer
        style={{
          alignItems: "flex-start",
          width: this.props.utilsStore.deviceType <= 1 ? "100%" : "55%",
        }}
      >
        <Title3 style={{ fontWeight: 600, color: PrimaryBlack }}>
          Oh No...
        </Title3>
        <Body style={{ marginTop: 30, fontWeight: 300, color: PrimaryBlack }}>
          You have accessed this content outside of Malaysia.
        </Body>
        <Body style={{ marginTop: 30, fontWeight: 300, color: PrimaryBlack }}>
          This website is restrictly to Members of National Library, Malaysia.
        </Body>
        <SubHeadline
          style={{
            marginTop: 40,
            color: PrimaryGreen,
            textDecoration: "underline",
          }}
          onClick={() => {
            window.open("https://www.mebooks.co/");
          }}
        >
          Visit Me Books Official Website
        </SubHeadline>
      </VerticalContainer>
    );
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <LoginContainer style={{ backgroundColor: SemanticBrown, height: 550 }}>
          <RowContainer
            style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }}
          >
            {this.renderContent()}
            {this.props.utilsStore.deviceType > 1 && (
              <>
                <div style={{ flexGrow: 1 }} />
                <img src={UnavailableIcon} style={{ width: "40%" }} />
              </>
            )}
          </RowContainer>
        </LoginContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(Unavailable);
