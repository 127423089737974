import React, { Component } from "react";
import { connect } from "react-redux";
//Styles
import { SpecBody } from "../../styles/Typography";
import { White } from "../../styles/Colors";
import { VerticalContainer } from "../../styles/GlobalStyles";
//Components
import AnimatedWrapper from "../../global/AnimatedWrapper";
//Utils
import { childAvatarList, parentAvatarList } from "../../utils/avatarList";

class ChildSelection extends Component {
  renderAvatars = () => {
    let avatarList = [];

    if (this.props.authStore.children.length > 0)
      this.props.authStore.children.map((eachChild, index) => {
        avatarList.push(
          <AnimatedWrapper
            key={index}
            onClick={
              this.props.handleAvatarSelected &&
              this.props.handleAvatarSelected.bind(
                this,
                eachChild.avatar,
                "child",
                eachChild.id
              )
            }
            style={{ marginLeft: 3, marginRight: 3 }}
          >
            <VerticalContainer>
              <div
                style={{
                  border:
                    this.props.selectedMember === eachChild.id
                      ? "solid 5px white"
                      : "none",
                  borderRadius: 100,
                  margin: this.props.selectedMember === eachChild.id ? 0 : 5,
                }}
              >
                <img
                  style={{
                    height: 60,
                    width: 60,
                    margin: 5,
                  }}
                  src={childAvatarList[eachChild.avatar]}
                />
              </div>
              <SpecBody
                style={{
                  color: White,
                }}
              >
                {eachChild.name}
              </SpecBody>
            </VerticalContainer>
          </AnimatedWrapper>
        );
      });

    if (this.props.authStore.user)
      avatarList.push(
        <AnimatedWrapper
          key={4}
          onClick={
            this.props.handleAvatarSelected &&
            this.props.handleAvatarSelected.bind(
              this,
              0,
              "parent",
              this.props.authStore.user.id
            )
          }
          style={{ marginLeft: 3, marginRight: 3 }}
        >
          <VerticalContainer>
            <div
              style={{
                border:
                  this.props.selectedMember === this.props.authStore.user.id
                    ? "solid 5px white"
                    : "none",
                borderRadius: 100,
                margin:
                  this.props.selectedMember === this.props.authStore.user.id
                    ? 0
                    : 5,
              }}
            >
              <img
                style={{
                  height: 60,
                  width: 60,
                  margin: 5,
                }}
                src={parentAvatarList[this.props.authStore.user.avatar]}
              />
            </div>
            <SpecBody
              style={{
                color: White,
              }}
            >
              {this.props.authStore.user && this.props.authStore.user.name}
            </SpecBody>
          </VerticalContainer>
        </AnimatedWrapper>
      );

    return avatarList;
  };

  render() {
    return <>{this.renderAvatars()}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(ChildSelection);
