import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
//Assets
import CharacterIconImage from "../assets/images/sliderIcons/character.png";
//Components
import AnimationWrapper from "../global/AnimatedWrapper";
//Actions
import { updateSelectedSeries } from "../store/actions/libraryActions";
import { PrimaryBrown, PrimaryRed, White } from "../styles/Colors";
import {
  Divider,
  RowContainer,
  VerticalContainer,
} from "../styles/GlobalStyles";
//Styles
import { SliderIcon, SliderTitleRowContainer } from "../styles/SliderStyles";
import { Headline, SpecBody, Title4 } from "../styles/Typography";

class CharacterIcon extends Component {
  render() {
    let uri = this.props.imageUrl;
    if (uri)
      return (
        <AnimationWrapper
          style={{ width: "100%", marginTop: 15 }}
          onClick={this.props.onClick}
        >
          <VerticalContainer>
            <CharacterImage src={uri} />
          </VerticalContainer>
          <SpecBody
            style={{ color: White, marginTop: 10, textAlign: "center" }}
          >
            {this.props.text}
          </SpecBody>
        </AnimationWrapper>
      );
    else return null;
  }
}

class CharacterSlider extends Component {
  handleSeriesSelected = (path, seriesId) => {
    if (this.props.authStore.userAuth) {
      if (
        seriesId &&
        this.props.homeLoginItems &&
        this.props.homeLoginItems.characterSeries.length > 0
      ) {
        let selectedSeriesData;
        this.props.homeLoginItems.characterSeries.map((eachSeries, index) => {
          if (eachSeries.id === seriesId) selectedSeriesData = eachSeries;
        });

        if (selectedSeriesData) {
          this.props.updateSelectedSeries(selectedSeriesData);
          this.props.history.push(path + `/${seriesId}`);
        }
      } else this.props.history.push(path);
      window.scrollTo({ top: 115, behavior: "smooth" });
    } else this.props.history.push("/auth/signin");
  };

  renderCharacters = () => {
    let characterView = [];
    let odd = true;
    let verticalContentList = [];

    if (
      this.props.homeLoginItems &&
      this.props.homeLoginItems.characterSeries.length > 0
    ) {
      this.props.homeLoginItems.characterSeries.map((eachSeries, index) => {
        if (!eachSeries.character) return;

        let imageUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${eachSeries.id}%2Fimage.png?alt=media&token=${eachSeries.image}`;

        if (odd) {
          verticalContentList = [
            <CharacterIcon
              key={index}
              imageUrl={imageUrl}
              text={eachSeries.name}
              onClick={this.handleSeriesSelected.bind(
                this,
                "/series",
                eachSeries.id
              )}
            />,
          ];
          odd = false;
        } else {
          verticalContentList.push(
            <CharacterIcon
              key={index}
              imageUrl={imageUrl}
              text={eachSeries.name}
              onClick={this.handleSeriesSelected.bind(
                this,
                "/series",
                eachSeries.id
              )}
            />
          );
          characterView.push(
            <VerticalContainer
              key={"container" + index}
              style={{ width: "15%", marginLeft: "2%", marginRight: "2%" }}
            >
              {verticalContentList}
            </VerticalContainer>
          );
          odd = true;
        }
      });

      if (!odd && verticalContentList.length > 0)
        characterView.push(
          <>
            <VerticalContainer
              style={{ width: "15%", marginLeft: "2%", marginRight: "2%" }}
              key={"containerodd"}
            >
              {verticalContentList}
            </VerticalContainer>
          </>
        );
    }

    return characterView;
  };

  render() {
    return (
      <CustomVerticalContainer>
        <SliderTitleRowContainer>
          <SliderIcon
            src={CharacterIconImage}
            style={{ width: 120, height: 120, marginLeft: 30 }}
            alt="Home Screen Slider Image"
          />
          <Title4 style={{ color: PrimaryRed }}>{this.props.title}</Title4>
          <Divider
            style={{
              height: 4,
              flexGrow: 1,
              marginLeft: 20,
              marginRight: 30,
            }}
          />
        </SliderTitleRowContainer>
        <RowContainer style={{ width: "100%", alignItems: "flex-start" }}>
          {this.renderCharacters()}
        </RowContainer>
        <Headline
          style={{
            color: White,
            marginTop: 60,
            marginBottom: 50,
            textDecoration: "underline",
          }}
          onClick={this.handleSeriesSelected.bind(this, "/discover", "")}
        >
          View more
        </Headline>
      </CustomVerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    homeLoginItems: state.dataStore.homeLoginItems,
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedSeries: (seriesData) =>
      dispatch(updateSelectedSeries(seriesData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CharacterSlider));

const CustomVerticalContainer = styled(VerticalContainer)`
  width: 100%;
  max-width: 1440px;
  background-color: ${PrimaryBrown};
  padding-top: 15px;
`;

const CharacterImage = styled.img`
  width: 100%;
  border-radius: 200px;
`;
