import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import {
  VerticalContainer,
  RowContainer,
  LoginContainer,
} from "../styles/GlobalStyles";
import { Title2, Body, Footnote } from "../styles/Typography";
import { White, Black, PrimaryRed } from "../styles/Colors";
//Components
import Button from "../global/Button";
import TextInput from "../global/TextInput";
import AvatarSelection from "../global/loginComponents/AvatarSelection";
import AnimatedWrapper from "../global/AnimatedWrapper";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import Loading from "../global/Loading";
//Assets
import CreateChildIcon from "../assets/images/login/createChild.png";
import WindowResizer from "../components/WindowResizer";
//Utils
import { avatarSoundList } from "../utils/avatarSoundList";
import EventBus from "js-event-bus";
//Actions
import { createChild } from "../store/actions/childActions";

const eventBus = EventBus();

class CreateChild extends Component {
  state = {
    name: "",
    nameError: "",
    selectedAge: "2",
    selectedAvatar: 0,
    page: 0,
    loading: false,
  };

  componentDidMount() {
    eventBus.on("create-child-success", this.handleChildCreated);
  }

  componentWillUnmount = async () => {
    eventBus.detach("create-child-success", this.handleChildCreated);
  };

  handleChildCreated = () => {
    this.setState({
      loading: false,
    });
    this.props.history.push("/user/add");
  };

  handleSubmit = () => {
    switch (this.state.page) {
      case 0:
        this.setState(
          {
            nameError: "",
          },
          () => {
            this.handleCheckCondition("name");
            setTimeout(() => {
              if (this.state.nameError === "")
                this.setState({
                  page: 1,
                });
            }, 250);
          }
        );
        break;
      case 1:
        this.setState({
          loading: true,
        });
        this.props.createChild({
          name: this.state.name,
          age: this.state.selectedAge,
          avatar: this.state.selectedAvatar,
        });
        break;
    }
  };

  handleCheckCondition = (type) => {
    switch (type) {
      case "name":
        if (this.state.name.length <= 0)
          this.setState({
            nameError: "Please enter your child's name",
          });
        break;
      default:
        break;
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSelectedAge = (index) => {
    this.setState({
      selectedAge: index,
    });
  };

  handleAvatarSelected = async (avatarIndex) => {
    this.setState(
      {
        selectedAvatar: avatarIndex,
      },
      async () => {
        let soundAvatarLength = avatarSoundList.length;
        if (avatarIndex <= soundAvatarLength - 1) {
          let selectedAvatarSound = avatarSoundList[avatarIndex];
          let selectedAvatarSoundLength = selectedAvatarSound.voiceList.length;
          let selectedSoundIndex = Math.floor(
            Math.random() * selectedAvatarSoundLength
          );
          let selectedSound = selectedAvatarSound.voiceList[selectedSoundIndex];
          this.audio = new Audio(selectedSound);
          this.audio.pause();
          this.audio.currentTime = 0;
          this.audio.play();
        }
      }
    );
  };

  renderAgeSelections = () => {
    let ageList = [];
    for (let i = 2; i <= 7; i++) {
      let selectionAge;
      if (i === 7) selectionAge = "7+";
      else selectionAge = String(i);

      ageList.push(
        <AnimatedWrapper
          key={i}
          onClick={this.handleSelectedAge.bind(this, selectionAge)}
        >
          <VerticalContainer
            style={{
              width: 70,
              height: 70,
              borderRadius: 70,
              backgroundColor:
                this.state.selectedAge === selectionAge ? PrimaryRed : White,
              margin: 10,
              marginTop: 0,
            }}
          >
            <Title2
              style={{
                color: this.state.selectedAge === selectionAge ? White : Black,
              }}
            >
              {selectionAge}
            </Title2>
          </VerticalContainer>
        </AnimatedWrapper>
      );
    }

    return ageList;
  };

  renderInputs = () => {
    return (
      <VerticalContainer
        style={{ alignItems: "flex-start", color: White, marginBottom: 160 }}
      >
        <Title2 style={{ marginBottom: 20, fontSize: 30 }}>
          Add up to (3) storytellers
        </Title2>
        <VerticalContainer>
          <TextInput
            style={{ width: 350 }}
            containerStyle={{ marginTop: 5, marginBottom: 5 }}
            id="name"
            value={this.state.name}
            onChange={this.handleChange}
            placeholder="Your Child's First Name"
            maxLength={15}
          />
          <Footnote style={{ marginTop: 5, color: PrimaryRed, fontSize: 15 }}>
            {this.state.nameError}
          </Footnote>
        </VerticalContainer>
        <Title2 style={{ marginTop: 20, marginBottom: 20, fontSize: 30 }}>
          Select Age
        </Title2>
        <RowContainer
          style={{
            width: "78%",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {this.renderAgeSelections()}
        </RowContainer>
        <Button
          style={{ marginTop: 40 }}
          onClick={this.handleSubmit}
          buttonText={"Next"}
        />
      </VerticalContainer>
    );
  };

  renderPage = () => {
    if (this.state.page === 1)
      return (
        <VerticalContainer style={{ marginBottom: 170 }}>
          <Body style={{ color: White, fontWeight: 300 }}>
            Choose An Avatar for
          </Body>
          <Title2 style={{ color: White }}>"{this.state.name} Avatar"</Title2>
          <AvatarSelection
            type="child"
            iconType="marker"
            onChange={this.handleAvatarSelected}
            selectedAvatar={this.state.selectedAvatar}
          />
          <RowContainer style={{ marginTop: 20 }}>
            <Button onClick={this.handleSubmit} buttonText="Next" />
          </RowContainer>
        </VerticalContainer>
      );
    else
      return (
        <RowContainer style={{ width: "65%" }}>
          {this.renderInputs()}
          <div style={{ flexGrow: 1 }} />
          <img src={CreateChildIcon} style={{ width: "50%" }} />
        </RowContainer>
      );
  };
  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;
    if (
      this.props.authStore.children &&
      this.props.authStore.children.length >= 3
    )
      return <Redirect to="/" />;

    return (
      <VerticalContainer>
        {this.state.loading && <Loading />}
        <WindowResizer />
        <Header />
        <SecondaryHeader screen="Create a Profile for Your Child" />
        <LoginContainer>{this.renderPage()}</LoginContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createChild: (details) => dispatch(createChild(details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateChild);
