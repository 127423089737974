import firebase from "../../utils/firebase";

//==================================== Data Listener =============================================
let dataPointsListener = [];
let dataPoints = [
  { data: "categories", orderBy: "priority" },
  { data: "collections", orderBy: "priority" },
  { data: "tags", orderBy: "title" },
  { data: "roles", orderBy: "name" },
];
let dataLimit =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? 15 : 0;

const handleSnapshot = (snapshot, list) => {
  if (snapshot)
    snapshot.forEach((eachDoc) => {
      list.push(eachDoc.data());
    });
};

export const handleCheckBookCondition = (eachBook, storytellingResource) => {
  let conditionCorrect;
  if (
    eachBook.country.includes("MY") ||
    eachBook.perpetualCountry.includes("MY") ||
    eachBook.subscriptionCountry.includes("MY")
  )
    conditionCorrect = true;
  else conditionCorrect = false;

  if (!storytellingResource && conditionCorrect) {
    if (eachBook.title.includes("(Storytelling Resource)"))
      conditionCorrect = false;
    else conditionCorrect = true;
  }

  return conditionCorrect;
};

export const getDatasetListener = () => {
  return async (dispatch, getState) => {
    try {
      if (dataPointsListener.length === 0)
        dataPoints.map((eachData) => {
          let singleDataListener = getSpecificData(
            dispatch,
            eachData.data,
            dataLimit,
            eachData.orderBy,
            eachData.where
          );
          if (singleDataListener) dataPointsListener.push(singleDataListener);
        });
    } catch (err) {}
  };
};

export const removeDatasetListener = () => {
  return async (dispatch, getState) => {
    try {
      if (dataPointsListener.length > 0)
        dataPointsListener.map((eachDataListener) => {
          eachDataListener();
        });
      dataPointsListener = [];
    } catch (err) {}
  };
};

const getSpecificData = (dispatch, data, limit, orderBy, where) => {
  if (data) {
    let upperCaseData = data.toUpperCase();
    let queryDetails = firebase.firestore().collection(data);
    if (limit) queryDetails = queryDetails.limit(limit);
    if (where) queryDetails = queryDetails.where(where[0], where[1], where[2]);
    if (orderBy) queryDetails = queryDetails.orderBy(orderBy);

    let queryListener = queryDetails.onSnapshot((querySnapshot) => {
      let dataList = [];
      if (querySnapshot)
        querySnapshot.forEach(function (doc) {
          dataList.push(doc.data());
        });

      dispatch({
        type: `UPDATE_${upperCaseData}_DATA`,
        payload: {
          data: dataList,
        },
      });
    });

    return queryListener;
  } else return null;
};

export const getHomePreviewItems = () => {
  return async (dispatch, getState) => {
    try {
      if (!getState().dataStore.homeItems) {
        //INFO : International Titles
        let internationalSeriesSnapshot = await firebase
          .firestore()
          .collection("series")
          .where("id", "in", [
            "series_fb7d8912-4d5b-4421-8bdf-caea8bbce25e",
            "series_f7eeeaec-de18-4acf-9f10-a96ab8bbbebd",
            "series_285407da-84e1-41c3-8c62-79feff43e75e",
          ])
          .get();

        //INFO : Malaysia Titles
        let malaysiaSeriesSnapshot = await firebase
          .firestore()
          .collection("series")
          .where("id", "in", [
            "series_4b7be5e6-d3e4-492e-bf6c-1cc4f9adf962",
            "series_293335c5-3938-4420-93ff-f109eed8fa75",
            "series_21798445-1321-4471-858a-e5b367031d4b",
          ])
          .get();

        //INFO : Recommended Book Titles
        let recommendedBooksSnapshot = await firebase
          .firestore()
          .collection("books")
          .orderBy("title")
          .where("published", "==", true)
          .limit(5)
          .get();

        let internationalSeries = [];
        let malaysiaSeries = [];
        let recommendedBooks = [];

        handleSnapshot(internationalSeriesSnapshot, internationalSeries);
        handleSnapshot(malaysiaSeriesSnapshot, malaysiaSeries);
        handleSnapshot(recommendedBooksSnapshot, recommendedBooks);

        dispatch({
          type: "UPDATE_HOME_DATA",
          payload: {
            homeItems: {
              internationalSeries: internationalSeries,
              malaysiaSeries: malaysiaSeries,
              recommendedBooks: recommendedBooks,
            },
          },
        });
      }
    } catch (err) {}
  };
};

export const getHomePreviewLoginItems = () => {
  return async (dispatch, getState) => {
    try {
      if (!getState().dataStore.homeLoginItems) {
        let bannerSnapshot = await firebase
          .firestore()
          .collection("banners")
          .limit(3)
          .get();

        let characterSeriesSnapshot = await firebase
          .firestore()
          .collection("series")
          .where("character", "==", true)
          .orderBy("priority")
          .limit(12)
          .get();

        //INFO : Movie Stars Series
        let movieStarBookSnapshot = await firebase
          .firestore()
          .collection("books")
          .where(
            "tags",
            "array-contains",
            "ed160ed2-3820-46d4-8099-f7df46f54f78"
          )
          .where("published", "==", true)
          .limit(5)
          .get();

        //INFO : Oxford Series
        let oxfordSeriesSnapshot = await firebase
          .firestore()
          .collection("series")
          .where(
            "tags",
            "array-contains",
            "b4c56868-fe9d-4672-b005-6898e463dde0"
          )
          .limit(5)
          .get();

        //INFO : Malaysia Titles
        let malaysiaSeriesSnapshot = await firebase
          .firestore()
          .collection("series")
          .where("id", "in", [
            "series_4b7be5e6-d3e4-492e-bf6c-1cc4f9adf962",
            "series_293335c5-3938-4420-93ff-f109eed8fa75",
            "series_21798445-1321-4471-858a-e5b367031d4b",
          ])
          .get();

        let banners = [];
        let characterSeries = [];
        let movieStarBooks = [];
        let oxfordSeries = [];
        let malaysiaSeries = [];

        handleSnapshot(bannerSnapshot, banners);
        handleSnapshot(characterSeriesSnapshot, characterSeries);
        handleSnapshot(movieStarBookSnapshot, movieStarBooks);
        handleSnapshot(oxfordSeriesSnapshot, oxfordSeries);
        handleSnapshot(malaysiaSeriesSnapshot, malaysiaSeries);

        dispatch({
          type: "UPDATE_HOME_LOGIN_DATA",
          payload: {
            homeLoginItems: {
              banners: banners,
              characterSeries: characterSeries,
              movieStarBooks: movieStarBooks,
              oxfordSeries: oxfordSeries,
              malaysiaSeries: malaysiaSeries,
            },
          },
        });
      }
    } catch (err) {}
  };
};

export const getBooks = (
  condition,
  startAfter,
  reset,
  bookLimit,
  storytellingResource
) => {
  return async (dispatch, getState) => {
    try {
      let bookQuery = firebase
        .firestore()
        .collection("books")
        .where("published", "==", true)
        .orderBy("title")
        .limit(bookLimit);

      if (condition) {
        let boolean;
        let query = condition.query;
        switch (condition.attribute) {
          case "tags":
          case "writer":
          case "narrator":
          case "publisher":
          case "illustrator":
            boolean = "array-contains";
            break;
          case "id":
            boolean = "in";
            let idList = getState().libraryStore.searchedBooks;
            query = idList.splice(0, bookLimit);
            break;
          case "languageId":
          case "ageId":
            query = String(query);
          default:
            boolean = "==";
            break;
        }

        bookQuery = bookQuery.where(condition.attribute, boolean, query);
      }

      if (startAfter) bookQuery = bookQuery.startAfter(startAfter);

      let bookSnapshot = await bookQuery.get();
      let bookList = [];
      handleSnapshot(bookSnapshot, bookList);

      let notIdLoop = !condition || condition.attribute !== "id";
      let lastBookName = "";

      if (!notIdLoop) {
        bookList = filterBooks(bookList, storytellingResource);
        let newLength = bookList.length;

        if (
          getState().libraryStore.searchedBooks.length > 0 &&
          newLength < bookLimit
        ) {
          let lengthDifference = bookLimit - newLength;
          getBooksLoop(
            dispatch,
            getState,
            bookQuery,
            lastBookName,
            lengthDifference,
            notIdLoop,
            storytellingResource
          );
        }
      } else if (bookList.length === bookLimit && notIdLoop) {
        lastBookName = bookList[bookLimit - 1].title;

        let previousLength = bookList.length;
        bookList = filterBooks(bookList, storytellingResource);
        let newLength = bookList.length;

        if (newLength < previousLength) {
          let lengthDifference = previousLength - newLength;
          getBooksLoop(
            dispatch,
            getState,
            bookQuery,
            lastBookName,
            lengthDifference,
            notIdLoop,
            storytellingResource
          );
        }
      }

      if (reset)
        dispatch({
          type: "UPDATE_BOOKS_DATA",
          payload: {
            data: bookList,
            bookPagination: lastBookName,
          },
        });
      else {
        let oldBooks = JSON.parse(JSON.stringify(getState().dataStore.books));
        let newBooks = oldBooks.concat(bookList);
        dispatch({
          type: "UPDATE_BOOKS_DATA",
          payload: {
            data: newBooks,
            bookPagination: lastBookName,
          },
        });
      }
    } catch (err) {}
  };
};

const getBooksLoop = async (
  dispatch,
  getState,
  bookQuery,
  startAfter,
  bookLimit,
  notIdLoop,
  storytellingResource
) => {
  try {
    if (!notIdLoop) {
      let idList = getState().libraryStore.searchedBooks;
      let query = idList.splice(0, bookLimit);
      bookQuery = firebase
        .firestore()
        .collection("books")
        .where("published", "==", true)
        .orderBy("title")
        .where("id", "in", query);
    } else bookQuery = bookQuery.startAfter(startAfter).limit(bookLimit);

    let bookSnapshot = await bookQuery.get();
    let newBookList = [];
    handleSnapshot(bookSnapshot, newBookList);

    let lastBookName = "";

    if (!notIdLoop) {
      newBookList = filterBooks(newBookList, storytellingResource);
      let newLength = newBookList.length;
      if (
        getState().libraryStore.searchedBooks.length > 0 &&
        newLength < bookLimit
      ) {
        let lengthDifference = bookLimit - newLength;
        getBooksLoop(
          dispatch,
          getState,
          bookQuery,
          lastBookName,
          lengthDifference,
          notIdLoop,
          storytellingResource
        );
      }
    } else if (newBookList.length === bookLimit && notIdLoop) {
      lastBookName = newBookList[bookLimit - 1].title;
      let previousLength = newBookList.length;
      newBookList = filterBooks(newBookList, storytellingResource);
      let newLength = newBookList.length;
      if (newLength < previousLength) {
        let lengthDifference = previousLength - newLength;
        getBooksLoop(
          dispatch,
          getState,
          bookQuery,
          lastBookName,
          lengthDifference,
          notIdLoop,
          storytellingResource
        );
      }
    }

    let oldBooks = JSON.parse(JSON.stringify(getState().dataStore.books));
    let newBooks = oldBooks.concat(newBookList);

    dispatch({
      type: "UPDATE_BOOKS_DATA",
      payload: {
        data: newBooks,
        bookPagination: lastBookName,
      },
    });
  } catch (err) {}
};

const filterBooks = (bookList, storytellingResource) => {
  let filteredBooks = [];
  bookList.map((eachBook) => {
    if (handleCheckBookCondition(eachBook, storytellingResource))
      filteredBooks.push(eachBook);
  });

  return filteredBooks;
};

export const clearBooks = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: "UPDATE_BOOKS_DATA",
      payload: {
        data: [],
        bookPagination: "",
      },
    });
  };
};

export const getStickers = (type, startAfter, reset, stickerLimit) => {
  return async (dispatch, getState) => {
    try {
      let stickerQuery = firebase
        .firestore()
        .collection("stickers")
        .where("type", "==", type)
        .orderBy("name")
        .limit(stickerLimit);

      if (startAfter) stickerQuery = stickerQuery.startAfter(startAfter);

      let stickerSnapshot = await stickerQuery.get();
      let stickerList = [];
      handleSnapshot(stickerSnapshot, stickerList);

      let lastSticker = "";
      if (stickerList.length === stickerLimit)
        lastSticker = stickerList[stickerLimit - 1].name;

      if (reset)
        dispatch({
          type: "UPDATE_STICKERS_DATA",
          payload: {
            data: stickerList,
            stickerPagination: lastSticker,
          },
        });
      else {
        let oldStickers = JSON.parse(
          JSON.stringify(getState().dataStore.stickers)
        );
        let newStickers = oldStickers.concat(stickerList);
        dispatch({
          type: "UPDATE_STICKERS_DATA",
          payload: {
            data: newStickers,
            stickerPagination: lastSticker,
          },
        });
      }
    } catch (err) {}
  };
};
