import React, { Component } from "react";
// import AudioManager from '../utils/audioManager';
import ButtonAudio from "../assets/audio/utils_button_audio.mp3";

class IconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 1,
    };
    this.audio = new Audio(ButtonAudio);
    this.allTimeouts = [];
  }

  handleMouseEnter = () => {
    let count = 1;
    for (let i = 1; i >= 0.9; i = i - 0.02) {
      count += 1;
      let currentTimeout = setTimeout(() => {
        this.setState({
          scale: i,
        });
      }, count * 35);
      this.allTimeouts.push(currentTimeout);
    }
  };

  handleMouseLeave = () => {
    if (this.allTimeouts.length > 0)
      this.allTimeouts.map((eachTimeout) => {
        clearTimeout(eachTimeout);
      });
    this.allTimeouts = [];
    this.setState({
      scale: 1,
    });
  };

  handleClick = async () => {
    this.audio.pause();
    this.audio.currentTime = 0;
    this.audio.play();

    if (this.props.onClick) this.props.onClick();
  };

  render() {
    return (
      <div
        style={{
          ...this.props.style,
          ...{ transform: `scale(${this.state.scale})`, cursor: "pointer" },
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
      >
        <img src={this.props.iconImage} style={this.props.imageStyle}></img>
      </div>
    );
  }
}

export default IconButton;
