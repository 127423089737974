import React, { Component } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
//Styles
import { SpecBody, SubFootnote } from "../styles/Typography";
import { White, SemanticBrown, LoadingGrey } from "../styles/Colors";
import { VerticalContainer, RowContainer } from "../styles/GlobalStyles";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
import Pagination from "../global/Pagination";
//Actions
import { getBooks, clearBooks } from "../store/actions/dataActions";
import { searchBook } from "../store/actions/libraryActions";
//Assets
import LoadingIcon from "../assets/icons/loadingIcon.png";
//Utils
import EventBus from "js-event-bus";

const eventBus = EventBus();
let bookLimit = 10;

class SearchBook extends Component {
  state = {
    currentPage: 1,
    pathName: "",
    loading: false,
  };

  componentDidMount() {
    this.props.clearBooks();
    this.handleSearchBook();
    eventBus.on("search-success", this.handleFirestoreSearchBooks.bind(this));
  }

  componentWillUnmount() {
    eventBus.detach(
      "search-success",
      this.handleFirestoreSearchBooks.bind(this)
    );
  }

  handleFirestoreSearchBooks = () => {
    this.setState(
      {
        loading: false,
      },
      () => {
        this.props.getBooks(
          {
            attribute: "id",
          },
          "",
          true,
          bookLimit,
          true
        );
      }
    );
  };

  handleSearchBook = () => {
    let pathNameList = this.props.location.pathname.split("/");
    if (pathNameList.length > 0) {
      let pathName = pathNameList[pathNameList.length - 1];

      if (pathName) {
        this.setState(
          {
            pathName: pathName,
            loading: true,
          },
          () => {
            this.props.searchBook(pathName);
          }
        );
      } else this.props.history.push("/");
    }
  };

  handlePagination = (type) => {
    switch (type) {
      case "minus":
        if (this.state.currentPage > 1)
          this.setState({
            currentPage: this.state.currentPage - 1,
          });
        break;
      case "plus":
        if (
          this.props.searchedBooks.length > 0 &&
          this.state.currentPage ===
            Math.ceil(this.props.books.length / bookLimit)
        ) {
          this.props.getBooks(
            {
              attribute: "id",
            },
            "",
            false,
            bookLimit,
            true
          );
          this.setState({
            currentPage: this.state.currentPage + 1,
          });
        } else if (this.props.books.length > this.state.currentPage * bookLimit)
          this.setState({
            currentPage: this.state.currentPage + 1,
          });
        break;
    }
  };

  renderNextAvailable = () => {
    return (
      (this.props.searchedBooks.length > 0 &&
        this.state.currentPage ===
          Math.ceil(this.props.books.length / bookLimit)) ||
      this.props.books.length > this.state.currentPage * bookLimit
    );
  };

  renderResultLabel = () => {
    if (this.state.selectedCategory)
      return this.state.selectedCategory.discoverName + " Category";
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;
    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            backgroundColor: SemanticBrown,
          }}
        >
          <VerticalContainer
            style={{ width: "90%", marginTop: 50, marginBottom: 50 }}
          >
            <SubFootnote style={{ fontWeight: 600, alignSelf: "flex-start" }}>
              Searching Books
            </SubFootnote>
            <VerticalContainer
              style={{
                backgroundColor: "#888",
                borderRadius: 20,
                padding: 10,
                marginTop: 10,
                alignSelf: "flex-start",
              }}
            >
              <SpecBody style={{ color: White }}>
                Result for {this.state.pathName}
              </SpecBody>
            </VerticalContainer>
          </VerticalContainer>
          {this.state.loading ? (
            <VerticalContainer style={{ padding: 50 }}>
              <LoadingContainer>
                <img
                  src={LoadingIcon}
                  style={{
                    width: 110,
                    height: 110,
                    marginBottom: 50,
                  }}
                />
                <SpecBody
                  style={{
                    marginLeft: 40,
                    color: White,
                  }}
                >
                  Searching ...
                </SpecBody>
              </LoadingContainer>
            </VerticalContainer>
          ) : (
            <Pagination
              style={{ width: "100%" }}
              type="book"
              data={this.props.books}
              limit={bookLimit}
              currentPage={this.state.currentPage}
              storytellingResource={true}
              nextAvailable={this.renderNextAvailable()}
              handlePagination={this.handlePagination}
            />
          )}
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    books: state.dataStore.books,
    searchedBooks: state.libraryStore.searchedBooks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBooks: (condition, startAfter, reset, bookLimit, storytellingResource) =>
      dispatch(
        getBooks(condition, startAfter, reset, bookLimit, storytellingResource)
      ),
    clearBooks: () => dispatch(clearBooks()),
    searchBook: (searchTitle) => dispatch(searchBook(searchTitle)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBook);

const LoadingContainer = styled(RowContainer)`
  width: ${400}px;
  height: ${60}px;
  margin-top: 30px;
  border-radius: 30px;
  background: ${LoadingGrey};
`;
