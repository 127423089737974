import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
//Styles
import {
  Title1,
  SubTitle2,
  Title4,
  SpecTitle4,
  Body,
  SpecHeadline,
  SubFootnote,
} from "../../styles/Typography";
import { White, OverlayOpacity, PrimaryRed } from "../../styles/Colors";
import {
  VerticalContainer,
  RowContainer,
  Overlay,
  bookInteractionAspectRatio,
  Divider,
} from "../../styles/GlobalStyles";
//Component
import Button from "../../global/Button";
import Tag from "../../global/Tag";
import Circle from "../../global/Circle";
import BookCatalog from "../../global/BookCatalog";
//Assets
import TooFastVisual from "../../assets/images/lastPage/readingCat.png";
import TryAgainVisual from "../../assets/images/lastPage/tryOver.png";
import StickerBackground from "../../assets/background/stickerBackground.jpg";
import CropStageBackground from "../../assets/background/cropStageBackground.png";
import ReadImage from "../../assets/images/homeScreen/readImage.png";
//Utils
import { childAvatarList, parentAvatarList } from "../../utils/avatarList";

let screenWidth = 0;
let screenHeight = 0;
let bookCatalogSize = 0;

class LastPage extends Component {
  state = {
    screen: "recommendation",
  };

  handleNextRound = (screen) => {
    this.setState({
      screen: screen,
    });
  };

  renderImageSize = () => {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    return divSize;
  };

  renderSVG = () => {
    if (this.state.screen === "stickerReadFast")
      return (
        <svg height={360} width={830}>
          <path
            d="M10.4744 196.621C17.3051 178.652 33.9549 98.4972 41.4259 60.6661H63.8389H92.6558C99.4154 54.755 118.057 41.2777 138.549 34.6573C164.165 26.3818 158.828 25.1995 254.885 9.83061C332.797 -5.53826 292.24 9.83061 306.115 9.83061H407.508C437.392 9.83061 593.217 19.2884 634.841 25.1995C676.466 31.1106 676.466 35.8395 713.821 42.9328C751.176 50.0261 757.58 68.9417 768.253 77.2172C778.926 85.4927 817.349 207.261 822.685 219.084C828.021 230.906 763.984 285.288 749.042 307.75C734.1 330.212 695.677 324.301 644.447 342.035C593.217 359.768 448.065 324.301 391.498 324.301H100.127C65.9735 324.301 56.368 319.572 25.4165 316.026C-5.53501 312.479 1.93605 219.084 10.4744 196.621Z"
            fill="#644E9F"
            stroke="#FFCB31"
            strokeWidth="5"
          />
        </svg>
      );
    if (this.state.screen === "stickerNarrateLow")
      return (
        <svg height={440} width={830}>
          <path
            d="M780.761 179.161C775.032 200.415 762.595 294.745 757.092 339.253L735.813 340.018L708.453 341.001C702.284 348.147 685.151 364.548 665.974 372.992C642.002 383.546 647.118 384.747 556.566 406.004C483.239 426.641 521.099 407.279 507.926 407.752L411.66 411.213C383.287 412.233 234.944 406.488 195.176 400.994C155.408 395.5 155.209 389.969 119.445 382.946C83.6799 375.924 76.8046 354.016 66.3233 344.7C55.8421 335.384 14.2418 194.255 8.67802 180.608C3.11426 166.961 61.6268 101.162 74.8687 74.3763C88.1106 47.591 124.839 53.1941 172.733 30.7019C220.626 8.20961 359.93 44.7427 413.636 42.812L690.273 32.867C722.7 31.7013 732.018 36.9051 761.554 39.9974C791.089 43.0897 787.923 152.595 780.761 179.161Z"
            fill="#FFCB31"
            stroke="#644E9F"
            strokeWidth="5"
          />
        </svg>
      );
  };

  renderSVGContent = () => {
    return (
      <RowContainer style={{ position: "absolute", marginTop: -70 }}>
        <img
          src={
            this.state.screen === "stickerReadFast"
              ? TooFastVisual
              : TryAgainVisual
          }
          style={{
            width: this.renderImageSize() * 0.25,
            height: this.renderImageSize() * 0.2,
            marginLeft: -250,
          }}
        />
        <CustomSubTitle2
          style={{
            color: White,
            marginLeft: 50,
            width: this.state.screen === "stickerReadFast" ? "50%" : "31%",
          }}
        >
          {this.state.screen === "stickerReadFast"
            ? "Read a little longer ?"
            : "Would you like to try a voice-over ?"}
        </CustomSubTitle2>
      </RowContainer>
    );
  };

  renderBottomButton = () => {
    return (
      <RowContainer>
        <Button buttonText="Start Again" />
        {this.state.screen === "stickerNarrateLow" && (
          <Button
            buttonText="Voice-Over Tips ?"
            style={{ marginLeft: 30, width: 240 }}
          />
        )}
        <Button
          buttonText="Next Round"
          style={{ marginLeft: 30 }}
          onClick={this.handleNextRound.bind(this, "recommendation")}
        />
      </RowContainer>
    );
  };

  renderFamilyMembersNarration = () => {
    let memberView = [];

    if (this.props.authStore.children.length > 0)
      this.props.authStore.children.map((eachChild, index) => {
        memberView.push(
          <VerticalContainer
            style={{
              marginLeft: 15,
              marginRight: 15,
            }}
            key={index}
          >
            <img
              src={childAvatarList[eachChild.avatar]}
              style={{
                width: 70,
                height: 70,
                marginLeft: 5,
                marginRight: 5,
              }}
            />
            <SpecHeadline style={{ color: White }}>
              {eachChild.name}
            </SpecHeadline>
            <Circle
              style={{
                borderColor: PrimaryRed,
                width: 60,
                height: 60,
                marginTop: 10,
              }}
              title="3"
            />
          </VerticalContainer>
        );
      });

    if (this.props.authStore.user)
      memberView.push(
        <VerticalContainer
          style={{
            marginLeft: 15,
            marginRight: 15,
          }}
          key="parent"
        >
          <img
            src={parentAvatarList[this.props.authStore.user.avatar]}
            style={{
              width: 70,
              height: 70,
              marginLeft: 5,
              marginRight: 5,
            }}
          />
          <SpecHeadline style={{ color: White }}>
            {this.props.authStore.user.name}
          </SpecHeadline>
          <Circle
            style={{
              borderColor: PrimaryRed,
              width: 60,
              height: 60,
              marginTop: 10,
            }}
            title="3"
          />
        </VerticalContainer>
      );

    return memberView;
  };

  renderRecommendation = () => {
    return (
      <RowContainer style={{ width: screenWidth, height: screenHeight }}>
        <VerticalContainer
          style={{
            height: "100%",
            width: "50%",
            backgroundImage: "url(" + CropStageBackground + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <VerticalContainer
            style={{
              width: "100%",
              height: "100%",
              background: OverlayOpacity,
              justifyContent: "flex-end",
            }}
          >
            <VerticalContainer
              style={{ alignItems: "flex-start", width: "90%" }}
            >
              <RowContainer style={{ width: "100%" }}>
                <Title4 style={{ color: PrimaryRed }}>
                  (Unsaved) Voice-over
                </Title4>
                <Divider
                  style={{
                    height: 8,
                    flexGrow: 1,
                    marginLeft: 20,
                  }}
                />
              </RowContainer>

              <Body style={{ color: White }}>“Untitled”</Body>
              <RowContainer style={{ marginTop: 10 }}>
                <Button buttonText="Save" style={{ width: 120 }} />
                <Button
                  buttonText="Start again"
                  style={{ width: 180, marginLeft: 10 }}
                />
                <Button
                  buttonText="Load"
                  style={{ width: 120, marginLeft: 10 }}
                />
              </RowContainer>
              <RowContainer style={{ marginTop: 30 }}>
                <SubFootnote style={{ marginRight: 20, color: White }}>
                  Storyteller(s)
                </SubFootnote>
                <Tag text="Narration created" width={150} height={40} />
              </RowContainer>
              <RowContainer style={{ marginTop: 10, marginBottom: 40 }}>
                {this.renderFamilyMembersNarration()}
              </RowContainer>
            </VerticalContainer>
          </VerticalContainer>
        </VerticalContainer>
        <VerticalContainer style={{ height: "100%", width: "50%" }}>
          <VerticalContainer
            style={{
              height: "90%",
              width: "90%",
              maxHeight: "90%",
              overflow: "auto",
              justifyContent: "flex-start",
            }}
          >
            <RowContainer
              style={{ width: "100%", marginBottom: 50, marginTop: 20 }}
            >
              <SpecTitle4 style={{ color: PrimaryRed }}>
                Recommended Books
              </SpecTitle4>
              <Divider
                style={{
                  height: 8,
                  flexGrow: 1,
                  marginLeft: 20,
                }}
              />
            </RowContainer>
            <BookCatalog
              width={bookCatalogSize}
              data={this.props.selectedBook}
            />
            <BookCatalog
              width={bookCatalogSize}
              data={this.props.selectedBook}
            />
            <BookCatalog
              width={bookCatalogSize}
              data={this.props.selectedBook}
            />
            <BookCatalog
              width={bookCatalogSize}
              data={this.props.selectedBook}
            />
            <BookCatalog
              width={bookCatalogSize}
              data={this.props.selectedBook}
            />
          </VerticalContainer>
        </VerticalContainer>
      </RowContainer>
    );
  };

  renderContent = () => {
    let content;
    switch (this.state.screen) {
      case "stickerNarrateLow":
      case "stickerReadFast":
        content = (
          <>
            {this.renderSVG()}
            {this.renderSVGContent()}
            {this.renderBottomButton()}
          </>
        );
        break;
      case "stickerWon":
        content = (
          <VerticalContainer style={{ position: "absolute", bottom: 30 }}>
            <RowContainer>
              <Title1 style={{ color: White }}>200&nbsp;</Title1>
              <CustomSubTitle2 style={{ color: White, marginTop: 10 }}>
                points
              </CustomSubTitle2>
            </RowContainer>
            <img
              src={ReadImage}
              style={{ width: this.renderImageSize() * 0.18, margin: 30 }}
            />
            <Button
              buttonText="Hooray!"
              onClick={this.handleNextRound.bind(this, "recommendation")}
            />
            <SpecHeadline style={{ marginTop: 10, color: White }}>
              Press to continue
            </SpecHeadline>
          </VerticalContainer>
        );

      default:
        break;
    }

    return <Overlay style={{ background: OverlayOpacity }}>{content}</Overlay>;
  };

  render() {
    if (this.props.utilsStore.deviceSize > 1440) {
      screenWidth = 1440;
      screenHeight = 1440 * (1 / bookInteractionAspectRatio);
    } else {
      screenWidth = this.props.utilsStore.deviceSize;
      screenHeight =
        this.props.utilsStore.deviceSize * (1 / bookInteractionAspectRatio);
    }

    bookCatalogSize = (screenWidth / 2) * 0.9;

    return (
      <>
        {this.state.screen === "recommendation" ? (
          <>{this.renderRecommendation()}</>
        ) : (
          <VerticalContainer
            style={{
              width: screenWidth,
              height: screenHeight,
              position: "relative",
              backgroundImage: "url(" + StickerBackground + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            {this.renderContent()}
          </VerticalContainer>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
    selectedBook: state.libraryStore.selectedBook,
  };
};

export default connect(mapStateToProps)(LastPage);

const CustomSubTitle2 = styled(SubTitle2)`
  font-size: 58px;
`;
