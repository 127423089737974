import React, { Component } from "react";
import styled from "styled-components";
import DownIcon from "../assets/icons/downIcon.png";
import { RowContainer, VerticalContainer } from "../styles/GlobalStyles";
import { SpecHeadline } from "../styles/Typography";

class DropDown extends Component {
  state = {
    dropdownOpen: false,
  };

  handleDropDown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  render() {
    return (
      <div style={{ width: this.props.width }}>
        <DropDownHeader
          onClick={this.handleDropDown}
          style={{
            height: this.state.dropdownOpen ? "auto" : 55,
          }}
        >
          <RowContainer style={{ margin: 20 }}>
            <SpecHeadline
              style={{ fontWeight: this.props.selectedName ? 900 : 300 }}
            >
              {this.props.selectedName || this.props.type}
            </SpecHeadline>
            <div style={{ flexGrow: 1 }} />
            <img
              style={{ width: 20, height: 10, marginLeft: 10 }}
              src={DownIcon}
            />
          </RowContainer>
          {this.state.dropdownOpen && (
            <VerticalContainer
              style={{
                width: this.props.width - 40,
                margin: 20,
                alignItems: "flex-start",
              }}
            >
              {this.props.options.map((option, index) => (
                <ListItem
                  onClick={this.props.handleSelect.bind(
                    this,
                    option.value,
                    option.name
                  )}
                  key={index}
                  style={{
                    backgroundColor:
                      this.props.option === option.value ? "#f0f0f0" : "ffffff",
                  }}
                >
                  <SpecHeadline>{option.name}</SpecHeadline>
                </ListItem>
              ))}
            </VerticalContainer>
          )}
        </DropDownHeader>
      </div>
    );
  }
}

export default DropDown;

const DropDownHeader = styled.div`
  height: 55px;
  border-radius: 30px;
  border: 1px solid;
  background-color: #ffffff;
  color: #888888;
`;

const ListItem = styled.div`
  list-style: none;
  padding: 5px 0px;
  cursor: pointer;
`;
