import styled from "styled-components";
import { Black, PrimaryGreen } from "./Colors";

export const BigTitle = styled.h1`
  font-family: museoSans;
  font-size: 66px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Title1 = styled.h1`
  font-family: museoSans;
  font-size: 50px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Title2 = styled.h2`
  font-family: visbyRound;
  font-size: 36px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubTitle2 = styled.h2`
  font-family: doctorSoos;
  font-size: 36px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Title3 = styled.h3`
  font-family: museoSans;
  font-size: 30px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Title4 = styled.h4`
  font-family: museoSans;
  font-weight: 600;
  font-size: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubTitle4 = styled.h4`
  font-family: doctorSoos;
  font-size: 25px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SpecTitle4 = styled.h4`
  font-family: visbyRound;
  font-size: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Body = styled.p`
  font-family: visbyRound;
  font-size: 21px;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubBody = styled.p`
  font-family: museoSans;
  font-weight: 600;
  font-size: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SpecBody = styled.p`
  font-family: doctorSoos;
  font-size: 21px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Headline = styled.a`
  font-family: "visbyRound";
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;
  color: ${Black};
  margin-top: 0px;
  margin-bottom: 0px;

  &:hover {
    color: ${PrimaryGreen};
  }
`;

export const SubHeadline = styled(Headline)`
  font-size: 16px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const ButtonHeadline = styled.a`
  font-family: doctorSoos;
  font-size: 30px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubButtonHeadline = styled.a`
  font-family: dkLemon;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SpecHeadline = styled.a`
  font-family: "visbyRound";
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Footnote = styled.h6`
  font-family: visbyRound;
  font-size: 19px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubFootnote = styled.h6`
  font-family: visbyRound;
  font-size: 19px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SpecFootnote = styled.h6`
  font-family: doctorSoos;
  font-size: 19px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Caption = styled.a`
  font-family: "visbyRound";
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubCaption = styled.a`
  font-family: "visbyRound";
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  color: ${Black};
  margin-top: 0px;
  margin-bottom: 0px;

  &:hover {
    color: ${PrimaryGreen};
  }
`;
