import { combineReducers } from "redux";
import utilsReducer from "./utilsReducer";
import authReducer from "./authReducer";
import dataReducer from "./dataReducer";
import childReducer from "./childReducer";
import libraryReducer from "./libraryReducer";

const rootReducer = combineReducers({
  utilsStore: utilsReducer,
  authStore: authReducer,
  dataStore: dataReducer,
  childStore: childReducer,
  libraryStore: libraryReducer,
});

export default rootReducer;
