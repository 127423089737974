import React, { Component } from "react";
import { connect } from "react-redux";
//Styles
import {
  RowContainer,
  VerticalContainer,
  bookInteractionAspectRatio,
  Divider,
  SemanticLayout,
} from "../../styles/GlobalStyles";
import { Title4, Body, SpecHeadline } from "../../styles/Typography";
import { PrimaryGreen, PrimaryRed, White } from "../../styles/Colors";
//Components
import Button from "../../global/Button";
import IconButton from "../../global/IconButton";
import Circle from "../../global/Circle";
import Tag from "../../global/Tag";
import BookImage from "../../components/catalog/BookImage";
import Pagination from "../../global/Pagination";
import SaveButton from "../../assets/icons/save.png";
//Assets
import UpperStage from "../../assets/background/upperStage.jpg";
import CropStage from "../../assets/background/cropStageBackground.png";
import Fairy from "../../assets/images/narrationScreen/fairy.png";
import ResetButton from "../../assets/icons/resetButton.png";
import NarrationGirl from "../../assets/images/lastPage/narrationGirl.png";
//Utils
import { childAvatarList, parentAvatarList } from "../../utils/avatarList";

class NarrationModal extends Component {
  handleSaveNarrationModal = () => {
    if (
      this.props.bookNarration &&
      this.props.bookNarration.narrationList.length === 5
    ) {
    } else this.props.handleModal("svgSave", "save");
  };

  renderSelectedNarrationName = () => {
    let title = ' "Untitled" ';
    if (this.props.selectedNarration) {
      title = this.props.selectedNarration.name;
      title = ' " ' + title + ' " ';
    }

    return title;
  };

  renderHeader = () => {
    if (this.props.screen !== "narration")
      return (
        <RowContainer
          style={{
            width: "90%",
            marginTop: 30,
            alignItems: "flex-start",
          }}
        >
          <img src={NarrationGirl} style={{ width: 120, marginLeft: "3%" }} />
          <VerticalContainer style={{ marginTop: 20 }}>
            <Title4 style={{ color: PrimaryGreen }}>Saved</Title4>
            <Tag text="Voice-Overs" height={30} type="narration" />
          </VerticalContainer>
          <div style={{ flexGrow: 1 }} />
          <IconButton
            iconImage={SaveButton}
            imageStyle={{
              width: 40,
              height: 40,
              margin: 10,
            }}
          />
        </RowContainer>
      );
    else
      return (
        <>
          <RowContainer style={{ width: "100%" }}>
            <img
              style={{
                width: 105,
                height: 105,
              }}
              src={Fairy}
            />
            <VerticalContainer style={{ alignItems: "flex-start" }}>
              <Title4 style={{ color: PrimaryRed }}>
                {this.props.selectedNarrationId ? "(Current)" : "(Unsaved)"}{" "}
                Voice Over
              </Title4>
              <Body style={{ color: White }}>
                {" "}
                {this.renderSelectedNarrationName()}
              </Body>
            </VerticalContainer>
            <Divider
              style={{
                height: 7,
                flexGrow: 1,
                marginLeft: 20,
              }}
            />
          </RowContainer>
          <RowContainer style={{ alignSelf: "flex-end", marginTop: -30 }}>
            {!this.props.selectedNarrationId && (
              <Button
                buttonText="Save As"
                style={{ marginRight: 10 }}
                onClick={this.handleSaveNarrationModal}
              />
            )}
            <Button
              style={{ width: "auto", paddingLeft: 15, paddingRight: 15 }}
              buttonText="Other Voice-over(s)"
              onClick={this.props.handleModal.bind(this, "narration", "load")}
            />
          </RowContainer>
        </>
      );
  };

  renderStorytellers = () => {
    let memberView = [];

    if (this.props.authStore.children.length > 0 && this.props.authStore.user) {
      let memberList = JSON.parse(
        JSON.stringify(this.props.authStore.children)
      );
      memberList.push(JSON.parse(JSON.stringify(this.props.authStore.user)));

      memberList.map((eachMember, index) => {
        let avatarImage;
        if (eachMember.role) avatarImage = parentAvatarList[eachMember.avatar];
        else avatarImage = childAvatarList[eachMember.avatar];

        let narrateCount = 0;
        this.props.hotspots.map((eachHotspot) => {
          if (eachHotspot.Member === eachMember.id) narrateCount += 1;
        });

        memberView.push(
          <VerticalContainer
            style={{
              marginRight: 15,
            }}
            key={index}
          >
            <img
              src={avatarImage}
              style={{
                width: 80,
                height: 80,
                margin: 5,
              }}
            />
            <SpecHeadline style={{ color: White }}>
              {eachMember.name}
            </SpecHeadline>
            <Circle
              style={{
                borderColor: PrimaryRed,
                width: 60,
                height: 60,
                margin: 5,
              }}
              title={narrateCount}
            />
            <Tag text="Narration created" height={30} type="narration" />
          </VerticalContainer>
        );
      });
    }

    return memberView;
  };

  renderContent = () => {
    return (
      <RowContainer
        style={{
          marginTop: 30,
          marginBottom: 30,
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <VerticalContainer style={{ width: "30%", alignItems: "flex-start" }}>
          <RowContainer
            style={{ width: 250, marginBottom: 10 }}
            onClick={this.props.handleModal.bind(this, "svgSave", "reset")}
          >
            <IconButton
              iconImage={ResetButton}
              imageStyle={{
                width: 40,
                height: 40,
                margin: 10,
              }}
            />
            <Body style={{ color: White }}>Reset to original voice-over</Body>
          </RowContainer>
          {this.props.selectedBook && (
            <BookImage
              width={210}
              height={210}
              src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.props.selectedBook.id}%2FpreviewImage.png?alt=media&token=${this.props.selectedBook.image}`}
            />
          )}
        </VerticalContainer>
        <VerticalContainer style={{ alignItems: "flex-start" }}>
          <Body style={{ color: White, marginBottom: 5 }}>Storyteller(s)</Body>
          <RowContainer>{this.renderStorytellers()}</RowContainer>
        </VerticalContainer>
      </RowContainer>
    );
  };

  renderBackground = () => {
    let background = "";
    if (this.props.screen === "narration") background = UpperStage;
    else background = CropStage;

    return background;
  };

  renderLoadScreen = () => {
    let voiceOverData = [];
    if (this.props.bookNarration && this.props.selectedBook)
      voiceOverData.push({
        book: this.props.selectedBook,
        narration: this.props.bookNarration,
      });

    return (
      <SemanticLayout
        style={{
          height: "auto",
          width: "100%",
          marginTop: 50,
          marginBottom: 30,
        }}
      >
        <Pagination
          type="narration"
          currentPage={1}
          data={voiceOverData}
          limit={1}
          exemptId={this.props.selectedNarrationId}
          handleLoad={this.props.handleLoad}
          handlePagination={() => {}}
        />
      </SemanticLayout>
    );
  };

  render() {
    let screenWidth = 0;
    let screenHeight = 0;
    if (this.props.utilsStore.deviceSize > 1440) {
      screenWidth = 1440;
      screenHeight = 1440 * (1 / bookInteractionAspectRatio);
    } else {
      screenWidth = this.props.utilsStore.deviceSize;
      screenHeight =
        this.props.utilsStore.deviceSize * (1 / bookInteractionAspectRatio);
    }

    return (
      <VerticalContainer
        style={{
          width: screenWidth,
          minHeight: screenHeight,
          position: "relative",
          backgroundImage: "url(" + this.renderBackground() + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          justifyContent:
            this.props.screen === "narration" ? "flex-end" : "center",
        }}
      >
        {this.props.screen !== "narration" && this.renderHeader()}
        <VerticalContainer
          style={{
            alignItems: "flex-start",
            width: this.props.screen === "narration" ? "90%" : "95%",
          }}
        >
          {this.props.screen === "narration" ? (
            <>
              {this.renderHeader()}
              {this.renderContent()}
            </>
          ) : (
            <>{this.renderLoadScreen()}</>
          )}
        </VerticalContainer>
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBook: state.libraryStore.selectedBook,
    selectedNarrationId: state.libraryStore.selectedNarrationId,
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps, null)(NarrationModal);
