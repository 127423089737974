import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//Styles
import { SubBody, SubFootnote } from "../styles/Typography";
import { VerticalContainer, RowContainer } from "../styles/GlobalStyles";
import { Grey, PrimaryRed, SvgYellow } from "../styles/Colors";
//Components
import BookImage from "../components/catalog/BookImage";
import Button from "./Button";
import Tag from "../global/Tag";
import SubscriptionEndModal from "../modal/SubscriptionEndModal";
//Actions
import { updateSelectedBook } from "../store/actions/libraryActions";

class BookCatalog extends Component {
  state = {
    subscriptionEndModal: false,
  };

  handleNavigate = () => {
    if (this.props.data) {
      this.props.updateSelectedBook(this.props.data);
      this.props.history.push(`/bookInteraction/${this.props.data.id}`);
      window.scrollTo({ top: 115, behavior: "smooth" });
    }
  };

  handleSubscriptionEndModal = (bool) => {
    this.setState({
      subscriptionEndModal: bool,
    });
  };

  renderCallToActions = () => {
    let imageSize = this.props.width * 0.33;
    let buttonText;
    let buttonColor;
    let buttonFunctions;

    if (!this.props.authStore.user) return;

    let today = new Date().getTime();
    let subscriptionEnd = new Date(
      this.props.authStore.user.subscriptionEnd.seconds * 1000
    ).getTime();

    let differenceInTime = subscriptionEnd - today;
    if (differenceInTime > 0) {
      buttonText = "Read";
      buttonColor = PrimaryRed;
      buttonFunctions = this.handleNavigate;
    } else {
      buttonText = "Subscription Ended";
      buttonColor = SvgYellow;
      buttonFunctions = this.handleSubscriptionEndModal.bind(this, true);
    }

    return (
      <Button
        buttonText={buttonText}
        style={{
          width: imageSize,
          marginTop: 20,
          backgroundColor: buttonColor,
        }}
        textStyle={{ fontSize: 21 }}
        onClick={buttonFunctions}
      />
    );
  };

  renderTags = () => {
    let tagList = [];
    let count = 0;
    if (
      this.props.data &&
      this.props.data.tags.length > 0 &&
      this.props.tags.length > 0
    )
      this.props.data.tags.map((eachBookTag) => {
        this.props.tags.map((eachTag, index) => {
          if (
            eachBookTag === eachTag.id &&
            !eachTag.title.includes("Home - ") &&
            eachTag.title.length <= 15 &&
            count <= 1
          ) {
            tagList.push(<Tag key={index} text={eachTag.title} />);
            count += 1;
          }
        });
      });

    return tagList;
  };

  render() {
    let imageSize = this.props.width * 0.33;
    let contentSize = this.props.width - imageSize - 40;

    if (this.props.data)
      return (
        <>
          {this.state.subscriptionEndModal && (
            <SubscriptionEndModal
              handleSubscriptionEndModal={this.handleSubscriptionEndModal}
            />
          )}
          <RowContainer
            style={{
              width: this.props.width,
              minHeight: 280,
              alignItems: "flex-start",
              marginBottom: 60,
            }}
          >
            <VerticalContainer>
              <BookImage
                width={imageSize}
                height={imageSize}
                src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.props.data.id}%2FpreviewImage.png?alt=media&token=${this.props.data.image}`}
              />
              {this.renderCallToActions()}
            </VerticalContainer>
            <VerticalContainer
              style={{
                marginLeft: 20,
                width: contentSize,
                alignItems: "flex-start",
              }}
            >
              <SubBody
                style={{
                  color: Grey,
                  marginBottom: 15,
                }}
              >
                {this.props.data.title}
              </SubBody>
              <BookTagsView style={{ width: contentSize }}>
                {this.renderTags()}
              </BookTagsView>
              <SubFootnote
                style={{
                  color: Grey,
                  marginTop: 10,
                  fontSize: 18,
                  lineHeight: 1.4,
                  width: contentSize,
                  height: 180,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "justify",
                }}
              >
                {this.props.data.description}
              </SubFootnote>
            </VerticalContainer>
          </RowContainer>
        </>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    tags: state.dataStore.tags,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedBook: (bookData) => dispatch(updateSelectedBook(bookData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookCatalog));

const BookTagsView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
