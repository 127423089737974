import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import { VerticalContainer, RowContainer } from "../styles/GlobalStyles";
import { SubTitle4 } from "../styles/Typography";
import { White } from "../styles/Colors";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
import AnimatedWrapper from "../global/AnimatedWrapper";
//Assets
import ReloginBackground from "../assets/background/reloginBackground.jpg";
import { childAvatarList } from "../utils/avatarList";
//Utils
import { avatarSoundList } from "../utils/avatarSoundList";
//Actions
import { updateSelectedChild } from "../store/actions/authActions";

let wrapperStyle = {
  margin: 20,
};

class SwitchProfile extends Component {
  constructor(props) {
    super(props);
    this.audio = null;
  }

  handleSwitchChild = (childId, childIndex) => {
    this.props.updateSelectedChild(childId);
    this.props.history.push("/");
    let soundAvatarLength = avatarSoundList.length;
    if (childIndex <= soundAvatarLength - 1) {
      let selectedAvatarSound = avatarSoundList[childIndex];
      let selectedAvatarSoundLength = selectedAvatarSound.voiceList.length;
      let selectedSoundIndex = Math.floor(
        Math.random() * selectedAvatarSoundLength
      );
      let selectedSound = selectedAvatarSound.voiceList[selectedSoundIndex];
      this.audio = new Audio(selectedSound);
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio.play();
    }
  };

  renderChildSelection = () => {
    let imageSize;
    if (this.props.utilsStore.deviceSize > 1200) imageSize = 180;
    else imageSize = 130;

    let childView = [];
    if (this.props.authStore.children.length > 0)
      this.props.authStore.children.map((eachChild, index) => {
        childView.push(
          <AnimatedWrapper
            key={index}
            style={wrapperStyle}
            onClick={this.handleSwitchChild.bind(
              this,
              eachChild.id,
              eachChild.avatar
            )}
          >
            <VerticalContainer>
              <img
                style={{
                  width: imageSize,
                }}
                src={childAvatarList[eachChild.avatar]}
                alt="Children Avatar"
              />
              <SubTitle4 style={{ color: White }}>{eachChild.name}</SubTitle4>
            </VerticalContainer>
          </AnimatedWrapper>
        );
      });

    return <RowContainer>{childView}</RowContainer>;
  };

  render() {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader screen="Select Your Child Profile to Enter" />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            height: divSize * 0.75,
            position: "relative",
            backgroundImage: "url(" + ReloginBackground + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          {this.renderChildSelection()}
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedChild: (childId) => dispatch(updateSelectedChild(childId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchProfile);
