import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
//Styles
import { SpecBody, SubFootnote } from "../styles/Typography";
import { White, SemanticBrown } from "../styles/Colors";
import { VerticalContainer } from "../styles/GlobalStyles";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
import Pagination from "../global/Pagination";
//Actions
import { getBooks } from "../store/actions/dataActions";

let bookLimit = 10;
class SelectedCategory extends Component {
  state = {
    selectedCategory: null,
    currentPage: 1,
  };

  componentDidMount() {
    this.handleUpdateSelectedCategory();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.categories) !==
        JSON.stringify(prevProps.categories) &&
      this.props.categories.length > 0
    )
      this.handleUpdateSelectedCategory();
  }

  handleUpdateSelectedCategory = () => {
    let pathNameList = this.props.location.pathname.split("/");
    if (pathNameList.length > 0) {
      let pathName = pathNameList[pathNameList.length - 1];
      let selectedCategory;
      this.props.categories.map((eachCategory) => {
        if (eachCategory.id === pathName) selectedCategory = eachCategory;
      });

      if (selectedCategory)
        this.setState(
          {
            selectedCategory: selectedCategory,
          },
          () => {
            if (this.state.selectedCategory.conditions.length > 0) {
              let condition = selectedCategory.conditions[0];
              this.props.getBooks(
                { attribute: condition.attribute, query: condition.query },
                "",
                true,
                bookLimit,
                true
              );
            }
          }
        );
      else this.props.history.push("/");
    }
  };

  handlePagination = (type) => {
    switch (type) {
      case "minus":
        if (this.state.currentPage > 1)
          this.setState({
            currentPage: this.state.currentPage - 1,
          });
        break;
      case "plus":
        if (
          this.props.bookPagination &&
          this.state.currentPage ===
            Math.ceil(this.props.books.length / bookLimit)
        ) {
          let selectedCondition = this.state.selectedCategory.conditions[0];
          this.props.getBooks(
            selectedCondition
              ? {
                  attribute: selectedCondition.attribute,
                  query: selectedCondition.query,
                }
              : null,
            this.props.bookPagination,
            false,
            bookLimit,
            true
          );
          this.setState({
            currentPage: this.state.currentPage + 1,
          });
        } else if (this.props.books.length > this.state.currentPage * bookLimit)
          this.setState({
            currentPage: this.state.currentPage + 1,
          });
        break;
    }
  };

  renderResultLabel = () => {
    if (this.state.selectedCategory)
      return this.state.selectedCategory.discoverName + " Category";
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            backgroundColor: SemanticBrown,
          }}
        >
          <VerticalContainer
            style={{ width: "90%", marginTop: 50, marginBottom: 50 }}
          >
            <SubFootnote style={{ fontWeight: 600, alignSelf: "flex-start" }}>
              Selected Category
            </SubFootnote>
            <VerticalContainer
              style={{
                backgroundColor: "#888",
                borderRadius: 20,
                padding: 10,
                marginTop: 10,
                alignSelf: "flex-start",
              }}
            >
              <SpecBody style={{ color: White }}>
                Result for {this.renderResultLabel()}
              </SpecBody>
            </VerticalContainer>
          </VerticalContainer>
          <Pagination
            style={{ width: "100%" }}
            type="book"
            data={this.props.books}
            limit={bookLimit}
            currentPage={this.state.currentPage}
            storytellingResource={true}
            nextAvailable={
              (this.props.bookPagination &&
                this.state.currentPage ===
                  Math.ceil(this.props.books.length / bookLimit)) ||
              this.props.books.length > this.state.currentPage * bookLimit
            }
            handlePagination={this.handlePagination}
          />
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    books: state.dataStore.books,
    bookPagination: state.dataStore.bookPagination,
    categories: state.dataStore.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBooks: (condition, startAfter, reset, bookLimit, storytellingResource) =>
      dispatch(
        getBooks(condition, startAfter, reset, bookLimit, storytellingResource)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedCategory);
