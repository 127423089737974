import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
//Styles
import {
  VerticalContainer,
  RowContainer,
  bookInteractionAspectRatio,
} from "../styles/GlobalStyles";
import { White } from "../styles/Colors";
import { Title4 } from "../styles/Typography";
//Components
import WindowResizer from "../components/WindowResizer";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import VideoModal from "../modal/VideoModal";
import Button from "../global/Button";
import VoiceOverModal from "../global/VoiceOverModal";
import LastPage from "../components/bookInteraction/LastPage";
import NarrationModal from "../components/bookInteraction/NarrationModal";
import BookInteractionFooter from "../components/bookInteraction/BookInteractionFooter";
import BookHotspots from "../components/bookInteraction/BookHotspots";
//Utils
import plist from "plist";
import axios from "axios";
import EventBus from "js-event-bus";
//Assets
import StoryMarker from "../assets/images/lastPage/storyMarker.png";
import StorytellerAudio from "../assets/audio/book_int_who_is_storyteller.mp3";
import SwipeSound from "../assets/audio/book_int_whoosh.mp3";
import LoadingBackground from "../assets/background/login.jpg";
import RainbowButton from "../assets/images/lastPage/rainbowButton.png";
import DeleteSound from "../assets/audio/book_int_delete.mp3";
import HighlightSound from "../assets/audio/book_int_highlight.mp3";
import SuccessfulAudio from "../assets/audio/book_int_success.mp3";
import PoofAudio from "../assets/audio/book_int_poof.mp3";
import SlideAudio from "../assets/audio/utils_slide.mp3";
//Actions
import {
  getSelectedNarration,
  updateAnalyticsList,
  updateSelectedBookAndNarration,
  saveBookVoiceOver,
  resetVoiceOver,
} from "../store/actions/libraryActions";

const eventBus = EventBus();
let pageIndex = -1;
let screenWidth = 0;
let screenHeight = 0;

class BookInteraction extends Component {
  constructor(props) {
    super(props);
    if (this.props.utilsStore.deviceSize > 1440) {
      screenWidth = 1440;
      screenHeight = 1440 * (1 / bookInteractionAspectRatio);
    } else {
      screenWidth = this.props.utilsStore.deviceSize;
      screenHeight =
        this.props.utilsStore.deviceSize * (1 / bookInteractionAspectRatio);
    }

    this.state = {
      //INFO : Interaction States
      time: 0,
      swiping: false,
      viewingPageArray: [null, 0, 2],
      viewingTranslateArray: ["A", "B", "C"],
      pagesName: [],
      pagesToken: [],
      pageA: [],
      pageB: [],
      pageC: [],
      imageWidth: 0,
      imageHeight: 0,
      pListData: null,
      hotspots: [],
      translateA: -screenWidth,
      translateB: 0,
      translateC: screenWidth,
      //INFO : Polygon Utils
      selectedIndex: -1,
      transformX: 0,
      preventDraw: false,
      swipeToDelete: false,
      isTouching: false,
      touchSensitivity: {
        x: 0,
        y: 0,
      },
      hotspotStateTrigger: false,
      //INFO : Rendering Hotspots & Modal
      viewHotspots: false,
      viewHotspotsStoryModal: true,
      circleDotShowing: false,
      lastPageModal: false,
      narrationModal: false,
      bottomHelper: "",
      videoTutorialModal: false,
      voiceOverModal: false,
      offsetX: 0,
      //INFO : Member Info
      selectedMember: "",
      //INFO : Narration States
      bookInitialHotspotLength: 0,
      editNarrationId: "",
      editNarrationName: "",
    };
    this.initialX = null;
    this.initialY = null;
    this.timerInterval = null;
    this.translateInterval = null;
    this.swipeSound = new Audio(SwipeSound);
    this.deleteSound = new Audio(DeleteSound);
    this.highlightSound = new Audio(HighlightSound);
    this.successSound = new Audio(SuccessfulAudio);
    this.poofSound = new Audio(PoofAudio);
    this.slideSound = new Audio(SlideAudio);
    this.pointRecogniserRef = React.createRef();
  }

  componentDidMount = async () => {
    try {
      eventBus.on("voice-over-saved", this.handleSaveVoiceOverEvent);
      eventBus.on("voice-over-saved-exit", this.handleOnConfirmLeave);
      eventBus.on("voice-over-reset", this.handleResetBookEvent);

      document.onkeydown = this.handleKeyPressed;
      let pathNameList = this.props.location.pathname.split("/");
      if (pathNameList.length > 0) {
        let pathName = pathNameList[pathNameList.length - 1];

        if (pathName) {
          if (
            this.props.selectedBook &&
            pathName === this.props.selectedBook.id
          ) {
            this.props.getSelectedNarration(
              this.props.authStore.selectedChild,
              this.props.selectedBook.id
            );
            if (!this.props.libraryStore.selectedNarrationId)
              this.handleInitialiseBook();

            if (this.props.authStore.children.length > 0)
              this.props.authStore.children.map((eachChild) => {
                if (eachChild.id === this.props.authStore.selectedChild)
                  this.setState({
                    selectedMember: eachChild.id,
                  });
              });
            else if (this.props.authStore.user)
              this.setState({
                selectedMember: this.props.authStore.user.id,
              });
          } else this.props.history.push(`/book/${pathName}`);
        } else this.props.history.push("/");
      }
    } catch (err) {}
  };

  componentWillUnmount() {
    eventBus.detach("voice-over-saved", this.handleSaveVoiceOverEvent);
    eventBus.detach("voice-over-saved-exit", this.handleOnConfirmLeave);
    eventBus.detach("voice-over-reset", this.handleResetBookEvent);
    document.onkeydown = null;
    clearInterval(this.timerInterval);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.utilsStore.deviceSize !== this.props.utilsStore.deviceSize)
      this.handleUpdateWindowSizeChanges();

    if (
      prevProps.selectedNarration !== this.props.selectedNarration &&
      !this.state.pListData
    )
      this.handleInitialiseBook();
  }

  handleResetBookEvent = () => {
    this.setState(
      {
        lastPageModal: false,
        time: 0,
        stickersWon: [],
        pointsWon: 0,
        translateA: -screenWidth,
        translateB: 0,
        translateC: screenWidth,
        viewingPageArray: [null, 0, 2],
        viewingTranslateArray: ["A", "B", "C"],
        pListData: null,
      },
      async () => {
        this.handleInitialiseBook(this.props.selectedBook);
        this.props.getSelectedNarration(
          this.props.authStore.selectedChild,
          this.props.selectedBook.id
        );
      }
    );
  };

  handleSaveVoiceOverEvent = (narrationId) => {
    this.props.updateSelectedBookAndNarration(
      this.props.selectedBook,
      narrationId
    );
    this.props.getSelectedNarration(
      this.props.authStore.selectedChild,
      this.props.selectedBook.id
    );
    this.handleVoiceOverModal(false);
  };

  handleOnConfirmLeave = () => {
    if (this.state.time && this.state.pListData)
      this.props.updateAnalyticsList(
        this.props.selectedBook.id,
        this.state.time,
        this.state.viewingPageArray[1],
        [],
        0
      );
    this.props.history.push("/library");
  };

  handleOnConfirmVoiceOver = (type) => {
    if (type === "leave") this.handleVoiceOverModal("saveLeave");
    else if (type === "delete") {
      this.props.deleteVoiceOver(
        this.props.selectedBook.id,
        this.state.editNarrationId
      );
      this.handleVoiceOverModal(false);
    } else if (type === "reset") this.handleResetBook();
  };

  handleResetBook = async () => {
    this.handleModal("narration", false);
    this.handleModal("svgSave", false);
    this.handleToggleViewHotspots();
    if (this.props.libraryStore.selectedNarrationId) {
      this.setState(
        {
          pListData: null,
        },
        () => {
          this.props.resetVoiceOver(
            this.props.selectedBook.id,
            this.props.libraryStore.selectedNarrationId,
            this.props.selectedBook.pListName,
            this.props.selectedBook.pList
          );
        }
      );
    } else this.handleStartOver();
  };

  handleStartOver = () => {
    if (this.props.selectedBook) {
      let pagesToken = [];
      let pagesName = [];
      this.props.selectedBook.pagesName.map((eachPage, index) => {
        if (eachPage.includes("_page")) {
          pagesName.push(eachPage);
          pagesToken.push(this.props.selectedBook.pages[index]);
        }
      });
      let hotspots = this.handleHotspotsData(this.state.pListData["Hotspots"]);
      this.setState(
        {
          lastPageModal: false,
          time: 0,
          stickersWon: [],
          pointsWon: 0,
          translateA: -screenWidth,
          translateB: 0,
          translateC: screenWidth,
          viewingPageArray: [null, 0, 2],
          viewingTranslateArray: ["A", "B", "C"],
          pageA: [null, null],
          pageB: [
            this.renderBookAssets(pagesName[0], pagesToken[0]),
            this.renderBookAssets(pagesName[1], pagesToken[1]),
          ],
          pageC: [
            this.renderBookAssets(pagesName[2], pagesToken[2]),
            this.renderBookAssets(pagesName[3], pagesToken[3]),
          ],
          hotspots: hotspots,
        },
        () => {
          if (this.timerInterval) clearInterval(this.timerInterval);
          this.timerInterval = setInterval(this.handleCountTimer, 1000);
        }
      );
    }
  };

  handleSaveVoiceOver = async (narrationName, type) => {
    this.props.saveBookVoiceOver(
      "",
      this.props.selectedBook.id,
      narrationName,
      this.state.hotspots,
      type
    );
  };

  handleExitBook = () => {
    let checkModified = false;
    if (this.state.hotspots.length > 0)
      this.state.hotspots.map((eachHotspot) => {
        if (eachHotspot.Modified) checkModified = true;
      });

    if (
      (!checkModified &&
        this.state.bookInitialHotspotLength === this.state.hotspots.length) ||
      (this.props.bookNarration &&
        this.props.bookNarration.narrationList.length === 5 &&
        !this.props.libraryStore.selectedNarrationId)
    )
      this.handleOnConfirmLeave();
    //INFO : Overwrite Old Narration Data
    else if (this.props.libraryStore.selectedNarrationId) {
      this.setState({
        pListData: null,
      });
      let selectedNarration;
      this.props.bookNarration.narrationList.map((eachNarration) => {
        if (eachNarration.id === this.props.libraryStore.selectedNarrationId)
          selectedNarration = eachNarration;
      });
      if (selectedNarration) {
        this.handleVoiceOverModal("saveLeave", "", "", "loading");
        this.props.saveBookVoiceOver(
          selectedNarration.id,
          this.props.selectedBook.id,
          selectedNarration.name,
          this.state.hotspots,
          "update"
        );
      }
    } else this.handleVoiceOverModal("leave");
  };

  handleVoiceOverModal = (
    screen,
    editNarrationId,
    editNarrationName,
    callback
  ) => {
    this.setState(
      {
        voiceOverModal: screen,
        editNarrationId: editNarrationId ? editNarrationId : "",
        editNarrationName: editNarrationName ? editNarrationName : "",
      },
      () => {
        if (callback === "loading") eventBus.emit("voice-over-loading");
      }
    );
  };

  handleModal = (type, bool) => {
    if (type === "video")
      this.setState({
        videoTutorialModal: bool,
      });
    else if (type === "svgSave")
      this.setState({
        voiceOverModal: bool,
      });
    else if (type === "narration")
      this.setState(
        {
          narrationModal: bool,
        },
        () => {
          if (this.state.narrationModal)
            window.scrollTo({ top: 115, behavior: "smooth" });
        }
      );
  };

  handleSelectMember = (id) => {
    this.setState({
      selectedMember: id,
    });
  };

  handleBottomHelper = (bool) => {
    this.setState(
      {
        bottomHelper: bool,
      },
      async () => {
        if (this.state.bottomHelper) {
          this.audio = new Audio(StorytellerAudio);
          this.audio.pause();
          this.audio.currentTime = 0;
          this.audio.play();
        }
      }
    );
  };

  handleToggleDot = (timeout) => {
    if (this.showDotTimeout) clearTimeout(this.showDotTimeout);
    if (this.hideDotTimeout) clearTimeout(this.hideDotTimeout);

    if (timeout)
      this.showDotTimeout = setTimeout(() => {
        this.setState({
          circleDotShowing: true,
        });
      }, 500);
    else
      this.setState({
        circleDotShowing: true,
      });

    this.hideDotTimeout = setTimeout(() => {
      this.setState({
        circleDotShowing: false,
      });
    }, 3500);
  };

  handleToggleViewHotspots = () => {
    this.setState(
      {
        viewHotspots: !this.state.viewHotspots,
      },
      () => {
        this.slideSound.pause();
        this.slideSound.currentTime = 0;
        this.slideSound.play();
        if (!this.state.viewHotspots)
          this.setState(
            {
              selectedIndex: -1,
            },
            () => {
              this.poofSound.pause();
              this.poofSound.currentTime = 0;
              this.poofSound.play();
            }
          );
      }
    );
  };

  handleToggleViewHotspotsStoryModal = () => {
    this.setState({
      viewHotspotsStoryModal: !this.state.viewHotspotsStoryModal,
    });
  };

  handleUpdateHotspots = (hotspots) => {
    this.setState({
      hotspots: hotspots,
    });
  };

  handleHotspotStateTrigger = () => {
    this.setState({
      hotspotStateTrigger: !this.state.hotspotStateTrigger,
    });
  };

  //===============================================================================
  //INFO : Narration CRUD
  handleLoad = (selectedNarrationId) => {
    this.props.updateSelectedBookAndNarration(
      this.props.selectedBook,
      selectedNarrationId
    );
    this.handleModal("narration", false);
    this.handleToggleViewHotspots();
    this.setState(
      {
        lastPageModal: false,
        time: 0,
        translateA: -screenWidth,
        translateB: 0,
        translateC: screenWidth,
        viewingPageArray: [null, 0, 2],
        viewingTranslateArray: ["A", "B", "C"],
        pListData: null,
      },
      async () => {
        this.handleInitialiseBook(this.props.selectedBook);
      }
    );
  };

  //================================================================================
  //INFO : Timer Algorithm
  handleCountTimer = () => {
    this.setState({
      time: this.state.time + 1,
    });
  };

  handleHighlightSound = (bool) => {
    if (bool) {
      if (this.highlightSound.currentTime === 0 && this.highlightSound.paused) {
        this.highlightSound.loop = true;
        this.highlightSound.play();
      }
    } else {
      if (!this.highlightSound.paused) {
        this.highlightSound.pause();
        this.highlightSound.currentTime = 0;
      }
    }
  };

  //================================================================================
  //INFO : Swipe Algorithm

  handleNavigationButtonPressed = (type) => {
    this.setState({
      circleDotShowing: false,
    });
    if (this.state.swiping) return;
    this.handleSwipeDelay();

    if (type === "right") {
      if (this.state.viewingPageArray[1] !== 0)
        this.handleSwipeSuccess("right");
    } else {
      if (this.state.viewingPageArray[1] + 2 <= this.state.pagesName.length - 1)
        this.handleSwipeSuccess("left");
      // else
      //   this.setState({
      //     lastPageModal: true,
      //   });
    }
  };

  handleKeyPressed = (event) => {
    let eventKeyCode = String(event.keyCode);
    if (this.state.pListData && !this.state.viewHotspots) {
      switch (eventKeyCode) {
        case "37":
        case "39":
          this.setState({
            circleDotShowing: false,
          });
          if (this.state.swiping) return;
          this.handleSwipeDelay();
          break;
        default:
          break;
      }

      if (eventKeyCode === "37") {
        if (this.state.viewingPageArray[1] !== 0)
          this.handleSwipeSuccess("right");
      } else if (eventKeyCode === "39") {
        if (
          this.state.viewingPageArray[1] + 2 <=
          this.state.pagesName.length - 1
        )
          this.handleSwipeSuccess("left");
        // else
        //   this.setState({
        //     lastPageModal: true,
        //   });
      }
    }
  };

  handleOnSwipeStart = (type, event) => {
    if (type === "touch") {
      this.initialX = event.touches[0].pageX;
      this.initialY = event.touches[0].pageY;
    } else {
      this.initialX = event.pageX;
      this.initialY = event.pageY;
    }

    this.initialX = this.initialX - this.pointRecogniserRef.current.offsetLeft;
    this.initialY = this.initialY - this.pointRecogniserRef.current.offsetTop;

    if (this.state.viewHotspots) {
      //INFO : Check for delete action
      let selectedIndex = this.state.selectedIndex;
      let minX,
        minY,
        maxX,
        maxY = 0;
      if (selectedIndex >= 0) {
        let listClass = this.handleCalculateMidpoint(
          this.state.hotspots[selectedIndex].Points,
          this.state.hotspots[selectedIndex].PageIndex,
          true
        );
        minX = listClass.minX;
        minY = listClass.minY;
        maxX = listClass.maxX;
        maxY = listClass.maxY;
      }

      if (
        this.initialX < maxX &&
        this.initialX > minX &&
        this.initialY < maxY &&
        this.initialY > minY
      )
        this.setState({
          preventDraw: true,
        });
      else {
        if (this.initialX <= screenWidth / 2)
          pageIndex = this.state.viewingPageArray[1];
        else pageIndex = this.state.viewingPageArray[1] + 1;
        this.state.hotspots.push({
          PageIndex: pageIndex,
          PrerecordingFilename: "",
          Points: [],
          Member: this.state.selectedMember,
          Modified: true,
        });
        this.setState(
          {
            touchSensitivity: {
              x: this.initialX,
              y: this.initialY,
            },
            isTouching: true,
            selectedIndex: -1,
            preventDraw: false,
          },
          () => {
            this.handleUpdateHotspots(this.state.hotspots);
          }
        );
      }
    }
  };

  handleOnSwipe = (type, event) => {
    if (!this.state.viewHotspots) {
      if (this.initialX === null) return;
      if (this.initialY === null) return;
    }

    let currentX;
    let currentY;
    if (type === "touch") {
      currentX = event.touches[0].pageX;
      currentY = event.touches[0].pageY;
    } else {
      currentX = event.pageX;
      currentY = event.pageY;
    }

    currentX = currentX - this.pointRecogniserRef.current.offsetLeft;
    currentY = currentY - this.pointRecogniserRef.current.offsetTop;

    let diffX = this.initialX - currentX;
    let diffY = this.initialY - currentY;

    if (this.state.viewHotspots) {
      //INFO : Delete algorithm
      if (Math.abs(diffX) > Math.abs(diffY) && this.state.preventDraw)
        this.setState({
          swipeToDelete: true,
        });
      //INFO : Draw algorithm
      else {
        if (this.state.isTouching) {
          let currentHotspot = this.state.hotspots[
            this.state.hotspots.length - 1
          ];

          //INFO : Check touch sensitivy
          if (currentHotspot.Points.length === 0) {
            let offsetValueX = Math.abs(
              this.state.touchSensitivity.x - currentX
            );
            let offsetValueY = Math.abs(
              this.state.touchSensitivity.y - currentY
            );
            let totalOffsetValue = offsetValueX + offsetValueY;
            if (totalOffsetValue <= 10) return;
          }

          this.handleHighlightSound(true);

          //INFO : Left
          let coordX = 0;
          let coordY = 0;
          let pageWidth = screenWidth / 2;
          let scaleRatioWidth =
            Number(this.state.imageWidth) / (screenWidth / 2);
          let exactHeight = Number(this.state.imageHeight) / scaleRatioWidth;
          let scaleRatioHeight = Number(this.state.imageHeight) / screenHeight;
          let exactWidth = Number(this.state.imageWidth) / scaleRatioHeight;
          let offsetY = (screenHeight - exactHeight) / 2;

          if (currentHotspot.PageIndex % 2 == 0) {
            if (this.state.offsetX > 0)
              coordX = (currentX - 2 * this.state.offsetX) / exactWidth;
            else coordX = currentX / pageWidth;
            if (offsetY > 0)
              coordY =
                (currentY - (screenHeight - exactHeight) / 2) / exactHeight;
            else coordY = currentY / screenHeight;
          } else {
            if (this.state.offsetX > 0)
              coordX = (currentX - pageWidth) / Number(exactWidth);
            else coordX = currentX / pageWidth - 1;
            if (offsetY > 0)
              coordY =
                (currentY - (screenHeight - exactHeight) / 2) / exactHeight;
            else coordY = currentY / screenHeight;
          }

          let currentPoint = `{${coordX.toPrecision(5)},${coordY.toPrecision(
            5
          )}}`;
          currentHotspot.Points.push(currentPoint);
          this.handleUpdateHotspots(this.state.hotspots);
          this.handleHotspotStateTrigger();
        }
      }
    } else {
      this.setState({
        circleDotShowing: false,
      });
      if (this.state.swiping) return;
      this.handleSwipeDelay();

      if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX > 0) {
          if (
            this.state.viewingPageArray[1] + 2 <=
            this.state.pagesName.length - 1
          )
            this.handleSwipeSuccess("left");
          // else
          //   this.setState({
          //     lastPageModal: true,
          //   });
        } else {
          if (this.state.viewingPageArray[1] !== 0)
            this.handleSwipeSuccess("right");
        }
      }
    }

    this.initialX = null;
    this.initialY = null;
  };

  handleOnSwipeEnd = () => {
    this.initialX = null;
    this.initialY = null;

    if (this.state.viewHotspots) {
      if (this.state.preventDraw && this.state.swipeToDelete) {
        try {
          this.deleteSound.play();
          this.translateInterval = setInterval(this.handleUpdateTranslate, 5);
          setTimeout(() => {
            clearInterval(this.translateInterval);
            this.state.hotspots.splice(this.state.selectedIndex, 1);
            this.handleUpdateHotspots(this.state.hotspots);
            this.setState({
              selectedIndex: -1,
              transformX: 0,
              swipeConfig: {
                initX: 0,
                lastX: 0,
                selectedIndex: -1,
              },
              preventDraw: false,
            });
          }, 50);
        } catch (err) {}
      } else if (this.state.isTouching) {
        this.handleCalculatePolygon();
        this.handleHighlightSound(false);
      }
    }

    this.setState({
      preventDraw: false,
      swipeToDelete: false,
      isTouching: false,
    });
  };

  handleSwipeDelay = () => {
    this.setState({
      swiping: true,
    });

    setTimeout(() => {
      this.setState({ swiping: false });
    }, 400);
  };

  //INFO : Swipe Action
  handleSwipeSuccess = async (position) => {
    let screenWidth;
    if (this.props.utilsStore.deviceSize > 1440) screenWidth = 1440;
    else screenWidth = this.props.utilsStore.deviceSize;

    this.handleToggleDot(true);
    eventBus.emit("swipe-success");

    try {
      if (position === "right") {
        this.state.viewingPageArray.pop();
        let newTranslate = this.state.viewingTranslateArray.pop();
        let minViewingIndex = Math.min(...this.state.viewingPageArray);
        let newIndex;

        if (minViewingIndex - 2 < 0) newIndex = null;
        else newIndex = minViewingIndex - 2;

        if (newIndex !== null) {
          let pageName = this.state.pagesName[newIndex];
          let pageToken = this.state.pagesToken[newIndex];
          let nextPageName = this.state.pagesName[newIndex + 1];
          let nextPageToken = this.state.pagesToken[newIndex + 1];
          switch (newTranslate) {
            case "A":
              this.setState({
                pageA: [
                  this.renderBookAssets(pageName, pageToken),
                  this.renderBookAssets(nextPageName, nextPageToken),
                ],
              });
              break;
            case "B":
              this.setState({
                pageB: [
                  this.renderBookAssets(pageName, pageToken),
                  this.renderBookAssets(nextPageName, nextPageToken),
                ],
              });
              break;
            case "C":
              this.setState({
                pageC: [
                  this.renderBookAssets(pageName, pageToken),
                  this.renderBookAssets(nextPageName, nextPageToken),
                ],
              });
              break;
          }
        }

        this.state.viewingPageArray.unshift(newIndex);
        this.state.viewingTranslateArray.unshift(newTranslate);

        switch (this.state.viewingTranslateArray[1]) {
          case "A":
            this.handleAnimation("translateA", this.state.translateA, 0);
            this.handleAnimation(
              "translateB",
              this.state.translateB,
              screenWidth
            );
            break;
          case "B":
            this.handleAnimation("translateB", this.state.translateB, 0);
            this.handleAnimation(
              "translateC",
              this.state.translateC,
              screenWidth
            );
            break;
          case "C":
            this.handleAnimation("translateC", this.state.translateC, 0);
            this.handleAnimation(
              "translateA",
              this.state.translateA,
              screenWidth
            );
            break;
        }

        switch (newTranslate) {
          case "A":
            this.setState({
              translateA: -screenWidth,
            });
            break;
          case "B":
            this.setState({
              translateB: -screenWidth,
            });
            break;
          case "C":
            this.setState({
              translateC: -screenWidth,
            });
            break;
        }
      } else {
        this.state.viewingPageArray.shift();
        let newTranslate = this.state.viewingTranslateArray.shift();
        let maxViewingIndex = Math.max(...this.state.viewingPageArray);
        let newIndex;
        if (maxViewingIndex + 2 >= this.state.pagesName.length - 1)
          newIndex = null;
        else newIndex = maxViewingIndex + 2;

        if (newIndex) {
          let pageName = this.state.pagesName[newIndex];
          let pageToken = this.state.pagesToken[newIndex];
          let nextPageName = this.state.pagesName[newIndex + 1];
          let nextPageToken = this.state.pagesToken[newIndex + 1];
          switch (newTranslate) {
            case "A":
              this.setState({
                pageA: [
                  this.renderBookAssets(pageName, pageToken),
                  this.renderBookAssets(nextPageName, nextPageToken),
                ],
              });
              break;
            case "B":
              this.setState({
                pageB: [
                  this.renderBookAssets(pageName, pageToken),
                  this.renderBookAssets(nextPageName, nextPageToken),
                ],
              });
              break;
            case "C":
              this.setState({
                pageC: [
                  this.renderBookAssets(pageName, pageToken),
                  this.renderBookAssets(nextPageName, nextPageToken),
                ],
              });
              break;
          }
        }

        this.state.viewingPageArray.push(newIndex);
        this.state.viewingTranslateArray.push(newTranslate);

        switch (this.state.viewingTranslateArray[0]) {
          case "A":
            this.handleAnimation(
              "translateA",
              this.state.translateA,
              -screenWidth
            );
            this.handleAnimation("translateB", this.state.translateB, 0);
            break;
          case "B":
            this.handleAnimation(
              "translateB",
              this.state.translateB,
              -screenWidth
            );
            this.handleAnimation("translateC", this.state.translateC, 0);
            break;
          case "C":
            this.handleAnimation(
              "translateC",
              this.state.translateC,
              -screenWidth
            );
            this.handleAnimation("translateA", this.state.translateA, 0);
            break;
        }

        switch (newTranslate) {
          case "A":
            this.setState({
              translateA: screenWidth,
            });
            break;
          case "B":
            this.setState({
              translateB: screenWidth,
            });
            break;
          case "C":
            this.setState({
              translateC: screenWidth,
            });
            break;
        }
      }

      this.swipeSound.currentTime = 0;
      this.swipeSound.play();
    } catch (error) {}
  };

  //===============================================================================
  //INFO : Polygon Utils
  handleCalculateMidpoint = (pointList, pageIndex, getList) => {
    let xList = [];
    let yList = [];
    let pageWidth = screenWidth / 2;
    let scaleRatioWidth = Number(this.state.imageWidth) / pageWidth;
    let exactHeight = Number(this.state.imageHeight) / scaleRatioWidth;
    let scaleRatioHeight = Number(this.state.imageHeight) / screenHeight;
    let exactWidth = Number(this.state.imageWidth) / scaleRatioHeight;
    let offsetY = (screenHeight - exactHeight) / 2;

    pointList.map((eachPoint) => {
      let point = eachPoint.substring(1, eachPoint.length - 1).split(",");
      if (pageIndex % 2 == 0) {
        if (!isNaN(point[0])) {
          if (this.state.offsetX > 0)
            xList.push(Number(point[0]) * exactWidth + this.state.offsetX * 2);
          else xList.push(Number(point[0]) * Number(pageWidth));
        }
      } else {
        if (!isNaN(point[0])) {
          if (this.state.offsetX > 0)
            xList.push(Number(point[0]) * Number(exactWidth) + pageWidth);
          else xList.push(Number(point[0]) * Number(pageWidth) + pageWidth);
        }
      }

      if (!isNaN(point[1])) {
        if (offsetY > 0) yList.push(Number(point[1]) * exactHeight + offsetY);
        else yList.push(Number(point[1]) * screenHeight);
      }
    });

    let minX = Math.min.apply(Math, xList);
    let maxX = Math.max.apply(Math, xList);
    let minY = Math.min.apply(Math, yList);
    let maxY = Math.max.apply(Math, yList);

    let xMidpoint = (maxX + minX) / 2;
    let yMidpoint = (maxY + minY) / 2;

    if (getList) return { minX, maxX, minY, maxY };
    return [xMidpoint, yMidpoint];
  };

  handleCalculatePolygon = () => {
    let lastHotspots = this.state.hotspots[this.state.hotspots.length - 1];
    if (lastHotspots.Points.length > 0) {
      let xList = [];
      let yList = [];
      lastHotspots.Points.map((eachPoint) => {
        let point = eachPoint.substring(1, eachPoint.length - 1).split(",");
        let pageWidth = screenWidth / 2;
        let scaleRatioWidth = Number(this.state.imageWidth) / pageWidth;
        let exactHeight = Number(this.state.imageHeight) / scaleRatioWidth;
        let offsetY = (screenHeight - exactHeight) / 2;
        xList.push(Number(point[0]) * Number(pageWidth));
        yList.push(Number(point[1]) * exactHeight + offsetY);
      });
      //INFO : Check Area Size
      let rangeLimit = 50;
      let minX = Math.min.apply(Math, xList);
      let maxX = Math.max.apply(Math, xList);
      let minY = Math.min.apply(Math, yList);
      let maxY = Math.max.apply(Math, yList);
      let xRange = maxX - minX;
      let yRange = maxY - minY;
      let area = xRange * yRange;
      if (area < 3000) this.state.hotspots.pop();
      //INFO : Check Straight Line Polygon
      else if (xRange < rangeLimit || yRange < rangeLimit)
        this.state.hotspots.pop();
    } else this.state.hotspots.pop();
    this.handleUpdateHotspots(this.state.hotspots);
  };

  //===============================================================================
  //INFO : Selected Index
  handleUpdateSelectedIndex = (selectedIndex) => {
    this.setState({
      selectedIndex: selectedIndex,
    });
  };

  //===============================================================================
  //INFO : Animation
  handleUpdateTranslate = () => {
    this.setState({
      transformX: this.state.transformX + 5,
    });
  };

  handleAnimation = (state, target, value) => {
    switch (state) {
      case "translateA":
        this.setState({
          translateA: value,
        });
        break;
      case "translateB":
        this.setState({
          translateB: value,
        });
        break;
      case "translateC":
        this.setState({
          translateC: value,
        });
        break;
    }

    // let count = 1;
    // for (let i = 1; i >= 0.9; i = i - 0.02) {
    //   count += 1;
    //   let currentTimeout = setTimeout(() => {
    //     this.setState({
    //       scale: i,
    //     });
    //   }, count * 35);
    // }
    // Animated.timing(target, {
    //   toValue: value,
    //   duration: 350,
    //   easing: Easing.linear,
    //   useNativeDriver: true,
    // }).start();
  };

  //================================================================================
  //INFO : Book Algorithm
  handleHotspotsData = (hotspotData) => {
    let hotspots = [];
    let totalLength = 0;
    if (hotspotData && hotspotData.length > 0) {
      hotspotData.map((eachHotspotData) => {
        if (eachHotspotData["Points"].length > 0) {
          hotspots.push({
            Points: eachHotspotData["Points"],
            PageIndex: eachHotspotData["PageIndex"],
            PrerecordingFilename: eachHotspotData["PrerecordingFilename"],
            Member: eachHotspotData["Member"],
          });
          totalLength += 1;
        }
      });
    }
    this.setState({
      bookInitialHotspotLength: totalLength,
    });

    return hotspots;
  };

  handleInitialiseBook = async () => {
    try {
      let pagesName = [];
      let pagesToken = [];
      this.props.selectedBook.pagesName.map((eachPage, index) => {
        if (eachPage.includes("_page")) {
          pagesName.push(eachPage);
          pagesToken.push(this.props.selectedBook.pages[index]);
        }
      });
      let firstPageUrl = this.renderBookAssets(pagesName[0], pagesToken[0]);
      let imageSize = new Image();
      imageSize.src = firstPageUrl;
      imageSize.onload = async () => {
        let width = imageSize.width;
        let height = imageSize.height;

        let pListFileUrl;
        if (
          this.props.libraryStore.selectedNarrationId &&
          this.props.selectedNarration
        ) {
          pListFileUrl = this.renderNarrationAssetUrl(
            this.props.selectedNarration.pListName,
            this.props.selectedNarration.pList
          );
        } else {
          pListFileUrl = this.renderBookAssets(
            this.props.selectedBook.pListName,
            this.props.selectedBook.pList
          );
        }

        try {
          let pListFile = await axios.get(pListFileUrl);
          let pListData = plist.parse(pListFile.data);
          let hotspots = this.handleHotspotsData(pListData["Hotspots"]);
          this.setState(
            {
              pListData: pListData,
              pagesName: pagesName,
              pagesToken: pagesToken,
              imageHeight: height,
              imageWidth: width,
              pageA: [null, null],
              pageB: [
                this.renderBookAssets(pagesName[0], pagesToken[0]),
                this.renderBookAssets(pagesName[1], pagesToken[1]),
              ],
              pageC: [
                this.renderBookAssets(pagesName[2], pagesToken[2]),
                this.renderBookAssets(pagesName[3], pagesToken[3]),
              ],
              hotspots: hotspots,
            },
            async () => {
              this.successSound.play();
              this.handleToggleDot(true);
              this.handleCalculateImageOffset();
              this.timerInterval = setInterval(this.handleCountTimer, 1000);
            }
          );
        } catch (err) {
          this.props.history.push("/library");
        }
      };
    } catch (err) {
      this.props.history.push("/library");
    }
  };

  handleUpdateWindowSizeChanges = () => {
    //INFO : Check TranslateA
    if (this.state.translateA < 0)
      this.setState({
        translateA: -screenWidth,
      });
    else if (this.state.translateA > 0)
      this.setState({
        translateA: screenWidth,
      });

    //INFO : Check TranslateB
    if (this.state.translateB < 0)
      this.setState({
        translateB: -screenWidth,
      });
    else if (this.state.translateB > 0)
      this.setState({
        translateB: screenWidth,
      });

    //INFO : Check TranslateA
    if (this.state.translateC < 0)
      this.setState({
        translateC: -screenWidth,
      });
    else if (this.state.translateC > 0)
      this.setState({
        translateC: screenWidth,
      });
  };

  handleCalculateImageOffset = () => {
    let screenHalfWidth = screenWidth / 2;
    let screenAspectRatio = screenHalfWidth / screenHeight;
    let pageAspectRatio = this.state.imageWidth / this.state.imageHeight;
    if (screenAspectRatio > pageAspectRatio) {
      let imageHeightRatio = screenHeight / this.state.imageHeight;
      let scaledImageWidth = this.state.imageWidth * imageHeightRatio;
      let offset = screenHalfWidth - scaledImageWidth;
      this.setState({
        offsetX: offset / 2,
      });
    }

    this.setState({
      translateA: -screenWidth,
      translateB: 0,
      translateC: screenWidth,
    });
  };

  renderNarrationAssetUrl = (fileName, fileToken) => {
    return `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/user_narration%2F${this.props.authStore.selectedChild}%2F${this.props.selectedBook.id}%2F${this.props.libraryStore.selectedNarrationId}%2F${fileName}?alt=media&token=${fileToken}`;
  };

  renderBookAssets = (assetsName, assetsToken) => {
    return `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.props.selectedBook.id}%2Fcontent%2F${assetsName}?alt=media&token=${assetsToken}`;
  };

  renderInteractionContent = () => {
    if (this.state.narrationModal)
      return (
        <NarrationModal
          screen={this.state.narrationModal}
          handleModal={this.handleModal}
          handleLoad={this.handleLoad}
          selectedNarration={this.props.selectedNarration}
          bookNarration={this.props.bookNarration}
          hotspots={this.state.hotspots}
        />
      );
    else if (this.state.lastPageModal) return <LastPage />;
    else
      return (
        <>
          <VerticalContainer
            style={{
              width: screenWidth,
              height: screenHeight,
              position: "relative",
              overflow: "hidden",
            }}
            ref={this.pointRecogniserRef}
            onMouseDown={this.handleOnSwipeStart.bind(this, "mouse")}
            onMouseUp={this.handleOnSwipeEnd}
            onMouseMove={this.handleOnSwipe.bind(this, "mouse")}
            onTouchStart={this.handleOnSwipeStart.bind(this, "touch")}
            onTouchMove={this.handleOnSwipe.bind(this, "touch")}
            onTouchEnd={this.handleOnSwipeEnd}
          >
            {this.state.viewHotspots && (
              <>
                <img
                  src={RainbowButton}
                  style={{
                    width: 100,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 5,
                  }}
                  onClick={this.handleToggleViewHotspots}
                />
                {this.state.viewHotspotsStoryModal && (
                  <VerticalContainer
                    style={{ position: "absolute", top: 0, zIndex: 5 }}
                  >
                    <img
                      src={StoryMarker}
                      style={{
                        width: 240,
                      }}
                    />
                    <Button
                      style={{
                        marginTop: -10,
                        height: 35,
                        fontSize: 25,
                        width: 130,
                      }}
                      buttonText="Close"
                      onClick={this.handleToggleViewHotspots}
                    />
                  </VerticalContainer>
                )}
              </>
            )}

            {!this.state.pListData && (
              <VerticalContainer
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: screenWidth,
                  height: screenHeight,
                  zIndex: 2,
                  backgroundImage: "url(" + LoadingBackground + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundColor: White,
                }}
              >
                <Title4>Loading ...</Title4>
              </VerticalContainer>
            )}

            {this.state.viewingPageArray[0] !== null && (
              <BookWrapper
                id="wrapperA"
                style={{
                  width: screenWidth,
                  height: screenHeight,
                  transform: `translate(${this.state.translateA}px, 0px)`,
                }}
              >
                {this.state.pageA[0] && (
                  <BookPage
                    style={{
                      transform: this.state.offsetX
                        ? `translate(${this.state.offsetX}px, 0px)`
                        : "none",
                    }}
                    src={this.state.pageA[0]}
                  />
                )}

                {this.state.pageA[1] && (
                  <BookPage
                    style={{
                      transform: this.state.offsetX
                        ? `translate(-${this.state.offsetX}px, 0px)`
                        : "none",
                    }}
                    src={this.state.pageA[1]}
                  />
                )}
              </BookWrapper>
            )}

            <BookWrapper
              id="wrapperB"
              style={{
                width: screenWidth,
                height: screenHeight,
                transform: `translate(${this.state.translateB}px, 0px)`,
              }}
            >
              <BookPage
                style={{
                  transform: this.state.offsetX
                    ? `translate(${this.state.offsetX}px, 0px)`
                    : "none",
                }}
                src={this.state.pageB[0]}
              />
              <BookPage
                style={{
                  transform: this.state.offsetX
                    ? `translate(-${this.state.offsetX}px, 0px)`
                    : "none",
                }}
                src={this.state.pageB[1]}
              />
            </BookWrapper>

            <BookWrapper
              id="wrapperC"
              style={{
                width: screenWidth,
                height: screenHeight,
                transform: `translate(${this.state.translateC}px, 0px)`,
              }}
            >
              <BookPage
                style={{
                  transform: this.state.offsetX
                    ? `translate(${this.state.offsetX}px, 0px)`
                    : "none",
                }}
                src={this.state.pageC[0]}
              />
              <BookPage
                style={{
                  transform: this.state.offsetX
                    ? `translate(-${this.state.offsetX}px, 0px)`
                    : "none",
                }}
                src={this.state.pageC[1]}
              />
            </BookWrapper>
            <BookHotspots
              imageWidth={this.state.imageWidth}
              imageHeight={this.state.imageHeight}
              selectedMember={this.state.selectedMember}
              selectedIndex={this.state.selectedIndex}
              transformX={this.state.transformX}
              offsetX={this.state.offsetX}
              viewHotspots={this.state.viewHotspots}
              hotspots={this.state.hotspots}
              circleDotShowing={this.state.circleDotShowing}
              pListData={this.state.pListData}
              viewingPageArray={this.state.viewingPageArray}
              hotspotStateTrigger={this.state.hotspotStateTrigger}
              selectedNarration={this.props.selectedNarration}
              handleHighlightSound={this.handleHighlightSound}
              handleUpdateHotspots={this.handleUpdateHotspots}
              handleUpdateSelectedIndex={this.handleUpdateSelectedIndex}
              handleCalculateMidpoint={this.handleCalculateMidpoint}
              renderBookAssets={this.renderBookAssets}
              renderNarrationAssetUrl={this.renderNarrationAssetUrl}
            />
          </VerticalContainer>

          <BookInteractionFooter
            open={this.state.viewHotspots}
            selectedMember={this.state.selectedMember}
            circleDotShowing={this.state.circleDotShowing}
            viewHotspots={this.state.viewHotspots}
            handleBottomHelper={this.handleBottomHelper}
            handleSelectMember={this.handleSelectMember}
            handleModal={this.handleModal}
            handleToggleDot={this.handleToggleDot}
            handleToggleViewHotspots={this.handleToggleViewHotspots}
            handleNavigationButtonPressed={this.handleNavigationButtonPressed}
          />
        </>
      );
  };

  render() {
    if (this.props.utilsStore.deviceSize > 1440) {
      screenWidth = 1440;
      screenHeight = 1440 * (1 / bookInteractionAspectRatio);
    } else {
      screenWidth = this.props.utilsStore.deviceSize;
      screenHeight =
        this.props.utilsStore.deviceSize * (1 / bookInteractionAspectRatio);
    }

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader
          narrationModal={this.state.narrationModal}
          handleModal={this.handleModal}
          handleExitBook={this.handleExitBook}
        />
        {this.state.voiceOverModal && (
          <VoiceOverModal
            voiceOver={this.state.voiceOverModal}
            handleModal={this.handleModal}
            onConfirmLeave={this.handleOnConfirmLeave}
            onConfirm={this.handleOnConfirmVoiceOver}
            onCancel={this.handleModal.bind(this, "svgSave", false)}
            onSave={this.handleSaveVoiceOver}
          />
        )}
        {this.state.videoTutorialModal && (
          <VideoModal
            handleHideModal={this.handleModal.bind(this, "video", false)}
            show={this.state.videoTutorialModal}
          />
        )}

        {this.renderInteractionContent()}

        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  let bookNarration = state.libraryStore.selectedNarration;
  let selectedNarration = null;

  if (bookNarration && state.libraryStore.selectedBook) {
    bookNarration.narrationList.map((eachNarration) => {
      if (eachNarration.id === state.libraryStore.selectedNarrationId)
        selectedNarration = eachNarration;
    });
  }

  return {
    selectedBook: state.libraryStore.selectedBook,
    selectedNarration: selectedNarration,
    bookNarration: bookNarration,
    utilsStore: state.utilsStore,
    authStore: state.authStore,
    libraryStore: state.libraryStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAnalyticsList: (bookId, screenTime, pageIndex, stickers, points) =>
      dispatch(
        updateAnalyticsList(bookId, screenTime, pageIndex, stickers, points)
      ),
    updateSelectedBookAndNarration: (book, narrationId) =>
      dispatch(updateSelectedBookAndNarration(book, narrationId)),
    saveBookVoiceOver: (
      narrationId,
      bookId,
      narrationName,
      allHotspots,
      type
    ) =>
      dispatch(
        saveBookVoiceOver(narrationId, bookId, narrationName, allHotspots, type)
      ),
    resetVoiceOver: (bookId, narrationId, pListName, pList) =>
      dispatch(resetVoiceOver(bookId, narrationId, pListName, pList)),
    getSelectedNarration: (childId, narrationId) =>
      dispatch(getSelectedNarration(childId, narrationId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookInteraction);

const BookPage = styled.img`
  object-fit: contain;
  width: 50%;
  height: 100%;
`;

const BookWrapper = styled(RowContainer)`
  position: absolute;
  top: 0px;
  left: 0px;
`;
