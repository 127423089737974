import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import {
  Divider,
  RowContainer,
  VerticalContainer,
} from "../styles/GlobalStyles";
import { Footnote, Headline } from "../styles/Typography";
import { LightGrey, DarkGrey } from "../styles/Colors";
import Button from "../global/Button";
//Utils
import { parentAvatarList } from "../utils/avatarList";
//Actions
import { signOut } from "../store/actions/authActions";

const marginStyle = {
  marginTop: 25,
  textDecoration: "none",
  color: DarkGrey,
};

class AuthModal extends Component {
  handleNavigate = (type) => {
    if (type === "parentDashboard") this.props.history.push("/user/dashboard");
    else if (type === "editParent") this.props.history.push("/user/edit");
    else if (type === "addChild") this.props.history.push("/user/add");
    else if (type === "logout") {
      this.props.signOut();
      this.props.history.push("/");
    }
  };

  renderParentFunctionalityLink = (type) => {
    if (
      this.props.authStore.userAuth &&
      this.props.authStore.user &&
      this.props.authStore.user.name &&
      this.props.authStore.user.children.length > 0
    )
      if (type === "edit")
        return (
          <Headline
            style={marginStyle}
            onClick={this.handleNavigate.bind(this, "editParent")}
          >
            Edit Profile
          </Headline>
        );
      else
        return (
          <>
            <Headline
              style={marginStyle}
              onClick={this.handleNavigate.bind(this, "parentDashboard")}
            >
              Parents Dashboard
            </Headline>
            <Headline
              style={marginStyle}
              onClick={this.handleNavigate.bind(this, "addChild")}
            >
              Add Storytellers
            </Headline>
          </>
        );
  };

  renderParentAvatar = () => {
    let selectedAvatar = 0;
    if (this.props.authStore.user)
      selectedAvatar = this.props.authStore.user.avatar;

    return selectedAvatar;
  };

  renderExpiredCountDown = () => {
    let today = new Date().getTime();
    let subscriptionEnd = new Date(
      this.props.authStore.user.subscriptionEnd.seconds * 1000
    ).getTime();

    let title = "";
    let daysLeft = "";
    let differenceInTime = subscriptionEnd - today;
    if (differenceInTime > 0) {
      daysLeft = Math.floor(differenceInTime / (1000 * 3600 * 24));
      if (daysLeft > 1) title = " day left";
      else title = " days left";
    } else daysLeft = "Expired";

    return daysLeft + title;
  };

  render() {
    return (
      <Overlay onClick={this.props.handleAuthModal}>
        <InnerModal
          style={{
            right:
              this.props.utilsStore.deviceSize > 1440
                ? (this.props.utilsStore.deviceSize - 1440) / 2
                : "2.5%",
          }}
        >
          <VerticalContainer style={{ alignItems: "flex-start" }}>
            <RowContainer>
              <img
                src={parentAvatarList[this.renderParentAvatar()]}
                style={{ width: 60 }}
              />
              <VerticalContainer
                style={{ alignItems: "flex-start", marginLeft: 15 }}
              >
                <Footnote style={{ color: DarkGrey }}>
                  {this.props.authStore.user && this.props.authStore.user.name}
                </Footnote>
                <Footnote
                  style={{
                    color: LightGrey,
                    width: 220,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {this.props.authStore.user.email}
                </Footnote>
              </VerticalContainer>
            </RowContainer>

            <Footnote style={{ marginTop: 15, color: LightGrey }}>
              Guest Login : {this.renderExpiredCountDown()}
            </Footnote>
            {this.renderParentFunctionalityLink()}

            <RowContainer style={{ width: "100%", marginTop: 25 }}>
              <Divider
                style={{
                  height: 1,
                  flexGrow: 1,
                }}
              />
            </RowContainer>
            {this.renderParentFunctionalityLink("edit")}
            {/* <Headline style={marginStyle}>Reset Password</Headline> */}
          </VerticalContainer>
          <VerticalContainer style={{ marginTop: 25, alignItems: "flex-end" }}>
            <Button
              buttonText="Log Out"
              onClick={this.handleNavigate.bind(this, "logout")}
            />
          </VerticalContainer>
        </InnerModal>
      </Overlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthModal)
);

const Overlay = styled(VerticalContainer)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  margin: auto;
  z-index: 5;
`;

const InnerModal = styled.div`
  position: absolute;
  top: 130px;
  width: 280px;
  border-radius: 20px;
  background: white;
  padding: 35px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
`;
