// Child Avatar
import AvatarA1 from "../assets/images/avatars/childAvatars/avatarA1.png";
import AvatarA2 from "../assets/images/avatars/childAvatars/avatarA2.png";
import AvatarA3 from "../assets/images/avatars/childAvatars/avatarA3.png";
import AvatarA4 from "../assets/images/avatars/childAvatars/avatarA4.png";
import AvatarA5 from "../assets/images/avatars/childAvatars/avatarA5.png";
import AvatarA6 from "../assets/images/avatars/childAvatars/avatarA6.png";
import AvatarA7 from "../assets/images/avatars/childAvatars/avatarA7.png";
import AvatarA8 from "../assets/images/avatars/childAvatars/avatarA8.png";
import AvatarB1 from "../assets/images/avatars/childAvatars/avatarB1.png";
import AvatarB2 from "../assets/images/avatars/childAvatars/avatarB2.png";
import AvatarB3 from "../assets/images/avatars/childAvatars/avatarB3.png";
import AvatarB4 from "../assets/images/avatars/childAvatars/avatarB4.png";
import AvatarB5 from "../assets/images/avatars/childAvatars/avatarB5.png";
import AvatarB6 from "../assets/images/avatars/childAvatars/avatarB6.png";
import AvatarB7 from "../assets/images/avatars/childAvatars/avatarB7.png";
import AvatarB8 from "../assets/images/avatars/childAvatars/avatarB8.png";
import AvatarC1 from "../assets/images/avatars/childAvatars/avatarC1.png";
import AvatarC2 from "../assets/images/avatars/childAvatars/avatarC2.png";
import AvatarC3 from "../assets/images/avatars/childAvatars/avatarC3.png";
import AvatarC4 from "../assets/images/avatars/childAvatars/avatarC4.png";
import AvatarC5 from "../assets/images/avatars/childAvatars/avatarC5.png";
import AvatarC6 from "../assets/images/avatars/childAvatars/avatarC6.png";
import AvatarC7 from "../assets/images/avatars/childAvatars/avatarC7.png";
import AvatarC8 from "../assets/images/avatars/childAvatars/avatarC8.png";
import AvatarD1 from "../assets/images/avatars/childAvatars/avatarD1.png";
import AvatarD2 from "../assets/images/avatars/childAvatars/avatarD2.png";
import AvatarD3 from "../assets/images/avatars/childAvatars/avatarD3.png";
import AvatarD4 from "../assets/images/avatars/childAvatars/avatarD4.png";
import AvatarD5 from "../assets/images/avatars/childAvatars/avatarD5.png";
import AvatarD6 from "../assets/images/avatars/childAvatars/avatarD6.png";
import AvatarD7 from "../assets/images/avatars/childAvatars/avatarD7.png";
// Parent Avatar
import AvatarPA1 from "../assets/images/avatars/parentAvatars/avatarA1.png";
import AvatarPA2 from "../assets/images/avatars/parentAvatars/avatarA2.png";
import AvatarPA3 from "../assets/images/avatars/parentAvatars/avatarA3.png";
import AvatarPA4 from "../assets/images/avatars/parentAvatars/avatarA4.png";
import AvatarPA5 from "../assets/images/avatars/parentAvatars/avatarA5.png";
import AvatarPA6 from "../assets/images/avatars/parentAvatars/avatarA6.png";
import AvatarPA7 from "../assets/images/avatars/parentAvatars/avatarA7.png";
import AvatarPA8 from "../assets/images/avatars/parentAvatars/avatarA8.png";
import AvatarPB1 from "../assets/images/avatars/parentAvatars/avatarB1.png";
import AvatarPB2 from "../assets/images/avatars/parentAvatars/avatarB2.png";
import AvatarPB3 from "../assets/images/avatars/parentAvatars/avatarB3.png";
import AvatarPB4 from "../assets/images/avatars/parentAvatars/avatarB4.png";
import AvatarPB5 from "../assets/images/avatars/parentAvatars/avatarB5.png";
import AvatarPB6 from "../assets/images/avatars/parentAvatars/avatarB6.png";
import AvatarPB7 from "../assets/images/avatars/parentAvatars/avatarB7.png";
import AvatarPB8 from "../assets/images/avatars/parentAvatars/avatarB8.png";

export const childAvatarList = [
  AvatarA1,
  AvatarA2,
  AvatarA3,
  AvatarA4,
  AvatarA5,
  AvatarA6,
  AvatarA7,
  AvatarA8,
  AvatarB1,
  AvatarB2,
  AvatarB3,
  AvatarB4,
  AvatarB5,
  AvatarB6,
  AvatarB7,
  AvatarB8,
  AvatarC1,
  AvatarC2,
  AvatarC3,
  AvatarC4,
  AvatarC5,
  AvatarC6,
  AvatarC7,
  AvatarC8,
  AvatarD1,
  AvatarD2,
  AvatarD3,
  AvatarD4,
  AvatarD5,
  AvatarD6,
  AvatarD7,
];

export const parentAvatarList = [
  AvatarPA1,
  AvatarPA2,
  AvatarPA3,
  AvatarPA4,
  AvatarPA5,
  AvatarPA6,
  AvatarPA7,
  AvatarPA8,
  AvatarPB1,
  AvatarPB2,
  AvatarPB3,
  AvatarPB4,
  AvatarPB5,
  AvatarPB6,
  AvatarPB7,
  AvatarPB8,
];
