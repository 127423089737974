import firebase from "../../utils/firebase";
//Utils
import EventBus from "js-event-bus";
import { monthNames, getMonday } from "../../utils/miscList";
import { handleCheckBookCondition } from "./dataActions";

const eventBus = EventBus();

const handleSnapshot = (snapshot, list) => {
  if (snapshot)
    snapshot.forEach((eachDoc) => {
      list.push(eachDoc.data());
    });
};

//==================================== Create Child Utils =============================================

const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

//==================================== Child Analytics Functions =============================================

export const getChildWeeklyReport = (childId) => {
  return async (dispatch, getState) => {
    let currentMonday = getMonday(new Date());
    let reportDate =
      currentMonday.getDate() +
      monthNames[currentMonday.getMonth()] +
      currentMonday.getFullYear();

    let reportDoc = await firebase
      .firestore()
      .collection(`children/${childId}/weeklyReports`)
      .doc(reportDate)
      .get();
    dispatch({
      type: "UPDATE_SELECTED_CHILD_REPORT",
      payload: {
        report: reportDoc.exists ? reportDoc.data() : null,
      },
    });
  };
};

//==================================== Add/Create Child Functions =============================================

export const createChild = (details) => {
  return async (dispatch, getState) => {
    try {
      let currentChildrenList = JSON.parse(
        JSON.stringify(getState().authStore.children)
      );
      let childId = generateUUID();
      let childClass = {
        id: childId,
        name: details.name,
        avatar: details.avatar,
        passcode: "1234",
        age: details.age,
        createdAt: new Date(),
        points: 0,
        stickers: [],
        narrations: [],
        booksRead: [],
        userId: firebase.auth().currentUser.uid,
      };

      if (currentChildrenList.length > 0 && currentChildrenList.length < 3) {
        currentChildrenList.push(childClass);
        let childrenIdList = [];
        currentChildrenList.map((eachChild) => {
          childrenIdList.push(eachChild.id);
        });

        let userDoc = firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid);
        await userDoc.update({
          children: childrenIdList,
        });
        await firebase
          .firestore()
          .collection("children")
          .doc(childId)
          .set(childClass);
      } else if (currentChildrenList.length === 0) {
        let userDoc = firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid);
        await userDoc.update({
          children: [childId],
        });
        await firebase
          .firestore()
          .collection("children")
          .doc(childId)
          .set(childClass);

        await window.localStorage.setItem("selectedChild", childId);
        dispatch({
          type: "UPDATE_SELECTED_CHILD",
          payload: {
            selectedChild: childId,
          },
        });
      }
      eventBus.emit("create-child-success");
    } catch (err) {}
  };
};

export const deleteChild = (childrenId) => {
  return async (dispatch, getState) => {
    try {
      let childrenList = getState().authStore.children;
      let currentUser = getState().authStore.user;
      let selectedChild;
      let newChildrenList = [];
      if (childrenList.length > 0)
        childrenList.map((eachChild) => {
          if (eachChild.id === childrenId) selectedChild = eachChild;
          else newChildrenList.push(eachChild.id);
        });

      if (selectedChild && currentUser) {
        let userDoc = firebase
          .firestore()
          .collection("users")
          .doc(currentUser.id);
        await userDoc.update({
          children: newChildrenList,
          selectedChild: newChildrenList[0],
        });
        let childDoc = firebase
          .firestore()
          .collection("children")
          .doc(selectedChild.id);
        await childDoc.delete();
      }
    } catch (err) {}
  };
};

//==================================== Child CRUD Functions =============================================

export const updateChildPriviledge = (parentPriviledge) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "UPDATE_CHILD_PRIVILEDGE",
      payload: {
        parentPriviledge: parentPriviledge,
      },
    });
  };
};

export const updateCurrentChildAttribute = (
  childIndex,
  attrType,
  attrValue
) => {
  return async (dispatch, getState) => {
    try {
      let oldChildrenList = getState().authStore.children;
      let updateChildId = oldChildrenList[childIndex]["id"];

      let childDoc = firebase
        .firestore()
        .collection("children")
        .doc(updateChildId);
      await childDoc.update({
        [attrType]: attrValue,
      });
    } catch (err) {}
  };
};

//==================================== Child Data Listener =============================================

let childBookAnalyticsListener = {
  id: "",
  listener: null,
};
let childBookNarrationListener = {
  id: "",
  listener: null,
};

export const getChildAnalytics = (
  childId,
  favourite,
  startAfter,
  reset,
  limit
) => {
  return async (dispatch, getState) => {
    try {
      let analyticsQuery = firebase
        .firestore()
        .collection(`children/${childId}/bookAnalytics`)
        .orderBy("updatedAt", "desc")
        .limit(limit);

      if (favourite)
        analyticsQuery = analyticsQuery.where("favourite", "==", true);

      if (startAfter) analyticsQuery = analyticsQuery.startAfter(startAfter);

      let analyticsQuerySnapshot = await analyticsQuery.get();
      let analyticList = [];
      handleSnapshot(analyticsQuerySnapshot, analyticList);

      if (analyticList.length === 0) {
        if (reset)
          dispatch({
            type: "UPDATE_CHILD_ANALYTICS",
            payload: {
              childAnalytics: [],
              childAnalyticsPagination: "",
            },
          });
        return;
      }

      let bookIdList = [];
      if (analyticList.length)
        analyticList.map((eachAnalytics) => {
          bookIdList.push(eachAnalytics.id);
        });

      let lastDate = "";
      if (analyticList.length === limit)
        lastDate = new Date(analyticList[limit - 1].updatedAt.seconds * 1000);

      let bookQuery = firebase
        .firestore()
        .collection("books")
        .where("published", "==", true)
        .where("id", "in", bookIdList);
      let bookQuerySnapshot = await bookQuery.get();
      let bookList = [];
      handleSnapshot(bookQuerySnapshot, bookList);

      let filteredBookList = [];
      if (bookList.length > 0)
        bookList.map((eachBook) => {
          if (handleCheckBookCondition(eachBook))
            filteredBookList.push(eachBook);
        });

      let analyticsAttribute = [];
      filteredBookList.map((eachBook) => {
        analyticList.map((eachAnalytics) => {
          if (eachAnalytics.id === eachBook.id)
            analyticsAttribute.push({
              book: eachBook,
              analytics: eachAnalytics,
            });
        });
      });

      if (reset)
        dispatch({
          type: "UPDATE_CHILD_ANALYTICS",
          payload: {
            childAnalytics: analyticsAttribute,
            childAnalyticsPagination: lastDate,
          },
        });
      else {
        let oldAnalytics = JSON.parse(
          JSON.stringify(getState().childStore.childAnalytics)
        );
        let newAnalyticsAttribute = oldAnalytics.concat(analyticsAttribute);
        dispatch({
          type: "UPDATE_CHILD_ANALYTICS",
          payload: {
            childAnalytics: newAnalyticsAttribute,
            childAnalyticsPagination: lastDate,
          },
        });
      }

      let previousLength = bookIdList.length;
      let newLength = filteredBookList.length;
      if (newLength < previousLength) {
        let lengthDiff = previousLength - newLength;
        getChildAnalyticsLoop(
          dispatch,
          getState,
          analyticsQuery,
          lastDate,
          lengthDiff
        );
      }
    } catch (err) {}
  };
};

const getChildAnalyticsLoop = async (
  dispatch,
  getState,
  analyticsQuery,
  startAfter,
  limit
) => {
  try {
    analyticsQuery = analyticsQuery.startAfter(startAfter).limit(limit);

    let analyticsQuerySnapshots = await analyticsQuery.get();
    let analyticList = [];
    handleSnapshot(analyticsQuerySnapshots, analyticList);

    if (analyticList.length === 0) return;

    let bookIdList = [];
    analyticList.map((eachAnalytic) => {
      bookIdList.push(eachAnalytic.id);
    });

    let lastDate = "";
    if (analyticList.length === limit)
      lastDate = new Date(analyticList[limit - 1].updatedAt.seconds * 1000);
    let bookQuery = firebase
      .firestore()
      .collection("books")
      .where("published", "==", true)
      .where("id", "in", bookIdList);

    let bookQuerySnapshot = await bookQuery.get();
    let bookList = [];
    handleSnapshot(bookQuerySnapshot, bookList);

    let filteredBookList = [];
    if (bookList.length > 0)
      bookList.map((eachBook) => {
        if (handleCheckBookCondition(eachBook)) filteredBookList.push(eachBook);
      });

    let analyticsAttribute = [];
    filteredBookList.map((eachBook) => {
      analyticList.map((eachAnalytic) => {
        if (eachAnalytic.id === eachBook.id)
          analyticsAttribute.push({
            book: eachBook,
            analytics: eachAnalytic,
          });
      });
    });

    let oldAnalytics = JSON.parse(
      JSON.stringify(getState().childStore.childAnalytics)
    );
    let newAnalyticsAttribute = oldAnalytics.concat(analyticsAttribute);
    dispatch({
      type: "UPDATE_CHILD_ANALYTICS",
      payload: {
        childAnalytics: newAnalyticsAttribute,
        childAnalyticsPagination: lastDate,
      },
    });

    let previousLength = bookIdList.length;
    let newLength = filteredBookList.length;
    if (newLength < previousLength) {
      let lengthDiff = previousLength - newLength;
      getChildAnalyticsLoop(
        dispatch,
        getState,
        analyticsQuery,
        lastDate,
        lengthDiff
      );
    }
  } catch (err) {}
};

export const getChildNarration = (childId, startAfter, reset, limit) => {
  return async (dispatch, getState) => {
    try {
      let narrationQuery = firebase
        .firestore()
        .collection("children")
        .doc(childId)
        .collection("narration")
        .orderBy("bookId")
        .limit(limit);

      if (startAfter) narrationQuery = narrationQuery.startAfter(startAfter);

      let narrationQuerySnapshot = await narrationQuery.get();
      let narrationList = [];
      handleSnapshot(narrationQuerySnapshot, narrationList);

      if (narrationList.length === 0) {
        if (reset)
          dispatch({
            type: "UPDATE_CHILD_NARRATION",
            payload: {
              childNarration: [],
              childNarrationPagination: "",
            },
          });
        return;
      }

      let bookIdList = [];
      if (narrationList.length)
        narrationList.map((eachNarration) => {
          bookIdList.push(eachNarration.bookId);
        });

      let lastBookId = "";
      if (narrationList.length === limit)
        lastBookId = narrationList[limit - 1].bookId;

      let bookQuery = firebase
        .firestore()
        .collection("books")
        .where("published", "==", true)
        .where("id", "in", bookIdList);
      let bookQuerySnapshot = await bookQuery.get();
      let bookList = [];
      handleSnapshot(bookQuerySnapshot, bookList);

      let filteredBookList = [];
      if (bookList.length > 0)
        bookList.map((eachBook) => {
          if (handleCheckBookCondition(eachBook))
            filteredBookList.push(eachBook);
        });

      let narrationListAttribute = [];
      filteredBookList.map((eachBook) => {
        narrationList.map((eachNarration) => {
          if (eachNarration.bookId === eachBook.id)
            narrationListAttribute.push({
              book: eachBook,
              narration: eachNarration,
            });
        });
      });

      if (reset)
        dispatch({
          type: "UPDATE_CHILD_NARRATION",
          payload: {
            childNarration: narrationListAttribute,
            childNarrationPagination: lastBookId,
          },
        });
      else {
        let oldNarration = JSON.parse(
          JSON.stringify(getState().childStore.childNarration)
        );
        let newNarration = oldNarration.concat(narrationListAttribute);
        dispatch({
          type: "UPDATE_CHILD_NARRATION",
          payload: {
            childNarration: newNarration,
            childNarrationPagination: lastBookId,
          },
        });
      }

      let previousLength = bookIdList.length;
      let newLength = filteredBookList.length;
      if (newLength < previousLength) {
        let lengthDiff = previousLength - newLength;
        getChildNarrationLoop(
          dispatch,
          getState,
          narrationQuery,
          lastBookId,
          lengthDiff
        );
      }
    } catch (err) {}
  };
};

const getChildNarrationLoop = async (
  dispatch,
  getState,
  narrationQuery,
  startAfter,
  limit
) => {
  try {
    narrationQuery = narrationQuery.startAfter(startAfter).limit(limit);

    let narrationQuerySnapshot = await narrationQuery.get();
    let narrationList = [];
    handleSnapshot(narrationQuerySnapshot, narrationList);

    if (narrationList.length === 0) return;

    let bookIdList = [];
    if (narrationList.length) {
      narrationList.map((eachNarration) => {
        bookIdList.push(eachNarration.bookId);
      });
    }

    let lastBookId = "";
    if (narrationList.length === limit)
      lastBookId = narrationList[limit - 1].bookId;

    let bookQuery = firebase
      .firestore()
      .collection("books")
      .where("published", "==", true)
      .where("id", "in", bookIdList);
    let bookQuerySnapshot = await bookQuery.get();
    let bookList = [];
    handleSnapshot(bookQuerySnapshot, bookList);

    let filteredBookList = [];
    if (bookList.length > 0)
      bookList.map((eachBook) => {
        if (handleCheckBookCondition(eachBook)) filteredBookList.push(eachBook);
      });

    let narrationListAttribute = [];
    filteredBookList.map((eachBook) => {
      narrationList.map((eachNarration) => {
        if (eachNarration.bookId === eachBook.id)
          narrationListAttribute.push({
            book: eachBook,
            narration: eachNarration,
          });
      });
    });

    let oldNarration = JSON.parse(
      JSON.stringify(getState().childStore.childNarration)
    );
    let newNarration = oldNarration.concat(narrationListAttribute);
    dispatch({
      type: "UPDATE_CHILD_NARRATION",
      payload: {
        childNarration: newNarration,
        childNarrationPagination: lastBookId,
      },
    });

    let previousLength = bookIdList.length;
    let newLength = filteredBookList.length;
    if (newLength < previousLength) {
      let lengthDiff = previousLength - newLength;
      getChildNarrationLoop(
        dispatch,
        getState,
        narrationQuery,
        lastBookId,
        lengthDiff
      );
    }
  } catch (err) {}
};

export const getChildBookAnalyticsListener = (childId) => {
  return async (dispatch, getState) => {
    try {
      if (childBookAnalyticsListener.id !== childId) {
        childBookAnalyticsListener.id = childId;

        if (childBookAnalyticsListener.listener)
          childBookAnalyticsListener.listener();

        //INFO : Setup Analytics Listener
        childBookAnalyticsListener.listener = firebase
          .firestore()
          .collection(`children/${childId}/bookAnalytics`)
          .orderBy("updatedAt", "desc")
          .onSnapshot((querySnapshot) => {
            if (querySnapshot) {
              let analyticsList = [];
              if (querySnapshot)
                querySnapshot.forEach(function (doc) {
                  analyticsList.push(doc.data());
                });

              dispatch({
                type: `UPDATE_CHILD_ANALYTICS`,
                payload: {
                  childAnalytics: analyticsList,
                },
              });
            } else
              dispatch({
                type: `UPDATE_CHILD_ANALYTICS`,
                payload: {
                  childAnalytics: [],
                },
              });
          });
      }
    } catch (err) {}
  };
};

export const getChildBookNarrationListener = (childId) => {
  return async (dispatch, getState) => {
    try {
      if (childBookNarrationListener.id !== childId) {
        childBookNarrationListener.id = childId;

        if (childBookNarrationListener.listener)
          childBookNarrationListener.listener();

        //INFO : Setup Analytics Listener
        childBookNarrationListener.listener = firebase
          .firestore()
          .collection("children")
          .doc(childId)
          .collection("narration")
          .onSnapshot((querySnapshot) => {
            if (querySnapshot) {
              let narrationList = [];
              if (querySnapshot)
                querySnapshot.forEach(function (doc) {
                  narrationList.push(doc.data());
                });
              dispatch({
                type: `UPDATE_CHILD_NARRATION`,
                payload: {
                  childNarration: narrationList,
                },
              });
            } else
              dispatch({
                type: `UPDATE_CHILD_NARRATION`,
                payload: {
                  childNarration: [],
                },
              });
          });
      }
    } catch (err) {}
  };
};

export const removeChildBookAnalyticsListener = () => {
  return async (dispatch, getState) => {
    try {
      if (childBookAnalyticsListener.listener) {
        childBookAnalyticsListener.listener();
        childBookAnalyticsListener = {
          id: "",
          listener: null,
        };
      }
    } catch (err) {}
  };
};

export const removeChildBookNarrationListener = () => {
  return async (dispatch, getState) => {
    try {
      if (childBookNarrationListener.listener) {
        childBookNarrationListener.listener();
        childBookNarrationListener = {
          id: "",
          listener: null,
        };
      }
    } catch (err) {}
  };
};
