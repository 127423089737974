import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
//Assets
import LeftIcon from "../assets/icons/leftIcon.png";
import NormalLock from "../assets/images/childrenDashboard/stickerLock.png";
import StarLock from "../assets/images/childrenDashboard/stickerLockStar.png";
import BookImage from "../components/catalog/BookImage";
import { handleCheckBookCondition } from "../store/actions/dataActions";
//Actions
import {
  updateSelectedBook,
  updateSelectedBookAndNarration,
} from "../store/actions/libraryActions";
import { DarkGrey, PrimaryGreen } from "../styles/Colors";
//Styles
import { RowContainer, VerticalContainer } from "../styles/GlobalStyles";
import { Body, Footnote, SubFootnote, SubTitle2 } from "../styles/Typography";
//Utils
import { childAvatarList, parentAvatarList } from "../utils/avatarList";
//Component
import AnimatedWrapper from "./AnimatedWrapper";
import Button from "./Button";
import IconButton from "./IconButton";
import Tag from "./Tag";

const lock = [NormalLock, StarLock];

class Pagination extends Component {
  handleStickerSound = (soundUrl) => {
    let stickerSound = new Audio(soundUrl);
    stickerSound.currentTime = 0;
    stickerSound.play();
  };

  handleBookSelected = (path, selectedBook) => {
    if (this.props.authStore.userAuth) {
      this.props.updateSelectedBook(selectedBook);
      this.props.history.push(path + `/${selectedBook.id}`);
      window.scrollTo({ top: 115, behavior: "smooth" });
    } else this.props.history.push("/auth/signin");
  };

  renderAttributesComponent = (text, color, value) => {
    return (
      <VerticalContainer>
        <Tag backgroundColor={color} text={text} />
        <CircleContainer
          style={{
            width: 65,
            height: 65,
            borderColor: "#FE5F60",
          }}
        >
          <SubTitle2 style={{ color: "#FE5F60" }}>{value}</SubTitle2>
        </CircleContainer>
      </VerticalContainer>
    );
  };

  renderMemberAvatar = (memberId) => {
    let selectedMemberId;
    if (
      this.props.authStore.children &&
      this.props.authStore.children.length > 0 &&
      this.props.authStore.user
    ) {
      let allFamily = this.props.authStore.children.concat([
        this.props.authStore.user,
      ]);
      allFamily.map((eachMember, index) => {
        if (eachMember.id === memberId) {
          if (eachMember.role)
            selectedMemberId = parentAvatarList[eachMember.avatar];
          else selectedMemberId = childAvatarList[eachMember.avatar];
        }
      });
    }
    return selectedMemberId;
  };

  renderVoiceOverAvatar = (memberList) => {
    let imageStyle = {
      width: 60,
      height: 60,
      marginLeft: 5,
      marginRight: 5,
    };
    let avatarList = [];

    if (
      this.props.authStore.children &&
      this.props.authStore.children.length > 0 &&
      this.props.authStore.user
    ) {
      let allFamily = this.props.authStore.children.concat([
        this.props.authStore.user,
      ]);
      allFamily.map((eachMember, index) => {
        if (memberList.includes(eachMember.id)) {
          let avatar;
          if (eachMember.role) avatar = parentAvatarList[eachMember.avatar];
          else avatar = childAvatarList[eachMember.avatar];

          avatarList.push(
            <VerticalContainer key={index}>
              <img src={avatar} style={imageStyle} />
              <SubFootnote style={{ marginTop: 10 }}>
                {eachMember.name}
              </SubFootnote>
            </VerticalContainer>
          );
        }
      });
    }
    return (
      <RowContainer style={{ marginLeft: 20 }}>
        {avatarList}
        {/* <img src={childAvatarList[0]} style={imageStyle} />
        <img src={childAvatarList[1]} style={imageStyle} />
        <img src={childAvatarList[2]} style={imageStyle} />
        <img src={childAvatarList[2]} style={imageStyle} /> */}
      </RowContainer>
    );
  };

  renderStickers = () => {
    let dataView = [];
    let divWidth;
    if (this.props.utilsStore.deviceSize > 1440) divWidth = 1440;
    else divWidth = this.props.utilsStore.deviceSize;

    divWidth = divWidth * 0.95;
    if (divWidth > 1000) divWidth = 1000;

    divWidth = divWidth * 0.8;
    divWidth = divWidth - 80;
    divWidth = Math.floor(divWidth / 5);

    if (this.props.data && this.props.data.length > 0)
      this.props.data.map((eachSticker, index) => {
        let conIndex = index + 1;
        if (
          conIndex > (this.props.currentPage - 1) * this.props.limit &&
          conIndex <= this.props.currentPage * this.props.limit
        ) {
          let stickerUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_stickers%2F${eachSticker.id}%2Fsticker.png?alt=media&token=${eachSticker.image}`;
          let stickerSound = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_stickers%2F${eachSticker.id}%2Fsound.mp3?alt=media&token=${eachSticker.sound}`;

          let stickersAchieved = this.props.renderSelectedChildAttribute(
            "stickersContent"
          );

          let stickerAchived = false;
          if (stickersAchieved.includes(eachSticker.id)) stickerAchived = true;

          dataView.push(
            <div key={index}>
              {!stickerAchived ? (
                <VerticalContainer
                  style={{
                    width: divWidth,
                    height: divWidth,
                    margin: 20,
                    marginBottom: 5,
                  }}
                >
                  <img
                    src={lock[index % 2]}
                    style={{
                      width: divWidth - 20,
                      height: divWidth - 20,
                      zIndex: 2,
                      position: "absolute",
                    }}
                  />
                  <img
                    src={stickerUrl}
                    style={{
                      width: divWidth,
                      height: divWidth,
                      filter: "grayscale(100%)",
                    }}
                  />
                </VerticalContainer>
              ) : (
                <IconButton
                  iconImage={stickerUrl}
                  style={{ margin: 20, marginBottom: 5 }}
                  imageStyle={{
                    width: divWidth,
                    height: divWidth,
                  }}
                  onClick={this.handleStickerSound.bind(this, stickerSound)}
                />
              )}
            </div>
          );
        }
      });

    return dataView;
  };

  renderBookAnalytics = () => {
    let dataView = [];

    let divWidth;
    if (this.props.utilsStore.deviceSize > 1440) divWidth = 1440;
    else divWidth = this.props.utilsStore.deviceSize;

    divWidth = divWidth * 0.95;
    divWidth = divWidth - 240;

    let bookSize = Math.floor(divWidth / 5);

    if (this.props.data && this.props.data.length > 0)
      this.props.data.map((eachData, index) => {
        let conIndex = index + 1;
        if (
          conIndex > (this.props.currentPage - 1) * this.props.limit &&
          conIndex <= this.props.currentPage * this.props.limit
        ) {
          let imageUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${eachData.book.id}%2FpreviewImage.png?alt=media&token=${eachData.book.image}`;

          let analyticsData = eachData.analytics.analytics;
          let totalTime = 0;
          let totalTimeType = "second";

          if (analyticsData && analyticsData.length > 0) {
            analyticsData.map((eachAnalytics) => {
              totalTime += eachAnalytics.screenTime;
            });
            if (totalTime >= 3600) {
              totalTime = Math.floor(totalTime / 3600);
              if (totalTime > 1) totalTimeType = "hours";
              else totalTimeType = "hour";
            } else if (totalTime >= 60) {
              totalTime = Math.floor(totalTime / 60);
              if (totalTime > 1) totalTimeType = "minutes";
              else totalTimeType = "minute";
            } else if (totalTime >= 0) totalTimeType = "seconds";
          }

          dataView.push(
            <AnimatedWrapper
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: 20,
              }}
              key={index}
              onClick={this.handleBookSelected.bind(
                this,
                "/book",
                eachData.book
              )}
            >
              <BookImage width={bookSize} height={bookSize} src={imageUrl} />
              <Tag
                style={{ width: bookSize - 40, marginTop: 20 }}
                backgroundColor={PrimaryGreen}
                text={totalTime + " " + totalTimeType}
              />
            </AnimatedWrapper>
          );
        }
      });
    else
      dataView.push(
        <Footnote
          key="empty"
          style={{
            marginTop: 20,
            marginBottom: 20,
            color: DarkGrey,
            textAlign: "center",
            width: "100%",
          }}
        >
          {this.props.type === "bookAnalyticsFavourite"
            ? "Treat your shelves to your favourites by tapping the jar!"
            : "Yellow there! Orange you glad there are so many books to be red?"}
        </Footnote>
      );

    return dataView;
  };

  renderBooks = () => {
    let dataView = [];

    let divWidth;
    if (this.props.utilsStore.deviceSize > 1440) divWidth = 1440;
    else divWidth = this.props.utilsStore.deviceSize;

    divWidth = divWidth * 0.95;
    divWidth = divWidth - 240;

    let bookSize = Math.floor(divWidth / 5);

    if (this.props.data && this.props.data.length > 0)
      this.props.data.map((eachData, index) => {
        let conIndex = index + 1;
        if (
          conIndex > (this.props.currentPage - 1) * this.props.limit &&
          conIndex <= this.props.currentPage * this.props.limit
        ) {
          if (
            !handleCheckBookCondition(eachData, this.props.storytellingResource)
          )
            return;

          let imageUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${eachData.id}%2FpreviewImage.png?alt=media&token=${eachData.image}`;

          dataView.push(
            <AnimatedWrapper
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: 20,
              }}
              key={index}
              onClick={this.handleBookSelected.bind(this, "/book", eachData)}
            >
              <BookImage width={bookSize} height={bookSize} src={imageUrl} />
            </AnimatedWrapper>
          );
        }
      });

    return dataView;
  };

  renderNarration = () => {
    let dataView = [];

    if (this.props.data && this.props.data.length > 0)
      this.props.data.map((eachData, narrationGroupIndex) => {
        let conIndex = narrationGroupIndex + 1;
        if (
          conIndex > (this.props.currentPage - 1) * this.props.limit &&
          conIndex <= this.props.currentPage * this.props.limit
        ) {
          if (eachData.narration.narrationList.length > 0)
            eachData.narration.narrationList.map((eachNarration, index) => {
              if (
                this.props.exemptId &&
                this.props.exemptId === eachNarration.id
              )
                return;

              let imageUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${eachData.book.id}%2FpreviewImage.png?alt=media&token=${eachData.book.image}`;
              let totalMembers = 0;
              let totalVoiceOver = 0;
              let memberList = [];
              eachNarration.members.map((eachMember) => {
                totalMembers += 1;
                totalVoiceOver += Number(eachMember.count);
                memberList.push(eachMember.id);
              });

              dataView.push(
                <RowContainer
                  style={{
                    width: "100%",
                    paddingBottom: 40,
                    borderBottom: "5px #fff solid",
                    marginTop: 20,
                  }}
                  key={"narration" + narrationGroupIndex + index}
                >
                  <BookImage height={170} width={170} src={imageUrl} />
                  <VerticalContainer
                    style={{
                      marginLeft: 30,
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    {/* <SubFootnote
                      style={{
                        color: "#39BDA1",
                      }}
                    >
                      Saved Voice-Over 
                    </SubFootnote> */}
                    <Body
                      style={{
                        color: "#666",
                        marginTop: 5,
                        marginBottom: 15,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth:
                          this.props.utilsStore.deviceSize < 1200 ? 250 : 350,
                      }}
                    >
                      "{eachNarration.name}"{" "}
                    </Body>
                    <div style={{ flexGrow: 1 }} />
                    <RowContainer>
                      {this.renderAttributesComponent(
                        "Voice-over(s)",
                        "green",
                        totalVoiceOver
                      )}
                      {this.renderAttributesComponent(
                        "Storyteller(s)",
                        "purple",
                        totalMembers
                      )}
                    </RowContainer>
                  </VerticalContainer>
                  {this.renderVoiceOverAvatar(memberList)}
                  <div style={{ flexGrow: 1 }} />
                  {this.props.loadMore ? (
                    <>
                      <Button
                        buttonText="Edit"
                        style={{ width: 100 }}
                        onClick={this.props.handleModal.bind(
                          this,
                          "edit",
                          eachNarration.id,
                          eachNarration.name,
                          eachData.book.id
                        )}
                      />
                      <Button
                        buttonText="Delete"
                        style={{ marginLeft: 10, width: 100 }}
                        onClick={this.props.handleModal.bind(
                          this,
                          "delete",
                          eachNarration.id,
                          eachNarration.name,
                          eachData.book.id
                        )}
                      />
                    </>
                  ) : (
                    <Button
                      buttonText="Load"
                      style={{ width: 100 }}
                      onClick={() => {
                        this.props.updateSelectedBookAndNarration(
                          eachData.book,
                          eachNarration.id
                        );
                        window.scrollTo({ top: 115, behavior: "smooth" });
                        if (this.props.handleLoad)
                          this.props.handleLoad(eachNarration.id);
                        else
                          this.props.history.push(
                            `/bookInteraction/${eachData.book.id}`
                          );
                      }}
                    />
                  )}
                </RowContainer>
              );
            });

          return;
        }
      });
    else
      dataView.push(
        <Footnote
          key="empty"
          style={{
            marginTop: 20,
            marginBottom: 20,
            color: DarkGrey,
            textAlign: "center",
            width: "100%",
          }}
        >
          Looks like your voice-overs are playing hide and seek!
          <br />
          Start narrating, and find them here.
        </Footnote>
      );

    return dataView;
  };

  renderContent = () => {
    if (this.props.type === "sticker") return this.renderStickers();
    else if (
      this.props.type === "bookAnalytics" ||
      this.props.type === "bookAnalyticsFavourite"
    )
      return this.renderBookAnalytics();
    else return this.renderBooks();
  };

  render() {
    return (
      <VerticalContainer style={{ width: "95%" }}>
        {this.props.type === "book" ||
        this.props.type === "bookAnalytics" ||
        this.props.type === "bookAnalyticsFavourite" ||
        this.props.type === "sticker" ? (
          <RowContainer
            style={{
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            {this.renderContent()}
          </RowContainer>
        ) : (
          <VerticalContainer style={{ width: "100%" }}>
            {this.renderNarration()}
          </VerticalContainer>
        )}

        <RowContainer
          style={{
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          <IconButton
            iconImage={LeftIcon}
            imageStyle={{
              width: 40,
              height: 40,
              filter: this.props.currentPage === 1 ? "grayscale(100%)" : "none",
            }}
            onClick={this.props.handlePagination.bind(this, "minus")}
          />
          <IconButton
            style={{ marginLeft: 80 }}
            iconImage={LeftIcon}
            imageStyle={{
              width: 40,
              height: 40,
              filter: this.props.nextAvailable ? "none" : "grayscale(100%)",
              transform: "rotate(180deg)",
            }}
            onClick={this.props.handlePagination.bind(this, "plus")}
          />
        </RowContainer>
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    dataStore: state.dataStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedBook: (bookData) => dispatch(updateSelectedBook(bookData)),
    updateSelectedBookAndNarration: (book, narrationId) =>
      dispatch(updateSelectedBookAndNarration(book, narrationId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Pagination));

const CircleContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
