import axios from "axios";
import { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
//Actions
import {
  handleFontLoaded,
  updateCountry,
  updateDeviceSize,
  updateDeviceType,
} from "../store/actions/utilsActions";
//Utils
import {
  minLaptopBreakpoints,
  minTabletBreakpoints,
} from "../styles/GlobalStyles";

class WindowResizer extends Component {
  componentDidMount = async () => {
    let reactClass = this;
    let fontLoaded = this.props.utilsStore.fontLoaded;

    this.handleCalculateWidth();
    if (!fontLoaded)
      this.handleWaitForWebFonts(
        [
          "doctorSoos",
          "dkLemon",
          "museoSans",
          "visbyRound",
          "proximaNova",
          "allisonScript",
        ],
        () => {
          setTimeout(() => {
            if (!fontLoaded) reactClass.props.handleFontLoaded();
          }, 500);
        }
      );
    else {
      if (!this.props.utilsStore.country) {
        try {
          let countryResult = await axios.get(
            "https://api.ipstack.com/check?access_key=ba8da0708ee10628bef408f662c5d28d"
          );
          if (countryResult.status === 200 && countryResult.data)
            this.props.updateCountry(countryResult.data.country_code);
          else this.props.updateCountry("UKN");
        } catch {
          this.props.updateCountry("UKN");
        }
      }
    }
    window.addEventListener("resize", this.handleCalculateWidth);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleCalculateWidth);
  }

  handleWaitForWebFonts = (fonts, callback) => {
    let loadedFonts = 0;
    for (let i = 0, l = fonts.length; i < l; ++i) {
      (function (font) {
        let node = document.createElement("span");
        node.innerHTML = "giItT1WQy@!-/#";
        node.style.position = "absolute";
        node.style.left = "-10000px";
        node.style.top = "-10000px";
        node.style.fontSize = "300px";
        node.style.fontFamily = "sans-serif";
        node.style.fontletiant = "normal";
        node.style.fontStyle = "normal";
        node.style.fontWeight = "normal";
        node.style.letterSpacing = "0";
        document.body.appendChild(node);

        let width = node.offsetWidth;
        node.style.fontFamily = font;

        let interval;
        const checkFont = () => {
          if (node && node.offsetWidth != width) {
            loadedFonts += 1;
            node.parentNode.removeChild(node);
            node = null;
          }
          if (loadedFonts >= fonts.length) {
            if (interval) {
              clearInterval(interval);
            }
            if (loadedFonts == fonts.length) {
              callback();
              return true;
            }
          }
        };

        if (!checkFont()) {
          interval = setInterval(checkFont, 50);
        }
      })(fonts[i]);
    }
  };

  handleCalculateWidth = () => {
    let deviceType = 2;
    if (window.innerWidth < minTabletBreakpoints) deviceType = 0;
    else if (window.innerWidth < minLaptopBreakpoints) deviceType = 1;
    else deviceType = 2;

    if (deviceType !== this.props.utilsStore.deviceType)
      this.props.updateDeviceType(deviceType);
    if (window.innerWidth !== this.props.utilsStore.deviceSize)
      this.props.updateDeviceSize(window.innerWidth);
  };

  render() {
    if (!this.props.utilsStore.country) return null;
    if (this.props.preventRedirection) return null;

    if (
      this.props.utilsStore.country === "MY" ||
      this.props.utilsStore.country === "UKN"
    ) {
      if (
        this.props.utilsStore.deviceType <= 1 &&
        this.props.location.pathname !== "/mobile"
      )
        return <Redirect to="/mobile" />;
      else if (
        this.props.utilsStore.deviceType > 1 &&
        this.props.location.pathname === "/mobile"
      )
        return <Redirect to="/" />;
      return null;
    } else return <Redirect to="/unavailable" />;
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDeviceType: (deviceType) => dispatch(updateDeviceType(deviceType)),
    updateDeviceSize: (deviceSize) => dispatch(updateDeviceSize(deviceSize)),
    updateCountry: (country) => dispatch(updateCountry(country)),
    handleFontLoaded: () => dispatch(handleFontLoaded()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WindowResizer));
