import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import appStore from "../assets/images/footer/appStore.png";
import logoLineUps from "../assets/images/footer/logoLineUps.png";
import playStore from "../assets/images/footer/playStore.png";
import Footer from "../components/Footer";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import WindowResizer from "../components/WindowResizer";
import Button from "../global/Button";
import Dropdown from "../global/DropDown";
import Loading from "../global/Loading";
import TextInput from "../global/TextInput";
import { createAccount } from "../store/actions/authActions";
import { PrimaryBlack, PrimaryRed, SemanticBrown } from "../styles/Colors";
import { VerticalContainer } from "../styles/GlobalStyles";
//Styles
import { Footnote, Title2 } from "../styles/Typography";
import stateList from "../utils/stateList";

const buttonStyle = {
  width: 150,
  alignSelf: "flex-end",
  height: 50,
  margin: 30,
};

const buttonFontSize = {
  fontSize: 23,
};

class SignUp extends Component {
  state = {
    loading: false,
    name: "",
    nameError: "",
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    confPassword: "",
    confPasswordError: "",
    selectedValue: "SGR",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleCheckCondition = (type) => {
    switch (type) {
      case "name":
        if (this.state.name.length < 1)
          this.setState({
            nameError: "Please enter your name",
          });
        break;
      case "email":
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(this.state.email))
          this.setState({
            emailError: "Email has been registed/ Invalid email address.",
          });
        break;
      case "password":
        if (
          this.state.password.includes(" ") ||
          this.state.password.includes(",") ||
          this.state.password.length < 6
        )
          this.setState({
            passwordError: "Please enter a valid password",
          });
        else
          this.setState({
            passwordError: "",
          });
        break;
      case "confPassword":
        if (this.state.confPassword !== this.state.password)
          this.setState({
            confPasswordError: "Please enter the same password",
          });
        else
          this.setState({
            confPasswordError: "",
          });
        break;
      default:
        break;
    }
  };

  handleLoading = (bool) => {
    this.setState({
      loading: bool,
    });
  };

  handleSelect = (value) => {
    this.setState({
      selectedValue: value,
    });
  };

  handleSubmit = () => {
    this.setState(
      {
        nameError: "",
        emailError: "",
        passwordError: "",
        confPasswordError: "",
      },
      () => {
        this.handleLoading(true);
        this.handleCheckCondition("name");
        this.handleCheckCondition("email");
        this.handleCheckCondition("password");
        this.handleCheckCondition("confPassword");

        setTimeout(async () => {
          if (
            this.state.nameError === "" &&
            this.state.emailError === "" &&
            this.state.passwordError === "" &&
            this.state.confPasswordError === ""
          ) {
            try {
              await createAccount({
                name: this.state.name,
                state: this.state.selectedValue,
                email: this.state.email,
                password: this.state.password,
              });
            } catch (err) {
              this.setState({
                passwordError: err.message,
              });
            }
            this.handleLoading(false);
          } else this.handleLoading(false);
        }, 250);
      }
    );
  };

  renderSelectedState = () => {
    let selectedState = "";
    stateList.map((eachState) => {
      if (eachState.value === this.state.selectedValue) {
        selectedState = eachState.name;
      }
    });

    return selectedState;
  };

  renderSignUp = () => {
    return (
      <VerticalContainer style={{ width: "80%", alignItems: "flex-start" }}>
        <Title2 style={{ marginTop: 40, marginBottom: 20, fontSize: 30 }}>
          Sign Up/Daftar
        </Title2>
        <Footnote
          style={{ textAlign: "flex-start", marginLeft: 15, marginTop: 15 }}
        >
          Name
        </Footnote>
        <TextInput
          style={{ width: 275, height: 50, marginTop: 10 }}
          value={this.state.name}
          placeholder="Name"
          onChange={this.handleChange}
          id="name"
          autoCapitalize="none"
        />
        <Footnote
          style={{
            marginTop: 5,
            marginBottom: this.state.nameError ? 10 : 0,
            marginLeft: 15,
            color: PrimaryRed,
            fontSize: 12,
          }}
        >
          {this.state.nameError}
        </Footnote>
        <Footnote
          style={{ textAlign: "flex-start", margin: 15, marginRight: 0 }}
        >
          State
        </Footnote>
        <Dropdown
          option={this.state.selectedValue}
          options={stateList}
          selectedName={this.renderSelectedState()}
          width={300}
          handleSelect={this.handleSelect}
        />
        <Footnote
          style={{ textAlign: "flex-start", marginLeft: 15, marginTop: 25 }}
        >
          Email Address
        </Footnote>
        <TextInput
          style={{ width: 275, height: 50, marginTop: 10 }}
          value={this.state.email}
          placeholder="Email Address"
          onChange={this.handleChange}
          id="email"
          autoCapitalize="none"
        />
        <Footnote
          style={{
            marginTop: 5,
            marginBottom: this.state.emailError ? 10 : 0,
            marginLeft: 15,
            color: PrimaryRed,
            fontSize: 12,
          }}
        >
          {this.state.emailError}
        </Footnote>
        <Footnote
          style={{ textAlign: "flex-start", marginLeft: 15, marginTop: 15 }}
        >
          Create Password
        </Footnote>
        <TextInput
          style={{ width: 275, height: 50, marginTop: 10 }}
          value={this.state.password}
          placeholder="Create Password"
          onChange={this.handleChange}
          id="password"
          autoCapitalize="none"
          type="password"
        />
        <Footnote
          style={{
            marginTop: 5,
            marginBottom: this.state.passwordError ? 10 : 0,
            marginLeft: 15,
            color: PrimaryRed,
            fontSize: 12,
          }}
        >
          {this.state.passwordError}
        </Footnote>
        <Footnote
          style={{ textAlign: "flex-start", marginLeft: 15, marginTop: 15 }}
        >
          Confirm Password
        </Footnote>
        <TextInput
          style={{ width: 275, height: 50, marginTop: 10 }}
          value={this.state.confPassword}
          placeholder="Confirm Password"
          onChange={this.handleChange}
          id="confPassword"
          autoCapitalize="none"
          type="password"
        />
        <Footnote
          style={{
            marginTop: 5,
            marginBottom: this.state.confPasswordError ? 30 : 20,
            marginLeft: 15,
            color: PrimaryRed,
            fontSize: 12,
          }}
        >
          {this.state.confPasswordError}
        </Footnote>
        <Button
          buttonText="Sign Up"
          style={buttonStyle}
          textStyle={buttonFontSize}
          onClick={this.handleSubmit}
        />
      </VerticalContainer>
    );
  };

  renderSignUpSuccess = () => {
    return (
      <VerticalContainer
        style={{
          backgroundColor: SemanticBrown,
          padding: 30,
          width: "100%",
        }}
      >
        <img
          src={logoLineUps}
          style={{
            width: this.props.utilsStore.deviceType === 0 ? 260 : 300,
            height: this.props.utilsStore.deviceType === 0 ? 165 : 200,
            margin: 10,
          }}
        />
        <Footnote
          style={{
            color: PrimaryBlack,
            maxWidth: 450,
            width: "90%",
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          You have successfully created an account.
        </Footnote>
        <Footnote
          style={{
            color: PrimaryBlack,
            maxWidth: 450,
            width: "90%",
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          Open the web with your laptop/desktop
        </Footnote>
        <Footnote
          style={{
            color: PrimaryBlack,
            maxWidth: 450,
            width: "90%",
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          or
        </Footnote>
        <Footnote
          style={{
            color: PrimaryBlack,
            maxWidth: 450,
            width: "90%",
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          Download Me Books Mobile App for Best Viewing Experience.
        </Footnote>
        <img
          src={appStore}
          style={{ width: 125, cursor: "pointer", marginTop: 20 }}
          alt="Button Linked to Me Books App in App Store"
          onClick={() => {
            window.open("https://apps.apple.com/us/app/id1510382298");
          }}
        />
        <img
          src={playStore}
          style={{ width: 145, cursor: "pointer", marginTop: 5 }}
          alt="Button Linked to Me Books App in Play Store"
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=co.mebooks.plus"
            );
          }}
        />
      </VerticalContainer>
    );
  };

  render() {
    if (!this.props.utilsStore.fontLoaded)
      return <WindowResizer preventRedirection={true} />;

    if (
      this.props.authStore.userAuth &&
      this.props.authStore.user &&
      this.props.authStore.user.name
    )
      return <Redirect to="/" />;
    else if (
      this.props.authStore.userAuth &&
      this.props.authStore.user &&
      !this.props.authStore.user.name &&
      this.props.utilsStore.deviceType === 2
    )
      return <Redirect to="/user/create/parent" />;

    return (
      <VerticalContainer>
        {this.state.loading && <Loading />}
        <WindowResizer preventRedirection={true} />
        <Header />
        <SecondaryHeader screen={"Welcome !"} />
        <VerticalContainer
          style={{
            width: "95%",
            maxWidth: 1440,
            height: "auto",
          }}
        >
          {this.props.authStore.userAuth
            ? this.renderSignUpSuccess()
            : this.renderSignUp()}
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(SignUp);
