import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import appStore from "../assets/images/footer/appStore.png";
//Assets
import logoLineUps from "../assets/images/footer/logoLineUps.png";
import playStore from "../assets/images/footer/playStore.png";
import { PrimaryBlack, White } from "../styles/Colors";
//Styles
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/GlobalStyles";
import { Caption, Footnote, SubCaption } from "../styles/Typography";

const footerList = [
  {
    title: "My Account",
    line1: "Log in with u-Pustaka",
    link1: "library",
    line2: "Log in as a Guest",
    link2: "signIn",
  },
  {
    title: "Support",
    line1: "Help",
    link1: "support",
    line2: "Contact US",
    link2: "contact",
  },
  {
    title: "For more information",
    line1: "National Library of Malaysia",
    link1: "library",
    line2: "How to register as u-Pustaka member?",
    link2: "library",
    line3: "Me Books",
    link3: "https://www.mebooks.co/home",
  },
];

class Footer extends Component {
  handleNavigate = (type) => {
    if (type === "signIn") this.props.history.push("/auth/signin");
    else if (type === "support") this.props.history.push("/support");
    else if (type === "library")
      window.open("https://www.u-library.gov.my/portal/web/guest/home");
    else if (type === "contact")
      window.open("https://www.mebooks.co/contact-us");

    window.scrollTo({ top: 115, behavior: "smooth" });
  };

  renderFooterContent = (details, width, containerStyle) => {
    return (
      <VerticalContainer
        style={{
          ...containerStyle,
          ...{
            alignItems: "flex-start",
            alignSelf: "flex-start",
            width: this.props.utilsStore.deviceType <= 1 ? "90%" : width,
            marginRight: "5%",
          },
        }}
      >
        <Footnote style={{ marginBottom: 15, color: PrimaryBlack }}>
          {details.title}
        </Footnote>
        <SubCaption
          style={{ marginBottom: 10 }}
          onClick={this.handleNavigate.bind(this, details.link1)}
        >
          {details.line1}
        </SubCaption>
        <SubCaption
          style={{ marginBottom: 10 }}
          onClick={this.handleNavigate.bind(this, details.link2)}
        >
          {details.line2}
        </SubCaption>
        <SubCaption
          onClick={() => {
            window.open(details.link3);
          }}
        >
          {details.line3}
        </SubCaption>
      </VerticalContainer>
    );
  };

  render() {
    return (
      <>
        <VerticalContainer
          style={{
            backgroundColor: White,
            width: "100%",
            borderTop: "0.2px solid #c4c4c4",
          }}
        >
          <VerticalContainer style={{ width: "95%", maxWidth: 1368 }}>
            <FlexibleRowContainer
              style={{
                marginTop: 60,
                marginLeft: 15,
                width: "100%",
              }}
            >
              {this.props.utilsStore.deviceType > 1 &&
                this.renderFooterContent(footerList[0], "17%")}
              {this.renderFooterContent(footerList[1], "12%")}
              {this.renderFooterContent(
                footerList[2],
                "24%",
                this.props.utilsStore.deviceType === 0 && { marginTop: 40 }
              )}
              <div style={{ flexGrow: 1 }} />
              {this.props.utilsStore.deviceType > 1 && (
                <VerticalContainer
                  style={{
                    alignSelf: "flex-start",
                    alignItems: "flex-start",
                    width: "30%",
                    color: PrimaryBlack,
                  }}
                >
                  <Footnote>Download Mobile App</Footnote>
                  <RowContainer>
                    <img
                      src={appStore}
                      style={{ width: 120, cursor: "pointer" }}
                      alt="Button Linked to Me Books App in App Store"
                      onClick={() => {
                        window.open("https://rebrand.ly/Me-Books-PNM-IOS");
                      }}
                    />
                    <img
                      src={playStore}
                      style={{ width: 145, cursor: "pointer", marginLeft: 10 }}
                      alt="Button Linked to Me Books App in Play Store"
                      onClick={() => {
                        window.open("https://rebrand.ly/Me-Books-PNM-Android");
                      }}
                    />
                  </RowContainer>
                  <img
                    src={logoLineUps}
                    style={{ width: 280 }}
                    alt="Me Books Year Logo"
                  />
                </VerticalContainer>
              )}
            </FlexibleRowContainer>
            <Caption
              style={{
                color: PrimaryBlack,
                fontSize: 16,
                width: this.props.utilsStore.deviceType === 0 ? "90%" : "95%",
                marginTop: this.props.utilsStore.deviceType <= 1 ? 40 : 0,
                marginBottom: 40,
                lineHeight: 1.5,
              }}
            >
              “We Believe in Our Children” © 2020-21 Me Books Ptd Ltd, Privacy
              Policy, User Terms & Conditions.
            </Caption>
          </VerticalContainer>
        </VerticalContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
