import React, { Component } from "react";
import { connect } from "react-redux";
//Navigator
import Navigator from "./navigator/Navigator";
//Actions
import {
  getAuthListener,
  removeAuthListener,
} from "./store/actions/authActions";
import {
  getDatasetListener,
  removeDatasetListener,
} from "./store/actions/dataActions";

class App extends Component {
  componentDidMount() {
    this.props.getAuthListener();
    //INFO : Disable right click on image
    document.addEventListener("contextmenu", this.handleRightClickDisability);
  }

  componentWillUnmount() {
    this.props.removeAuthListener();
    this.props.removeDatasetListener();
    document.removeEventListener(
      "contextmenu",
      this.handleRightClickDisability
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.authStore.userAuth !== prevProps.authStore.userAuth &&
      this.props.authStore.userAuth
    )
      this.props.getDatasetListener();
  }

  handleRightClickDisability = (event) => {
    if (event.path && event.path.length > 0 && event.path[0].nodeName === "IMG")
      event.preventDefault();
  };

  render() {
    return <Navigator />;
  }
}

const mapStateToProps = (state) => {
  return {
    dataStore: state.dataStore,
    authStore: state.authStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAuthListener: () => dispatch(getAuthListener()),
    removeAuthListener: () => dispatch(removeAuthListener()),
    getDatasetListener: () => dispatch(getDatasetListener()),
    removeDatasetListener: () => dispatch(removeDatasetListener()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
