import React, { Component } from "react";
import styled from "styled-components";
//Styles
import { VerticalContainer } from "../../styles/GlobalStyles";
import { SpecBody } from "../../styles/Typography";
import { White, Yellow, BorderGrey } from "../../styles/Colors";
//Components
import AnimatedWrapper from "../../global/AnimatedWrapper";

export default class CategoryIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
    };
    this.sound = null;
  }

  componentDidMount = async () => {
    if (this.props.image) {
      let imageUrl = this.props.image;
      this.setState({
        imageUrl: imageUrl,
      });
    }
  };

  handleOnClick = async () => {
    if (!this.props.selected && this.props.onClick) this.props.onClick();

    if (this.props.sound) {
      this.sound = new Audio(this.props.sound);
      this.sound.currentTime = 0;
      this.sound.pause();
      this.sound.play();
    }
  };

  render() {
    return (
      <AnimatedWrapper
        style={{
          width: this.props.size + 40,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onClick={this.handleOnClick}
      >
        {this.state.imageUrl && (
          <img
            src={this.state.imageUrl}
            style={{
              width: this.props.selected
                ? this.props.size
                : this.props.size - 20,
              height: this.props.selected
                ? this.props.size
                : this.props.size - 20,
              filter: this.props.selected ? null : "grayscale(100%)",
            }}
          />
        )}
        <DescriptionView
          style={{
            backgroundColor: this.props.selected ? Yellow : BorderGrey,
            width: this.props.size + 20,
            padding: 5,
          }}
        >
          <SpecBody style={{ color: White, textAlign: "center" }}>
            {this.props.title}
          </SpecBody>
        </DescriptionView>
      </AnimatedWrapper>
    );
  }
}

const DescriptionView = styled(VerticalContainer)`
  border-radius: 20px;
  margin: 10px 0px;
`;
