import firebase from "../../utils/firebase";
import { getMonday, monthNames } from "../../utils/miscList";
import plist from "plist";
import axios from "axios";
//Utils
import EventBus from "js-event-bus";

const eventBus = EventBus();

const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const updateCategory = (categoryId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "UPDATE_CATEGORY",
      payload: {
        category: categoryId,
      },
    });
  };
};

export const updateSelectedBook = (bookData) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "UPDATE_SELECTED_BOOK",
      payload: {
        selectedBook: bookData,
      },
    });
  };
};

export const updateSelectedBookAndNarration = (book, narrationId) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_SELECTED_BOOK_AND_NARRATION",
      payload: {
        book: book,
        narrationId: narrationId,
      },
    });
  };
};

export const updateSelectedSeries = (seriesData) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "UPDATE_SELECTED_SERIES",
      payload: {
        selectedSeries: seriesData,
      },
    });
  };
};

export const searchBook = (searchTitle) => {
  return async (dispatch, getState) => {
    try {
      let results = await axios.post(
        "https://us-central1-mebooks-plus.cloudfunctions.net/searchBooks",
        { search: searchTitle }
      );
      let bookList = [];
      if (results.data)
        results.data.map((eachBook) => {
          bookList.push(eachBook.id);
        });

      dispatch({
        type: "UPDATE_SEARCHED_BOOKS",
        payload: {
          searchedBooks: bookList,
        },
      });
      eventBus.emit("search-success");
    } catch (err) {}
  };
};

export const updateSearchedBooks = (searchedBooks) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "UPDATE_SEARCHED_BOOKS",
      payload: {
        searchedBooks: searchedBooks,
      },
    });
  };
};

export const getSeries = (seriesId) => {
  return async (dispatch, getState) => {
    try {
      let seriesQuery = await firebase
        .firestore()
        .collection("series")
        .doc(seriesId)
        .get();

      if (seriesQuery.exists)
        dispatch({
          type: "UPDATE_SELECTED_SERIES",
          payload: {
            selectedSeries: seriesQuery.data(),
          },
        });
    } catch (err) {}
  };
};

export const getSelectedNarration = (childId, narrationId) => {
  return async (dispatch, getState) => {
    try {
      let narrationQuery = await firebase
        .firestore()
        .collection("children")
        .doc(childId)
        .collection("narration")
        .doc(narrationId)
        .get();

      if (narrationQuery.exists)
        dispatch({
          type: "UPDATE_SELECTED_NARRATION",
          payload: {
            selectedNarration: narrationQuery.data(),
          },
        });
      else
        dispatch({
          type: "UPDATE_SELECTED_NARRATION",
          payload: {
            selectedNarration: null,
          },
        });
    } catch (err) {}
  };
};

export const getSelectedBook = (bookId) => {
  return async (dispatch, getState) => {
    try {
      let bookQuery = await firebase
        .firestore()
        .collection("books")
        .doc(bookId)
        .get();

      if (bookQuery.exists)
        dispatch({
          type: "UPDATE_SELECTED_BOOK",
          payload: {
            selectedBook: bookQuery.data(),
          },
        });
    } catch (err) {}
  };
};

export const getBookAnalytics = (bookId, childId) => {
  return async (dispatch, getState) => {
    if (bookId && childId) {
      let bookAnalyticsDoc = await firebase
        .firestore()
        .collection(`children/${childId}/bookAnalytics`)
        .doc(bookId)
        .get();

      if (bookAnalyticsDoc.exists)
        dispatch({
          type: "UPDATE_SELECTED_BOOK_ANALYTICS",
          payload: {
            selectedBookAnalytics: bookAnalyticsDoc.data(),
          },
        });
      else
        dispatch({
          type: "UPDATE_SELECTED_BOOK_ANALYTICS",
          payload: {
            selectedBookAnalytics: null,
          },
        });
    }
  };
};

//INFO : Action to handle favourite books
export const updateFavouriteBook = (bookId, childId, isFavourite) => {
  return async (dispatch, getState) => {
    if (bookId && childId) {
      let verifyAnalytics = firebase
        .firestore()
        .collection(`children/${childId}/bookAnalytics`)
        .doc(bookId);
      let verifyAnalyticsDoc = await verifyAnalytics.get();
      if (verifyAnalyticsDoc.exists)
        await verifyAnalytics.update({
          favourite: isFavourite,
        });
      else
        await verifyAnalytics.set({
          id: bookId,
          childId: childId,
          favourite: isFavourite,
          updatedAt: new Date(),
          analytics: [],
          stickers: [],
        });
    }
  };
};

//INFO : Action update analytics list
export const updateAnalyticsList = (
  bookId,
  screenTime,
  pageIndex,
  stickersWon,
  pointsWon
) => {
  return async (dispatch, getState) => {
    try {
      let currentChildren = getState().authStore.children;

      let selectedChildId = getState().authStore.selectedChild;
      let selectedChild;
      currentChildren.map((eachChild) => {
        if (eachChild.id === selectedChildId)
          selectedChild = JSON.parse(JSON.stringify(eachChild));
      });

      if (selectedChild) {
        let childAmended = false;
        //INFO : Update Books Read For Child
        if (!selectedChild.booksRead.includes(bookId)) {
          selectedChild.booksRead.push(bookId);
          childAmended = true;
        }

        //INFO : Update Stickers For Child
        let selectedChildStickers = JSON.parse(
          JSON.stringify(selectedChild.stickers)
        );
        let addingChildSticker = [];
        if (stickersWon.length > 0)
          stickersWon.map((eachWonSticker) => {
            if (selectedChildStickers.indexOf(eachWonSticker) === -1)
              addingChildSticker.push(eachWonSticker);
          });

        if (addingChildSticker.length > 0) {
          selectedChildStickers = selectedChildStickers.concat(
            addingChildSticker
          );
          selectedChild.stickers = selectedChildStickers;
          selectedChild.points += pointsWon;
          childAmended = true;
        }

        if (childAmended) {
          let childDoc = firebase
            .firestore()
            .collection("children")
            .doc(selectedChild.id);

          await childDoc.update({
            booksRead: selectedChild.booksRead,
            stickers: selectedChild.stickers,
            points: selectedChild.points,
          });
        }

        //INFO : Update book analytics
        let verifyAnalytics = firebase
          .firestore()
          .collection(`children/${selectedChild.id}/bookAnalytics`)
          .doc(bookId);
        let verifyAnalyticsDoc = await verifyAnalytics.get();
        if (verifyAnalyticsDoc.exists) {
          //INFO : Analytics
          let analytics = verifyAnalyticsDoc.data().analytics;
          analytics.push({
            pageView: pageIndex,
            screenTime: screenTime,
            date: new Date(),
          });
          //INFO : Analytics Stickers
          let bookAnalyticStickers = JSON.parse(
            JSON.stringify(verifyAnalyticsDoc.data().stickers)
          );
          let addingAnalyticStickers = [];
          if (stickersWon.length > 0)
            stickersWon.map((eachWonSticker) => {
              if (bookAnalyticStickers.indexOf(eachWonSticker) === -1)
                addingAnalyticStickers.push(eachWonSticker);
            });
          bookAnalyticStickers = bookAnalyticStickers.concat(
            addingAnalyticStickers
          );

          await verifyAnalytics.update({
            analytics: analytics,
            updatedAt: new Date(),
            stickers: bookAnalyticStickers,
          });
        } else {
          await verifyAnalytics.set({
            id: bookId,
            childId: selectedChild.id,
            favourite: false,
            updatedAt: new Date(),
            stickers: stickersWon,
            analytics: [
              {
                pageView: pageIndex,
                screenTime: screenTime,
                date: new Date(),
              },
            ],
          });
        }

        //INFO : Update weekly report
        let currentMonday = getMonday(new Date());
        let reportDate =
          currentMonday.getDate() +
          monthNames[currentMonday.getMonth()] +
          currentMonday.getFullYear();
        let verifyReport = firebase
          .firestore()
          .collection(`children/${selectedChild.id}/weeklyReports`)
          .doc(reportDate);
        let verifyReportDoc = await verifyReport.get();
        if (verifyReportDoc.exists) {
          let bookDetailsList = verifyReportDoc.data().details;
          bookDetailsList.push({
            bookId: bookId,
            pageView: pageIndex,
            stickers: stickersWon,
            screenTime: screenTime,
            narration: 0,
            date: new Date(),
          });
          await verifyReport.update({
            details: bookDetailsList,
          });
        } else
          await verifyReport.set({
            date: reportDate,
            childId: selectedChild.id,
            details: [
              {
                bookId: bookId,
                pageView: pageIndex,
                stickers: stickersWon,
                screenTime: screenTime,
                narration: 0,
                date: new Date(),
              },
            ],
          });
      }
    } catch (err) {}
  };
};

//==================================== Voice Over Functions =============================================

const getFileBlob = (blobUrl) => {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", blobUrl);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
      resolve(xhr.response);
    });
    xhr.send();
  });
};

//INFO : Utils to strip url into token
const stripUrlToToken = (downloadUrl) => {
  if (downloadUrl) {
    let downloadToken = downloadUrl.split("token=")[1];
    return downloadToken;
  }
};

export const saveBookVoiceOver = (
  narrationIdGiven,
  bookId,
  narrationName,
  allHotspots,
  type
) => {
  return async (dispatch, getState) => {
    try {
      //INFO : Find selected child
      let selectedChildId = JSON.parse(
        JSON.stringify(getState().authStore.selectedChild)
      );
      let currentChildren = JSON.parse(
        JSON.stringify(getState().authStore.children)
      );
      let selectedChild;
      currentChildren.map((eachChild) => {
        if (eachChild.id === selectedChildId) selectedChild = eachChild;
      });

      //INFO : Get narration id
      let narrationId;
      if (narrationIdGiven) narrationId = narrationIdGiven;
      else narrationId = generateUUID();
      if (selectedChild && bookId) {
        let uploadPlistClass = {
          Hotspots: [],
        };
        let uploadSoundList = [];
        //INFO : Member Attributes
        let narrateMembers = [];
        //INFO : Hotspots bridge
        if (allHotspots.length > 0)
          allHotspots.map((eachHotspot, index) => {
            let uploadHotspot = {
              Points: eachHotspot.Points,
              PageIndex: eachHotspot.PageIndex,
            };
            if (eachHotspot.PrerecordingFilename) {
              if (eachHotspot.PrerecordingFilename.includes("blob:")) {
                let stripRecordingUrl = eachHotspot.PrerecordingFilename.split(
                  "/"
                );
                let recordingFileName =
                  stripRecordingUrl[stripRecordingUrl.length - 1] + ".mp3";
                uploadHotspot.PrerecordingFilename = recordingFileName;
                uploadSoundList.push({
                  index: index,
                  fileName: recordingFileName,
                  uploadPath:
                    "user_narration/" +
                    selectedChild.id +
                    "/" +
                    bookId +
                    "/" +
                    narrationId +
                    "/" +
                    recordingFileName,
                });
              } else
                uploadHotspot.PrerecordingFilename =
                  eachHotspot.PrerecordingFilename;
            } else uploadHotspot.PrerecordingFilename = "";
            if (eachHotspot.Member) {
              uploadHotspot.Member = eachHotspot.Member;
              //INFO : Member Attributes
              let selectedMemberIndex = -1;
              narrateMembers.map((eachMember, index) => {
                if (eachMember.id === eachHotspot.Member) {
                  selectedMemberIndex = index;
                }
              });
              if (selectedMemberIndex > -1)
                narrateMembers[selectedMemberIndex].count += 1;
              else
                narrateMembers.push({
                  id: eachHotspot.Member,
                  count: 1,
                });
            }
            uploadPlistClass["Hotspots"].push(uploadHotspot);
          });
        //INFO : Upload PList
        let uploadPlistData = plist.build(uploadPlistClass);
        let pListFileName = bookId + ".plist";
        let pListBlob = new Blob([uploadPlistData], {
          type: "application/octet-stream",
        });

        let pListUploadPath =
          "user_narration/" +
          selectedChild.id +
          "/" +
          bookId +
          "/" +
          narrationId +
          "/" +
          pListFileName;

        await firebase.storage().ref(pListUploadPath).put(pListBlob);

        //INFO : Upload Narrated Sound
        if (uploadSoundList.length > 0)
          await Promise.all(
            uploadSoundList.map(async (eachUploadSound) => {
              let recordingUrl =
                allHotspots[eachUploadSound.index].PrerecordingFilename;

              let recordingBlob = await getFileBlob(recordingUrl);
              recordingBlob = recordingBlob.slice(
                0,
                recordingBlob.size,
                "audio/mpeg"
              );
              await firebase
                .storage()
                .ref(eachUploadSound.uploadPath)
                .put(recordingBlob);
            })
          );
        //INFO : Get PList Token
        let pListToken = stripUrlToToken(
          await firebase.storage().ref(pListUploadPath).getDownloadURL()
        );
        //INFO : Get Upload Sound Token
        let narrationNameList = [];
        let narrationList = [];
        if (uploadSoundList.length > 0)
          await Promise.all(
            uploadSoundList.map(async (eachUploadSound) => {
              narrationNameList.push(eachUploadSound.fileName);
              narrationList.push(
                stripUrlToToken(
                  await firebase
                    .storage()
                    .ref(eachUploadSound.uploadPath)
                    .getDownloadURL()
                )
              );
            })
          );

        let verifyNarrationQuery = firebase
          .firestore()
          .collection(`children/${selectedChild.id}/narration`)
          .doc(bookId);
        let verifyNarrationDoc = await verifyNarrationQuery.get();
        if (verifyNarrationDoc.exists) {
          let verifyNarrationData = verifyNarrationDoc.data();
          let bookNarrationList = JSON.parse(
            JSON.stringify(verifyNarrationData.narrationList)
          );
          let selectedNarrationClass = {
            narrationClass: null,
            narrationIndex: -1,
          };
          bookNarrationList.map((eachNarration, narrationIndex) => {
            if (eachNarration.id === narrationId) {
              selectedNarrationClass = {
                narrationClass: eachNarration,
                narrationIndex: narrationIndex,
              };
            }
          });
          //INFO : Update old narration
          if (selectedNarrationClass.narrationIndex > -1) {
            let newNarrationList =
              selectedNarrationClass.narrationClass.narration;
            let newNarrationNameList =
              selectedNarrationClass.narrationClass.narrationName;
            newNarrationList = newNarrationList.concat(narrationList);
            newNarrationNameList = newNarrationNameList.concat(
              narrationNameList
            );
            selectedNarrationClass.narrationClass[
              "narration"
            ] = newNarrationList;
            selectedNarrationClass.narrationClass[
              "narrationName"
            ] = newNarrationNameList;
            selectedNarrationClass.narrationClass["pList"] = pListToken;
            selectedNarrationClass.narrationClass["members"] = narrateMembers;
            selectedNarrationClass.narrationClass["updatedAt"] = new Date();
            bookNarrationList[selectedNarrationClass.narrationIndex] =
              selectedNarrationClass.narrationClass;
            await verifyNarrationQuery.update({
              narrationList: bookNarrationList,
            });
          }
          //INFO : Push new narration
          else {
            let newNarrationClass = {
              id: narrationId,
              members: narrateMembers,
              name: narrationName,
              narrationName: narrationNameList,
              narration: narrationList,
              updatedAt: new Date(),
              pListName: pListFileName,
              pList: pListToken,
            };
            bookNarrationList.push(newNarrationClass);
            await verifyNarrationQuery.update({
              narrationList: bookNarrationList,
            });
          }
        }
        //INFO : Create new narration
        else {
          await verifyNarrationQuery.set({
            narrationList: [
              {
                id: narrationId,
                members: narrateMembers,
                name: narrationName,
                narrationName: narrationNameList,
                narration: narrationList,
                updatedAt: new Date(),
                pListName: pListFileName,
                pList: pListToken,
              },
            ],
            bookId: bookId,
            childId: selectedChild.id,
          });
        }
        if (type === "save")
          eventBus.emit("voice-over-saved", null, narrationId);
        else eventBus.emit("voice-over-saved-exit");
      }
    } catch (err) {}
  };
};

export const editVoiceOver = (name, bookId, narrationId) => {
  return async (dispatch, getState) => {
    try {
      let selectedChildId = JSON.parse(
        JSON.stringify(getState().authStore.selectedChild)
      );
      let currentChildren = JSON.parse(
        JSON.stringify(getState().authStore.children)
      );
      let selectedChild;
      currentChildren.map((eachChild) => {
        if (eachChild.id === selectedChildId) selectedChild = eachChild;
      });

      if (name && bookId && narrationId && selectedChild) {
        let verifyNarrationQuery = firebase
          .firestore()
          .collection(`children/${selectedChild.id}/narration`)
          .doc(bookId);
        let verifyNarrationDoc = await verifyNarrationQuery.get();
        let narrationList = JSON.parse(
          JSON.stringify(verifyNarrationDoc.data().narrationList)
        );

        let selectedNarrationIndex = -1;
        narrationList.map((eachNarration, narrationIndex) => {
          if (eachNarration.id === narrationId)
            selectedNarrationIndex = narrationIndex;
        });

        if (selectedNarrationIndex > -1) {
          narrationList[selectedNarrationIndex].name = name;
          await verifyNarrationQuery.update({
            narrationList: narrationList,
          });
        }

        //INFO : Local Data Update
        let oldNarrationGroup = JSON.parse(
          JSON.stringify(getState().childStore.childNarration)
        );
        let oldSelectedNarrationGroupIndex = -1;
        if (oldNarrationGroup.length > 0)
          oldNarrationGroup.map((eachNarrationGroup, index) => {
            if (eachNarrationGroup.book.id === bookId)
              oldSelectedNarrationGroupIndex = index;
          });

        let oldSelectedNarrationIndex = -1;
        if (oldSelectedNarrationGroupIndex >= 0) {
          oldNarrationGroup[
            oldSelectedNarrationGroupIndex
          ].narration.narrationList.map((eachNarration, index) => {
            if (eachNarration.id === narrationId)
              oldSelectedNarrationIndex = index;
          });

          if (oldSelectedNarrationIndex >= 0) {
            oldNarrationGroup[
              oldSelectedNarrationGroupIndex
            ].narration.narrationList[oldSelectedNarrationIndex].name = name;
            dispatch({
              type: "UPDATE_CHILD_NARRATION",
              payload: {
                childNarration: oldNarrationGroup,
                childNarrationPagination: getState().childStore
                  .childNarrationPagination,
              },
            });
          }
        }
      }
    } catch (err) {}
  };
};

export const deleteVoiceOver = (bookId, narrationId) => {
  return async (dispatch, getState) => {
    try {
      //INFO : Find selected child
      let selectedChildId = JSON.parse(
        JSON.stringify(getState().authStore.selectedChild)
      );

      let currentChildren = JSON.parse(
        JSON.stringify(getState().authStore.children)
      );
      let selectedChild;
      currentChildren.map((eachChild) => {
        if (eachChild.id === selectedChildId) selectedChild = eachChild;
      });

      if (selectedChild) {
        let verifyNarrationQuery = firebase
          .firestore()
          .collection(`children/${selectedChild.id}/narration`)
          .doc(bookId);
        let verifyNarrationDoc = await verifyNarrationQuery.get();
        let narrationList = JSON.parse(
          JSON.stringify(verifyNarrationDoc.data().narrationList)
        );

        let selectedNarrationIndex = -1;
        narrationList.map((eachNarration, narrationIndex) => {
          if (eachNarration.id === narrationId)
            selectedNarrationIndex = narrationIndex;
        });

        if (selectedNarrationIndex > -1) {
          //INFO : Local Data Update
          let oldNarrationGroup = JSON.parse(
            JSON.stringify(getState().childStore.childNarration)
          );
          let oldSelectedNarrationGroupIndex = -1;
          if (oldNarrationGroup.length > 0)
            oldNarrationGroup.map((eachNarrationGroup, index) => {
              if (eachNarrationGroup.book.id === bookId)
                oldSelectedNarrationGroupIndex = index;
            });

          //INFO : Delete firestore
          if (narrationList.length === 1) {
            await verifyNarrationQuery.delete();
            oldNarrationGroup.splice(oldSelectedNarrationGroupIndex, 1);
          } else {
            let dummyNarrationList = JSON.parse(JSON.stringify(narrationList));
            dummyNarrationList.splice(selectedNarrationIndex, 1);
            await verifyNarrationQuery.update({
              narrationList: dummyNarrationList,
            });
            oldNarrationGroup[
              oldSelectedNarrationGroupIndex
            ].narration.narrationList = dummyNarrationList;
          }
          //INFO : Local Data Update
          dispatch({
            type: "UPDATE_CHILD_NARRATION",
            payload: {
              childNarration: oldNarrationGroup,
              childNarrationPagination: getState().childStore
                .childNarrationPagination,
            },
          });

          //INFO : Delete firebase storage
          let selectedNarration = narrationList[selectedNarrationIndex];
          let uploadedItemList = selectedNarration.narrationName;
          uploadedItemList.push(selectedNarration.pListName);
          await Promise.all(
            uploadedItemList.map(async (eachUploadedItem) => {
              await firebase
                .storage()
                .ref(
                  `user_narration/${selectedChild.id}/${bookId}/${narrationId}`
                )
                .child(eachUploadedItem)
                .delete();
            })
          );
        }
      }
    } catch (err) {}
  };
};

export const resetVoiceOver = (bookId, narrationId, pListName, pList) => {
  return async (dispatch, getState) => {
    try {
      //INFO : Find selected child
      let selectedChildId = JSON.parse(
        JSON.stringify(getState().authStore.selectedChild)
      );
      let currentChildren = JSON.parse(
        JSON.stringify(getState().authStore.children)
      );
      let selectedChild;
      currentChildren.map((eachChild) => {
        if (eachChild.id === selectedChildId) selectedChild = eachChild;
      });

      if (selectedChild) {
        let originalPListUrl = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${bookId}%2Fcontent%2F${pListName}?alt=media&token=${pList}`;
        const plistResponse = await fetch(originalPListUrl);
        const plistBlob = await plistResponse.blob();
        let uploadPListPath = `user_narration/${
          selectedChild.id
        }/${bookId}/${narrationId}/${bookId + ".plist"}`;
        await firebase.storage().ref(uploadPListPath).put(plistBlob);
        //INFO : Get PList Token
        let pListToken = stripUrlToToken(
          await firebase.storage().ref(uploadPListPath).getDownloadURL()
        );

        //INFO : Update Narration PList Token
        let verifyNarrationQuery = firebase
          .firestore()
          .collection(`children/${selectedChild.id}/narration`)
          .doc(bookId);
        let verifyNarrationDoc = await verifyNarrationQuery.get();
        let narrationList = JSON.parse(
          JSON.stringify(verifyNarrationDoc.data().narrationList)
        );
        let selectedNarrationIndex = -1;
        narrationList.map((eachNarration, narrationIndex) => {
          if (eachNarration.id === narrationId)
            selectedNarrationIndex = narrationIndex;
        });

        if (selectedNarrationIndex > -1) {
          narrationList[selectedNarrationIndex].pList = pListToken;
          narrationList[selectedNarrationIndex].members = [];
          narrationList[selectedNarrationIndex].updatedAt = new Date();
          await verifyNarrationQuery.update({
            narrationList: narrationList,
          });
        }
        eventBus.emit("voice-over-reset");
      }
    } catch (err) {}
  };
};
