const initState = {
  category: "allBooks",
  selectedBook: null,
  selectedNarrationId: "",
  selectedNarration: null,
  selectedSeries: null,
  selectedBookAnalytics: null,
  searchedBooks: [],
};

const libraryReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_CATEGORY":
      return {
        ...state,
        category: action.payload.category,
      };
    case "UPDATE_SEARCHED_BOOKS":
      return {
        ...state,
        searchedBooks: action.payload.searchedBooks,
      };
    case "UPDATE_SELECTED_BOOK":
      return {
        ...state,
        selectedBook: action.payload.selectedBook,
        selectedNarrationId: "",
      };
    case "UPDATE_SELECTED_BOOK_AND_NARRATION":
      return {
        ...state,
        selectedBook: action.payload.book,
        selectedNarrationId: action.payload.narrationId,
      };
    case "UPDATE_SELECTED_NARRATION":
      return {
        ...state,
        selectedNarration: action.payload.selectedNarration,
      };
    case "UPDATE_SELECTED_SERIES":
      return {
        ...state,
        selectedSeries: action.payload.selectedSeries,
      };
    case "UPDATE_SELECTED_BOOK_ANALYTICS":
      return {
        ...state,
        selectedBookAnalytics: action.payload.selectedBookAnalytics,
      };
    default:
      return state;
  }
};

export default libraryReducer;
