import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
//Styles
import { SubTitle4, SubButtonHeadline } from "../styles/Typography";
import {
  PrimaryRed,
  PrimaryGreen,
  PrimaryPurple,
  White,
  GoldYellow,
  SvgYellow,
} from "../styles/Colors";
import {
  VerticalContainer,
  RowContainer,
  SemanticLayout,
} from "../styles/GlobalStyles";
import { Line } from "rc-progress";
import SemiCircleProgressBar from "react-progressbar-semicircle";
//Component
import Button from "../global/Button";
import Tag from "../global/Tag";
import Pagination from "../global/Pagination";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
import EditChildProfile from "../components/dashboard/EditChildProfile";
import ChildStickers from "../components/dashboard/ChildStickers";
//Utils
import { childAvatarList } from "../utils/avatarList";
import { isToday } from "../utils/miscList";
//Assets
import ChildBackground from "../assets/background/childBackground.jpg";
import ChildStickerCoverImg from "../assets/background/childStickerBackground.jpg";
import EditProfileCover from "../assets/background/cropStageBackground.png";
import ChildVoiceOverCover from "../assets/background/childVoiceOverBackground.jpg";
import EditProfileAudio from "../assets/audio/achievement_edit_profile.mp3";
import AchievementStickerAudio from "../assets/audio/achievement_stickers.mp3";
import DiscoverBooks from "../assets/audio/achievement_discover_more_books.mp3";
//Actions
import {
  getChildWeeklyReport,
  getChildAnalytics,
  getChildNarration,
  updateChildPriviledge,
} from "../store/actions/childActions";

let analyticsLimit = 10;
let narrationLimit = 2;

class Profile extends Component {
  state = {
    screen: "",
    currentPage: 1,
  };

  componentDidMount() {
    this.handleCheckScreen();
    this.handleGetChildReport();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.location) !==
        JSON.stringify(this.props.location) &&
      this.props.location
    )
      this.handleCheckScreen();
    if (
      JSON.stringify(this.props.authStore.children) !==
      JSON.stringify(prevProps.authStore.children)
    ) {
      this.handleCheckScreen();
      this.handleGetChildReport();
    }
  }

  componentWillUnmount() {
    this.props.updateChildPriviledge(false);
  }

  handlePagination = (type) => {
    switch (type) {
      case "minus":
        if (this.state.currentPage > 1)
          this.setState({
            currentPage: this.state.currentPage - 1,
          });
        break;
      case "plus":
        if (this.state.screen === "narration") {
          if (
            this.props.childNarrationPagination &&
            this.state.currentPage ===
              Math.ceil(this.props.childNarration.length / narrationLimit)
          ) {
            this.props.getChildNarration(
              this.props.authStore.selectedChild,
              this.props.childNarrationPagination,
              false,
              narrationLimit
            );
            this.setState({
              currentPage: this.state.currentPage + 1,
            });
          } else if (
            this.props.childNarration.length >
            this.state.currentPage * narrationLimit
          )
            this.setState({
              currentPage: this.state.currentPage + 1,
            });
        } else {
          if (
            this.props.childAnalyticsPagination &&
            this.state.currentPage ===
              Math.ceil(this.props.childAnalytics.length / analyticsLimit)
          ) {
            this.props.getChildAnalytics(
              this.props.authStore.selectedChild,
              this.state.selectedValue === "favourite",
              this.props.childAnalyticsPagination,
              false,
              analyticsLimit
            );
            this.setState({
              currentPage: this.state.currentPage + 1,
            });
          } else if (
            this.props.childAnalytics.length >
            this.state.currentPage * analyticsLimit
          )
            this.setState({
              currentPage: this.state.currentPage + 1,
            });
        }
        break;
    }
  };

  handleGetChildReport = () => {
    if (
      this.props.authStore.selectedChild &&
      this.props.authStore.children.length > 0
    ) {
      this.props.authStore.children.map((eachChild) => {
        if (eachChild.id === this.props.authStore.selectedChild)
          this.props.getChildWeeklyReport(eachChild.id);
      });
    }
  };

  handleCheckScreen = () => {
    let pathNameList = this.props.location.pathname.split("/");
    if (pathNameList.length > 0) {
      let pathName = pathNameList[pathNameList.length - 1];
      this.setState(
        {
          screen: pathName,
        },
        () => {
          if (
            this.props.authStore.children.length > 0 &&
            this.props.authStore.selectedChild
          ) {
            this.props.authStore.children.map((eachChild) => {
              if (this.props.authStore.selectedChild === eachChild.id)
                if (this.state.screen === "books")
                  this.props.getChildAnalytics(
                    eachChild.id,
                    false,
                    "",
                    true,
                    analyticsLimit
                  );
                else if (this.state.screen === "narration")
                  this.props.getChildNarration(
                    eachChild.id,
                    "",
                    true,
                    narrationLimit
                  );
            });
          }
        }
      );
    }
  };

  handleNavigateScreen = async (screen, sound) => {
    this.props.history.push(screen);

    if (sound) {
      this.sound = new Audio(sound);
      this.sound.pause();
      this.sound.currentTime = 0;
      this.sound.play(sound);
    }
  };

  renderProgressBar = (type) => {
    if (type === "circle") {
      if (this.props.report) {
        let totalTime = this.renderDailyScreenTime("number");
        if (totalTime > 600) totalTime = 600;

        return Math.floor((totalTime / 600) * 100);
      } else return 0;
    } else {
      let point = this.renderSelectedChildAttribute("points");
      let percent = Math.floor(point / 100);
      return percent;
    }
  };

  renderSelectedChildAttribute = (attribute) => {
    let childAttribute = "";

    if (
      this.props.authStore.selectedChild &&
      this.props.authStore.children.length > 0
    ) {
      this.props.authStore.children.map((eachChild, index) => {
        if (eachChild.id === this.props.authStore.selectedChild) {
          switch (attribute) {
            case "index":
              childAttribute = index;
              break;
            case "name":
              childAttribute = eachChild.name;
              break;
            case "namePossess":
              childAttribute = eachChild.name + "'s";
              break;
            case "avatar":
              childAttribute = eachChild.avatar;
              break;
            case "points":
              childAttribute = eachChild.points;
              break;
            case "stickers":
              childAttribute = 0;
              let stickerAchived = eachChild.stickers;
              if (stickerAchived.length) childAttribute = stickerAchived.length;
              break;
            case "stickersContent":
              childAttribute = eachChild.stickers;
              break;
            case "narrations":
              childAttribute = eachChild.narrations.length;
              break;
            case "booksRead":
              childAttribute = 0;
              let booksRead = eachChild.booksRead;
              if (booksRead.length) childAttribute = booksRead.length;
              break;
          }
        }
      });
    }

    return childAttribute;
  };

  renderRightPointContainer = (type) => {
    let top = "54%";
    let left = "";
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    if (type === "narrations") left = "54%";
    else if (type === "stickers") left = "69.6%";
    else if (type === "booksRead") left = "84.7%";

    return (
      <PointsText
        style={{
          position: "absolute",
          top: top,
          left: left,
          width: (100 * divSize) / 1440,
        }}
      >
        {this.renderSelectedChildAttribute(type)}
      </PointsText>
    );
  };

  renderRigthButtonContainer = () => {
    let buttonList = [];
    let buttonText = [
      "MY AWESOME STICKERS",
      "MY VOICE-OVER STORIES",
      "DISCOVER MORE BOOKS",
    ];
    let backgroundColor = [PrimaryPurple, PrimaryGreen, PrimaryRed];
    let screen = ["profile/sticker", "profile/narration", "discover"];
    let voice = [AchievementStickerAudio, DiscoverBooks, DiscoverBooks];

    buttonText.map((eachButtonText, index) => {
      buttonList.push(
        <Button
          key={index}
          style={{
            backgroundColor: backgroundColor[index],
            width: "85%",
            height: 50,
            marginTop: 5,
            marginBottom: 5,
          }}
          buttonText={eachButtonText}
          onClick={this.handleNavigateScreen.bind(
            this,
            screen[index],
            voice[index]
          )}
          textStyle={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        ></Button>
      );
    });
    return buttonList;
  };

  renderDailyScreenTime = (type) => {
    let totalTime = 0;
    let totalTimeString = "00 : 00";
    if (this.props.report.details && this.props.report.details.length > 0)
      this.props.report.details.map((eachDetail) => {
        if (isToday(new Date(eachDetail.date.seconds * 1000)))
          totalTime += eachDetail.screenTime;
      });

    if (totalTime >= 600) totalTimeString = "10 : 00";
    else {
      let minutes = Math.floor(totalTime / 60);
      let minutesString = "0" + minutes;
      let seconds = totalTime % 60;
      let secondString = "00";
      if (seconds < 10) secondString = "0" + seconds;
      else secondString = String(seconds);
      totalTimeString = minutesString + " : " + secondString;
    }

    if (type === "number") return totalTime;
    else return totalTimeString;
  };

  renderProfileScreen = () => {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    return (
      <>
        <PointsText
          style={{
            position: "absolute",
            top: "27%",
            left: "21%",
            color: GoldYellow,
            width: (290 * divSize) / 1440,
          }}
        >
          {this.renderSelectedChildAttribute("name")}
        </PointsText>
        <VerticalContainer
          style={{
            position: "absolute",
            top: "40%",
            left: "22.6%",
            width: (230 * divSize) / 1440,
          }}
        >
          <img
            style={{
              width: 120,
              height: 120,
              marginBottom: 10,
            }}
            src={childAvatarList[this.renderSelectedChildAttribute("avatar")]}
          />
          <Button
            buttonText="Edit Profile"
            style={{
              width: "100%",
              height: 50,
            }}
            onClick={this.handleNavigateScreen.bind(
              this,
              "profile/edit",
              EditProfileAudio
            )}
          ></Button>
        </VerticalContainer>
        <VerticalContainer
          style={{ position: "absolute", left: "60.5%", top: "8%" }}
        >
          <SemiCircleProgressBar
            percentage={this.renderProgressBar("circle")}
            stroke={SvgYellow}
            diameter={(300 * divSize) / 1440}
          />
        </VerticalContainer>
        <PointsText
          style={{
            color: GoldYellow,
            position: "absolute",
            top: "15%",
            left: "63%",
            width: (230 * divSize) / 1440,
          }}
          onClick={this.handleStoryTimeSound}
        >
          {this.props.report ? this.renderDailyScreenTime() : "00 : 00"}
        </PointsText>
        <PointBarContainer
          style={{ width: (390 * divSize) / 1440 }}
          percent={this.renderProgressBar("bar")}
          strokeWidth="5"
          strokeColor={SvgYellow}
          trailColor={null}
        />
        <PointsText
          style={{
            position: "absolute",
            top: "35.5%",
            left: "58.5%",
            width: (180 * divSize) / 1440,
          }}
        >
          {this.renderSelectedChildAttribute("points")}
        </PointsText>
        {this.renderRightPointContainer("narrations")}
        {this.renderRightPointContainer("stickers")}
        {this.renderRightPointContainer("booksRead")}
        <VerticalContainer
          style={{
            position: "absolute",
            top: "70.3%",
            left: "57.8%",
            width: (400 * divSize) / 1440,
          }}
        >
          {this.renderRigthButtonContainer()}
        </VerticalContainer>
      </>
    );
  };

  renderNextAvailablePagination = () => {
    if (this.state.screen === "narration")
      return (
        (this.props.childNarrationPagination &&
          this.state.currentPage ===
            Math.ceil(this.props.childNarration.length / narrationLimit)) ||
        this.props.childNarration.length >
          this.state.currentPage * narrationLimit
      );
    else
      return (
        (this.props.childAnalyticsPagination &&
          this.state.currentPage ===
            Math.ceil(this.props.childAnalytics.length / analyticsLimit)) ||
        this.props.childAnalytics.length >
          this.state.currentPage * analyticsLimit
      );
  };

  renderEachBook = () => {
    let bookList = [];

    if (
      this.props.childStore.childAnalytics.length > 0 &&
      this.props.dataStore.books.length > 0
    ) {
      this.props.dataStore.books.map((eachBook, index) => {
        this.props.childStore.childAnalytics.map((eachBookAnalytics) => {
          if (eachBookAnalytics.id === eachBook.id) {
            if (this.state.selectedValue === "all")
              bookList.push({
                book: eachBook,
                analytics: eachBookAnalytics,
              });
            else {
              if (eachBookAnalytics.favourite)
                bookList.push({
                  book: eachBook,
                  analytics: eachBookAnalytics,
                });
            }
          }
        });
      });
    }

    return bookList;
  };

  renderContent = (type) => {
    return (
      <>
        <RowContainer
          style={{ justifyContent: "flex-start", width: "90%", marginTop: 30 }}
        >
          <img
            src={childAvatarList[this.renderSelectedChildAttribute("avatar")]}
            resizemode="contain"
            style={{
              width: 75,
              height: 75,
            }}
          />
          <VerticalContainer
            style={{
              marginLeft: 30,
              marginBottom: 15,
              alignItems: "flex-start",
            }}
          >
            <SubTitle4 style={{ color: PrimaryGreen }}>
              {this.renderSelectedChildAttribute("name")}
            </SubTitle4>
            <Tag
              width={250}
              text={type === "narration" ? "Voice-Over Stories" : "Books Read"}
            />
          </VerticalContainer>
        </RowContainer>
        <SemanticLayout
          style={{ width: "97%", height: "auto", marginBottom: 30 }}
        >
          <Pagination
            type={type}
            data={
              type === "narration"
                ? this.props.childNarration
                : this.props.childAnalytics
            }
            limit={type === "narration" ? narrationLimit : analyticsLimit}
            nextAvailable={this.renderNextAvailablePagination()}
            currentPage={this.state.currentPage}
            handlePagination={this.handlePagination}
          />
        </SemanticLayout>
      </>
    );
  };

  renderBackground = () => {
    let background = "";

    if (this.state.screen === "profile") background = ChildBackground;
    else if (this.state.screen === "edit" || this.state.screen === "books")
      background = EditProfileCover;
    else if (this.state.screen === "sticker") background = ChildStickerCoverImg;
    else if (this.state.screen === "narration")
      background = ChildVoiceOverCover;
    return background;
  };

  renderScreen = () => {
    switch (this.state.screen) {
      case "profile":
        return this.renderProfileScreen();
      case "edit":
        return (
          <EditChildProfile
            type={
              this.props.childStore.parentPriviledge
                ? "parentDashboard"
                : "childProfile"
            }
            renderSelectedChildAttribute={this.renderSelectedChildAttribute}
          />
        );
      case "sticker":
        return (
          <ChildStickers
            style={{ marginTop: -50 }}
            type="childProfile"
            renderSelectedChildAttribute={this.renderSelectedChildAttribute}
          />
        );
      case "books":
        return this.renderContent("bookAnalytics");
      case "narration":
        return this.renderContent("narration");
    }
  };

  render() {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    if (!this.state.screen) return null;
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            minHeight: divSize * 0.749,
            position: "relative",
            backgroundImage: "url(" + this.renderBackground() + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          {this.renderScreen()}
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    childNarration: state.childStore.childNarration,
    childNarrationPagination: state.childStore.childNarrationPagination,
    childAnalytics: state.childStore.childAnalytics,
    childAnalyticsPagination: state.childStore.childAnalyticsPagination,
    childStore: state.childStore,
    dataStore: state.dataStore,
    report: state.childStore.selectedChildReport,
    books: state.dataStore.books,
    stickers: state.dataStore.stickers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChildWeeklyReport: (childId) => dispatch(getChildWeeklyReport(childId)),
    getChildAnalytics: (childId, favourite, startAfter, reset, limit) =>
      dispatch(getChildAnalytics(childId, favourite, startAfter, reset, limit)),
    getChildNarration: (childId, startAfter, reset, limit) =>
      dispatch(getChildNarration(childId, startAfter, reset, limit)),
    updateChildPriviledge: (parentPriviledge) =>
      dispatch(updateChildPriviledge(parentPriviledge)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

const PointsText = styled(SubButtonHeadline)`
  height: 40px;
  font-size: 40px;
  text-align: center;
  color: ${White};
`;

const PointBarContainer = styled(Line)`
  height: 25px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${GoldYellow};
  border-width: 2px;
  border-style: solid;
  margin-top: 50px;
  position: absolute;
  left: 57%;
  top: 35.5%;
`;
