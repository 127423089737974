import Tommy1 from "../assets/audio/avatar_tommy1.mp3";
import Tommy2 from "../assets/audio/avatar_tommy2.mp3";
import Tommy3 from "../assets/audio/avatar_tommy3.mp3";
import Tommy4 from "../assets/audio/avatar_tommy4.mp3";
import Tommy5 from "../assets/audio/avatar_tommy5.mp3";
import Tommy6 from "../assets/audio/avatar_tommy6.mp3";
import Tommy7 from "../assets/audio/avatar_tommy7.mp3";
import Tommy8 from "../assets/audio/avatar_tommy8.mp3";
import Tommy9 from "../assets/audio/avatar_tommy9.mp3";
import Tommy10 from "../assets/audio/avatar_tommy10.mp3";
import Alison1 from "../assets/audio/avatar_alison1.mp3";
import Alison2 from "../assets/audio/avatar_alison2.mp3";
import Alison3 from "../assets/audio/avatar_alison3.mp3";
import Alison4 from "../assets/audio/avatar_alison4.mp3";
import Alison5 from "../assets/audio/avatar_alison5.mp3";
import Alison6 from "../assets/audio/avatar_alison6.mp3";
import Alison7 from "../assets/audio/avatar_alison7.mp3";
import Alison8 from "../assets/audio/avatar_alison8.mp3";
import Alison9 from "../assets/audio/avatar_alison9.mp3";
import SunshineCat1 from "../assets/audio/avatar_sunshine_cat1.mp3";
import SunshineCat2 from "../assets/audio/avatar_sunshine_cat1.mp3";
import SunshineCat3 from "../assets/audio/avatar_sunshine_cat1.mp3";
import SunshineCat4 from "../assets/audio/avatar_sunshine_cat1.mp3";
import SunshineCat5 from "../assets/audio/avatar_sunshine_cat1.mp3";
import SunshineCat6 from "../assets/audio/avatar_sunshine_cat1.mp3";
import SunshineCat7 from "../assets/audio/avatar_sunshine_cat1.mp3";
import SunshineCat8 from "../assets/audio/avatar_sunshine_cat1.mp3";
import Book1 from "../assets/audio/avatar_book1.mp3";
import Book2 from "../assets/audio/avatar_book1.mp3";
import Book3 from "../assets/audio/avatar_book1.mp3";
import Book4 from "../assets/audio/avatar_book1.mp3";
import Book5 from "../assets/audio/avatar_book1.mp3";
import Book6 from "../assets/audio/avatar_book1.mp3";
import Book7 from "../assets/audio/avatar_book1.mp3";
import Book8 from "../assets/audio/avatar_book1.mp3";
import Tapir1 from "../assets/audio/avatar_tapir1.mp3";
import Tapir2 from "../assets/audio/avatar_tapir2.mp3";
import Tapir3 from "../assets/audio/avatar_tapir3.mp3";
import Tapir4 from "../assets/audio/avatar_tapir4.mp3";
import Tapir5 from "../assets/audio/avatar_tapir5.mp3";
import Tapir6 from "../assets/audio/avatar_tapir6.mp3";
import Tapir7 from "../assets/audio/avatar_tapir7.mp3";
import Cheshire_cat1 from "../assets/audio/avatar_cheshire_cat1.mp3";
import Cheshire_cat2 from "../assets/audio/avatar_cheshire_cat2.mp3";
import Cheshire_cat3 from "../assets/audio/avatar_cheshire_cat3.mp3";
import Cheshire_cat4 from "../assets/audio/avatar_cheshire_cat4.mp3";
import Cheshire_cat5 from "../assets/audio/avatar_cheshire_cat5.mp3";
import Cheshire_cat6 from "../assets/audio/avatar_cheshire_cat6.mp3";
import Cheshire_cat7 from "../assets/audio/avatar_cheshire_cat7.mp3";
import Cheshire_cat8 from "../assets/audio/avatar_cheshire_cat8.mp3";
import Cheshire_cat9 from "../assets/audio/avatar_cheshire_cat9.mp3";
import Cheshire_cat10 from "../assets/audio/avatar_cheshire_cat10.mp3";
import Dog1 from "../assets/audio/avatar_dog1.mp3";
import Dog2 from "../assets/audio/avatar_dog2.mp3";
import Dog3 from "../assets/audio/avatar_dog3.mp3";
import Dog4 from "../assets/audio/avatar_dog4.mp3";
import Dog5 from "../assets/audio/avatar_dog5.mp3";
import Dog6 from "../assets/audio/avatar_dog6.mp3";
import Dog7 from "../assets/audio/avatar_dog7.mp3";
import Dog8 from "../assets/audio/avatar_dog8.mp3";
import Ali1 from "../assets/audio/avatar_ali1.mp3";
import Ali2 from "../assets/audio/avatar_ali2.mp3";
import Ali3 from "../assets/audio/avatar_ali3.mp3";
import Ali4 from "../assets/audio/avatar_ali4.mp3";
import Ali5 from "../assets/audio/avatar_ali5.mp3";
import Ali6 from "../assets/audio/avatar_ali6.mp3";
import Ali7 from "../assets/audio/avatar_ali7.mp3";
import Ali8 from "../assets/audio/avatar_ali8.mp3";
import Unicorn1 from "../assets/audio/avatar_unicorn1.mp3";
import Unicorn2 from "../assets/audio/avatar_unicorn2.mp3";
import Unicorn3 from "../assets/audio/avatar_unicorn3.mp3";
import Unicorn4 from "../assets/audio/avatar_unicorn4.mp3";
import Unicorn5 from "../assets/audio/avatar_unicorn5.mp3";
import Unicorn6 from "../assets/audio/avatar_unicorn6.mp3";
import Unicorn7 from "../assets/audio/avatar_unicorn7.mp3";
import Lion1 from "../assets/audio/avatar_lion1.mp3";
import Lion2 from "../assets/audio/avatar_lion2.mp3";
import Lion3 from "../assets/audio/avatar_lion3.mp3";
import Lion4 from "../assets/audio/avatar_lion4.mp3";
import Lion5 from "../assets/audio/avatar_lion5.mp3";
import Lion6 from "../assets/audio/avatar_lion6.mp3";
import Lion7 from "../assets/audio/avatar_lion7.mp3";
import Lion8 from "../assets/audio/avatar_lion8.mp3";
import Lion9 from "../assets/audio/avatar_lion9.mp3";
import Lion10 from "../assets/audio/avatar_lion10.mp3";
import Tiger1 from "../assets/audio/avatar_tiger1.mp3";
import Tiger2 from "../assets/audio/avatar_tiger2.mp3";
import Tiger3 from "../assets/audio/avatar_tiger3.mp3";
import Tiger4 from "../assets/audio/avatar_tiger4.mp3";
import Tiger5 from "../assets/audio/avatar_tiger5.mp3";
import Tiger6 from "../assets/audio/avatar_tiger6.mp3";
import Tiger7 from "../assets/audio/avatar_tiger7.mp3";
import Panda1 from "../assets/audio/avatar_panda1.mp3";
import Panda2 from "../assets/audio/avatar_panda2.mp3";
import Panda3 from "../assets/audio/avatar_panda3.mp3";
import Panda4 from "../assets/audio/avatar_panda4.mp3";
import Panda5 from "../assets/audio/avatar_panda5.mp3";
import Panda6 from "../assets/audio/avatar_panda6.mp3";
import Panda7 from "../assets/audio/avatar_panda7.mp3";
import Brown_bear1 from "../assets/audio/avatar_brown_bear1.mp3";
import Brown_bear2 from "../assets/audio/avatar_brown_bear2.mp3";
import Brown_bear3 from "../assets/audio/avatar_brown_bear3.mp3";
import Brown_bear4 from "../assets/audio/avatar_brown_bear4.mp3";
import Brown_bear5 from "../assets/audio/avatar_brown_bear5.mp3";
import Brown_bear6 from "../assets/audio/avatar_brown_bear6.mp3";
import Brown_bear7 from "../assets/audio/avatar_brown_bear7.mp3";
import Brown_bear8 from "../assets/audio/avatar_brown_bear8.mp3";
import Brown_bear9 from "../assets/audio/avatar_brown_bear9.mp3";
import Dragon1 from "../assets/audio/avatar_dragon1.mp3";
import Dragon2 from "../assets/audio/avatar_dragon2.mp3";
import Dragon3 from "../assets/audio/avatar_dragon3.mp3";
import Dragon4 from "../assets/audio/avatar_dragon4.mp3";
import Dragon5 from "../assets/audio/avatar_dragon5.mp3";
import Dragon6 from "../assets/audio/avatar_dragon6.mp3";
import Dragon7 from "../assets/audio/avatar_dragon7.mp3";
import Dragon8 from "../assets/audio/avatar_dragon8.mp3";
import Elephant1 from "../assets/audio/avatar_elephant1.mp3";
import Elephant2 from "../assets/audio/avatar_elephant2.mp3";
import Elephant3 from "../assets/audio/avatar_elephant3.mp3";
import Elephant4 from "../assets/audio/avatar_elephant4.mp3";
import Elephant5 from "../assets/audio/avatar_elephant5.mp3";
import Elephant6 from "../assets/audio/avatar_elephant6.mp3";
import Elephant7 from "../assets/audio/avatar_elephant7.mp3";
import Merlion1 from "../assets/audio/avatar_merlion1.mp3";
import Merlion2 from "../assets/audio/avatar_merlion2.mp3";
import Merlion3 from "../assets/audio/avatar_merlion3.mp3";
import Merlion4 from "../assets/audio/avatar_merlion4.mp3";
import Merlion5 from "../assets/audio/avatar_merlion5.mp3";
import Merlion6 from "../assets/audio/avatar_merlion6.mp3";
import Merlion7 from "../assets/audio/avatar_merlion7.mp3";
import Trex1 from "../assets/audio/avatar_trex1.mp3";
import Trex2 from "../assets/audio/avatar_trex2.mp3";
import Trex3 from "../assets/audio/avatar_trex3.mp3";
import Trex4 from "../assets/audio/avatar_trex4.mp3";
import Trex5 from "../assets/audio/avatar_trex5.mp3";
import Trex6 from "../assets/audio/avatar_trex6.mp3";
import Trex7 from "../assets/audio/avatar_trex7.mp3";
import Trex8 from "../assets/audio/avatar_trex8.mp3";
import Trex9 from "../assets/audio/avatar_trex9.mp3";
import Cow1 from "../assets/audio/avatar_cow1.mp3";
import Cow2 from "../assets/audio/avatar_cow2.mp3";
import Cow3 from "../assets/audio/avatar_cow3.mp3";
import Cow4 from "../assets/audio/avatar_cow4.mp3";
import Cow5 from "../assets/audio/avatar_cow5.mp3";
import Cow6 from "../assets/audio/avatar_cow6.mp3";
import Cow7 from "../assets/audio/avatar_cow7.mp3";
import Cow8 from "../assets/audio/avatar_cow8.mp3";
import Mice1 from "../assets/audio/avatar_mice1.mp3";
import Mice2 from "../assets/audio/avatar_mice2.mp3";
import Mice3 from "../assets/audio/avatar_mice3.mp3";
import Mice4 from "../assets/audio/avatar_mice4.mp3";
import Mice5 from "../assets/audio/avatar_mice5.mp3";
import Mice6 from "../assets/audio/avatar_mice6.mp3";
import Mice7 from "../assets/audio/avatar_mice7.mp3";
import Mice8 from "../assets/audio/avatar_mice8.mp3";
import Mice9 from "../assets/audio/avatar_mice9.mp3";
import Mice10 from "../assets/audio/avatar_mice10.mp3";
import Fairy_pixie1 from "../assets/audio/avatar_fairy_pixie1.mp3";
import Fairy_pixie2 from "../assets/audio/avatar_fairy_pixie2.mp3";
import Fairy_pixie3 from "../assets/audio/avatar_fairy_pixie3.mp3";
import Fairy_pixie4 from "../assets/audio/avatar_fairy_pixie4.mp3";
import Fairy_pixie5 from "../assets/audio/avatar_fairy_pixie5.mp3";
import Fairy_pixie6 from "../assets/audio/avatar_fairy_pixie6.mp3";
import Fairy_pixie7 from "../assets/audio/avatar_fairy_pixie7.mp3";
import Fairy_pixie8 from "../assets/audio/avatar_fairy_pixie8.mp3";
import Mei_ling1 from "../assets/audio/avatar_mei_ling1.mp3";
import Mei_ling2 from "../assets/audio/avatar_mei_ling2.mp3";
import Mei_ling3 from "../assets/audio/avatar_mei_ling3.mp3";
import Mei_ling4 from "../assets/audio/avatar_mei_ling4.mp3";
import Mei_ling5 from "../assets/audio/avatar_mei_ling5.mp3";
import Mei_ling6 from "../assets/audio/avatar_mei_ling6.mp3";
import Mei_ling7 from "../assets/audio/avatar_mei_ling7.mp3";
import Mei_ling8 from "../assets/audio/avatar_mei_ling8.mp3";
import Koala1 from "../assets/audio/avatar_koala1.mp3";
import Koala2 from "../assets/audio/avatar_koala2.mp3";
import Koala3 from "../assets/audio/avatar_koala3.mp3";
import Koala4 from "../assets/audio/avatar_koala4.mp3";
import Koala5 from "../assets/audio/avatar_koala5.mp3";
import Koala6 from "../assets/audio/avatar_koala6.mp3";
import Koala7 from "../assets/audio/avatar_koala7.mp3";
import Koala8 from "../assets/audio/avatar_koala8.mp3";
import Arrow_boy1 from "../assets/audio/avatar_arrow_boy1.mp3";
import Arrow_boy2 from "../assets/audio/avatar_arrow_boy2.mp3";
import Arrow_boy3 from "../assets/audio/avatar_arrow_boy3.mp3";
import Arrow_boy4 from "../assets/audio/avatar_arrow_boy4.mp3";
import Arrow_boy5 from "../assets/audio/avatar_arrow_boy5.mp3";
import Arrow_boy6 from "../assets/audio/avatar_arrow_boy6.mp3";
import Arrow_boy7 from "../assets/audio/avatar_arrow_boy7.mp3";
import Arrow_boy8 from "../assets/audio/avatar_arrow_boy8.mp3";
import Arrow_boy9 from "../assets/audio/avatar_arrow_boy9.mp3";
import Pyper1 from "../assets/audio/avatar_pyper1.mp3";
import Pyper2 from "../assets/audio/avatar_pyper2.mp3";
import Pyper3 from "../assets/audio/avatar_pyper3.mp3";
import Pyper4 from "../assets/audio/avatar_pyper4.mp3";
import Pyper5 from "../assets/audio/avatar_pyper5.mp3";
import Pyper6 from "../assets/audio/avatar_pyper6.mp3";
import Pyper7 from "../assets/audio/avatar_pyper7.mp3";
import Pyper8 from "../assets/audio/avatar_pyper8.mp3";
import Grumpy_cat1 from "../assets/audio/avatar_grumpy_cat1.mp3";
import Grumpy_cat2 from "../assets/audio/avatar_grumpy_cat2.mp3";
import Grumpy_cat3 from "../assets/audio/avatar_grumpy_cat3.mp3";
import Grumpy_cat4 from "../assets/audio/avatar_grumpy_cat4.mp3";
import Grumpy_cat5 from "../assets/audio/avatar_grumpy_cat5.mp3";
import Grumpy_cat6 from "../assets/audio/avatar_grumpy_cat6.mp3";
import Grumpy_cat7 from "../assets/audio/avatar_grumpy_cat7.mp3";
import Xiao_mei1 from "../assets/audio/avatar_xiao_mei1.mp3";
import Xiao_mei2 from "../assets/audio/avatar_xiao_mei2.mp3";
import Xiao_mei3 from "../assets/audio/avatar_xiao_mei3.mp3";
import Xiao_mei4 from "../assets/audio/avatar_xiao_mei4.mp3";
import Xiao_mei5 from "../assets/audio/avatar_xiao_mei5.mp3";
import Xiao_mei6 from "../assets/audio/avatar_xiao_mei6.mp3";
import Xiao_mei7 from "../assets/audio/avatar_xiao_mei7.mp3";
import Xiao_mei8 from "../assets/audio/avatar_xiao_mei8.mp3";
import Nikki_dog1 from "../assets/audio/avatar_nikki_dog1.mp3";
import Nikki_dog2 from "../assets/audio/avatar_nikki_dog2.mp3";
import Nikki_dog3 from "../assets/audio/avatar_nikki_dog3.mp3";
import Nikki_dog4 from "../assets/audio/avatar_nikki_dog4.mp3";
import Nikki_dog5 from "../assets/audio/avatar_nikki_dog5.mp3";
import Nikki_dog6 from "../assets/audio/avatar_nikki_dog6.mp3";
import Nikki_dog7 from "../assets/audio/avatar_nikki_dog7.mp3";
import Nikki_dog8 from "../assets/audio/avatar_nikki_dog8.mp3";
import Indian_boy1 from "../assets/audio/avatar_indian_boy1.mp3";
import Indian_boy2 from "../assets/audio/avatar_indian_boy2.mp3";
import Indian_boy3 from "../assets/audio/avatar_indian_boy3.mp3";
import Indian_boy4 from "../assets/audio/avatar_indian_boy4.mp3";
import Indian_boy5 from "../assets/audio/avatar_indian_boy5.mp3";
import Indian_boy6 from "../assets/audio/avatar_indian_boy6.mp3";
import Indian_boy8 from "../assets/audio/avatar_indian_boy8.mp3";
import Indian_boy9 from "../assets/audio/avatar_indian_boy9.mp3";
import Siti1 from "../assets/audio/avatar_siti1.mp3";
import Siti2 from "../assets/audio/avatar_siti2.mp3";
import Siti3 from "../assets/audio/avatar_siti3.mp3";
import Siti4 from "../assets/audio/avatar_siti4.mp3";
import Siti5 from "../assets/audio/avatar_siti5.mp3";
import Siti6 from "../assets/audio/avatar_siti6.mp3";
import Siti7 from "../assets/audio/avatar_siti7.mp3";
import Siti8 from "../assets/audio/avatar_siti8.mp3";
import Tommy_pyper1 from "../assets/audio/avatar_tommy_pyper1.mp3";
import Tommy_pyper2 from "../assets/audio/avatar_tommy_pyper2.mp3";
import Tommy_pyper3 from "../assets/audio/avatar_tommy_pyper3.mp3";
import Tommy_pyper4 from "../assets/audio/avatar_tommy_pyper4.mp3";
import Tommy_pyper5 from "../assets/audio/avatar_tommy_pyper5.mp3";
import Tommy_pyper6 from "../assets/audio/avatar_tommy_pyper6.mp3";
import Tommy_pyper7 from "../assets/audio/avatar_tommy_pyper7.mp3";
import Tommy_pyper8 from "../assets/audio/avatar_tommy_pyper8.mp3";
import Whitney1 from "../assets/audio/avatar_whitney1.mp3";
import Whitney2 from "../assets/audio/avatar_whitney2.mp3";
import Whitney3 from "../assets/audio/avatar_whitney3.mp3";
import Whitney4 from "../assets/audio/avatar_whitney4.mp3";
import Whitney5 from "../assets/audio/avatar_whitney5.mp3";
import Whitney6 from "../assets/audio/avatar_whitney6.mp3";
import Whitney7 from "../assets/audio/avatar_whitney7.mp3";
import Whitney8 from "../assets/audio/avatar_whitney8.mp3";

export const avatarSoundList = [
  {
    voiceList: [
      Tommy1,
      Tommy2,
      Tommy3,
      Tommy4,
      Tommy5,
      Tommy6,
      Tommy7,
      Tommy8,
      Tommy9,
      Tommy10,
    ],
  },
  {
    voiceList: [
      Alison1,
      Alison2,
      Alison3,
      Alison4,
      Alison5,
      Alison6,
      Alison7,
      Alison8,
      Alison9,
    ],
  },
  {
    voiceList: [
      SunshineCat1,
      SunshineCat2,
      SunshineCat3,
      SunshineCat4,
      SunshineCat5,
      SunshineCat6,
      SunshineCat7,
      SunshineCat8,
    ],
  },
  {
    voiceList: [Book1, Book2, Book3, Book4, Book5, Book6, Book7, Book8],
  },
  {
    voiceList: [Tapir1, Tapir2, Tapir3, Tapir4, Tapir5, Tapir6, Tapir7],
  },

  {
    voiceList: [
      Cheshire_cat1,
      Cheshire_cat2,
      Cheshire_cat3,
      Cheshire_cat4,
      Cheshire_cat5,
      Cheshire_cat6,
      Cheshire_cat7,
      Cheshire_cat8,
      Cheshire_cat9,
      Cheshire_cat10,
    ],
  },

  {
    voiceList: [Dog1, Dog2, Dog3, Dog4, Dog5, Dog6, Dog7, Dog8],
  },

  {
    voiceList: [Ali1, Ali2, Ali3, Ali4, Ali5, Ali6, Ali7, Ali8],
  },
  {
    voiceList: [
      Unicorn1,
      Unicorn2,
      Unicorn3,
      Unicorn4,
      Unicorn5,
      Unicorn6,
      Unicorn7,
    ],
  },

  {
    voiceList: [
      Lion1,
      Lion2,
      Lion3,
      Lion4,
      Lion5,
      Lion6,
      Lion7,
      Lion8,
      Lion9,
      Lion10,
    ],
  },
  {
    voiceList: [Tiger1, Tiger2, Tiger3, Tiger4, Tiger5, Tiger6, Tiger7],
  },

  {
    voiceList: [Panda1, Panda2, Panda3, Panda4, Panda5, Panda6, Panda7],
  },

  {
    voiceList: [
      Brown_bear1,
      Brown_bear2,
      Brown_bear3,
      Brown_bear4,
      Brown_bear5,
      Brown_bear6,
      Brown_bear7,
      Brown_bear8,
      Brown_bear9,
    ],
  },

  {
    voiceList: [
      Dragon1,
      Dragon2,
      Dragon3,
      Dragon4,
      Dragon5,
      Dragon6,
      Dragon7,
      Dragon8,
    ],
  },

  {
    voiceList: [
      Elephant1,
      Elephant2,
      Elephant3,
      Elephant4,
      Elephant5,
      Elephant6,
      Elephant7,
    ],
  },

  {
    voiceList: [
      Merlion1,
      Merlion2,
      Merlion3,
      Merlion4,
      Merlion5,
      Merlion6,
      Merlion7,
    ],
  },

  {
    voiceList: [Trex1, Trex2, Trex3, Trex4, Trex5, Trex6, Trex7, Trex8, Trex9],
  },

  {
    voiceList: [Cow1, Cow2, Cow3, Cow4, Cow5, Cow6, Cow7, Cow8],
  },

  {
    voiceList: [
      Mice1,
      Mice2,
      Mice3,
      Mice4,
      Mice5,
      Mice6,
      Mice7,
      Mice8,
      Mice9,
      Mice10,
    ],
  },

  {
    voiceList: [
      Fairy_pixie1,
      Fairy_pixie2,
      Fairy_pixie3,
      Fairy_pixie4,
      Fairy_pixie5,
      Fairy_pixie6,
      Fairy_pixie7,
      Fairy_pixie8,
    ],
  },

  {
    voiceList: [
      Mei_ling1,
      Mei_ling2,
      Mei_ling3,
      Mei_ling4,
      Mei_ling5,
      Mei_ling6,
      Mei_ling7,
      Mei_ling8,
    ],
  },
  {
    voiceList: [Koala1, Koala2, Koala3, Koala4, Koala5, Koala6, Koala7, Koala8],
  },

  {
    voiceList: [
      Arrow_boy1,
      Arrow_boy2,
      Arrow_boy3,
      Arrow_boy4,
      Arrow_boy5,
      Arrow_boy6,
      Arrow_boy7,
      Arrow_boy8,
      Arrow_boy9,
    ],
  },

  {
    voiceList: [Pyper1, Pyper2, Pyper3, Pyper4, Pyper5, Pyper6, Pyper7, Pyper8],
  },

  {
    voiceList: [
      Grumpy_cat1,
      Grumpy_cat2,
      Grumpy_cat3,
      Grumpy_cat4,
      Grumpy_cat5,
      Grumpy_cat6,
      Grumpy_cat7,
    ],
  },

  {
    voiceList: [
      Xiao_mei1,
      Xiao_mei2,
      Xiao_mei3,
      Xiao_mei4,
      Xiao_mei5,
      Xiao_mei6,
      Xiao_mei7,
      Xiao_mei8,
    ],
  },

  {
    voiceList: [
      Nikki_dog1,
      Nikki_dog2,
      Nikki_dog3,
      Nikki_dog4,
      Nikki_dog5,
      Nikki_dog6,
      Nikki_dog7,
      Nikki_dog8,
    ],
  },

  {
    voiceList: [
      Indian_boy1,
      Indian_boy2,
      Indian_boy3,
      Indian_boy4,
      Indian_boy5,
      Indian_boy6,
      Indian_boy8,
      Indian_boy9,
    ],
  },

  {
    voiceList: [Siti1, Siti2, Siti3, Siti4, Siti5, Siti6, Siti7, Siti8],
  },

  {
    voiceList: [
      Tommy_pyper1,
      Tommy_pyper2,
      Tommy_pyper3,
      Tommy_pyper4,
      Tommy_pyper5,
      Tommy_pyper6,
      Tommy_pyper7,
      Tommy_pyper8,
    ],
  },
  {
    voiceList: [
      Whitney1,
      Whitney2,
      Whitney3,
      Whitney4,
      Whitney5,
      Whitney6,
      Whitney7,
      Whitney8,
    ],
  },
];
