export const updateCountry = (country) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_COUNTRY",
        payload: {
          country: country,
        },
      });
    } catch (err) {}
  };
};

export const updateDeviceType = (deviceType) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_DEVICE_TYPE",
        payload: {
          deviceType: deviceType,
        },
      });
    } catch (err) {}
  };
};

export const updateDeviceSize = (deviceSize) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_DEVICE_SIZE",
        payload: {
          deviceSize: deviceSize,
        },
      });
    } catch (err) {}
  };
};

export const updateSuccessType = (successType) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_SUCCESS_TYPE",
        payload: {
          successType: successType,
        },
      });
    } catch (err) {}
  };
};

export const handleFontLoaded = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_FONT_LOADED",
        payload: {
          fontLoaded: true,
        },
      });
    } catch (err) {}
  };
};
