//Assets
import ActorIcon from "../assets/images/sliderIcons/actor.png";
import MeBooksIcon from "../assets/images/sliderIcons/meBooks.png";
import OxfordOwl from "../assets/images/sliderIcons/oxfordOwl.png";
import Boy from "../assets/images/sliderIcons/boy.png";
import { SemanticBrown, PrimaryBrown } from "../styles/Colors";
import FavouriteVOAudio from "../assets/audio/home_favourite_movie.mp3";
import LovingCareAudio from "../assets/audio/home_tender_loving_care.mp3";
import OxfordOwlAudio from "../assets/audio/home_oxford_owl.mp3";

export const homeScreenData = [
  {
    key: "0",
    title: "Begin Your Storytelling Journey Here",
    categoryId: "categories_a9b368f1-1c9e-49dd-98e5-85aa81fbcc02",
    type: "Character",
    iconStyle: { marginLeft: 30 },
  },
  {
    key: "1",
    title: "Your Favorite Movie Star’s Voice-over Actors",
    categoryId: "categories_35051db0-f940-423d-ab0a-023083234771",
    tagId: "ed160ed2-3820-46d4-8099-f7df46f54f78",
    image: ActorIcon,
    sound: FavouriteVOAudio,
    style: {
      backgroundColor: SemanticBrown,
      paddingBottom: 50,
      paddingTop: 40,
    },
    iconStyle: { height: 120, width: 120, marginLeft: 30 },
    type: "Book",
  },
  {
    key: "2",
    title: "Tender Loving Care by Me Books Creations",
    categoryId: "categories_79c1fb29-1872-46fa-812c-1f945fd853fa",
    tagId: "a116455e-deb5-46b7-adaf-37035892c7fc",
    image: MeBooksIcon,
    sound: LovingCareAudio,
    iconStyle: { height: 120, width: 120, marginLeft: 30 },
    type: "Book",
    style: {
      paddingBottom: 50,
      backgroundColor: SemanticBrown,
    },
  },
  {
    key: "3",
    title: "",
    tagId: "b4c56868-fe9d-4672-b005-6898e463dde0",
    image: OxfordOwl,
    sound: OxfordOwlAudio,
    style: { paddingTop: 50, backgroundColor: PrimaryBrown },
    iconStyle: { width: 180, height: 30, marginLeft: 30 },
    type: "Series",
  },
  {
    key: "4",
    title: "Malaysian Titles",
    tagId: "6716801c-0bfa-47dd-aed7-cfab9600d889",
    image: Boy,
    sound: require("../assets/audio/home_you_might_like.mp3"),
    style: { paddingBottom: 50, backgroundColor: PrimaryBrown },
    iconStyle: { height: 120, width: 90, marginLeft: 30 },
    type: "Series",
  },
];
