import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
//Styles
import { PrimaryGreen } from "../styles/Colors";
import { SubFootnote, Footnote, SpecTitle4 } from "../styles/Typography";
import { RowContainer, VerticalContainer } from "../styles/GlobalStyles";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";

const titleStyle = {
  marginTop: 100,
  marginBottom: 30,
};

const headerStyle = {
  marginBottom: 10,
};

class Support extends Component {
  renderTroubleSignIn = () => {
    return (
      <>
        <SpecTitle4 style={titleStyle}>Trouble Signing In?</SpecTitle4>
        <Container>
          <Footnote style={headerStyle}>I forgot my member ID</Footnote>
          <RowContainer style={{ justifyContent: "flex-start" }}>
            <CustomSubFootnote>Click&nbsp;</CustomSubFootnote>
            <CustomSubFootnote
              style={{
                color: PrimaryGreen,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => {
                window.open(
                  "https://www.u-library.gov.my/ulibrary/personal/mem/pmemForgotPassword.jsp"
                );
              }}
            >
              here&nbsp;
            </CustomSubFootnote>
            <CustomSubFootnote>
              and provide us with your u-Pustaka User-ID. OR User ID to
            </CustomSubFootnote>
          </RowContainer>
          <CustomSubFootnote>reset your password.</CustomSubFootnote>
        </Container>
        <Container>
          <Footnote style={headerStyle}>Member number is invalid</Footnote>
          <CustomSubFootnote>
            The library staff at u-Pustaka is ready to help you in many ways,
            &nbsp;
          </CustomSubFootnote>
          <RowContainer style={{ justifyContent: "flex-start" }}>
            <CustomSubFootnote>
              kindly contact the National Library at &nbsp;
            </CustomSubFootnote>
            <CustomSubFootnote
              style={{
                color: PrimaryGreen,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => {
                window.open("mailto:webmaster@u-library.gov.my");
              }}
            >
              webmaster@u-library.gov.my
            </CustomSubFootnote>
          </RowContainer>
        </Container>
      </>
    );
  };

  renderNeedCard = () => {
    return (
      <>
        <SpecTitle4 style={titleStyle}>
          Need a u-Pustaka Library Card?
        </SpecTitle4>
        <Container>
          <Footnote style={headerStyle}>
            I would like to register for a u-Pustaka Library Membership
          </Footnote>
          <RowContainer style={{ justifyContent: "flex-start" }}>
            <CustomSubFootnote>Kindly complete the &nbsp;</CustomSubFootnote>
            <CustomSubFootnote
              style={{
                color: PrimaryGreen,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => {
                window.open(
                  "https://www.u-library.gov.my/portal/web/guest/registration"
                );
              }}
            >
              u-Member Sign-Up form &nbsp;
            </CustomSubFootnote>
            <CustomSubFootnote>from anywhere via the &nbsp;</CustomSubFootnote>
          </RowContainer>
          <RowContainer style={{ justifyContent: "flex-start" }}>
            <CustomSubFootnote>
              u-Pustaka Portal or at any &nbsp;
            </CustomSubFootnote>
            <CustomSubFootnote
              style={{
                color: PrimaryGreen,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => {
                window.open(
                  "https://www.u-library.gov.my/portal/web/guest/background"
                );
              }}
            >
              u-Pustaka Consortium &nbsp;
            </CustomSubFootnote>
            <CustomSubFootnote>counter stations</CustomSubFootnote>
          </RowContainer>
          <CustomSubFootnote>to obtain your Membership.</CustomSubFootnote>
        </Container>
        <Container>
          <Footnote style={headerStyle}>Want to find out more?</Footnote>
          <RowContainer style={{ justifyContent: "flex-start" }}>
            <CustomSubFootnote>
              For more information about our membership click&nbsp;
            </CustomSubFootnote>
            <CustomSubFootnote
              style={{
                color: PrimaryGreen,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => {
                window.open(
                  "https://www.u-library.gov.my/portal/web/guest/background"
                );
              }}
            >
              here.
            </CustomSubFootnote>
          </RowContainer>
        </Container>
      </>
    );
  };

  renderContact = () => {
    return (
      <>
        <SpecTitle4 style={titleStyle}>Contact Us</SpecTitle4>
        <Container>
          <CustomSubFootnote>
            If the above steps is not working for you and further assistance
          </CustomSubFootnote>
          <RowContainer style={{ justifyContent: "flex-start" }}>
            <CustomSubFootnote>
              is required, reach out to us at&nbsp;
            </CustomSubFootnote>
            <CustomSubFootnote
              style={{
                color: PrimaryGreen,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => {
                window.open("mailto:hello@mebooks.co");
              }}
            >
              hello@mebooks.co
            </CustomSubFootnote>
          </RowContainer>
        </Container>
      </>
    );
  };
  render() {
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <VerticalContainer
          style={{
            width: "100%",
            alignItems: "flex-start",
            marginBottom: 120,
            maxWidth: 1440,
          }}
        >
          <div style={{ marginLeft: 120, maxWidth: 800 }}>
            {this.renderTroubleSignIn()}
            {this.renderNeedCard()}
            {this.renderContact()}
          </div>
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(Support);

const Container = styled.div`
  margin-bottom: 30px;
`;

const CustomSubFootnote = styled(SubFootnote)`
  line-height: 30px;
`;
