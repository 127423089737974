import React, { Component } from "react";
import { connect } from "react-redux";
//Styles
import { RowContainer } from "../../styles/GlobalStyles";
//Components
import IconButton from "../../global/IconButton";
//Assets
import SpeechDrama from "../../assets/images/childrenDashboard/speechAndDramaLight.png";
import Read from "../../assets/images/childrenDashboard/readLight.png";
import SpeechDramaImage from "../../assets/images/childrenDashboard/speechAndDrama.png";
import ReadImage from "../../assets/images/childrenDashboard/read.png";
import SpeechDramaAudio from "../../assets/audio/achievement_speech_drama.mp3";
import ReadAudio from "../../assets/audio/achievement_read.mp3";

class StickerSignBoard extends Component {
  constructor(props) {
    super(props);
    this.sound = null;
  }

  handleCategory = async (category, sound) => {
    this.props.handleCurrentCategory(category);

    this.sound = new Audio(sound);
    this.sound.pause();
    this.sound.currentTime = 0;
    this.sound.play(sound);
  };

  renderStickerSignBoard = () => {
    let imageWidth;
    if (this.props.utilsStore.deviceSize > 1200) imageWidth = 150;
    else imageWidth = 100;

    return (
      <RowContainer>
        <IconButton
          iconImage={
            this.props.currentCategory === "narration"
              ? SpeechDrama
              : SpeechDramaImage
          }
          style={{
            margin: 5,
          }}
          imageStyle={{
            width: imageWidth,
          }}
          onClick={this.handleCategory.bind(
            this,
            "narration",
            SpeechDramaAudio
          )}
        />
        <IconButton
          iconImage={
            this.props.currentCategory === "reading" ? Read : ReadImage
          }
          style={{
            margin: 5,
          }}
          imageStyle={{
            width: imageWidth,
          }}
          onClick={this.handleCategory.bind(this, "reading", ReadAudio)}
        />
      </RowContainer>
    );
  };

  render() {
    return <>{this.renderStickerSignBoard()}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(StickerSignBoard);
