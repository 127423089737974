import firebase from "../../utils/firebase";

export const updateParentAttribute = (attrType, attrValue) => {
  return async (dispatch, getState) => {
    let userDoc = firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid);
    await userDoc.update({
      [attrType]: attrValue,
    });
  };
};
