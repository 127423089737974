const initState = {
  userLanguage: "EN",
  userAuth: null,
  userLoading: true,
  user: null,
  children: [],
  selectedChild: "",
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_USER_LANG":
      return {
        ...state,
        userLanguage: action.payload.userLanguage,
      };
    case "UPDATE_USER_AUTH":
      return {
        ...state,
        userAuth: action.payload.userAuth,
        userLoading: false,
      };
    case "UPDATE_USER_LOGIN":
      return {
        ...state,
        login: action.payload.login,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload.user,
      };
    case "UPDATE_CHILDREN":
      return {
        ...state,
        children: action.payload.children,
      };
    case "UPDATE_SELECTED_CHILD":
      return {
        ...state,
        selectedChild: action.payload.selectedChild,
      };
    default:
      return state;
  }
};

export default authReducer;
