import React, { Component } from "react";
import styled from "styled-components";
//Components
import IconButton from "../../global/IconButton";
import AnimatedWrapper from "../../global/AnimatedWrapper";
//Styles
import { RowContainer } from "../../styles/GlobalStyles";
import { White } from "../../styles/Colors";
//Assets
import RightIcon from "../../assets/icons/rightIcon.png";
import LeftIcon from "../../assets/icons/leftIcon.png";
import RightMarker from "../../assets/icons/right.png";
import LeftMarker from "../../assets/icons/left.png";
//Utils
import { parentAvatarList, childAvatarList } from "../../utils/avatarList";

class AvatarSelection extends Component {
  state = {
    avatarPage: 0,
  };

  handleAvatarPage = (page) => {
    this.setState({
      avatarPage: page,
    });
  };

  renderAvatars = () => {
    let avatarList = [];

    let selectedBorderStyle = (selected) => ({
      margin: selected ? 0 : 5,
      borderColor: this.props.borderColor ? this.props.borderColor : White,
      borderWidth: selected ? 7 : 0,
      borderRadius: 100,
      borderStyle: "solid",
    });

    let avatarImageSize = {
      height: 130,
      width: 130,
      margin: 5,
    };

    if (this.props.type === "child")
      childAvatarList.map((eachAvatar, index) => {
        if (
          index >= this.state.avatarPage * 8 &&
          index < (this.state.avatarPage + 1) * 8
        )
          avatarList.push(
            <AnimatedWrapper
              key={index}
              onClick={this.props.onChange.bind(this, index)}
              style={selectedBorderStyle(this.props.selectedAvatar === index)}
            >
              <img style={avatarImageSize} src={eachAvatar} />
            </AnimatedWrapper>
          );
      });
    else
      parentAvatarList.map((eachAvatar, index) => {
        if (
          index >= this.state.avatarPage * 8 &&
          index < (this.state.avatarPage + 1) * 8
        )
          avatarList.push(
            <AnimatedWrapper
              key={index}
              onClick={this.props.onChange.bind(this, index)}
              style={selectedBorderStyle(this.props.selectedAvatar === index)}
            >
              <img style={avatarImageSize} src={eachAvatar} />
            </AnimatedWrapper>
          );
      });

    return (
      <RowContainer style={this.props.style}>
        {this.state.avatarPage === 0 ? (
          <WhiteSpace />
        ) : (
          <IconButton
            iconImage={this.props.iconType === "marker" ? LeftMarker : LeftIcon}
            imageStyle={{
              width: 80,
              height: 80,
            }}
            onClick={this.handleAvatarPage.bind(
              this,
              this.state.avatarPage - 1
            )}
          ></IconButton>
        )}
        <AvatarContainer>{avatarList}</AvatarContainer>
        {(
          this.props.type === "child"
            ? this.state.avatarPage === 3
            : this.state.avatarPage === 1
        ) ? (
          <WhiteSpace />
        ) : (
          <IconButton
            iconImage={
              this.props.iconType === "marker" ? RightMarker : RightIcon
            }
            imageStyle={{
              width: this.props.iconType === "marker" ? 80 : 60,
              height: this.props.iconType === "marker" ? 80 : 60,
            }}
            onClick={this.handleAvatarPage.bind(
              this,
              this.state.avatarPage + 1
            )}
          ></IconButton>
        )}
      </RowContainer>
    );
  };

  render() {
    return <>{this.renderAvatars()}</>;
  }
}

export default AvatarSelection;

const AvatarContainer = styled(RowContainer)`
  width: 700px;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const WhiteSpace = styled.div`
  height: 40px;
  width: 60px;
`;
