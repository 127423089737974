import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { VerticalContainer } from "../styles/GlobalStyles";
import { SubTitle4, SpecBody, SubTitle2 } from "../styles/Typography";
import { SvgYellow, SemanticBrown } from "../styles/Colors";
import Button from "../global/Button";
//Assets
import SubscriptionEndImage from "../assets/background/subscriptionEnd.png";

class SubscriptionEndModal extends Component {
  render() {
    return (
      <Overlay
        onClick={this.props.handleSubscriptionEndModal.bind(this, false)}
      >
        <InnerModal>
          <VerticalContainer
            style={{ alignItems: "flex-start", marginLeft: 60, marginTop: 10 }}
          >
            <SubTitle4>Your Subscription Has Ended</SubTitle4>
            <SpecBody style={{ color: "#888888" }}>
              A Journey of a Thousand Words Begins Here.
            </SpecBody>
          </VerticalContainer>
          <img src={SubscriptionEndImage} style={{ width: "100%" }} />
          <VerticalContainer>
            <SubTitle4>Login via u-Pustaka to continue reading.</SubTitle4>
            <Button
              buttonText="Register Now"
              style={{ backgroundColor: SvgYellow, marginTop: 10 }}
              onClick={() => {
                window.open(
                  "https://www.u-library.gov.my/portal/web/guest/home"
                );
              }}
            />
          </VerticalContainer>
        </InnerModal>
      </Overlay>
    );
  }
}

export default withRouter(SubscriptionEndModal);

const Overlay = styled(VerticalContainer)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
`;

const InnerModal = styled.div`
  position: absolute;
  width: 800px !important;
  height: 600px !important;
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${SemanticBrown};
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
`;
