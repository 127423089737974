export const PrimaryRed = "#FE5F60";
export const PrimaryGreen = "#39BDA1";
export const PrimaryPurple = "#644E9F";
export const PrimaryBrown = "#2C1610";
export const BlackBrown = "#2B1814";
export const SemanticBrown = "#FFF7E4";
export const Black = "#000";
export const PrimaryBlack = "#585858";
export const Grey = "#666";
export const LoadingGrey = "#888";
export const LightGrey = "#C4C4C4";
export const DarkGrey = "#333";
export const BorderGrey = "#818181";
export const DarkWhite = "#F5F8FA";
export const GoldYellow = "#FFD67D";
export const Yellow = "#FFCB37";
export const ModalBackground = "rgb(28, 28, 30)";
export const SvgBlue = "#3B7FC0";
export const SvgLightBlue = "#77ABD3";
export const SvgYellow = "#FFCB31";
export const White = "#FFF";
export const TintGrey = "#888888bb";
export const OverlayOpacity = "rgba(0, 0, 0, 0.4)";
export const TextOpacity = "rgba(0, 0, 0, 0.2)";
export const BookModalBackground = "#F8AC95";
