import React, { Component } from "react";
import { connect } from "react-redux";
//Assets
import UnavailableIcon from "../assets/images/login/unavailable.png";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import WindowResizer from "../components/WindowResizer";
//Components
import Button from "../global/Button";
import { PrimaryBlack, SemanticBrown } from "../styles/Colors";
//Styles
import {
  LoginContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/GlobalStyles";
import { Body, Title3 } from "../styles/Typography";

class NotFoundPage extends Component {
  handleRedirect = () => {
    this.props.history.push("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  renderContent = () => {
    return (
      <VerticalContainer
        style={{
          alignItems: "flex-start",
          width: this.props.utilsStore.deviceType <= 1 ? "100%" : "55%",
        }}
      >
        <Title3 style={{ fontWeight: 600, color: PrimaryBlack }}>
          404 - Page Not Found
        </Title3>
        <Body style={{ marginTop: 30, fontWeight: 300, color: PrimaryBlack }}>
          We looked everywhere for this page.
        </Body>
        <Body style={{ marginTop: 30, fontWeight: 300, color: PrimaryBlack }}>
          Are you sure the website URL is correct?
        </Body>
        <Button
          buttonText="Back To Home"
          style={{ marginTop: 40, width: 280 }}
          onClick={this.handleRedirect}
        />
      </VerticalContainer>
    );
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded)
      return <WindowResizer preventRedirection={true} />;

    return (
      <VerticalContainer>
        <WindowResizer preventRedirection={true} />
        <Header />
        <SecondaryHeader />
        <LoginContainer style={{ backgroundColor: SemanticBrown, height: 550 }}>
          <RowContainer
            style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }}
          >
            {this.renderContent()}
            {this.props.utilsStore.deviceType > 1 && (
              <>
                <div style={{ flexGrow: 1 }} />
                <img src={UnavailableIcon} style={{ width: "40%" }} />
              </>
            )}
          </RowContainer>
        </LoginContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(NotFoundPage);
