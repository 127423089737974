import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../index.css";
import AddChild from "../pages/AddChild";
import Book from "../pages/Book";
import BookInteraction from "../pages/BookInteraction";
import Categories from "../pages/Categories";
import CreateChild from "../pages/CreateChild";
import CreateParent from "../pages/CreateParent";
import Discover from "../pages/Discover";
import EditParent from "../pages/EditParent";
import Home from "../pages/Home";
import Library from "../pages/Library";
import Mobile from "../pages/Mobile";
import NotFoundPage from "../pages/NotFoundPage";
import ParentDashboard from "../pages/ParentDashboard";
import Profile from "../pages/Profile";
import SearchBook from "../pages/SearchBook";
import SelectedCategory from "../pages/SelectedCategory";
import Series from "../pages/Series";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Support from "../pages/Support";
import SwitchProfile from "../pages/SwitchProfile";
import Unavailable from "../pages/Unavailable";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/discover" component={Discover} />
          <Route exact path="/categories" component={Categories} />
          <Route exact path="/categories/:id" component={SelectedCategory} />
          <Route exact path="/library" component={Library} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/profile/edit" component={Profile} />
          <Route exact path="/profile/sticker" component={Profile} />
          <Route exact path="/profile/books" component={Profile} />
          <Route exact path="/profile/narration" component={Profile} />
          <Route exact path="/profile/switch" component={SwitchProfile} />
          <Route exact path="/user/dashboard" component={ParentDashboard} />
          <Route exact path="/user/edit" component={EditParent} />
          <Route exact path="/user/add" component={AddChild} />
          <Route exact path="/user/create/child" component={CreateChild} />
          <Route exact path="/user/create/parent" component={CreateParent} />
          <Route exact path="/book/:id" component={Book} />
          <Route exact path="/search/:id" component={SearchBook} />
          <Route
            exact
            path="/bookInteraction/:id"
            component={BookInteraction}
          />
          <Route exact path="/series/:id" component={Series} />
          <Route exact path="/auth/signin" component={SignIn} />
          <Route exact path="/auth/signup" component={SignUp} />
          <Route exact path="/mobile" component={Mobile} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/unavailable" component={Unavailable} />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
