import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import { Title4, SubHeadline } from "../styles/Typography";
import {
  RowContainer,
  VerticalContainer,
  Divider,
} from "../styles/GlobalStyles";
import { PrimaryRed, SemanticBrown } from "../styles/Colors";
//Components
import CategoryIcon from "../components/catalog/CategoryIcon";
import IconButton from "../global/IconButton";
import TextInput from "../global/TextInput";
import Pagination from "../global/Pagination";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
//Assets
import AllBooksIcon from "../assets/images/discover/allBooks.png";
import DiscoverIcon from "../assets/images/discover/discoverImage.png";
import HeaderImage from "../assets/images/discover/header.png";
import DiscoverCatIcon from "../assets/images/discover/discoverDashboard.png";
import SearchIcon from "../assets/icons/searchSmallIcon.png";
import LeftIcon from "../assets/icons/leftIcon.png";
import RightIcon from "../assets/icons/rightIcon.png";
//Actions
import { updateCategory } from "../store/actions/libraryActions";
import { getBooks } from "../store/actions/dataActions";

let allBooksDetails = [
  {
    image: AllBooksIcon,
    avatarImage: DiscoverCatIcon,
    id: "allBooks",
    title: "All Books",
    value: "allBooks",
    description: "Discover an interesting story for yourself",
  },
];

let categoryLimit = 5;
let bookLimit = 10;
class Discover extends Component {
  state = {
    allCategories: [],
    currentPage: 1,
    currentBookPage: 1,
    maxPage: 0,
    search: "",
    selectedCondition: null,
  };

  componentDidMount() {
    this.handleGetCategoryData();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.categories) !==
        JSON.stringify(this.props.categories) &&
      this.props.categories.length > 0
    )
      this.handleGetCategoryData();

    if (prevProps.libraryStore.category !== this.props.libraryStore.category)
      this.handlePerformBookQuery();
  }

  handlePerformBookQuery = () => {
    if (
      this.state.allCategories.length > 0 &&
      this.props.libraryStore.category
    ) {
      let selectedCategory;
      this.state.allCategories.map((eachCategory) => {
        if (eachCategory.id === this.props.libraryStore.category)
          selectedCategory = eachCategory;
      });
      if (selectedCategory) {
        if (selectedCategory.id === "allBooks") {
          this.props.getBooks(null, "", true, bookLimit);
          this.setState({
            selectedCondition: null,
          });
        } else if (selectedCategory.conditions.length > 0) {
          let condition = selectedCategory.conditions[0];
          this.props.getBooks(
            { attribute: condition.attribute, query: condition.query },
            "",
            true,
            bookLimit
          );
          this.setState({
            selectedCondition: condition,
          });
        }
      }
    }
  };

  handleChangeSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handlePagination = (type) => {
    switch (type) {
      case "minus":
        if (this.state.currentPage > 1)
          this.setState({
            currentPage: this.state.currentPage - 1,
          });
        break;
      case "plus":
        if (this.state.currentPage < this.state.maxPage)
          this.setState({
            currentPage: this.state.currentPage + 1,
          });
        break;
    }
  };

  handleBookPagination = (type) => {
    switch (type) {
      case "minus":
        if (this.state.currentBookPage > 1)
          this.setState({
            currentBookPage: this.state.currentBookPage - 1,
          });
        break;
      case "plus":
        if (
          this.props.bookPagination &&
          this.state.currentBookPage ===
            Math.ceil(this.props.books.length / bookLimit)
        ) {
          let selectedCondition = this.state.selectedCondition;
          this.props.getBooks(
            selectedCondition
              ? {
                  attribute: selectedCondition.attribute,
                  query: selectedCondition.query,
                }
              : null,
            this.props.bookPagination,
            false,
            bookLimit
          );
          this.setState({
            currentBookPage: this.state.currentBookPage + 1,
          });
        } else if (
          this.props.books.length >
          this.state.currentBookPage * bookLimit
        )
          this.setState({
            currentBookPage: this.state.currentBookPage + 1,
          });
        break;
    }
  };

  handleGetCategoryData = () => {
    if (this.props.categories && this.props.categories.length > 0) {
      let categoryList = JSON.parse(JSON.stringify(allBooksDetails));
      this.props.categories.map((eachCategory, index) => {
        if (eachCategory.discoverShow)
          categoryList.push({
            id: eachCategory.id,
            image: `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategory.id}%2Fselection_icon.png?alt=media&token=${eachCategory.selectionIcon}`,
            avatarImage: `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategory.id}%2Favatar.png?alt=media&token=${eachCategory.avatar}`,
            sound: `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategory.id}%2Fsound.mp3?alt=media&token=${eachCategory.sound}`,
            title: eachCategory.discoverName,
            value: eachCategory.id,
            description: eachCategory.description,
            conditions: eachCategory.conditions,
            updatedAt: eachCategory.updatedAt,
          });
      });

      let previousLength = this.state.allCategories.length;

      this.setState(
        {
          allCategories: categoryList,
          maxPage: Math.ceil(categoryList.length / categoryLimit),
        },
        () => {
          if (!previousLength) this.handlePerformBookQuery();
        }
      );
    }
  };

  handleCategoryIconSelected = (categoryId) => {
    this.setState({
      search: "",
      currentBookPage: 1,
    });
    this.props.updateCategory(categoryId);
  };

  handleSearchBook = () => {
    if (this.state.search)
      this.props.history.push(`/search/${this.state.search}`);
  };

  renderSelectedCategoryAvatar = () => {
    let selectedImage = DiscoverCatIcon;

    if (
      this.props.libraryStore.category &&
      this.state.allCategories.length > 0 &&
      this.props.libraryStore.category !== "allBooks"
    )
      this.state.allCategories.map((eachCategory) => {
        if (eachCategory.id === this.props.libraryStore.category)
          selectedImage = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategory.id}%2Favatar.png?alt=media&token=${eachCategory.avatar}`;
      });

    return (
      <img
        style={{
          position: "absolute",
          width: 150,
          height: 150,
          right: 10,
          bottom: 0,
          objectFit: "contain",
        }}
        src={selectedImage}
      />
    );
  };

  renderSelectedCategoryTitle = () => {
    let selectedTitle = "";

    if (this.props.libraryStore.category && this.state.allCategories.length > 0)
      this.state.allCategories.map((eachCategory) => {
        if (eachCategory.id === this.props.libraryStore.category)
          selectedTitle = eachCategory.description;
      });

    return selectedTitle;
  };

  renderEachCategory = () => {
    let categoryList = [];
    let selectedSize;
    let unselectedSize;
    if (this.props.utilsStore.deviceSize >= 1200) {
      selectedSize = 180;
      unselectedSize = 160;
    } else {
      selectedSize = 150;
      unselectedSize = 130;
    }

    this.state.allCategories.map((eachCategory, index) => {
      let conIndex = index + 1;
      if (
        conIndex > (this.state.currentPage - 1) * categoryLimit &&
        conIndex <= this.state.currentPage * categoryLimit
      ) {
        let image;
        let sound;
        if (eachCategory.id === "allBooks") image = eachCategory.image;
        else {
          image = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategory.id}%2Fselection_icon.png?alt=media&token=${eachCategory.selectionIcon}`;
          if (eachCategory.sound)
            sound = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_categories%2F${eachCategory.id}%2Fsound.mp3?alt=media&token=${eachCategory.sound}`;
        }
        categoryList.push(
          <CategoryIcon
            key={index}
            size={eachCategory.selected ? selectedSize : unselectedSize}
            image={image}
            sound={sound}
            title={eachCategory.title}
            selected={this.props.libraryStore.category === eachCategory.id}
            onClick={this.handleCategoryIconSelected.bind(
              this,
              eachCategory.id
            )}
          />
        );
      }
    });

    return categoryList;
  };

  renderDiscoverHeader = () => {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    let divHeight = divSize * 0.349;

    return (
      <VerticalContainer>
        <RowContainer
          style={{
            width: divSize,
            height: divHeight,
            backgroundImage: "url(" + HeaderImage + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          {this.renderSelectedCategoryAvatar()}

          <IconButton
            iconImage={LeftIcon}
            style={{
              margin: 10,
              marginTop: -20,
            }}
            imageStyle={{
              width: 50,
              height: 50,
              filter: this.state.currentPage === 1 ? "grayscale(100%)" : "none",
            }}
            onClick={this.handlePagination.bind(this, "minus")}
          />
          {this.renderEachCategory()}
          <IconButton
            iconImage={RightIcon}
            style={{
              margin: 10,
              marginTop: -20,
            }}
            imageStyle={{
              width: 50,
              height: 50,
              filter:
                this.state.currentPage < this.state.maxPage
                  ? "none"
                  : "grayscale(100%)",
            }}
            onClick={this.handlePagination.bind(this, "plus")}
          />
        </RowContainer>

        <RowContainer style={{ width: "95%", margin: 20 }}>
          <img
            style={{
              width: 100,
              height: 100,
              objectFit: "contain",
            }}
            src={DiscoverIcon}
          />
          <div style={{ flexGrow: 1 }} />
          {this.renderSearchBar()}
        </RowContainer>
        <RowContainer style={{ width: "95%", margin: 20 }}>
          <Title4 style={{ color: PrimaryRed }}>
            {this.renderSelectedCategoryTitle()}
          </Title4>
          <Divider
            style={{
              height: 4,
              flexGrow: 1,
              marginLeft: 10,
              marginRight: 10,
            }}
          />
        </RowContainer>
      </VerticalContainer>
    );
  };

  renderSearchBar = () => {
    return (
      <VerticalContainer style={{ alignItems: "flex-end" }}>
        <SubHeadline
          style={{
            marginBottom: 20,
            fontWeight: 600,
            textDecoration: "underline",
          }}
          onClick={() => {
            this.props.history.push("/categories");
          }}
        >
          View all Categories
        </SubHeadline>
        <RowContainer>
          <TextInput
            value={this.state.search}
            onChange={this.handleChangeSearch}
            placeholder="Search Books by Titles, Publishers "
            style={{
              width: 400,
              border: "none",
              backgroundColor: "#efefef",
              color: "#818181",
              boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          />
          <IconButton
            resizeMode="contain"
            iconImage={SearchIcon}
            style={{
              marginLeft: 20,
            }}
            imageStyle={{
              width: 50,
              height: 50,
            }}
            onClick={this.handleSearchBook}
          ></IconButton>
        </RowContainer>
      </VerticalContainer>
    );
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            backgroundColor: SemanticBrown,
          }}
        >
          {this.renderDiscoverHeader()}
          <Pagination
            type="book"
            data={this.props.books}
            limit={bookLimit}
            currentPage={this.state.currentBookPage}
            nextAvailable={
              (this.props.bookPagination &&
                this.state.currentBookPage ===
                  Math.ceil(this.props.books.length / bookLimit)) ||
              this.props.books.length > this.state.currentBookPage * bookLimit
            }
            handlePagination={this.handleBookPagination}
          />
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    books: state.dataStore.books,
    bookPagination: state.dataStore.bookPagination,
    categories: state.dataStore.categories,
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    libraryStore: state.libraryStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCategory: (category) => dispatch(updateCategory(category)),
    getBooks: (condition, startAfter, reset, bookLimit) =>
      dispatch(getBooks(condition, startAfter, reset, bookLimit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Discover);
