import React, { Component } from "react";
import styled from "styled-components";
//Component
import { Title3 } from "../styles/Typography";
import {
  PrimaryRed,
  PrimaryGreen,
  PrimaryPurple,
  SvgBlue,
  Black,
  White,
} from "../styles/Colors";
import { RowContainer } from "../styles/GlobalStyles";

class Circle extends Component {
  renderTextColor = () => {
    if (this.props.textColor) {
      switch (this.props.textColor) {
        case "green":
          return PrimaryGreen;
        case "purple":
          return PrimaryPurple;
        case "blue":
          return SvgBlue;
        case "black":
          return Black;
        case "white":
          return White;
        default:
          return PrimaryRed;
      }
    }

    return PrimaryRed;
  };

  render() {
    return (
      <CircleContainer
        style={this.props.style}
        onClick={this.props.onClick ? this.props.onClick : null}
      >
        <Title3
          style={{
            color: this.renderTextColor(),
          }}
        >
          {this.props.title}
        </Title3>
      </CircleContainer>
    );
  }
}

export default Circle;

const CircleContainer = styled(RowContainer)`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border-width: 5px;
  border-color: #fff;
  border-style: solid;
`;
