import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import {
  RowContainer,
  SemanticLayout,
  VerticalContainer,
  Divider,
} from "../styles/GlobalStyles";
import {
  SubTitle4,
  Title4,
  SpecTitle4,
  SubFootnote,
} from "../styles/Typography";
import {
  PrimaryRed,
  PrimaryBlack,
  BookModalBackground,
} from "../styles/Colors";
//Components
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import Tag from "../global/Tag";
import BookCatalog from "../global/BookCatalog";
import IconButton from "../global/IconButton";
import WindowResizer from "../components/WindowResizer";
//Assets
import InfoIcon from "../assets/icons/info.png";
import LeftIcon from "../assets/icons/leftIcon.png";
//Actions
import {
  getSeries,
  updateSearchedBooks,
} from "../store/actions/libraryActions";
import { getBooks } from "../store/actions/dataActions";

let dataLimit = 6;
class Series extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      selectedBooks: [],
    };
    this.audio = null;
  }

  componentDidMount() {
    this.handlePreviewSound();
    if (!this.props.selectedSeries) this.handleUpdateSelectedSeries();
    else this.handleSeriesBookQuery();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.selectedSeries) !==
        JSON.stringify(this.props.selectedSeries) &&
      this.props.selectedSeries
    )
      this.handleSeriesBookQuery();
  }

  handleSeriesBookQuery = () => {
    this.props.updateSearchedBooks(
      JSON.parse(JSON.stringify(this.props.selectedSeries.books))
    );
    this.setState(
      {
        loading: false,
      },
      () => {
        this.props.getBooks(
          {
            attribute: "id",
          },
          "",
          true,
          dataLimit
        );
      }
    );
  };

  handlePreviewSound = () => {
    if (this.props.selectedSeries && this.props.selectedSeries.sound) {
      let url = `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${this.props.selectedSeries.id}%2Fsound.mp3?alt=media&token=${this.props.selectedSeries.sound}`;
      this.audio = new Audio(url);
      this.audio.play();
    }
  };

  handlePagination = (type) => {
    switch (type) {
      case "minus":
        if (this.state.currentPage > 1)
          this.setState({
            currentPage: this.state.currentPage - 1,
          });
        break;
      case "plus":
        if (
          this.props.searchedBooks.length > 0 &&
          this.state.currentPage ===
            Math.ceil(this.props.books.length / dataLimit)
        ) {
          this.props.getBooks(
            {
              attribute: "id",
            },
            "",
            false,
            dataLimit
          );
          this.setState({
            currentPage: this.state.currentPage + 1,
          });
        } else if (this.props.books.length > this.state.currentPage * dataLimit)
          this.setState({
            currentPage: this.state.currentPage + 1,
          });
        break;
    }
  };

  handleUpdateSelectedSeries = () => {
    let pathNameList = this.props.location.pathname.split("/");
    if (pathNameList.length > 0) {
      let pathName = pathNameList[pathNameList.length - 1];
      this.props.getSeries(pathName);
    } else this.props.history.push("/");
  };

  renderNextAvailable = () => {
    return (
      (this.props.searchedBooks.length > 0 &&
        this.state.currentPage ===
          Math.ceil(this.props.books.length / dataLimit)) ||
      this.props.books.length > this.state.currentPage * dataLimit
    );
  };

  renderTags = () => {
    let tagList = [];
    let count = 0;
    if (
      this.props.selectedSeries &&
      this.props.selectedSeries.tags.length > 0 &&
      this.props.tags.length > 0
    )
      this.props.selectedSeries.tags.map((eachBookTag) => {
        this.props.tags.map((eachTag, index) => {
          if (
            eachBookTag === eachTag.id &&
            !eachTag.title.includes("Home - ") &&
            eachTag.title.length <= 15 &&
            count <= 1
          ) {
            tagList.push(<Tag key={index} text={eachTag.title} />);
            count += 1;
          }
        });
      });

    return tagList;
  };

  renderEachBook = () => {
    let divWidth;
    if (this.props.utilsStore.deviceSize > 1440) divWidth = 1440;
    else divWidth = this.props.utilsStore.deviceSize;

    divWidth = divWidth * 0.9;

    let previewSize = Math.floor(divWidth / 2) - 20;

    let bookView = [];
    if (this.props.selectedSeries && this.props.selectedSeries.books.length > 0)
      this.props.books.map((eachBook, index) => {
        let conIndex = index + 1;
        if (
          conIndex > (this.state.currentPage - 1) * dataLimit &&
          conIndex <= this.state.currentPage * dataLimit
        )
          bookView.push(
            <BookCatalog width={previewSize} data={eachBook} key={conIndex} />
          );
      });
    return bookView;
  };

  renderSemanticContent = () => {
    return (
      <>
        <CustomSemanticLayout>
          <SubFootnote
            style={{
              margin: "10px 30px",
              marginTop: 30,
              fontSize: 18,
              lineHeight: 1.5,
            }}
          >
            {this.props.selectedSeries && this.props.selectedSeries.description}
          </SubFootnote>
        </CustomSemanticLayout>
      </>
    );
  };

  renderSeriesInformation = () => {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    let imageWidth = divSize * 0.33;
    let imageHeight = Math.floor(imageWidth * 1.46);

    let smallDiv = divSize * 0.9;

    return (
      <RowContainer style={{ width: "90%" }}>
        <VerticalContainer
          style={{
            height: imageHeight,
            width: imageWidth,
            backgroundColor: BookModalBackground,
          }}
        >
          {this.props.selectedSeries &&
            this.props.selectedSeries.backgroundImage && (
              <img
                style={{ height: imageHeight, width: imageWidth }}
                src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${this.props.selectedSeries.id}%2FbackgroundImage.png?alt=media&token=${this.props.selectedSeries.backgroundImage}`}
              />
            )}
        </VerticalContainer>

        <VerticalContainer
          style={{
            width: smallDiv - imageWidth,
            marginTop: 30,
          }}
        >
          <VerticalContainer
            style={{
              width: "90%",
              alignItems: "flex-start",
              marginLeft: "10%",
            }}
          >
            <RowContainer style={{ marginBottom: 20 }}>
              <img src={InfoIcon} style={{ width: 40 }} />
              <SubTitle4 style={{ color: PrimaryRed, marginLeft: 10 }}>
                About this Series
              </SubTitle4>
            </RowContainer>
            <RowContainer>
              {this.props.selectedSeries && (
                <img
                  src={
                    this.props.selectedSeries.id &&
                    `https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/assets_series%2F${this.props.selectedSeries.id}%2Fimage.png?alt=media&token=${this.props.selectedSeries.image}`
                  }
                  style={{ width: 140 }}
                />
              )}
              <VerticalContainer
                style={{
                  alignItems: "flex-start",
                  marginLeft: 20,
                }}
              >
                <Title4 style={{ color: PrimaryBlack }}>
                  {this.props.selectedSeries && this.props.selectedSeries.name}
                </Title4>
                <BookTagsView>{this.renderTags()}</BookTagsView>
              </VerticalContainer>
            </RowContainer>
            {this.renderSemanticContent()}
          </VerticalContainer>
        </VerticalContainer>
      </RowContainer>
    );
  };

  renderSeriesContent = () => {
    return (
      <VerticalContainer
        style={{
          width: "90%",
          marginTop: 30,
        }}
      >
        <RowContainer
          style={{ width: "100%", marginTop: 40, marginBottom: 40 }}
        >
          <SpecTitle4 style={{ color: PrimaryRed }}>Series</SpecTitle4>
          <Divider
            style={{
              height: 8,
              flexGrow: 1,
              marginLeft: 20,
            }}
          />
        </RowContainer>
        <RowContainer
          style={{
            width: "100%",
            marginTop: 20,
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {this.renderEachBook()}
        </RowContainer>
        <RowContainer style={{ marginTop: 30, marginBottom: 50 }}>
          <IconButton
            iconImage={LeftIcon}
            imageStyle={{
              width: 40,
              height: 40,
              filter: this.state.currentPage === 1 ? "grayscale(100%)" : "none",
            }}
            onClick={this.handlePagination.bind(this, "minus")}
          />
          <IconButton
            style={{ marginLeft: 30 }}
            iconImage={LeftIcon}
            imageStyle={{
              width: 40,
              height: 40,
              filter: this.renderNextAvailable() ? "none" : "grayscale(100%)",
              transform: "rotate(180deg)",
            }}
            onClick={this.handlePagination.bind(this, "plus")}
          />
        </RowContainer>
      </VerticalContainer>
    );
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <VerticalContainer style={{ width: "100%", maxWidth: 1440 }}>
          {this.renderSeriesInformation()}
          {this.renderSeriesContent()}
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchedBooks: state.libraryStore.searchedBooks,
    selectedSeries: state.libraryStore.selectedSeries,
    series: state.dataStore.series,
    books: state.dataStore.books,
    tags: state.dataStore.tags,
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSeries: (seriesId) => dispatch(getSeries(seriesId)),
    getBooks: (condition, startAfter, reset, bookLimit) =>
      dispatch(getBooks(condition, startAfter, reset, bookLimit)),
    updateSearchedBooks: (searchedBooks) =>
      dispatch(updateSearchedBooks(searchedBooks)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Series);

const BookTagsView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 380px;
  margin-top: 20px;
`;

const CustomSemanticLayout = styled(SemanticLayout)`
  border-radius: 30px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 250px;
`;
