import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import { Title4, SpecBody } from "../styles/Typography";
import {
  Divider,
  RowContainer,
  VerticalContainer,
} from "../styles/GlobalStyles";
import { SemanticBrown, White, PrimaryRed } from "../styles/Colors";
//Components
import SecondaryHeader from "../components/SecondaryHeader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
import Pagination from "../global/Pagination";
import AnimatedWrapper from "../global/AnimatedWrapper";
import IconButton from "../global/IconButton";
import VoiceOverModal from "../global/VoiceOverModal";
//Assets
import TopBackground from "../assets/images/library/libraryTopBackground.jpg";
import FavouriteTopBackground from "../assets/images/library/favouriteTopBackground.jpg";
import VoiceOverTopBackground from "../assets/images/library/voiceOverTopBackground.jpg";
import AllMyBooks from "../assets/images/library/libraryAllBook.png";
import FavouriteIcon from "../assets/images/library/libraryFavourite.png";
import VoiceOverIcon from "../assets/images/library/libraryVoiceOver.png";
import AllBookAudio from "../assets/audio/library_all_my_books.mp3";
import LibraryFavourite from "../assets/audio/library_favourites.mp3";
import VoiceOver from "../assets/audio/library_my_voice_overs.mp3";
import SaveIcon from "../assets/icons/save.png";
//Actions
import {
  getChildAnalytics,
  getChildNarration,
} from "../store/actions/childActions";
import {
  editVoiceOver,
  deleteVoiceOver,
} from "../store/actions/libraryActions";

let analyticsLimit = 10;
let narrationLimit = 5;

class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "all",
      currentPage: 1,
      loadMore: false,
      voiceOverModal: "",
      editNarrationId: "",
      editNarrationName: "",
      editBookId: "",
      userInitialised: false,
    };
    this.audio = new Audio();
  }

  componentDidMount() {
    this.handleGetChildData();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.authStore.children) !==
        JSON.stringify(prevProps.authStore.children) &&
      !this.state.userInitialised
    )
      this.setState(
        {
          userInitialised: true,
        },
        () => {
          this.handleGetChildData();
        }
      );
  }

  handlePagination = (type) => {
    switch (type) {
      case "minus":
        if (this.state.currentPage > 1)
          this.setState({
            currentPage: this.state.currentPage - 1,
          });
        break;
      case "plus":
        if (this.state.selectedValue === "narration") {
          if (
            this.props.childNarrationPagination &&
            this.state.currentPage ===
              Math.ceil(this.props.childNarration.length / narrationLimit)
          ) {
            this.props.getChildNarration(
              this.props.authStore.selectedChild,
              this.props.childNarrationPagination,
              false,
              narrationLimit
            );
            this.setState({
              currentPage: this.state.currentPage + 1,
            });
          } else if (
            this.props.childNarration.length >
            this.state.currentPage * narrationLimit
          )
            this.setState({
              currentPage: this.state.currentPage + 1,
            });
        } else {
          if (
            this.props.childAnalyticsPagination &&
            this.state.currentPage ===
              Math.ceil(this.props.childAnalytics.length / analyticsLimit)
          ) {
            this.props.getChildAnalytics(
              this.props.authStore.selectedChild,
              this.state.selectedValue === "favourite",
              this.props.childAnalyticsPagination,
              false,
              analyticsLimit
            );
            this.setState({
              currentPage: this.state.currentPage + 1,
            });
          } else if (
            this.props.childAnalytics.length >
            this.state.currentPage * analyticsLimit
          )
            this.setState({
              currentPage: this.state.currentPage + 1,
            });
        }
        break;
    }
  };

  handleModal = (type, bool) => {
    if (type === "svgSave")
      this.setState({
        voiceOverModal: bool,
      });
  };

  handleEditVoiceOver = (name) => {
    this.props.editVoiceOver(
      name,
      this.state.editBookId,
      this.state.editNarrationId
    );
    this.handleModal("svgSave", false);
  };

  handleDeleteVoiceOver = () => {
    this.props.deleteVoiceOver(
      this.state.editBookId,
      this.state.editNarrationId
    );
    this.handleModal("svgSave", false);
  };

  handlePaginationModal = (bool, narrationId, narrationName, bookId) => {
    this.setState({
      voiceOverModal: bool,
      editNarrationId: narrationId,
      editNarrationName: narrationName,
      editBookId: bookId,
    });
  };

  handleGetChildData = () => {
    if (
      this.props.authStore.children.length > 0 &&
      this.props.authStore.selectedChild
    ) {
      this.props.authStore.children.map((eachChild) => {
        if (this.props.authStore.selectedChild === eachChild.id) {
          if (this.state.selectedValue === "narration")
            this.props.getChildNarration(
              eachChild.id,
              "",
              true,
              narrationLimit
            );
          else
            this.props.getChildAnalytics(
              eachChild.id,
              this.state.selectedValue === "favourite",
              "",
              true,
              analyticsLimit
            );
        }
      });
    }
  };

  handleCategorySelected = (value) => {
    let selectedSound = null;
    if (value === "all") selectedSound = AllBookAudio;
    else if (value === "favourite") selectedSound = LibraryFavourite;
    else selectedSound = VoiceOver;

    if (this.state.selectedValue !== value) {
      this.setState(
        {
          selectedValue: value,
          currentPage: 1,
        },
        async () => {
          this.audio = new Audio(selectedSound);
          this.audio.pause();
          this.audio.currentTime = 0;
          this.audio.play();
          this.handleGetChildData();
        }
      );
    }
  };

  renderTopCategory = (categoryValue, categoryTitle, categoryImage) => {
    return (
      <AnimatedWrapper
        style={{
          marginLeft: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onClick={this.handleCategorySelected.bind(this, categoryValue)}
      >
        <img
          src={categoryImage}
          style={{
            ...{ height: 120, width: 120 },
            ...{
              filter:
                this.state.selectedValue === categoryValue
                  ? "none"
                  : "grayscale(100%)",
            },
          }}
        />
        <DescriptionView
          style={{
            backgroundColor:
              this.state.selectedValue === categoryValue ? "#ffcb37" : "#888",
          }}
        >
          <SpecBody style={{ color: White }}>{categoryTitle}</SpecBody>
        </DescriptionView>
      </AnimatedWrapper>
    );
  };

  renderCategoryTitle = () => {
    let selectedTitle;
    switch (this.state.selectedValue) {
      case "all":
        selectedTitle = "All My Books";
        break;
      case "favourite":
        selectedTitle = "All My Favourite Books";
        break;
      case "narration":
        selectedTitle = "My Voice-over(s)";
        break;
    }

    return selectedTitle;
  };

  renderTopImageBackground = () => {
    switch (this.state.selectedValue) {
      case "favourite":
        return FavouriteTopBackground;
      case "narration":
        return VoiceOverTopBackground;
      default:
        return TopBackground;
    }
  };

  renderLibraryHeader = () => {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    let divHeight = divSize * 0.2785 * 0.9;

    return (
      <VerticalContainer>
        <VerticalContainer
          style={{
            width: divSize,
            height: divHeight,
            backgroundImage: "url(" + this.renderTopImageBackground() + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <RowContainer
            style={{
              marginRight: 30,
              marginBottom: 30,
            }}
          >
            {this.renderTopCategory("all", "All My Books", AllMyBooks)}
            {this.renderTopCategory("favourite", "Favourites", FavouriteIcon)}
            {this.renderTopCategory(
              "narration",
              "My Voice-over(s)",
              VoiceOverIcon
            )}
          </RowContainer>
        </VerticalContainer>
        <RowContainer
          style={{
            marginTop: 35,
            marginBottom: 15,
            width: "95%",
          }}
        >
          <Title4 style={{ color: PrimaryRed }}>
            {this.renderCategoryTitle()}
          </Title4>
          <Divider
            style={{
              flexGrow: 1,
              marginLeft: 20,
            }}
          />
          {this.state.selectedValue === "narration" && (
            <IconButton
              iconImage={SaveIcon}
              imageStyle={{
                width: 40,
                height: 40,
                marginLeft: 10,
                transform: "rotate(90deg)",
              }}
              onClick={() => {
                this.setState({
                  loadMore: !this.state.loadMore,
                });
              }}
            />
          )}
        </RowContainer>
      </VerticalContainer>
    );
  };

  renderPaginationType = () => {
    let type = "bookAnalytics";

    switch (this.state.selectedValue) {
      case "all":
        type = "bookAnalytics";
        break;
      case "favourite":
        type = "bookAnalyticsFavourite";
        break;
      case "narration":
        type = "narration";
      default:
        break;
    }

    return type;
  };

  renderNextAvailablePagination = () => {
    if (this.state.selectedValue === "narration")
      return (
        (this.props.childNarrationPagination &&
          this.state.currentPage ===
            Math.ceil(this.props.childNarration.length / narrationLimit)) ||
        this.props.childNarration.length >
          this.state.currentPage * narrationLimit
      );
    else
      return (
        (this.props.childAnalyticsPagination &&
          this.state.currentPage ===
            Math.ceil(this.props.childAnalytics.length / analyticsLimit)) ||
        this.props.childAnalytics.length >
          this.state.currentPage * analyticsLimit
      );
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        {this.state.voiceOverModal && (
          <VoiceOverModal
            voiceOver={this.state.voiceOverModal}
            editNarrationName={this.state.editNarrationName}
            handleModal={this.handleModal}
            onEdit={this.handleEditVoiceOver}
            onConfirm={this.handleDeleteVoiceOver}
            onCancel={this.handleModal.bind(this, "svgSave", false)}
          />
        )}
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            backgroundColor: SemanticBrown,
          }}
        >
          {this.renderLibraryHeader()}
          <Pagination
            type={this.renderPaginationType()}
            loadMore={this.state.loadMore}
            data={
              this.state.selectedValue === "narration"
                ? this.props.childNarration
                : this.props.childAnalytics
            }
            limit={
              this.state.selectedValue === "narration"
                ? narrationLimit
                : analyticsLimit
            }
            nextAvailable={this.renderNextAvailablePagination()}
            currentPage={this.state.currentPage}
            handleModal={this.handlePaginationModal}
            handlePagination={this.handlePagination}
          />
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
    childNarration: state.childStore.childNarration,
    childNarrationPagination: state.childStore.childNarrationPagination,
    childAnalytics: state.childStore.childAnalytics,
    childAnalyticsPagination: state.childStore.childAnalyticsPagination,
    dataStore: state.dataStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChildAnalytics: (childId, favourite, startAfter, reset, limit) =>
      dispatch(getChildAnalytics(childId, favourite, startAfter, reset, limit)),
    getChildNarration: (childId, startAfter, reset, limit) =>
      dispatch(getChildNarration(childId, startAfter, reset, limit)),
    editVoiceOver: (name, bookId, narrationId) =>
      dispatch(editVoiceOver(name, bookId, narrationId)),
    deleteVoiceOver: (bookId, narrationId) =>
      dispatch(deleteVoiceOver(bookId, narrationId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Library);

const DescriptionView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin: 10px 0px;
  width: 170px;
  height: 35px;
`;
