import EventBus from "js-event-bus";
import firebase from "../../utils/firebase";

const eventBus = EventBus();

//INFO : Auth Listener
let unsubscribeAuthListener = null;
let unsubscribeUserListener = null;
let unsubscribeChildrenListener = null;

export const getAuthListener = () => {
  return async (dispatch, getState) => {
    if (!unsubscribeAuthListener)
      unsubscribeAuthListener = firebase
        .auth()
        .onAuthStateChanged(function (user) {
          if (user) {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: user,
              },
            });
            getUserInfoListener(dispatch);
          } else {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: null,
              },
            });
            removeUserInfoListener();
          }
        });
  };
};

export const removeAuthListener = () => {
  return async (dispatch, getState) => {
    if (unsubscribeAuthListener) {
      unsubscribeAuthListener();
      unsubscribeAuthListener = null;
    }
    removeUserInfoListener();
  };
};

const getUserInfoListener = (dispatch) => {
  try {
    unsubscribeUserListener = firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(async (doc) => {
        if (doc) {
          dispatch({
            type: "UPDATE_USER",
            payload: {
              user: doc.data(),
            },
          });

          let selectedChild = window.localStorage.getItem("selectedChild");
          if (!selectedChild) {
            selectedChild = doc.data().children[0];
            await window.localStorage.setItem("selectedChild", selectedChild);
          }
          dispatch({
            type: "UPDATE_SELECTED_CHILD",
            payload: {
              selectedChild: selectedChild,
            },
          });

          getChildrenInfoListener(dispatch, doc.data().children);
        }
      });
  } catch (err) {}
};

const removeUserInfoListener = () => {
  if (unsubscribeUserListener) {
    unsubscribeUserListener();
    unsubscribeUserListener = null;
  }
  removeChildrenInfoListener();
};

const getChildrenInfoListener = (dispatch, childrenIdList) => {
  try {
    if (childrenIdList && childrenIdList.length > 0) {
      removeChildrenInfoListener();
      unsubscribeChildrenListener = firebase
        .firestore()
        .collection("children")
        .where("id", "in", childrenIdList)
        .onSnapshot((querySnapshot) => {
          let childrenList = [];
          if (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              childrenList.push(doc.data());
            });
          }

          dispatch({
            type: "UPDATE_CHILDREN",
            payload: {
              children: childrenList,
            },
          });
        });
    }
  } catch (err) {}
};

const removeChildrenInfoListener = () => {
  if (unsubscribeChildrenListener) {
    unsubscribeChildrenListener();
    unsubscribeChildrenListener = null;
  }
};

//================================================================
//INFO : Account Creation
export const createAccount = async (credentials) => {
  await firebase
    .auth()
    .createUserWithEmailAndPassword(credentials.email, credentials.password);
  const userUUID = firebase.auth().currentUser.uid;
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();

  await firebase
    .firestore()
    .collection("users")
    .doc(userUUID)
    .set({
      id: userUUID,
      email: firebase.auth().currentUser.email,
      state: credentials.state,
      passcode: "",
      name: "",
      role: "",
      avatar: 0,
      country: "MY",
      planType: "trial",
      children: [],
      selectedChild: "",
      subscriptionEnd: new Date(year + 1, month, day),
      pnmName: credentials.name,
      pnmUser: "guestOneYear",
    });
};

export const updateParentDetails = (details) => {
  return async (dispatch, getState) => {
    try {
      let userUUID = firebase.auth().currentUser.uid;
      let userDoc = firebase.firestore().collection("users").doc(userUUID);
      await userDoc.update({
        name: details.name,
        role: details.role,
        avatar: details.avatar,
        passcode: "1234",
      });
      getUserInfoListener(dispatch);
    } catch (err) {}
  };
};

//================================================================
//INFO : Auth Actions
export const signIn = async (credentials) => {
  await firebase
    .auth()
    .signInWithEmailAndPassword(credentials.email, credentials.password);
};

export const signOut = () => {
  return async (dispatch, getState) => {
    try {
      await firebase.auth().signOut();
      await window.localStorage.removeItem("selectedChild");
      dispatch({
        type: "UPDATE_SELECTED_CHILD",
        payload: {
          selectedChild: "",
        },
      });
      dispatch({
        type: "UPDATE_USER",
        payload: {
          user: null,
        },
      });
      dispatch({
        type: "UPDATE_CHILDREN",
        payload: {
          children: [],
        },
      });
    } catch (err) {}
  };
};

//================================================================
//INFO : Password Function
export const resetPassword = async (email) => {
  await firebase.auth().sendPasswordResetEmail(email);
};

//================================================================
//INFO : Update Local Preferences

export const updateSelectedChild = (childId) => {
  return async (dispatch, getState) => {
    try {
      await window.localStorage.setItem("selectedChild", childId);
      dispatch({
        type: "UPDATE_SELECTED_CHILD",
        payload: {
          selectedChild: childId,
        },
      });
    } catch (err) {}
  };
};

export const updateLanguagePreferences = (language) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "UPDATE_USER_LANG",
        payload: {
          userLanguage: language,
        },
      });
    } catch (err) {}
  };
};
