import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import BookImage from "../components/catalog/BookImage";
//Components
import AnimatedWrapper from "../global/AnimatedWrapper";
import Button from "../global/Button";
//Actions
import { updateSelectedBook } from "../store/actions/libraryActions";
import { PrimaryRed } from "../styles/Colors";
import {
  Divider,
  RowContainer,
  VerticalContainer,
} from "../styles/GlobalStyles";
//Styles
import { SliderIcon, SliderTitleRowContainer } from "../styles/SliderStyles";
import { Body, Headline, Title4 } from "../styles/Typography";

class BookSlider extends Component {
  handleBookSelected = (path, selectedBook) => {
    if (this.props.authStore.userAuth) {
      if (selectedBook) {
        this.props.updateSelectedBook(selectedBook);
        this.props.history.push(path + `/${selectedBook.id}`);
      } else this.props.history.push(path);
      window.scrollTo({ top: 115, behavior: "smooth" });
    } else this.props.history.push("/auth/signin");
  };

  renderBooks = () => {
    let bookCardView = [];
    let count = 0;

    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    let imageWidth = divSize * 0.15;
    let imageHeight = 240;

    if (this.props.data && this.props.data.length > 0) {
      for (let i = 0; i < this.props.data.length; i++) {
        if (this.props.type === "exclude") {
          bookCardView.push(
            <CustomAnimatedWrapper
              key={i}
              style={{ width: "15%", marginLeft: "2%", marginRight: "2%" }}
              onClick={this.handleBookSelected.bind(
                this,
                "/book",
                this.props.data[i]
              )}
            >
              <BookImage
                src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.props.data[i].id}%2FpreviewImage.png?alt=media&token=${this.props.data[i].image}`}
                width={imageWidth}
                height={imageHeight}
              />
              <Button
                buttonText="Read"
                style={{ width: "100%", marginTop: 20 }}
              />
            </CustomAnimatedWrapper>
          );
        } else {
          if (this.props.data[i].tags.includes(this.props.tagId) && count < 5) {
            bookCardView.push(
              <CustomAnimatedWrapper
                key={i}
                style={{ width: "15%", marginLeft: "2%", marginRight: "2%" }}
                onClick={this.handleBookSelected.bind(
                  this,
                  "/book",
                  this.props.data[i]
                )}
              >
                <BookImage
                  src={`https://firebasestorage.googleapis.com/v0/b/mebooks-plus.appspot.com/o/books%2F${this.props.data[i].id}%2FpreviewImage.png?alt=media&token=${this.props.data[i].image}`}
                  width={imageWidth}
                  height={imageHeight}
                />
                <Button
                  buttonText="Read"
                  style={{ width: "100%", marginTop: 20 }}
                />
              </CustomAnimatedWrapper>
            );
            count += 1;
          }
        }
      }
    }
    return bookCardView;
  };

  render() {
    return (
      <CustomVerticalContainer style={this.props.style}>
        <SliderTitleRowContainer>
          {this.props.authStore.userAuth ? (
            <>
              <SliderIcon style={this.props.iconStyle} src={this.props.image} />
              <Title4 style={{ color: PrimaryRed }}>{this.props.title}</Title4>
              <Divider
                style={{
                  height: 4,
                  flexGrow: 1,
                  marginLeft: 10,
                  marginRight: 30,
                  width: "10%",
                }}
              />
            </>
          ) : (
            <Body style={{ marginTop: 60, marginBottom: 20 }}>
              Recommended Titles for you
            </Body>
          )}
        </SliderTitleRowContainer>
        <RowContainer style={{ width: "100%" }}>
          {this.renderBooks()}
        </RowContainer>
        {this.props.authStore.userAuth ? (
          <Headline
            style={{
              marginTop: 60,
              marginBottom: 10,
              textDecoration: "underline",
            }}
            onClick={this.handleBookSelected.bind(this, "/discover", "")}
          >
            View more
          </Headline>
        ) : (
          <Space />
        )}
      </CustomVerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    dataStore: state.dataStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedBook: (bookData) => dispatch(updateSelectedBook(bookData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookSlider));

const CustomVerticalContainer = styled(VerticalContainer)`
  width: 100%;
  max-width: 1440px;
  border-bottom: solid 1px black;
`;

const CustomAnimatedWrapper = styled(AnimatedWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Space = styled.div`
  height: 100px;
`;
