import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import {
  PrimaryGreen,
  PrimaryRed,
  SvgBlue,
  PrimaryPurple,
  White,
  GoldYellow,
  SvgYellow,
} from "../styles/Colors";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { Line } from "rc-progress";
import {
  RowContainer,
  VerticalContainer,
  SemanticLayout,
} from "../styles/GlobalStyles";
import {
  Title2,
  SubTitle2,
  Title3,
  SubTitle4,
  SpecFootnote,
} from "../styles/Typography";
//Components
import Button from "../global/Button";
import AnimatedWrapper from "../global/AnimatedWrapper";
import WindowResizer from "../components/WindowResizer";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import Tag from "../global/Tag";
import Circle from "../global/Circle";
//Assets
import EditProfileCover from "../assets/background/cropStageBackground.png";
//Utils
import { childAvatarList } from "../utils/avatarList";
import { avatarSoundList } from "../utils/avatarSoundList";
import { isToday } from "../utils/miscList";
//Actions
import {
  getChildWeeklyReport,
  updateChildPriviledge,
} from "../store/actions/childActions";
import { updateSelectedChild } from "../store/actions/authActions";

let tagWidth = 130;
const buttonWidth = 300;

class ParentDashboard extends Component {
  constructor(props) {
    super(props);
    this.audio = null;
  }

  componentDidMount() {
    this.handleGetChildReport();
    this.props.updateChildPriviledge(true);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.authStore.children) !==
        JSON.stringify(prevProps.authStore.children) ||
      this.props.authStore.selectedChild !== prevProps.authStore.selectedChild
    )
      this.handleGetChildReport();
  }

  handleNavigateScreen = (childId, screenName) => {
    if (childId) {
      this.props.updateSelectedChild(childId);
      this.props.history.push(screenName);
    }
  };

  handleSelectChild = (childId, childIndex) => {
    this.props.updateSelectedChild(childId);
    let soundAvatarLength = avatarSoundList.length;
    if (childIndex <= soundAvatarLength - 1) {
      let selectedAvatarSound = avatarSoundList[childIndex];
      let selectedAvatarSoundLength = selectedAvatarSound.voiceList.length;
      let selectedSoundIndex = Math.floor(
        Math.random() * selectedAvatarSoundLength
      );
      let selectedSound = selectedAvatarSound.voiceList[selectedSoundIndex];
      this.audio = new Audio(selectedSound);
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio.play();
    }
  };

  handleGetChildReport = () => {
    if (
      this.props.authStore.selectedChild &&
      this.props.authStore.children.length > 0
    ) {
      this.props.authStore.children.map((eachChild) => {
        if (eachChild.id === this.props.authStore.selectedChild)
          this.props.getChildWeeklyReport(eachChild.id);
      });
    }
  };

  renderDailyScreenTime = (type) => {
    let totalTime = 0;
    let totalTimeString = "00 : 00";
    if (this.props.report.details && this.props.report.details.length > 0)
      this.props.report.details.map((eachDetail) => {
        if (isToday(new Date(eachDetail.date.seconds * 1000)))
          totalTime += eachDetail.screenTime;
      });

    if (totalTime >= 600) totalTimeString = "10 : 00";
    else {
      let minutes = Math.floor(totalTime / 60);
      let minutesString = "0" + minutes;
      let seconds = totalTime % 60;
      let secondString = "00";
      if (seconds < 10) secondString = "0" + seconds;
      else secondString = String(seconds);
      totalTimeString = minutesString + " : " + secondString;
    }

    if (type === "number") return totalTime;
    else return totalTimeString;
  };

  renderSelectedChildAttribute = (attribute) => {
    let childAttribute = "";

    if (
      this.props.authStore.selectedChild &&
      this.props.authStore.children.length > 0
    ) {
      this.props.authStore.children.map((eachChild, index) => {
        if (eachChild.id === this.props.authStore.selectedChild) {
          switch (attribute) {
            case "id":
              childAttribute = eachChild.id;
              break;
            case "name":
              childAttribute = eachChild.name;
              break;
            case "namePossess":
              childAttribute = eachChild.name + "'s";
              break;
            case "avatar":
              childAttribute = eachChild.avatar;
              break;
            case "points":
              childAttribute = eachChild.points;
              break;
            case "stickers":
              childAttribute = 0;
              let stickerAchived = eachChild.stickers;
              if (stickerAchived.length) childAttribute = stickerAchived.length;
              break;
            case "narrations":
              childAttribute = eachChild.narrations.length;
              break;
            case "booksRead":
              childAttribute = 0;
              let booksRead = eachChild.booksRead;
              if (booksRead.length) childAttribute = booksRead.length;
              break;
          }
        }
      });
    }

    return childAttribute;
  };

  renderMembers = () => {
    let imageSize;
    let wrapperStyle = {
      margin: 20,
    };

    if (this.props.utilsStore.deviceSize > 1200) imageSize = 130;
    else imageSize = 110;

    let childrenView = [];

    if (this.props.authStore.children.length > 0)
      this.props.authStore.children.map((eachChild, index) => {
        childrenView.push(
          <AnimatedWrapper
            style={wrapperStyle}
            onClick={this.handleSelectChild.bind(
              this,
              eachChild.id,
              eachChild.avatar
            )}
            key={index}
          >
            <VerticalContainer>
              <VerticalContainer
                style={{
                  borderRadius: imageSize,
                  border:
                    this.props.authStore.selectedChild === eachChild.id
                      ? "solid 3px white"
                      : "",
                }}
              >
                <img
                  style={{
                    width: imageSize,
                    margin: 8,
                  }}
                  src={childAvatarList[eachChild.avatar]}
                  alt="Children Avatar"
                />
              </VerticalContainer>
              <SubTitle4 style={{ color: White }}>{eachChild.name}</SubTitle4>
            </VerticalContainer>
          </AnimatedWrapper>
        );
      });

    return <>{childrenView}</>;
  };

  renderProgressBar = (type) => {
    if (type === "circle") {
      if (this.props.report) {
        let totalTime = this.renderDailyScreenTime("number");
        if (totalTime > 600) totalTime = 600;

        return Math.floor((totalTime / 600) * 100);
      } else return 0;
    } else {
      let point = this.renderSelectedChildAttribute("points");
      let percent = Math.floor(point / 100);
      return percent;
    }
  };

  renderDashboardHomeScreen = () => {
    return (
      <>
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <SpecFootnote
            style={{
              color: White,
              textDecorationLine: "underline",
            }}
          >
            My Family
          </SpecFootnote>
          <RowContainer style={{ marginTop: 10 }}>
            {this.renderMembers()}
          </RowContainer>
        </VerticalContainer>
        <SemanticLayout style={{ height: "auto" }}>
          <RowContainer
            style={{ width: "100%", marginTop: 30, marginBottom: 30 }}
          >
            <VerticalContainer style={{ width: "50%" }}>
              <RowContainer style={{ marginBottom: 40 }}>
                <img
                  style={{
                    height: 85,
                    width: 85,
                  }}
                  src={
                    childAvatarList[this.renderSelectedChildAttribute("avatar")]
                  }
                />
                <Title3 style={{ color: PrimaryGreen, margin: 10 }}>
                  {this.renderSelectedChildAttribute("namePossess")}{" "}
                  Achievements
                </Title3>
              </RowContainer>
              <Button
                style={{ width: buttonWidth }}
                buttonText="Edit Profile"
                onClick={this.handleNavigateScreen.bind(
                  this,
                  this.renderSelectedChildAttribute("id"),
                  "/profile/edit"
                )}
              />
              <div
                style={{
                  width: buttonWidth + 20,
                  height: 3,
                  backgroundColor: PrimaryGreen,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
              {/* <Button
                style={{
                  backgroundColor: PrimaryGreen,
                  width: buttonWidth,
                  margin: 5,
                }}
                buttonText="Voice Over Stories"
                onClick={this.handleNavigateScreen.bind(
                  this,
                  renderSelectedChildAttribute('id'),
                  "/profile/narration"
                )}
              /> */}
              <Button
                style={{
                  backgroundColor: SvgBlue,
                  width: buttonWidth,
                  margin: 5,
                }}
                buttonText="Books Read"
                onClick={this.handleNavigateScreen.bind(
                  this,
                  this.renderSelectedChildAttribute("id"),
                  "/profile/books"
                )}
              />
              <Button
                style={{
                  backgroundColor: PrimaryPurple,
                  width: buttonWidth,
                  margin: 5,
                }}
                buttonText="Stickers"
                onClick={this.handleNavigateScreen.bind(
                  this,
                  this.renderSelectedChildAttribute("id"),
                  "/profile/sticker"
                )}
              />
            </VerticalContainer>
            <VerticalContainer style={{ width: "50%" }}>
              <VerticalContainer style={{ width: 400, position: "relative" }}>
                <SemiCircleProgressBar
                  percentage={this.renderProgressBar("circle")}
                  stroke={PrimaryRed}
                  diameter={400}
                />
                <VerticalContainer
                  style={{
                    position: "absolute",
                  }}
                >
                  <Title2
                    style={{
                      color: PrimaryGreen,
                      marginTop: 20,
                      fontSize: 28,
                    }}
                  >
                    Today's
                  </Title2>
                  <Title2
                    style={{
                      color: PrimaryGreen,
                      margin: 5,
                      fontSize: 28,
                    }}
                  >
                    Story Time
                  </Title2>
                  <Title3
                    style={{
                      color: PrimaryRed,
                      fontSize: 44,
                    }}
                  >
                    {this.props.report
                      ? this.renderDailyScreenTime()
                      : "00 : 00"}
                  </Title3>
                  <Title2
                    style={{
                      color: PrimaryGreen,
                      marginTop: 10,
                      marginBottom: 0,
                      fontSize: 28,
                    }}
                  >
                    Of 10 minutes goals
                  </Title2>
                </VerticalContainer>
              </VerticalContainer>
              <PointBarContainer
                percent={this.renderProgressBar("bar")}
                strokeWidth="5"
                strokeColor={SvgYellow}
                trailColor={null}
              />
              <SubTitle2
                style={{
                  margin: 15,
                  marginTop: 15,
                  color: SvgYellow,
                }}
              >
                {this.renderSelectedChildAttribute("points") + " Points"}
              </SubTitle2>
              <RowContainer
                style={{
                  width: 400,
                  justifyContent: "space-between",
                }}
              >
                <VerticalContainer>
                  <Circle
                    style={{
                      borderColor: PrimaryGreen,
                      width: 80,
                      height: 80,
                    }}
                    textColor="green"
                    title={this.renderSelectedChildAttribute("narrations")}
                  />
                  <Tag width={tagWidth} text="Voice Overs" />
                </VerticalContainer>
                <VerticalContainer>
                  <Circle
                    style={{
                      borderColor: PrimaryPurple,
                      width: 80,
                      height: 80,
                    }}
                    textColor="purple"
                    title={this.renderSelectedChildAttribute("stickers")}
                  />
                  <Tag
                    width={tagWidth}
                    backgroundColor="purple"
                    text="Stickers"
                  />
                </VerticalContainer>
                <VerticalContainer>
                  <Circle
                    style={{
                      borderColor: SvgBlue,
                      width: 80,
                      height: 80,
                    }}
                    textColor="blue"
                    title={this.renderSelectedChildAttribute("booksRead")}
                  />
                  <Tag
                    width={tagWidth}
                    backgroundColor="blue"
                    text="Books Read"
                  />
                </VerticalContainer>
              </RowContainer>
            </VerticalContainer>
          </RowContainer>
        </SemanticLayout>
      </>
    );
  };

  render() {
    let divSize;
    let multiplier;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    if (this.props.utilsStore.deviceSize > 1200) multiplier = 0.75;
    else multiplier = 0.9;

    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            height: divSize * multiplier,
            position: "relative",
            backgroundImage: "url(" + EditProfileCover + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          {this.renderDashboardHomeScreen()}
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    report: state.childStore.selectedChildReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChildWeeklyReport: (childId) => dispatch(getChildWeeklyReport(childId)),
    updateSelectedChild: (childId) => dispatch(updateSelectedChild(childId)),
    updateChildPriviledge: (parentPriviledge) =>
      dispatch(updateChildPriviledge(parentPriviledge)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentDashboard);

const PointBarContainer = styled(Line)`
  width: 390px;
  height: 25px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${GoldYellow};
  border-width: 2px;
  border-style: solid;
  margin-top: 50px;
`;
