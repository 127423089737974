import React, { Component } from "react";
import { ButtonHeadline } from "../styles/Typography";
import { PrimaryRed, White } from "../styles/Colors";
import styled from "styled-components";
import { minTabletBreakpoints, RowContainer } from "../styles/GlobalStyles";
//Audio
import ButtonAudio from "../assets/audio/utils_button_audio.mp3";

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 1,
    };
    this.audio = new Audio(ButtonAudio);
    this.allTimeouts = [];
  }

  handleMouseEnter = () => {
    let count = 1;
    for (let i = 1; i >= 0.9; i = i - 0.02) {
      count += 1;
      let currentTimeout = setTimeout(() => {
        this.setState({
          scale: i,
        });
      }, count * 35);
      this.allTimeouts.push(currentTimeout);
    }
  };

  handleMouseLeave = () => {
    if (this.allTimeouts.length > 0)
      this.allTimeouts.map((eachTimeout) => {
        clearTimeout(eachTimeout);
      });
    this.allTimeouts = [];
    this.setState({
      scale: 1,
    });
  };

  handleClick = async () => {
    this.audio.pause();
    this.audio.currentTime = 0;
    this.audio.play();

    if (this.props.onClick) this.props.onClick();
  };

  render() {
    return (
      <ButtonContainer
        style={{
          ...this.props.style,
          ...{ transform: `scale(${this.state.scale})` },
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
      >
        <ButtonCustomHeadline
          style={this.props.textStyle ? this.props.textStyle : {}}
        >
          {this.props.buttonText}
        </ButtonCustomHeadline>
      </ButtonContainer>
    );
  }
}

export default Button;

const ButtonContainer = styled(RowContainer)`
  width: 200px;
  height: 50px;
  border-radius: 30px;
  background: ${PrimaryRed};
  cursor: pointer;

  @media only screen and (max-width: ${minTabletBreakpoints - 1}px) {
    width: 100px;
    height: 44px;
  }
`;

const ButtonCustomHeadline = styled(ButtonHeadline)`
  text-align: center;
  cursor: pointer;
  color: ${White};
  text-shadow-color: rgba(0, 0, 0, 0.5);
  text-shadow-offset: 1px 1px;
  text-shadow-radius: 5px;
`;
