import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import {
  VerticalContainer,
  RowContainer,
  LoginContainer,
} from "../styles/GlobalStyles";
import {
  Title2,
  SubTitle4,
  SubFootnote,
  SpecHeadline,
} from "../styles/Typography";
import { White } from "../styles/Colors";
//Components
import Button from "../global/Button";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import WindowResizer from "../components/WindowResizer";
import AnimatedWrapper from "../global/AnimatedWrapper";
//Assets
import AddIcon from "../assets/icons/addIcon.png";
import UnknownChild from "../assets/images/login/unknownChild.png";
//Utils
import { childAvatarList } from "../utils/avatarList";

class AddChild extends Component {
  handleNavigate = (type) => {
    if (type === "addChid") this.props.history.push("/user/create/child");
    if (type === "skip") this.props.history.push("/");
  };

  renderChildren = () => {
    let childView = [];
    let childrenLength = this.props.authStore.children.length;
    let addChildrenLength = 3 - childrenLength;

    if (childrenLength > 0)
      for (let i = 0; i < childrenLength; i++) {
        childView.push(
          <VerticalContainer key={i + "children"} style={{ margin: 20 }}>
            <img
              src={childAvatarList[this.props.authStore.children[i].avatar]}
              style={{ width: 155, height: 155 }}
            />
            <SubTitle4 style={{ marginTop: 20 }}>
              {this.props.authStore.children[i].name}
            </SubTitle4>
          </VerticalContainer>
        );
      }

    if (addChildrenLength > 0)
      for (let i = 0; i < addChildrenLength; i++) {
        childView.push(
          <AnimatedWrapper
            style={{ margin: 20 }}
            onClick={this.handleNavigate.bind(this, "addChid")}
            key={i + "addChildren"}
          >
            <VerticalContainer>
              <img src={UnknownChild} style={{ width: 170, height: 170 }} />
              <img src={AddIcon} style={{ width: 50, height: 50 }} />
            </VerticalContainer>
          </AnimatedWrapper>
        );
      }

    return <RowContainer>{childView}</RowContainer>;
  };

  renderInputs = () => {
    return (
      <VerticalContainer style={{ color: White, marginBottom: 120 }}>
        <Title2 style={{ marginBottom: 20 }}>Add Another Storyteller?</Title2>
        {this.renderChildren()}
        <SubFootnote style={{ margin: 40 }}>
          One Step Away to Start Storytelling Journey with Me Books
        </SubFootnote>
        <Button
          onClick={this.handleNavigate.bind(this, "skip")}
          buttonText="Skip"
        />
        <SpecHeadline style={{ marginTop: 30 }}>
          Tips : Create additional child profiles at any time later at parent’s
          dashboard.
        </SpecHeadline>
      </VerticalContainer>
    );
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <LoginContainer>
          <RowContainer style={{ width: "80%" }}>
            {this.renderInputs()}
          </RowContainer>
        </LoginContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(AddChild);
