import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//Styles
import {
  Title4,
  SpecBody,
  SubBody,
  Headline,
  SpecHeadline,
  SubHeadline,
  ButtonHeadline,
} from "../styles/Typography";
import { RowContainer, VerticalContainer } from "../styles/GlobalStyles";
import { PrimaryBrown, PrimaryGreen, White } from "../styles/Colors";
//Components
import AnimatedWrapper from "../global/AnimatedWrapper";
//Assets
import MoreIcon from "../assets/icons/moreWhiteIcon.png";
import WhiteCross from "../assets/icons/whiteCross.png";
import Library from "../assets/images/header/iconLibrary.png";
import Discover from "../assets/images/header/iconDiscover.png";
import SwitchStoryteller from "../assets/audio/achievement_switch_storyteller.mp3";
//Utils
import { childAvatarList } from "../utils/avatarList";
import { avatarSoundList } from "../utils/avatarSoundList";

class SecondaryHeader extends Component {
  constructor(props) {
    super(props);
    this.audio = null;
    this.switchAudio = null;
  }

  handleNavigate = (type, audio) => {
    let pathNameList = this.props.location.pathname.split("/");
    let pathName = "";
    if (pathNameList.length > 0)
      pathName = pathNameList[pathNameList.length - 1];

    if (pathName !== type) {
      if (audio) {
        this.switchAudio = new Audio(audio);
        this.switchAudio.pause();
        this.switchAudio.currentTime = 0;
        this.switchAudio.play();
      } else {
        if (
          this.props.authStore.selectedChild &&
          this.props.authStore.children.length > 0
        ) {
          let selectedAvatar = 0;
          this.props.authStore.children.map((eachChild) => {
            if (eachChild.id === this.props.authStore.selectedChild)
              selectedAvatar = eachChild.avatar;
          });

          let soundAvatarLength = avatarSoundList.length;
          if (selectedAvatar <= soundAvatarLength - 1) {
            let selectedAvatarSound = avatarSoundList[selectedAvatar];
            let selectedAvatarSoundLength =
              selectedAvatarSound.voiceList.length;
            let selectedSoundIndex = Math.floor(
              Math.random() * selectedAvatarSoundLength
            );
            let selectedSound =
              selectedAvatarSound.voiceList[selectedSoundIndex];
            this.audio = new Audio(selectedSound);
            this.audio.pause();
            this.audio.currentTime = 0;
            this.audio.play();
          }
        }
      }

      if (type === "") this.props.history.push("/");
      else if (type === "switch") this.props.history.push("/profile/switch");
      else if (type === "discover") this.props.history.push("/discover");
      else if (type === "library") this.props.history.push("/library");
      else if (type === "profile") this.props.history.push("/profile");
    }
  };

  renderSelectedChildAvatar = () => {
    if (
      this.props.authStore.selectedChild &&
      this.props.authStore.children.length > 0
    ) {
      let selectedAvatar = 0;
      this.props.authStore.children.map((eachChild) => {
        if (eachChild.id === this.props.authStore.selectedChild)
          selectedAvatar = eachChild.avatar;
      });

      return (
        <HeaderImage
          src={childAvatarList[selectedAvatar]}
          style={{ marginLeft: 50, width: 55, marginTop: 5 }}
        />
      );
    }
  };

  renderSelectedChildName = () => {
    let childName = "";

    if (
      this.props.authStore.selectedChild &&
      this.props.authStore.children.length > 0
    ) {
      this.props.authStore.children.map((eachChild) => {
        if (eachChild.id === this.props.authStore.selectedChild)
          childName = eachChild.name;
      });
    }

    return childName;
  };

  renderLoginSubHeader = () => {
    let pathNameList = this.props.location.pathname.split("/");
    let pathName = "";
    if (pathNameList.length > 0)
      pathName = pathNameList[pathNameList.length - 1];

    return (
      <>
        <RowContainer>
          <img src={MoreIcon} style={{ marginLeft: 20 }} />
          <AnimatedWrapper
            onClick={this.handleNavigate.bind(this, "profile", null)}
          >
            {this.renderSelectedChildAvatar()}
          </AnimatedWrapper>
          <VerticalContainer
            style={{ marginLeft: 20, alignItems: "flex-start" }}
          >
            <SpecBody
              style={{ color: White, cursor: "pointer" }}
              onClick={this.handleNavigate.bind(this, "profile")}
              style={{ color: pathName === "profile" ? PrimaryGreen : White }}
            >
              {this.renderSelectedChildName() + "'s Profile"}
            </SpecBody>
            <SubHeadline
              style={{
                color: pathName === "switch" ? PrimaryGreen : White,
                textDecoration: "underline",
                marginTop: -5,
              }}
              onClick={this.handleNavigate.bind(
                this,
                "switch",
                SwitchStoryteller
              )}
            >
              Switch Profile
            </SubHeadline>
          </VerticalContainer>
        </RowContainer>
        <div style={{ flexGrow: 1 }} />
        <AnimatedWrapper
          style={{ marginRight: 40, marginTop: 10 }}
          onClick={this.handleNavigate.bind(this, "library", null)}
        >
          <RowContainer>
            <HeaderImage src={Library} />
            <SpecBody
              style={{
                color: pathName === "library" ? PrimaryGreen : White,
                marginLeft: 10,
              }}
            >
              My Library
            </SpecBody>
          </RowContainer>
        </AnimatedWrapper>
        <AnimatedWrapper
          style={{ marginTop: 10 }}
          onClick={this.handleNavigate.bind(this, "discover", null)}
        >
          <RowContainer>
            <HeaderImage src={Discover} />
            <SpecBody
              style={{
                color: White,
                marginLeft: 10,
                color: pathName === "discover" ? PrimaryGreen : White,
              }}
            >
              Discover More Books
            </SpecBody>
          </RowContainer>
        </AnimatedWrapper>
      </>
    );
  };

  renderActions = () => {
    if (this.props.narrationModal) {
      if (this.props.narrationModal === "narration")
        return this.props.handleModal.bind(this, "narration", false);
      else return this.props.handleModal.bind(this, "narration", "narration");
    } else return this.props.handleExitBook;
  };

  renderActionTitle = () => {
    let title = "";
    if (this.props.narrationModal) {
      if (this.props.narrationModal === "narration") title = "Close Modal";
      else title = "Close Voice-Over List";
    } else title = "Exit Book";

    return title;
  };

  renderContent = () => {
    if (this.props.location.pathname.includes("bookInteraction/"))
      return (
        <>
          <Title4 style={{ color: White, position: "absolute" }}>
            {this.props.selectedBook && this.props.selectedBook.title}
          </Title4>
          <AnimatedWrapper onClick={this.renderActions()}>
            <RowContainer>
              <img src={WhiteCross} style={{ width: 30 }} />
              <Headline style={{ color: White, marginLeft: 10 }}>
                {this.renderActionTitle()}
              </Headline>
            </RowContainer>
          </AnimatedWrapper>
          <div style={{ flexGrow: 1 }} />
          <ButtonHeadline
            style={{ color: White, cursor: "pointer" }}
            onClick={this.props.handleModal.bind(this, "video", true)}
          >
            Tutorial?
          </ButtonHeadline>
        </>
      );
    else {
      return (
        <>
          <VerticalContainer
            style={{
              alignItems: "flex-end",
              marginLeft: 15,
              cursor: "pointer",
            }}
            onClick={this.handleNavigate.bind(this, "", null)}
          >
            <Title4 style={{ color: White }}>Me Books</Title4>
            <SpecHeadline style={{ color: White, marginTop: -5 }}>
              Desktop
            </SpecHeadline>
          </VerticalContainer>
          {this.props.authStore.userAuth &&
          this.props.authStore.user &&
          this.props.authStore.user.name &&
          this.props.authStore.children.length > 0 ? (
            this.renderLoginSubHeader()
          ) : (
            <>
              <div style={{ flexGrow: 1 }} />
              <SubBody style={{ color: White }}>
                {this.props.screen
                  ? this.props.screen
                  : "For Every Child in Malaysia"}
              </SubBody>
            </>
          )}
        </>
      );
    }
  };

  render() {
    if (this.props.utilsStore.deviceType <= 1) return null;

    return (
      <HeaderContainer style={{ height: 70 }}>
        <RowContainer
          style={{
            width: "95%",
            maxWidth: 1368,
          }}
        >
          {this.renderContent()}
        </RowContainer>
      </HeaderContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBook: state.libraryStore.selectedBook,
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(withRouter(SecondaryHeader));

const HeaderContainer = styled(RowContainer)`
  width: 100%;
  background-color: ${PrimaryBrown};
`;

const HeaderImage = styled.img`
  width: 70px;
  cursor: pointer;
`;
