import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Button from "../global/Button";
import Loading from "../global/Loading";
import TextInput from "../global/TextInput";
//Actions
import { resetPassword, signIn } from "../store/actions/authActions";
import { PrimaryRed } from "../styles/Colors";
import { VerticalContainer } from "../styles/GlobalStyles";
//Styles
import { Footnote, SubHeadline, Title2 } from "../styles/Typography";

class SignInComponent extends Component {
  state = {
    signInOption: "signIn",
    loading: false,
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
  };

  handleSignInOption = (type) => {
    this.setState({
      signInOption: type,
    });
  };

  handleLoading = (bool) => {
    this.setState({
      loading: bool,
    });
  };

  handleSubmit = () => {
    this.setState(
      {
        emailError: "",
        passwordError: "",
      },
      () => {
        this.handleLoading(true);
        if (this.state.signInOption === "signIn") {
          this.handleCheckCondition("email");
          this.handleCheckCondition("password");
        } else {
          this.handleCheckCondition("email");
        }

        setTimeout(async () => {
          if (this.state.signInOption === "signIn") {
            if (
              this.state.emailError === "" &&
              this.state.passwordError === ""
            ) {
              try {
                await signIn({
                  email: this.state.email,
                  password: this.state.password,
                });
              } catch (err) {
                this.handleLoading(false);
                this.setState({
                  passwordError: err.message,
                });
              }
            } else this.handleLoading(false);
          } else if (this.state.signInOption === "forgot") {
            if (this.state.emailError === "") {
              try {
                resetPassword(this.state.email);
                this.handleLoading(false);
                this.setState({
                  emailError:
                    "Instructions to reset your password have been sent to your email.",
                });
              } catch (err) {
                this.handleLoading(false);
                this.setState({
                  emailError: err.message,
                });
              }
            } else this.handleLoading(false);
          }
        }, 250);
      }
    );
  };

  handleCheckCondition = (type) => {
    switch (type) {
      case "email":
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(this.state.email))
          this.setState({
            emailError: "Email has been registed/ Invalid email address.",
          });
        break;
      case "password":
        if (
          this.state.password.includes(" ") ||
          this.state.password.includes(",") ||
          this.state.password.length < 6
        )
          this.setState({
            passwordError: "Please enter a valid password",
          });
        else
          this.setState({
            passwordError: "",
          });
        break;
      default:
        break;
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  renderOptionsContent = () => {
    if (this.state.signInOption === "signIn") {
      return (
        <>
          <TextInput
            style={{ width: 275, height: 50, marginTop: 10 }}
            value={this.state.email}
            placeholder="Email Address"
            onChange={this.handleChange}
            id="email"
            autoCapitalize="none"
          />
          <Footnote
            style={{
              marginTop: 5,
              marginBottom: this.state.emailError ? 10 : 0,
              marginLeft: 15,
              color: PrimaryRed,
              fontSize: 12,
            }}
          >
            {this.state.emailError}
          </Footnote>
          <TextInput
            style={{ width: 275, height: 50, marginTop: 5 }}
            value={this.state.password}
            placeholder="Password"
            onChange={this.handleChange}
            id="password"
            autoCapitalize="none"
            type="password"
          />
          <Footnote
            style={{
              marginTop: 5,
              marginBottom: this.state.passwordError ? 10 : 0,
              marginLeft: 15,
              color: PrimaryRed,
              fontSize: 12,
              maxWidth: 300,
            }}
          >
            {this.state.passwordError}
          </Footnote>
          <Button
            buttonText="Log In"
            style={{
              width: 150,
              alignSelf: "flex-end",
              height: 50,
              marginTop: 5,
            }}
            textStyle={{
              fontSize: 23,
            }}
            onClick={this.handleSubmit}
          />
          <SubHeadline
            style={{ textDecoration: "underline", marginTop: 20 }}
            onClick={this.handleSignInOption.bind(this, "forgot")}
          >
            Forgot Your Password?
          </SubHeadline>
        </>
      );
    } else if (this.state.signInOption === "forgot")
      return (
        <>
          <TextInput
            style={{ width: 275, height: 50, marginTop: 10 }}
            value={this.state.email}
            placeholder="Email Address"
            onChange={this.handleChange}
            id="email"
            autoCapitalize="none"
          />
          <Footnote
            style={{
              marginTop: 5,
              marginBottom: this.state.emailError ? 10 : 0,
              marginLeft: 15,
              color: PrimaryRed,
              fontSize: 12,
            }}
          >
            {this.state.emailError}
          </Footnote>
          <Button
            buttonText="Reset Password"
            style={{
              width: 150,
              alignSelf: "flex-end",
              height: 50,
              marginTop: 5,
            }}
            textStyle={{
              fontSize: 23,
            }}
            onClick={this.handleSubmit}
          />
          <SubHeadline
            style={{ textDecoration: "underline", marginTop: 20 }}
            onClick={this.handleSignInOption.bind(this, "signIn")}
          >
            Back
          </SubHeadline>
        </>
      );
  };

  renderTitle = () => {
    let title;
    if (this.state.signInOption === "forgot") title = "Forgot Password";
    else title = "Log in/Log Masuk";
    return title;
  };

  renderSignInOptions = () => {
    return (
      <VerticalContainer style={{ alignItems: "flex-start" }}>
        <Title2 style={{ marginBottom: 20, fontSize: 30 }}>
          {this.renderTitle()}
        </Title2>
        {this.renderOptionsContent()}
      </VerticalContainer>
    );
  };

  render() {
    if (
      this.props.authStore.userAuth &&
      this.props.authStore.user &&
      this.props.authStore.user.name
    )
      return <Redirect to="/" />;
    else if (
      this.props.authStore.userAuth &&
      this.props.authStore.user &&
      !this.props.authStore.user.name
    )
      return <Redirect to="/user/create/parent" />;

    return (
      <VerticalContainer>
        {this.state.loading && <Loading />}
        {this.renderSignInOptions()}
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(SignInComponent);
