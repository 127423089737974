import React, { Component } from "react";
import styled from "styled-components";

class TextInput extends Component {
  render() {
    return (
      <InputText
        id={this.props.id}
        style={this.props.style ? this.props.style : {}}
        value={this.props.value}
        onChange={this.props.onChange}
        placeholder={this.props.placeholder}
        maxLength={this.props.maxLength}
        type={this.props.type}
      />
    );
  }
}

export default TextInput;

const InputText = styled.input`
  width: 240px;
  height: 55px;
  border-radius: 30px;
  font-family: proximaNova;
  padding-left: 20px;
  font-size: 16px;
  border: 1px #818181 solid;
`;
