import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Styles
import { SubTitle2, Title3, Footnote } from "../styles/Typography";
import { PrimaryRed, White } from "../styles/Colors";
import {
  VerticalContainer,
  RowContainer,
  SemanticLayout,
  minLaptopBreakpoints,
  minTabletBreakpoints,
} from "../styles/GlobalStyles";
//Components
import WindowResizer from "../components/WindowResizer";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import AvatarSelection from "../global/loginComponents/AvatarSelection";
import TextInput from "../global/TextInput";
import Button from "../global/Button";
//Assets
import EditProfileCover from "../assets/background/cropStageBackground.png";
import ErrorSound from "../assets/audio/utils_error_audio.mp3";
//Utils
import { parentAvatarList } from "../utils/avatarList";
//Actions
import { updateParentAttribute } from "../store/actions/parentActions";

class EditParentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameError: "",
      selectedAvatar: 0,
    };
    this.errorAudio = new Audio(ErrorSound);
  }

  componentDidMount() {
    if (this.props.authStore.user) this.handleParentAvatar();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.authStore.user) !==
        JSON.stringify(prevProps.authStore.user) &&
      this.props.authStore.user
    )
      this.handleParentAvatar();
  }

  handleParentAvatar = () => {
    this.setState({
      selectedAvatar: this.renderParentAttribute("avatar"),
    });
  };

  handleSubmit = (type) => {
    this.setState({
      nameError: "",
    });

    if (!this.props.authStore.user) return;

    switch (type) {
      case "name":
        if (this.state.name === "") {
          this.errorAudio.currentTime = 0;
          this.errorAudio.play();
          this.setState({
            nameError: "Please enter your first name",
          });
        } else {
          this.props.updateParentAttribute("name", this.state.name);
          this.setState({
            name: "",
          });
        }
        break;
    }
  };

  handleChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleAvatarSelected = (avatarIndex) => {
    if (this.props.authStore.user) {
      this.setState(
        {
          selectedAvatar: avatarIndex,
        },
        () => {
          this.props.updateParentAttribute("avatar", this.state.selectedAvatar);
        }
      );
    }
  };

  renderParentAttribute = (attribute) => {
    let parentAttribute = "";
    if (this.props.authStore.user) {
      switch (attribute) {
        case "name":
          parentAttribute = this.props.authStore.user.name;
          break;
        case "avatar":
          parentAttribute = this.props.authStore.user.avatar;
          break;
      }
    }

    return parentAttribute;
  };

  renderBackgroundAttributes = (attribute) => {
    if (attribute === "backgroundSize") {
      if (this.props.utilsStore.deviceSize >= minLaptopBreakpoints)
        return "contain";
      else if (this.props.utilsStore.deviceSize >= minTabletBreakpoints)
        return "cover";
      else return "contain";
    } else if (attribute === "margin") {
      if (this.props.utilsStore.deviceSize >= minLaptopBreakpoints) return 250;
      else return this.props.utilsStore.deviceSize * 0.25;
    } else return this.props.utilsStore.deviceSize / 1.319;
  };

  renderMainContent = () => {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    let imageSize;
    if (divSize > 1200) imageSize = 120;
    else imageSize = 100;

    return (
      <>
        <RowContainer style={{ marginBottom: 30 }}>
          <VerticalContainer>
            <img
              src={parentAvatarList[this.renderParentAttribute("avatar")]}
              style={{
                height: imageSize,
                width: imageSize,
              }}
            />
            <Title3 style={{ color: "#fff", margin: 10 }}>
              {this.renderParentAttribute("name")}
            </Title3>
          </VerticalContainer>
          <RowContainer>
            <VerticalContainer style={{ marginLeft: 30 }}>
              <TextInput
                style={{ width: 300 }}
                value={this.state.name}
                placeholder="First Name"
                onChange={this.handleChange}
                autoCapitalize="none"
                maxLength={15}
              />
              {this.state.nameError && (
                <Footnote style={{ color: PrimaryRed }}>
                  {this.state.nameError}
                </Footnote>
              )}
            </VerticalContainer>

            <Button
              style={{
                width: 250,
                marginTop: 10,
                marginLeft: 30,
              }}
              buttonText="Edit Profile Name"
              onClick={this.handleSubmit.bind(this, "name")}
            />
          </RowContainer>
        </RowContainer>
        <SemanticLayout style={{ height: "auto", maxWidth: 1000 }}>
          <SubTitle2
            style={{
              color: "#39BDA1",
              marginBottom: 10,
              marginTop: 50,
            }}
          >
            Change Avatar
          </SubTitle2>
          <AvatarSelection
            style={{ marginBottom: 50 }}
            type="parent"
            onChange={this.handleAvatarSelected}
            selectedAvatar={this.state.selectedAvatar}
            borderColor={PrimaryRed}
            tintColor={PrimaryRed}
          />
        </SemanticLayout>
      </>
    );
  };

  render() {
    let divSize;
    if (this.props.utilsStore.deviceSize > 1440) divSize = 1440;
    else divSize = this.props.utilsStore.deviceSize;

    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    return (
      <VerticalContainer>
        <WindowResizer />
        <Header />
        <SecondaryHeader />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1440,
            height: divSize * 0.75,
            backgroundImage: "url(" + EditProfileCover + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          {this.renderMainContent()}
        </VerticalContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateParentAttribute: (attrType, attrValue) =>
      dispatch(updateParentAttribute(attrType, attrValue)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditParentProfile);
