export default [
  {
    id: 1,
    value: "SGR",
    name: "Selangor",
  },
  {
    id: 2,
    value: "KUL",
    name: "Kuala Lumpur",
  },
  {
    id: 3,
    value: "JHR",
    name: "Johor",
  },
  {
    id: 4,
    value: "SWK",
    name: "Sarawak",
  },
  {
    id: 5,
    value: "SBH",
    name: "Sabah",
  },
  {
    id: 6,
    value: "PRK",
    name: "Perak",
  },
  {
    id: 7,
    value: "PNG",
    name: "Penang",
  },
  {
    id: 8,
    value: "KDH",
    name: "Kedah",
  },
  {
    id: 9,
    value: "PHG",
    name: "Pahang",
  },
  {
    id: 11,
    value: "NSN",
    name: "Negeri Sembilan",
  },
  {
    id: 12,
    value: "KTN",
    name: "Kelantan",
  },
  {
    id: 13,
    value: "TRG",
    name: "Terengganu",
  },
  {
    id: 14,
    value: "MLK",
    name: "Melaka",
  },
  {
    id: 15,
    value: "PJY",
    name: "Putrajaya",
  },
  {
    id: 16,
    value: "PLS",
    name: "Perlis",
  },
  {
    id: 17,
    value: "LBN",
    name: "Labuan",
  },
];
