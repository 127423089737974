import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//Assets
import LoginIcon from "../assets/images/login/strongMan.png";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SecondaryHeader from "../components/SecondaryHeader";
import WindowResizer from "../components/WindowResizer";
//Components
import Button from "../global/Button";
import DropDown from "../global/DropDown";
import Loading from "../global/Loading";
import AvatarSelection from "../global/loginComponents/AvatarSelection";
import TextInput from "../global/TextInput";
//Actions
import { updateParentDetails } from "../store/actions/authActions";
import { PrimaryRed, White } from "../styles/Colors";
//Styles
import {
  LoginContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/GlobalStyles";
import { Footnote, Title2 } from "../styles/Typography";

class CreateParent extends Component {
  state = {
    page: 0,
    name: "",
    nameError: "",
    role: "Daddy",
    selectedAvatar: 0,
    loading: false,
  };

  handleSelect = (role) => {
    this.setState({
      role: role,
    });
  };

  handleSubmit = () => {
    switch (this.state.page) {
      case 0:
        this.setState(
          {
            nameError: "",
          },
          () => {
            this.handleCheckCondition("name");
            setTimeout(() => {
              if (this.state.nameError === "")
                this.setState({
                  page: 1,
                });
            }, 250);
          }
        );
        break;
      case 1:
        this.setState({
          loading: true,
        });
        this.props.updateParentDetails({
          name: this.state.name,
          role: this.state.role,
          avatar: this.state.selectedAvatar,
        });
        break;
    }
  };

  handleCheckCondition = (type) => {
    switch (type) {
      case "name":
        if (this.state.name.length <= 0)
          this.setState({
            nameError: "Please enter your name",
          });
        break;
      default:
        break;
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleAvatarSelected = (index) => {
    this.setState({
      selectedAvatar: index,
    });
  };

  renderInputs = () => {
    return (
      <VerticalContainer
        style={{
          alignItems: "flex-start",
          color: White,
        }}
      >
        <Title2 style={{ marginBottom: 20, fontSize: 30 }}>
          Parents Profile
        </Title2>
        <VerticalContainer>
          <TextInput
            style={{ width: 300, height: 50 }}
            containerStyle={{ marginTop: 5, marginBottom: 5 }}
            id="name"
            value={this.state.name}
            onChange={this.handleChange}
            placeholder="Your First Name"
          />
          <Footnote style={{ marginTop: 5, color: PrimaryRed, fontSize: 15 }}>
            {this.state.nameError}
          </Footnote>
        </VerticalContainer>
        <div style={{ margin: 10 }} />
        <VerticalContainer style={{ marginBottom: 20 }}>
          <DropDown
            width={220}
            type="Role"
            option={this.state.role}
            options={[
              { value: "Daddy", name: "Daddy" },
              { value: "Mommy", name: "Mommy" },
              { value: "Guardian", name: "Guardian" },
            ]}
            selectedName={this.state.role}
            handleSelect={this.handleSelect}
          />
        </VerticalContainer>
        <Button onClick={this.handleSubmit} buttonText="Next" />
      </VerticalContainer>
    );
  };

  renderPage = () => {
    if (this.state.page === 1)
      return (
        <VerticalContainer style={{ marginBottom: 160 }}>
          <Title2 style={{ color: White, fontSize: 30 }}>
            Parents’ Avatar
          </Title2>
          <AvatarSelection
            onChange={this.handleAvatarSelected}
            selectedAvatar={this.state.selectedAvatar}
            iconType="marker"
          />
          <RowContainer style={{ marginTop: 40 }}>
            <Button onClick={this.handleSubmit} buttonText={"Next"} />
          </RowContainer>
        </VerticalContainer>
      );
    else
      return (
        <RowContainer
          style={{
            width: "70%",
            alignItems: "flex-start",
          }}
        >
          {this.renderInputs()}
          <div style={{ flexGrow: 1 }} />
          <img src={LoginIcon} style={{ width: "55%" }} />
        </RowContainer>
      );
  };

  render() {
    if (this.props.authStore.userLoading) return null;
    if (!this.props.utilsStore.fontLoaded) return <WindowResizer />;
    if (!this.props.authStore.userAuth) return <Redirect to="/" />;

    if (
      this.props.authStore.user &&
      this.props.authStore.user.name &&
      this.props.authStore.children.length > 0
    )
      return <Redirect to="/" />;
    else if (
      this.props.authStore.user &&
      this.props.authStore.user.name &&
      this.props.authStore.children.length === 0
    )
      return <Redirect to="/user/create/child" />;

    return (
      <VerticalContainer>
        {this.state.loading && <Loading />}
        <WindowResizer />
        <Header />
        <SecondaryHeader screen="Register a Parent Account" />
        <LoginContainer style={{ height: this.state.page === 0 ? 600 : null }}>
          {this.renderPage()}
        </LoginContainer>
        <Footer />
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateParentDetails: (details) => dispatch(updateParentDetails(details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateParent);
