import React, { Component } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { VerticalContainer } from "../styles/GlobalStyles";

class VideoModal extends Component {
  render() {
    return (
      <Overlay onClick={this.props.handleHideModal}>
        <CustomVideo
          url={"https://www.youtube.com/watch?v=LbfgHB8Oe_U"}
          onEnded={this.props.handleHideModal}
          controls
          type="video/mp4"
        ></CustomVideo>
      </Overlay>
    );
  }
}

export default VideoModal;

const CustomVideo = styled(ReactPlayer)`
  width: 1000px !important;
  height: 600px !important;
`;

const Overlay = styled(VerticalContainer)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
`;
