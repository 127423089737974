const initState = {
  books: [],
  bookPagination: "",
  series: [],
  categories: [],
  collections: [],
  tags: [],
  roles: [],
  stickers: [],
  stickerPagination: "",
  banners: [],
  homeItems: null,
  homeLoginItems: null,
};

const dataReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_BOOKS_DATA":
      return {
        ...state,
        books: action.payload.data,
        bookPagination: action.payload.bookPagination,
      };
    case "UPDATE_SERIES_DATA":
      return {
        ...state,
        series: action.payload.data,
      };
    case "UPDATE_CATEGORIES_DATA":
      return {
        ...state,
        categories: action.payload.data,
      };
    case "UPDATE_COLLECTIONS_DATA":
      return {
        ...state,
        collections: action.payload.data,
      };
    case "UPDATE_TAGS_DATA":
      return {
        ...state,
        tags: action.payload.data,
      };
    case "UPDATE_ROLES_DATA":
      return {
        ...state,
        roles: action.payload.data,
      };
    case "UPDATE_STICKERS_DATA":
      return {
        ...state,
        stickers: action.payload.data,
        stickerPagination: action.payload.stickerPagination,
      };
    case "UPDATE_BANNERS_DATA":
      return {
        ...state,
        banners: action.payload.data,
      };
    case "UPDATE_HOME_DATA":
      return {
        ...state,
        homeItems: action.payload.homeItems,
      };
    case "UPDATE_HOME_LOGIN_DATA":
      return {
        ...state,
        homeLoginItems: action.payload.homeLoginItems,
      };
    default:
      return state;
  }
};

export default dataReducer;
